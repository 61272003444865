import {
  createContext, ReactNode, useContext, useEffect, useState
} from 'react';
import { t } from 'i18next';
import ThemeVariants from '../../constants/theme';
import { THEME } from '../../constants/storage';
import useLastStateUpdateStore from './useLastStateUpdateStore';
import { setToLocalStorage } from '../storageWorks';

type ModeType = ThemeVariants;

type ContextType = {
  theme: ModeType;
  previousTheme: ModeType;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ContextType | null>(null);

interface IThemeContextProvider {
  children: ReactNode,
  themeState: ModeType
}

const ThemeContextProvider = ({ children, themeState }: IThemeContextProvider): ReactNode => {
  const [theme, setTheme] = useState<ModeType>(ThemeVariants.LIGHT_MODE);
  const [previousTheme, setPreviousTheme] = useState<ModeType>(ThemeVariants.LIGHT_MODE);
  const { updateStoreHandler } = useLastStateUpdateStore();

  useEffect(() => {
    if (themeState) {
      setPreviousTheme(themeState);
      setTheme(themeState);
    } else {
      setPreviousTheme(ThemeVariants.LIGHT_MODE as ModeType);
      setTheme(ThemeVariants.LIGHT_MODE as ModeType);
    }
  }, [themeState]);
  function toggleTheme() {
    const newValue = themeState === ThemeVariants.LIGHT_MODE || !themeState
      ? ThemeVariants.DARK_MODE : ThemeVariants.LIGHT_MODE;
    updateStoreHandler(THEME, newValue);
    setPreviousTheme(themeState as ModeType);
    setToLocalStorage('theme', newValue);
    setTheme(newValue as ModeType);
  }

  const context = { theme, previousTheme, toggleTheme };

  return <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>;
};

export const useTheme = (): ContextType => {
  const themeContext = useContext(ThemeContext);

  if (themeContext === null) {
    throw new Error(t('themeContextNotFound'));
  }

  return themeContext;
};
export default ThemeContextProvider;
