import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import $api, { REACT_APP_PUBLIC_ALERTS } from '../../../http';
import {
  ALERTS_NOTIFICATION_PATH,
  DASHBOARD_FOLDERS,
  DASHBOARD_ITEM_CHANGE_ORDER,
  DASHBOARD_ITEM_CHANGE_ORDER_FOLDER,
  DASHBOARD_ITEMS, LINK_TO_WATCHLIST, UNLINK_FROM_WATCHLIST
} from '../../../constants/paths';
import { getBodyRequest, typeHandler } from '../../../utils/helperNewScreenModal';
import {
  FILTER_SETTINGS, TABLE_SCREEN_PATH, TEMPLATE_TYPE, VIEW_TYPES
} from '../../../constants/screener';
import {
  AddSymbolToWLArgs,
  ICreateArgs, ICreateDashboardArgs, IDuplicateDashboardArgs,
  IDuplicatePresetArgs, ILinkUnlinkArgs,
  IMoveToArgs,
  IRemoveArgs, IRemoveDashboardArgs,
  IRemoveFromArgs,
  ISaveScreenToColumnsSetArgs, IStockArgs,
  IUpdateArgs, IUpdateDashboardArgs, IUpdateDataPanelArgs,
  IUpdateItemArgs,
  IUpdateItemTitleArgs, IUpdateTLItemArgs,
  SaveOrderArgs,
  SaveSortDataArgs,
  UpdateColumnsStateArgs,
  UpdateFiltersArgs
} from '../types';
import RootStateTypes from '../../../store/RootStateTypes';
import { errorMessageAction } from '../../../store/account/actions';
import { getAllColumns } from '../../../utils/helperColumnsWorker';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';
import { messageIdGenerator } from '../../../components/ChartContainer/utils/chartingHelper';
import { userPanel } from '../../../constants/account';
import {
  ColumnSetInnerData,
  DashboardInnerData,
  DataPanelInnerData,
  DeepListInnerData,
  DeepListItemData,
  ScreenerInnerData, TFolderInnerData
} from '../../../utils/Types';

type TDataField = ScreenerInnerData
| DeepListInnerData
| DataPanelInnerData
| DashboardInnerData
| ColumnSetInnerData
| TFolderInnerData
| undefined;

interface IUseItemsMutations {
  addSymbolToWatchlistMutation: UseMutationResult<DeepListItemData, Error, AddSymbolToWLArgs>,
  updateScreenFiltersMutation: UseMutationResult<TDataField, Error, UpdateFiltersArgs>,
  updateFiltersInBackgroundMutation: UseMutationResult<TDataField, Error, UpdateFiltersArgs>,
  updateColumnsSetStateMutation: UseMutationResult<TDataField, Error, UpdateColumnsStateArgs>,
  updateSortDataMutation: UseMutationResult<TDataField, Error, SaveSortDataArgs>,
  saveOrderMutation: UseMutationResult<TDataField, Error, SaveOrderArgs>,
  updateItemMutation: UseMutationResult<TDataField, Error, IUpdateArgs>,
  moveToItemMutation: UseMutationResult<TDataField, Error, IMoveToArgs>,
  createItemMutation: UseMutationResult<TDataField, Error, ICreateArgs>,
  removeItemMutation: UseMutationResult<TDataField, Error, IRemoveArgs>,
  removeItemFromFolderMutation: UseMutationResult<TDataField, Error, IRemoveFromArgs>,
  duplicatePresetMutation: UseMutationResult<TDataField, Error, IDuplicatePresetArgs>,
  saveScreenToColumnsSetMutation: UseMutationResult<TDataField, Error, ISaveScreenToColumnsSetArgs>,
  updateItemTitleMutation: UseMutationResult<TDataField, Error, IUpdateItemTitleArgs>,
  updateDashboardItemMutation: UseMutationResult<TDataField, Error, IUpdateItemArgs>,
  updateStocksForEmptyWlItemMutation: UseMutationResult<TDataField, Error, IStockArgs>,
  linkToWatchListMutation: UseMutationResult<TDataField, Error, ILinkUnlinkArgs>,
  unlLinkFromWatchListMutation: UseMutationResult<TDataField, Error, ILinkUnlinkArgs>,
  updateWatchListDataMutation: UseMutationResult<TDataField, Error, IUpdateTLItemArgs>,
  createDashboardMutation: UseMutationResult<TDataField, Error, ICreateDashboardArgs>,
  duplicateDashboardMutation: UseMutationResult<TDataField, Error, IDuplicateDashboardArgs>,
  updateDashboardMutation: UseMutationResult<TDataField, Error, IUpdateDashboardArgs>,
  removeDashboardMutation: UseMutationResult<TDataField, Error, IRemoveDashboardArgs>,
  updateDataPanelMutation: UseMutationResult<TDataField, Error, IUpdateDataPanelArgs>,
  updateAlertNotificationsButchMutation: UseMutationResult<TDataField, Error,
  { ids: number[], errorHandler: (e: Error) => void }>,
}
const useItemsMutations = (): IUseItemsMutations => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    itemsColumnSets,
    tabsList,
    listPresetsWatchlist,
    storedDataPanelsPresetsList,
    storedDataPanels
  } = useDashboardItems();

  const favoritesFilter = useSelector((state: RootStateTypes) => state.screenerState.favoritesFilter, shallowEqual);
  const selectedTab = useSelector(
    (state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual
  );

  const addSymbolToWatchlist = async ({
    selectedSymbolsNew,
    newSelectedTab,
    copyToFlag
  }: AddSymbolToWLArgs) => {
    const currentTab = newSelectedTab ?? selectedTab;
    const currentTabSymbolList = currentTab?.data?.filters[0][0][2] || [];
    const preparedData = [...selectedSymbolsNew.map((item) => (item.index ? item.index : item))];

    const sortedData = selectedSymbolsNew
      ? [...new Set(copyToFlag ? [...currentTabSymbolList, ...preparedData] : preparedData)]
      : currentTabSymbolList;

    const currentTabListData = [...tabsList, ...listPresetsWatchlist].find(
      (tab) => tab?.id === currentTab?.id
    )?.data || [];

    const response = await $api.patch(`${DASHBOARD_ITEMS}/${currentTab.id}`,
      {
        data: {
          ...currentTabListData,
          stocks: sortedData?.length,
          filters: [[[
            FILTER_SETTINGS.ZERO,
            FILTER_SETTINGS.ZERO,
            sortedData.length ? sortedData : []
          ]]]
        },
      });
    return response.data;
  };
  const addSymbolToWatchlistMutation = useMutation({
    mutationFn: addSymbolToWatchlist,
    onSuccess: (updatedItem, variables) => {
      variables.wlSuccessItemHandler(updatedItem);
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.wlErrorItemHandler(error, variables.newSelectedTab);
    }
  });

  const updateScreenFilters = async ({ screenId, data, filters }: UpdateFiltersArgs) => {
    const response = await $api.patch(
      `${DASHBOARD_ITEMS}/${screenId}`,
      {
        data: {
          ...data,
          filters,
          favoriteFilters: favoritesFilter
        },
      }
    );

    return response.data;
  };
  const updateScreenFiltersMutation = useMutation({
    mutationFn: updateScreenFilters,
    onSuccess: (updatedItem, variables) => {
      if (variables.successActionHandler) {
        variables.successActionHandler(updatedItem);
      }
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error) => {
      dispatch(errorMessageAction({ errorMessage: error.message }));
    }
  });

  const updateFiltersInBackground = async ({ screenId, data }: UpdateFiltersArgs) => {
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${screenId}`, { data });
    return response.data;
  };
  const updateFiltersInBackgroundMutation = useMutation({
    mutationFn: updateFiltersInBackground,
    onSuccess: (updatedItem) => {
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error) => {
      dispatch(errorMessageAction({ errorMessage: error.message }));
    }
  });

  const updateColumnsSetState = async ({ columnsSetId, selectedColumns, callback }: UpdateColumnsStateArgs) => {
    const columnsData = getAllColumns(itemsColumnSets);
    const currentItem = columnsData.find((el) => el.id === columnsSetId);
    const data = { ...currentItem?.data };
    data.selectedColumns = selectedColumns;
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${columnsSetId}`, { data });
    if (callback) {
      callback();
    }

    return response.data;
  };
  const updateColumnsSetStateMutation = useMutation({
    mutationFn: updateColumnsSetState,
    onSuccess: (updatedItem) => {
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error) => {
      dispatch(errorMessageAction({ errorMessage: error.message }));
    }
  });

  const saveSortDataToMyScreen = async ({ currentSelectedScreen, sortData }: SaveSortDataArgs) => {
    const data = { ...currentSelectedScreen.data };
    data.sortData = sortData;

    const response = await $api.patch(`${DASHBOARD_ITEMS}/${currentSelectedScreen.id}`, { data });
    return response.data;
  };
  const updateSortDataMutation = useMutation({
    mutationFn: saveSortDataToMyScreen,
    onSuccess: (updatedItem, variables) => {
      if (variables.successItemHandler) {
        variables.successItemHandler(updatedItem);
      }

      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.currentSelectedScreen.title);
    }
  });

  const saveOrder = async ({ itemId, placeAfterId, isFolder }: SaveOrderArgs) => {
    const data = isFolder ? {
      folderId: +itemId,
      placeAfterId: +placeAfterId
    } : {
      itemId: +itemId,
      placeAfterId: +placeAfterId
    };

    const response = await $api.post(`${isFolder
      ? DASHBOARD_FOLDERS : DASHBOARD_ITEMS}/${isFolder
      ? DASHBOARD_ITEM_CHANGE_ORDER_FOLDER : DASHBOARD_ITEM_CHANGE_ORDER}`, data);
    return response.data;
  };
  const saveOrderMutation = useMutation({
    mutationFn: saveOrder,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`${variables.itemType}${variables.isFolder ? 'Folders' : 'Items'}`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error) => {
      dispatch(errorMessageAction({ errorMessage: error.message }));
    }
  });

  const updateItem = async ({ item }: IUpdateArgs) => {
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${item.id}`,
      {
        title: item.title,
        data: {
          ...item.data,
          description: item.data?.description
        }
      });
    return response.data;
  };
  const updateItemMutation = useMutation({
    mutationFn: updateItem,
    onSuccess: (newItem, variables) => {
      variables.itemSuccessActionHandler(newItem);
      queryClient.invalidateQueries({
        queryKey: [`${newItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.item.title);
    }
  });

  const moveToItem = async ({ id, folderId }: IMoveToArgs) => {
    const response = await $api.patch(
      `${DASHBOARD_ITEMS}/${id}`,
      {
        folder: folderId ? +folderId : null
      }
    );

    return response.data;
  };
  const moveToItemMutation = useMutation({
    mutationFn: moveToItem,
    onSuccess: (newItem, variables) => {
      variables.itemSuccessActionHandler();
      queryClient.invalidateQueries({
        queryKey: [`${newItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.details?.screen || '');
    }
  });

  const createDbItem = async ({
    nameScreen,
    filters,
    folderId,
    nameFolder,
    type,
    columns,
    description,
  }: ICreateArgs) => {
    const body = getBodyRequest(type, nameScreen, filters, favoritesFilter, columns, folderId, nameFolder, description);
    const response = await $api.post(DASHBOARD_ITEMS, body);
    return response.data;
  };
  const createItemMutation = useMutation({
    mutationFn: createDbItem,
    onSuccess: (newItem, variables) => {
      variables.successActionHandler(newItem);
      queryClient.invalidateQueries({
        queryKey: [`${newItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.nameScreen);
    }
  });

  const removeItem = async ({ item }: IRemoveArgs) => {
    const response = await $api.delete(`${DASHBOARD_ITEMS}/${item.id}`);
    return response.data;
  };
  const removeItemMutation = useMutation({
    mutationFn: removeItem,
    onSuccess: (_, variables) => {
      variables.successActionHandler(variables.item);
      queryClient.invalidateQueries({
        queryKey: [`${variables.item.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.item.title);
    }
  });

  const removeItemFromFolder = async ({ item }: IRemoveFromArgs) => {
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${item.id}`, { folder: null });
    return response.data;
  };
  const removeItemFromFolderMutation = useMutation({
    mutationFn: removeItemFromFolder,
    onSuccess: (_, variables) => {
      variables.successActionHandler();
      queryClient.invalidateQueries({
        queryKey: [`${variables.item.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.item.title);
    }
  });

  const duplicatePreset = async ({ titlePreset, dataPreset, type }: IDuplicatePresetArgs) => {
    const typeScreen = typeHandler(type);
    const response = await $api.post(
      DASHBOARD_ITEMS,
      {
        title: titlePreset,
        type: typeScreen,
        viewType: VIEW_TYPES.TABLE,
        data: dataPreset,
        isFavorite: false,
      }
    );
    return response.data;
  };
  const duplicatePresetMutation = useMutation({
    mutationFn: duplicatePreset,
    onSuccess: (newItem, variables) => {
      variables.successActionHandler(newItem);
      queryClient.invalidateQueries({
        queryKey: [`${newItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();

      if (variables.folderId && +variables.folderId > -1) {
        $api.patch(`${DASHBOARD_ITEMS}/${newItem.id}`, { folder: variables.folderId }).then(
          () => queryClient.invalidateQueries({
            queryKey: [`${newItem.type}Items`],
            exact: true,
            refetchType: 'active',
          }).then()
        );
      }
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.titlePreset);
    }
  });

  const saveScreenToColumnsSet = async ({ data, id }: ISaveScreenToColumnsSetArgs) => {
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${id}`, { data });
    return response.data;
  };
  const saveScreenToColumnsSetMutation = useMutation({
    mutationFn: saveScreenToColumnsSet,
    onSuccess: (updatedItem, variables) => {
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
      queryClient.invalidateQueries({
        queryKey: [variables.pagePath === TABLE_SCREEN_PATH.SCREENER ? 'screenerItems' : 'watchListItems'],
        exact: true,
        refetchType: 'active',
      }).then();
    },
  });

  const updateItemTitle = async ({ id, title }: IUpdateItemTitleArgs) => {
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${id}`, { title });
    return response.data;
  };
  const updateItemTitleMutation = useMutation({
    mutationFn: updateItemTitle,
    onSuccess: (updatedItem, variables) => {
      variables.successActionHandler(updatedItem);
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const updateDashboardItem = async ({ data, id }: IUpdateItemArgs) => {
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${id}`, { data });
    return response.data;
  };
  const updateDashboardItemMutation = useMutation({
    mutationFn: updateDashboardItem,
    onSuccess: (updatedItem) => {
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const updateStocksForEmptyWlItem = async ({ item }:IStockArgs) => {
    const response = await $api.patch(`${DASHBOARD_ITEMS}/${item.id}`, {
      data: {
        ...item.data,
        stocks: 0,
      },
    });
    return response.data;
  };
  const updateStocksForEmptyWlItemMutation = useMutation({
    mutationFn: updateStocksForEmptyWlItem,
    onSuccess: (updatedItem) => {
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const linkToWatchList = async ({ id }: ILinkUnlinkArgs) => {
    const response = await $api.post(`${DASHBOARD_ITEMS}${LINK_TO_WATCHLIST}/${id}`);
    return response.data;
  };
  const linkToWatchListMutation = useMutation({
    mutationFn: linkToWatchList,
    onSuccess: (updatedItem, variables) => {
      variables.successActionHandler();
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const unlLinkFromWatchList = async ({ id }: ILinkUnlinkArgs) => {
    const response = await $api.delete(`${DASHBOARD_ITEMS}${UNLINK_FROM_WATCHLIST}/${id}`);
    return response.data;
  };
  const unlLinkFromWatchListMutation = useMutation({
    mutationFn: unlLinkFromWatchList,
    onSuccess: (_, variables) => {
      variables.successActionHandler();
      queryClient.invalidateQueries({
        queryKey: [`${variables.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const updateWatchListData = async ({ data, symbolsArray }: IUpdateTLItemArgs) => {
    const response = await $api.patch(
      `${DASHBOARD_ITEMS}/${data.id}`,
      {
        data: {
          ...data.data,
          stocks: symbolsArray.length,
          filters: [[[
            FILTER_SETTINGS.ZERO,
            FILTER_SETTINGS.ZERO,
            symbolsArray.length > 0 ? [...new Set([...symbolsArray])] : []
          ]]]
        },
      }
    );

    return response.data;
  };

  const updateWatchListDataMutation = useMutation({
    mutationFn: updateWatchListData,
    onSuccess: (updatedItem, variables) => {
      variables.successActionHandler(updatedItem);
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const createDashboard = async ({ name, isPreset }: ICreateDashboardArgs) => {
    const response = await $api.post(DASHBOARD_ITEMS,
      {
        title: name,
        type: isPreset ? TEMPLATE_TYPE.DASHBOARDS_PRESET : TEMPLATE_TYPE.DASHBOARDS,
        viewType: VIEW_TYPES.TABLE,
        data: {
          widgets: [],
        },
      });

    return response.data;
  };
  const createDashboardMutation = useMutation({
    mutationFn: createDashboard,
    onSuccess: (newItem, variables) => {
      variables.successActionHandler(newItem);
      queryClient.invalidateQueries({
        queryKey: [`${newItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const duplicateDashboard = async ({ dashboard, title, folderId }: IDuplicateDashboardArgs) => {
    const cloneWidget = dashboard?.data?.widgets?.map((widget: Widget) => {
      const newWidget = { ...widget };
      newWidget.id = `${messageIdGenerator()}`;
      return newWidget;
    });

    const response = await $api.post(
      DASHBOARD_ITEMS,
      {
        title,
        type: TEMPLATE_TYPE.DASHBOARDS,
        viewType: VIEW_TYPES.TABLE,
        folder: folderId,
        data: { ...dashboard.data, widgets: cloneWidget },
      }
    );

    return response.data;
  };
  const duplicateDashboardMutation = useMutation({
    mutationFn: duplicateDashboard,
    onSuccess: (newItem, variables) => {
      variables.successActionHandler(newItem);
      queryClient.invalidateQueries({
        queryKey: [`${newItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const updateDashboard = async ({ dashboard }: IUpdateDashboardArgs) => {
    const response = await $api.patch(
      `${DASHBOARD_ITEMS}/${dashboard.id}`,
      {
        title: dashboard.title,
        description: dashboard.description,
        data: dashboard.data,
      }
    );

    return response.data;
  };
  const updateDashboardMutation = useMutation({
    mutationFn: updateDashboard,
    onSuccess: (newItem, variables) => {
      variables.successActionHandler(newItem);
      queryClient.invalidateQueries({
        queryKey: [`${newItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const removeDashboard = async ({ id }: IRemoveDashboardArgs) => {
    const response = await $api.delete(`${DASHBOARD_ITEMS}/${id}`);
    return response.data;
  };
  const removeDashboardMutation = useMutation({
    mutationFn: removeDashboard,
    onSuccess: (_, variables) => {
      variables.successActionHandler();
      queryClient.invalidateQueries({
        queryKey: ['userDashboardItems'],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const updateDataPanel = async ({ id, data }: IUpdateDataPanelArgs) => {
    const dataPanelId = id || [...storedDataPanels, ...storedDataPanelsPresetsList].find(
      (dataPanel) => dataPanel?.title === userPanel
    )?.id;

    const response = await $api.patch(`${DASHBOARD_ITEMS}/${dataPanelId}`, { data });
    return response.data;
  };

  const updateDataPanelMutation = useMutation({
    mutationFn: updateDataPanel,
    onSuccess: (updatedItem, variables) => {
      variables.successActionHandler(updatedItem);
      queryClient.invalidateQueries({
        queryKey: [`${updatedItem.type}Items`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });

  const updateAlertNotificationsButch = async ({ ids }: { ids: number[], errorHandler: (e: Error) => void }) => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERTS_NOTIFICATION_PATH}`;
    const response = await $api.patch(URL, { ids });
    return response.data;
  };
  const updateAlertNotificationsButchMutation = useMutation({
    mutationFn: updateAlertNotificationsButch,
    onError: (error, variables) => {
      variables.errorHandler(error);
    }
  });
  return {
    addSymbolToWatchlistMutation,
    updateScreenFiltersMutation,
    updateFiltersInBackgroundMutation,
    updateColumnsSetStateMutation,
    updateSortDataMutation,
    saveOrderMutation,
    updateItemMutation,
    moveToItemMutation,
    createItemMutation,
    removeItemMutation,
    removeItemFromFolderMutation,
    duplicatePresetMutation,
    saveScreenToColumnsSetMutation,
    updateItemTitleMutation,
    updateDashboardItemMutation,
    updateStocksForEmptyWlItemMutation,
    linkToWatchListMutation,
    unlLinkFromWatchListMutation,
    updateWatchListDataMutation,
    createDashboardMutation,
    duplicateDashboardMutation,
    updateDashboardMutation,
    removeDashboardMutation,
    updateDataPanelMutation,
    updateAlertNotificationsButchMutation
  };
};
export default useItemsMutations;
