export const DATA_POINT_PERFORMANCE_FILTER = [
  11, 12, 37, 68, 69, 75, 76, 77, 218, 227, 228, 229, 230, 231, 232, 233, 234, 235,
  236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250,
  251, 252, 253, 274, 275, 276, 277, 278, 279, 280, 281, 1873, 1874, 1875, 1876, 1877, 1878,
  1866, 1886, 1887, 1889, 1978, 1881, 1882, 1883, 1884, 1885, 1981, 1994, 1995, 1996, 2048,
  2049, 2051, 2052, 2054, 2055, 2057, 2058, 2064, 2065, 2071, 2072, 8, 7, 1803, 1804, 1810,
  222, 1816, 1817, 217, 1861, 1862, 2042,
];

export const DATA_POINT_POSITIVE = [11, 12];

export const BUBBLE_FORMATTING_M = [37];

export const SCREENER_TYPES = [
  { title: 'Screener', key: '1', type: 's' },
  { title: 'Screener Presets', key: '2', type: 'sp' },
  { title: 'Watchlist', key: '3', type: 'd' },
  { title: 'Watchlist Presets', key: '4', type: 'dp' },
];
export const DEFAULT_SCREENER_TITLE = 'Deepvue Universe';
export const DEFAULT_HEATMAP_SCREENER_TITLE = 'S&P 500';

export const DEFAULT_DATA_POINT_TITLE = 'Price % Change Today';
export const DEFAULT_DATA_POINT_INDEX = 218;

export const DEFAULT_HEATMAP_Y_DATA_POINT_TITLE = 'Market Cap';
export const DEFAULT_HEATMAP_Y_DATA_POINT_INDEX = 37;

export const DEFAULT_RR50DAY_TITLE = 'Run Rate - 50 Day';
export const DEFAULT_RR50DAY_INDEX = 1887;

export const DEEPVUE_UNIVERSE_ID_PROD = 16192;

export const S_P_500_ID_PROD = 359;

export const BAR_CHART_COLORS = {
  blue: '#3A75E8',
  pink: '#E83AAD',
  gray: '#B5B4C9',
  midGray: '#283555',
  white: '#FFFFFF',
  black: '#000000',
  textLight: '#59595B',
  textDark: '#D5D4E9',
  headerDark: '#232B3B',
  transparent: 'transparent',
  nuetral: '#C0C4CD',
  sectorBgDark: '#121721',
  industryBg: '#E8E8FB',
  industryBgDark: '#2C3445',
  light_Blue: '#7B9CDE',
  medium_Blue: '#467FED',
  dark_Blue: '#1961EE',
  light_Pink: '#DF94C6',
  medium_Pink: '#FE5EC7',
  dark_Pink: '#F114A6',
  dark_gray: '#EBF1FD1A',
  off_white: '#CFD7E852',
  bubble_blue: '#3A75E850',
  bubble_pink: '#E83AAD50',
};

export const BAR_HEIGHT = {
  canvasBarHeight: 22,
  targetListIcon: 18,
  headerOffset: 57,
  heatMapOffset: 38,
  bubbleOffset: 70,
};

export const HEADER_BREAK_POINT = {
  screener: 280,
  dataPoint: 480,
  dataPointY: 650,
  dataPointZ: 820,
  colorGroup: 110
};

export const LAST_STATE_KEYS = {
  performance: 'performanceLastState',
  heatmap: 'heatmapLastState',
  bubble: 'bubbleLastState',
};

export const EmptyChartNames = {
  performanceChart: 'Performance Chart',
  bubbleChart: 'Bubble Chart',
  heatMap: 'Heat Map',
  pieChart: 'Pie Chart',
};

export const VirtualizeData = {
  DEBOUNCE: 300,
  OVER_SCAN_ROW_COUNT: 10,
  MAX_OFFSET: 0.08
};

export const SelectedScreenerTypes = {
  SCREENER: 's',
  SCREENERPRESET: 'sp',
  DEEPLIST: 'd'
};

export const HEATMAP_GROUPS = ['sector', 'industry', 'value'];

export const DROPDOWN_STYLES = {
  TOP_UP: '31px',
  TOP_BOTTOM: '-258px',
  TOP_SUB_ITEM: '90px',
  UNSET: 'unset',
  ZERO: '0',
  LEFT: '430px',
  RIGHT: '268px',
  RIGHT_SUB_ITEM: '-22px',
  FOLDER_BOTTOM: '-200px',
  CONTAINER_WIDTH_OFFSET: 258,
  CONTAINER_HEIGHT_OFFSET: 390,
  FOLDER_WIDTH_OFFSET: 300,
  WIDGET_WIDTH: 450,
  WIDGET_FOLDER_WIDTH: 700,
  LEFT_OFFSET: 217,
  DP_WIDTH_OFFSET: 600,
};

export const FOLDER_ITEM = 'folder';

export const FONT_FAMILY = 'Quicksand';

export const DEFAULT_SCREENER_STATE = {
  folderMenuIsOpen: -1,
  screenerIsOpen: false,
  selectedScreenerTitle: '',
  searchText: '',
  selectedScreener: '',
  filtersData: '',

  dataPointsXIsOpen: false,
  dataPointsXTitle: '',
  dataPointsXSearch: '',
  dataPointsXIndex: null,
  dataPointsXType: '',

  dataPointsYIsOpen: false,
  dataPointsYTitle: '',
  dataPointsYSearch: '',
  dataPointsYIndex: null,
  dataPointsYType: '',
  sortBy: 1,

  dataPointsZIsOpen: false,
  dataPointsZTitle: '',
  dataPointsZSearch: '',
  dataPointsZIndex: null,
  dataPointsZType: '',

  screenerId: null,
};
