import React, { useMemo } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../sass/Dashboards.module.scss';
import {
  MENU_WIDGETS, DRAG_MENU_SELECTOR,
  DRAG_ITEM_SELECTOR, DragWidgetTypes, NO_DRAG_SELECTOR
} from '../types/DashboardEnums';
import { ReactComponent as IconClose } from '../../../assets/images/icons/alertsIcons/close.svg';
import { setWidgetMenuOpen } from '../../../store/dashboards/slice';
import RootStateTypes from '../../../store/RootStateTypes';
import ThemeVariants from '../../../constants/theme';
import { useTheme } from '../../../utils/hooks/useTheme';
import { IDashboardAddMenu } from '../types/DashboardInterfaces';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction, messageAction,
  notionAnimationAction,
  popUpAction
} from '../../../store/auth/actions';

const DashboardAddMenu = ({
  enableTVDrag,
  enableStatsDrag,
  enableScreenDrag,
  enablePSCDrag,
  enableWidgetChartDrag
}: IDashboardAddMenu): React.ReactElement => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const widgetMenuOpen = useSelector((state: RootStateTypes) => state.dashboardsState.widgetMenuOpen, shallowEqual);

  const hideWidgetMenu = () => {
    dispatch(setWidgetMenuOpen(false));
  };

  const getDisableDragClass = (type: DragWidgetTypes): string => {
    switch (type) {
      case DragWidgetTypes.TV:
        return enableTVDrag ? '' : `${styles.preventDrag} ${NO_DRAG_SELECTOR}`;
      case DragWidgetTypes.STATS:
        return enableStatsDrag ? '' : `${styles.preventDrag} ${NO_DRAG_SELECTOR}`;
      case DragWidgetTypes.SCREEN:
        return enableScreenDrag ? '' : `${styles.preventDrag} ${NO_DRAG_SELECTOR}`;
      case DragWidgetTypes.POSITIONSIZE:
        return enablePSCDrag ? '' : `${styles.preventDrag} ${NO_DRAG_SELECTOR}`;
      case DragWidgetTypes.BUBBLE:
      case DragWidgetTypes.HEATMAP:
      case DragWidgetTypes.PERFORMANCE:
        return enableWidgetChartDrag ? '' : `${styles.preventDrag} ${NO_DRAG_SELECTOR}`;
      case DragWidgetTypes.DATA_PANEL:
        return enableWidgetChartDrag ? '' : `${styles.preventDrag} ${NO_DRAG_SELECTOR}`;
      default:
        return '';
    }
  };

  const preventDrag = (dragClass: string) => {
    if (dragClass) {
      dispatch(popUpAction(true));
      dispatch(notionAnimationAction(true));
      dispatch(errorMessageTitleAction({ messageTitle: 'Your dashboard is currently full.' }));
      dispatch(messageAction({ message: '' }));
      dispatch(changeNotificationTypeAction({ type: 'error' }));
    }
  };

  return (
    <div className={`${styles.dashboardMenu} ${widgetMenuOpen && styles.isOpened} ${theme}`}>
      <div className={styles.dashboardMenuHeader}>
        {t('dashboardAddWidget')}
        <IconClose onClick={hideWidgetMenu} />
      </div>
      <div className={`${DRAG_MENU_SELECTOR} ${styles.dashboardMenuItems}`}>
        {MENU_WIDGETS.map((item) => (
          <div
            draggable
            onDragStart={() => preventDrag(getDisableDragClass(item.dragType))}
            onClick={() => preventDrag(getDisableDragClass(item.dragType))}
            aria-hidden
            className={`${styles.dashboardMenuItem} ${DRAG_ITEM_SELECTOR}
              ${item.typeSelector}
              ${getDisableDragClass(item.dragType)}`}
            key={item.id}
          >
            <div className={styles.dashboardMenuItemIcon}>
              {useMemo(() => (theme === ThemeVariants.LIGHT_MODE
                ? <img src={item.icon as string} alt="lightImg" />
                : <img src={item.iconDark as string} alt="darkImg" />
              ), [theme])}
            </div>
            <span className={styles.dashboardMenuItemText}>
              {t(item.name)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardAddMenu;
