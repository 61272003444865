import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

export default function ClientOnlyPortal({ children, selector }) {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    const root = document.getElementById('root');
    ref.current = document.querySelector(selector.includes('#') ? selector : `#${selector}`);

    if (!ref.current && root) {
      ref.current = document.createElement('div');
      ref.current.setAttribute('id', selector);
      root.appendChild(ref.current);
    }
    setMounted(true);
  }, [selector]);

  return mounted ? createPortal(children, ref.current) : null;
}

ClientOnlyPortal.propTypes = {
  children: PropTypes.node.isRequired,
  selector: PropTypes.string.isRequired,
};
