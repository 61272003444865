import React from 'react';
import PropTypes from 'prop-types';
import ScreenerTableHeaderItem from './ScreenerTableHeaderItem';
import SortIconsGroup from '../Sorting/SortIconsGroup/SortIconsGroup';
import styles from './sass/ScreenerTable.module.scss';
import { deepEqual } from '../../utils/objectHelper';

const areEqual = (prevProps, nextProps) => {
  return deepEqual(prevProps.column, nextProps.column)
    && prevProps.sortStatus === nextProps.sortStatus;
};

const CellContainer = ({
  sortHandler, column, sortStatus, isNumberColumn,
  showSortIcons, handleMouseDown
}) => (
  <div
    aria-hidden="true"
    onMouseDown={handleMouseDown}
    className={`${styles.tableHeaderTitleWrapper} ${isNumberColumn ? styles.tableHeaderTitleRight : ''}`}
  >
    <ScreenerTableHeaderItem
      column={column}
      sortHandler={sortHandler}
      isSorted={!!sortStatus(column?.index)}
      sortPosition={sortStatus(column?.index)?.position}
    />
    {showSortIcons() && (
      <SortIconsGroup
        index={column?.index}
        sortStatus={sortStatus}
      />
    )}
  </div>
);

CellContainer.propTypes = {
  sortStatus: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired,
  column:
    PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
  columnsSize: PropTypes.shape({}),
  showSortIcons: PropTypes.func,
  handleMouseDown: PropTypes.func,
  isNumberColumn: PropTypes.bool
};

CellContainer.defaultProps = {
  columnsSize: {},
  showSortIcons: () => undefined,
  handleMouseDown: () => undefined,
  isNumberColumn: false,
};

export default React.memo(CellContainer, areEqual);
