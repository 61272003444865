import moment from 'moment';
import {
  DEFAULT_ALERT_STATE,
  ALERT_TYPES,
  ADDITIONAL_YEARS,
  ALERT_COLUMN_INDEXES,
  COLUMN_NAME_BY_INDEX,
  FACTORIAL_MULTIPLY,
  AVALIBLE_SHAPES,
  DEFAULT_SHAPE_SETTINGS,
  COMMON_LINE,
  ALERT_HIDE,
  ALERT_ACTIVE,
  SHAPES_DATA,
  TREND_LINE_NAME,
  RAY_LINE_NAME,
  PRICE_NAME,
  MULTI_POINTS_SHAPES,
  SINGLE_POINT_SHAPES, RAY_PRICE
} from '../../../constants/alerts';
import { SECOND } from '../../../constants/tvWidgetOptions';
import track from '../../../assets/sounds/message-13716.mp3';
import { currentAlertData, pricePoints, sharedChartData } from '../constants/constants';
import { lastBarsCache, lastBarsPrice } from '../../../services/HistoricalSharedData';
import { TV_EVENTS } from '../../../constants/screener';

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getDateTimeForPeriod = (timestart, step) => {
  const timestartMilisecond = timestart * SECOND;
  const startDate = new Date(timestartMilisecond);
  if (step > 0) {
    const dateWithMonthPeriods = startDate.getMonth() + step;
    startDate?.setMonth(dateWithMonthPeriods);
  } else {
    const dateWithMonthPeriods = startDate.getFullYear() + ADDITIONAL_YEARS;
    startDate?.setFullYear(dateWithMonthPeriods);
  }
  return startDate?.getTime() - timestartMilisecond;
};

const comparePointsForCreate = (points) => {
  if (points?.length > 1) {
    if (points[0]?.time > points[1]?.time) {
      return points.reverse();
    }
  }
  return points;
};

const lineTypeHandler = (lineType) => {
  if (lineType === SHAPES_DATA.HORZ_LINE) {
    return ALERT_TYPES.PRICE;
  }
  if (lineType === SHAPES_DATA.TRND_LINE) {
    return ALERT_TYPES.TRENDLINE;
  }
  if (lineType === SHAPES_DATA.RAY_LINE) {
    return ALERT_TYPES.RAYLINE;
  }
  if (lineType === SHAPES_DATA.RAY_PRICE) {
    return ALERT_TYPES.RAYPRICE;
  }

  return ALERT_TYPES.PRICE;
};

export const createAlertModalData = (symbol, symbolIndex, points, lastPrice, lineId, lineType) => {
  let currentPoints = comparePointsForCreate(points);
  const type = lineTypeHandler(lineType);

  if (!currentPoints?.length) {
    currentPoints = [{ price: lastPrice || 0, time: new Date().getTime() }];
  }

  let shapePoints = {};

  const currentlifetime = getDateTimeForPeriod(currentPoints[0]?.time, DEFAULT_ALERT_STATE.lifetime) / SECOND;
  const activityStart = currentPoints[0]?.time;

  const activityEnd = MULTI_POINTS_SHAPES.includes(type)
    ? currentPoints[1]?.time : currentPoints[0]?.time + currentlifetime;

  const startPrice = Number(currentPoints[0]?.price);

  const endPrice = MULTI_POINTS_SHAPES.includes(type) ? Number(currentPoints[1]?.price) : startPrice;

  if (currentPoints) {
    shapePoints = {
      pointTime1: currentPoints[0]?.time,
      pointTime2: activityEnd,
      priceTime1: startPrice,
      priceTime2: endPrice,
    };
  }
  return {
    ...DEFAULT_ALERT_STATE,
    ...{
      lineId,
      symbol,
      activityStart,
      symbolIndex,
      activityEnd,
      type,
      ...shapePoints
    },
  };
};

export const notionSoundsController = () => {
  const sound = new Audio();
  sound.src = track;
  sound.load();
  sound.play().then();
};

export const currentLimitationHandler = () => {
  const isoNow = moment().toISOString();

  return {
    0: [moment().subtract(15, 'minutes').toISOString(), isoNow],
    1: [moment().subtract(24, 'hours').toISOString(), isoNow],
    2: [moment().subtract(1, 'week').toISOString(), isoNow],
    3: [moment().subtract(1, 'month').toISOString(), isoNow]
  };
};

export const checkedNotificationHandler = (notificationItems, updateAlertNotificationsButch) => {
  const item = document.querySelector('#alertsNotificationList');
  const elements = [];

  notificationItems.current.forEach((notificationItem) => {
    const YCoordinate = notificationItem?.getBoundingClientRect().y;
    if (item && YCoordinate > 0 && YCoordinate < item?.getBoundingClientRect().height) {
      const itemData = notificationItem.id.split(':');

      if (itemData[1] === 'false') {
        elements.push(+itemData[0]);
      }
    }
  });

  if (elements.length > 0) {
    updateAlertNotificationsButch(elements);
  }
};

const sortStatus = (prev, next, sortValue) => {
  if (prev?.isActive === next?.isActive) return 0;
  return prev?.isActive ? -sortValue : sortValue;
};

const sortByText = (prev, next, sortValue, indexColumn) => {
  const columnName = COLUMN_NAME_BY_INDEX[indexColumn];
  if (prev[columnName] < next[columnName]) return -sortValue;
  if (prev[columnName] > next[columnName]) return sortValue;
  return 0;
};

const sortByDate = (prev, next, sortValue) => {
  const prevDate = new Date(prev?.createdAt);
  const nexDate = new Date(next?.createdAt);
  if (prevDate < nexDate) return sortValue;
  if (prevDate > nexDate) return -sortValue;
  return 0;
};

export const sortingAlerts = (sortData, alerts) => {
  let sortedAlerts = [...alerts];
  sortData.forEach((data) => {
    switch (data?.indexElement) {
      case ALERT_COLUMN_INDEXES.STATUS:
        sortedAlerts = sortedAlerts.sort((prev, next) => sortStatus(prev, next, data.sortValue));
        break;
      case ALERT_COLUMN_INDEXES.SYMBOL:
      case ALERT_COLUMN_INDEXES.TYPE:
        sortedAlerts = sortedAlerts.sort((prev, next) => sortByText(prev, next, data.sortValue, data?.indexElement));
        break;
      case ALERT_COLUMN_INDEXES.DATE:
        sortedAlerts = sortedAlerts.sort((prev, next) => sortByDate(prev, next, data.sortValue));
        break;
      default:
        break;
    }
  });
  return sortedAlerts;
};

export const setSortAlertArray = (array, defaultItem, currentIndex) => {
  const newArray = [...array];
  const item = newArray[currentIndex];

  if (currentIndex === -1) {
    newArray.push(defaultItem);
    return newArray;
  }

  item.sortValue = item.sortValue === 1 ? 0 : -1;

  if (item.sortValue === -1) {
    newArray.splice(currentIndex, 1);
  }
  return newArray;
};

export const alertsModalStateHandler = (alertData, alertSettingsItemData) => {
  const result = JSON.parse(JSON.stringify(alertData));

  if (Object.keys(alertSettingsItemData).length > 0) {
    const { data } = alertSettingsItemData;

    result.usePush = data.usePush;
    result.isAbove = data.isAbove;
    result.isBellow = data.isBellow;
    result.useEmail = data.useEmail;
    result.usePopup = data.usePopup;
  }

  return result;
};

export const roundedNumber = (num, precision) => {
  const factor = FACTORIAL_MULTIPLY ** precision;

  return Math.round((num + Number.EPSILON) * factor) / factor;
};

export const chunkArrayWorker = (array, chunkSize) => {
  if (array.length <= chunkSize) {
    return [array];
  }

  const data = [...array];
  const result = [];

  const counter = Math.ceil(array.length / chunkSize);

  for (let i = 0; i < counter; i += 1) {
    const chunk = data.slice(0, chunkSize);
    if (chunk.length) {
      result.push(chunk);
      data.splice(0, chunkSize);
    }
  }

  return result;
};

export const nestedFind = (targetArray, id) => {
  return targetArray?.find((item) => {
    const ids = item.lineId?.split(',');
    return ids?.find((subId) => subId === id);
  });
};

const priceHandler = async (tradingViewWidget, updateAlertLinks, alert, type) => {
  const overrides = { linecolor: !alert.isActive ? ALERT_HIDE : ALERT_ACTIVE };

  await delay(200);

  const lineId = tradingViewWidget?.activeChart().createMultipointShape(
    [{ price: alert.priceTime1, time: alert.pointTime1 }],
    {
      ...DEFAULT_SHAPE_SETTINGS,
      shape: type,
      lock: false,
      disableSelection: false,
      disableSave: false,
      overrides
    }
  );

  updateAlertLinks(alert, lineId);
};

const trendLineHandler = async (tradingViewWidget, updateAlertLinks, alert, type) => {
  const overrides = { linecolor: !alert.isActive ? ALERT_HIDE : ALERT_ACTIVE };

  await delay(200);

  const lineId = tradingViewWidget?.activeChart().createMultipointShape(
    [{ price: alert.priceTime1, time: alert.pointTime1 }, { price: alert.priceTime2, time: alert.pointTime2 }],
    {
      ...DEFAULT_SHAPE_SETTINGS,
      shape: type,
      lock: false,
      disableSelection: false,
      disableSave: false,
      overrides
    }
  );

  updateAlertLinks(alert, lineId);
};

const findIntersection = (arr1, arr2) => {
  return arr1.filter((value) => arr2.includes(value));
};

export const multipleLayoutHandler = (tradingViewWidget, updateAlertLinks, alerts) => {
  const allShapes = tradingViewWidget?.activeChart()?.getAllShapes();
  const currentSymbolFromChart = tradingViewWidget?.activeChart()?.symbol();
  if (currentSymbolFromChart !== alerts[0]?.symbol) {
    return;
  }

  alerts.forEach((alert) => {
    const alertHasShape = findIntersection(allShapes.map((item) => item.id), alert.lineId?.split(','));

    if (!alertHasShape.length && alert.type === ALERT_TYPES.PRICE) {
      priceHandler(tradingViewWidget, updateAlertLinks, alert, PRICE_NAME);
    }

    if (!alertHasShape.length && alert.type === ALERT_TYPES.RAYPRICE) {
      priceHandler(tradingViewWidget, updateAlertLinks, alert, RAY_PRICE);
    }

    if (!alertHasShape.length && alert.type === ALERT_TYPES.TRENDLINE) {
      trendLineHandler(tradingViewWidget, updateAlertLinks, alert, TREND_LINE_NAME);
    }

    if (!alertHasShape.length && alert.type === ALERT_TYPES.RAYLINE) {
      trendLineHandler(tradingViewWidget, updateAlertLinks, alert, RAY_LINE_NAME);
    }
  });
};

export const shapeTypeHandler = (alertListFromStore, currentWidget, id) => {
  const alreadyAlert = nestedFind(alertListFromStore, id);
  const shape = currentWidget?.activeChart()?.getShapeById(id);
  // eslint-disable-next-line no-underscore-dangle
  const shapeType = AVALIBLE_SHAPES?.includes(shape?._source?.toolname);

  return {
    alreadyAlert,
    shapeType,
    shape
  };
};

export const priceLineGenerator = async (currentWidgetRef, symbol, key = '', isEmptyPriceValue = false) => {
  const overrides = { linecolor: 'rgb(42,98,255)', linestyle: 2, linewidth: 1 };
  const existLastBar = lastBarsCache.get(key);
  let priceValue = existLastBar ? existLastBar.open : lastBarsPrice.open;

  if (isEmptyPriceValue) {
    priceValue = 0;
  }

  const lineId = currentWidgetRef?.activeChart().createMultipointShape(
    [key ? { price: priceValue, time: Date.now() } : pricePoints.data[0]],
    {
      ...DEFAULT_SHAPE_SETTINGS,
      shape: 'horizontal_line',
      lock: false,
      disableSelection: false,
      disableSave: false,
      overrides
    }
  );

  await delay(200);

  currentWidgetRef.saveChartToServer();
  const shape = currentWidgetRef?.activeChart().getShapeById(lineId);

  currentAlertData.data = {
    id: lineId,
    points: shape.getPoints(),
    properties: shape.getProperties(),
    symbol
  };
};

export const webViewPriceLineGenerator = async (currentWidgetRef, symbol) => {
  const overrides = { linecolor: 'rgb(42,98,255)', linestyle: 2, linewidth: 1 };

  const lineId = currentWidgetRef?.activeChart().createMultipointShape(
    [symbol ? { price: +symbol.priceTime1, time: Date.now() } : pricePoints.data[0]],
    {
      ...DEFAULT_SHAPE_SETTINGS,
      shape: 'horizontal_line',
      lock: false,
      disableSelection: false,
      disableSave: false,
      overrides
    }
  );
  await delay(200);

  currentWidgetRef.saveChartToServer();
  const shape = currentWidgetRef?.activeChart().getShapeById(lineId);

  currentAlertData.data = {
    id: lineId,
    points: shape.getPoints(),
    properties: shape.getProperties(),
    symbol
  };
};

export const setPointsForClick = (points) => {
  currentAlertData.data = null;
  pricePoints.data = [{ price: Number(points[1]?.toFixed(2)), time: points[0] }];
};

const pointsDiffPriceHandler = (tradingViewWidget, id, points, alreadyAlert) => {
  const charts = tradingViewWidget?.chartsCount();

  for (let i = 0; i < charts; i += 1) {
    const currentShapeData = tradingViewWidget?.chart(i)?.getShapeById(id);
    currentShapeData.setPoints([{ time: points[0]?.time, price: alreadyAlert.priceTime1 }]);
  }
};

const pointsDiffTrandLineHandler = (tradingViewWidget, id, points, alreadyAlert) => {
  const charts = tradingViewWidget?.chartsCount();

  for (let i = 0; i < charts; i += 1) {
    const currentShapeData = tradingViewWidget?.chart(i)?.getShapeById(id);
    currentShapeData.setPoints([
      { time: alreadyAlert.pointTime1, price: alreadyAlert.priceTime1 },
      { time: alreadyAlert.pointTime2, price: alreadyAlert.priceTime2 }
    ]);
  }
};

export const alertsHandler = (tradingViewWidget, alertListFromStore) => {
  const allShapes = tradingViewWidget?.activeChart()?.getAllShapes();

  const currentSymbolFromChart = tradingViewWidget?.activeChart()?.symbol();

  if (alertListFromStore.length > 0 && currentSymbolFromChart !== alertListFromStore[0]?.symbol) {
    return;
  }

  allShapes.forEach((shape) => {
    const currentShapeData = tradingViewWidget?.chart()?.getShapeById(shape.id);
    const alreadyAlert = nestedFind(alertListFromStore, shape.id);

    if (!alreadyAlert) {
      const color = currentShapeData.getProperties();
      const isAlertColor = color.linecolor === ALERT_ACTIVE;
      currentShapeData?.setProperties({ linecolor: color.linecolor && !isAlertColor ? color.linecolor : COMMON_LINE });
    }

    if (alreadyAlert && !alreadyAlert?.isActive) {
      currentShapeData?.setProperties({ linecolor: ALERT_HIDE });
    }

    if (alreadyAlert && alreadyAlert?.isActive) {
      currentShapeData?.setProperties(
        { linecolor: ALERT_ACTIVE, ...alreadyAlert.type === ALERT_TYPES.PRICE ? { linestyle: 2, linewidth: 1 } : {} }
      );
    }
  });
};

export const crossLayoutShapeHandler = (tradingViewWidget, alerts) => {
  const shapes = tradingViewWidget?.activeChart()?.getAllShapes();
  shapes?.forEach((shape) => {
    const currentAlert = alerts?.find((alert) => alert?.lineId?.includes(shape.id));
    if (!currentAlert && shape.name === PRICE_NAME) {
      tradingViewWidget?.activeChart().removeEntity(shape.id);
    }
  });
};

export const removeAlertWithTrendLine = (chart, alertListFromStore, removeAlerts, handleSetSelectedShapeId) => {
  let isLoading = false;

  chart?.subscribe(TV_EVENTS.LAYOUT_CHANGED, () => {
    isLoading = false;
  });
  chart?.subscribe(TV_EVENTS.LAYOUT_TO_BE_CHANGED, () => {
    isLoading = true;
  });
  chart?.subscribe(TV_EVENTS.DRAWING_EVENT, (sourceId, drawingEventType) => {
    if (drawingEventType === TV_EVENTS.REMOVE && !isLoading) {
      const currentAlert = alertListFromStore?.find((alert) => alert?.lineId.includes(sourceId));
      if (currentAlert) {
        removeAlerts([currentAlert?.id], true);
        handleSetSelectedShapeId(null);
      }
    }
  });
};

export const crossLayoutPositionHandler = (tradingViewWidget, alertListFromStore) => {
  const allShapes = tradingViewWidget?.activeChart()?.getAllShapes();
  const currentSymbolFromChart = tradingViewWidget?.activeChart()?.symbol();
  if (currentSymbolFromChart !== alertListFromStore[0]?.symbol) {
    return;
  }

  allShapes.forEach((shape) => {
    const currentShapeData = tradingViewWidget?.chart()?.getShapeById(shape.id);
    const alreadyAlert = nestedFind(alertListFromStore, shape.id);
    const points = currentShapeData?.getPoints();

    if (alreadyAlert && SINGLE_POINT_SHAPES.includes(alreadyAlert.type)
      && alreadyAlert.priceTime1 !== points[0]?.price
    ) {
      pointsDiffPriceHandler(tradingViewWidget, shape.id, points, alreadyAlert);
    }

    if (alreadyAlert && MULTI_POINTS_SHAPES.includes(alreadyAlert.type)
      && (alreadyAlert.priceTime1 !== points[0]?.price || alreadyAlert.priceTime2 !== points[1]?.price)
    ) {
      pointsDiffTrandLineHandler(tradingViewWidget, shape.id, points, alreadyAlert);
    }
  });
};

export const fillAlertData = (id, points, properties, symbol, shape = undefined) => {
  const shapeData = {
    type: ''
  };

  if (shape) {
    // eslint-disable-next-line no-underscore-dangle
    shapeData.type = shape?._source?.toolname;
  }
  currentAlertData.data = {
    id, points, properties, symbol, ...shapeData
  };
};

export const setAlertActionFromApp = async () => {
  await priceLineGenerator(
    sharedChartData.currentWidgetRef,
    sharedChartData.currentSymbolData,
    sharedChartData.currentHistoryCacheKey,
    true
  );
};

export const symbolFromLayoutHandler = (symbolsList, symbol) => {
  const currentSymbol = symbolsList.find((subSym) => subSym.sym === symbol);

  if (currentSymbol) {
    return { name: currentSymbol.sym, index: currentSymbol.index };
  }

  return { name: 'A', index: 0 };
};

export const symbolFromActiveChart = (symName, symList) => {
  const { sym: name, index } = symList.find((item) => item.sym === symName) || {};
  return { name, index };
};
