import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setColumnSetsId, setNameColumnSets } from '../../../store/newScreen/actions';
import { setColumnsScreenerAction } from '../../../store/screener/actions';
import { getActiveColumns, getAllColumns, getColumnsSetItem } from '../../../utils/helperColumnsWorker';
import { FIXED_COLUMNS, TABLE_SCREEN_PATH } from '../../../constants/screener';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const useApplyColumns = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);

  const { itemsColumnSets, itemsColumnSetsPresets } = useDashboardItems();

  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);

  const dispatchHelper = (id, columns, name) => {
    dispatch(setColumnSetsId(id));
    dispatch(setColumnsScreenerAction({ data: columns }));
    dispatch(setNameColumnSets(name || t('recommended')));
  };

  const findKeyWithValue = (targetObj, targetValue) => {
    const entry = Object.entries(targetObj).find(([, values]) => values.includes(targetValue));
    return entry ? parseInt(entry[0]) : null;
  };

  const applyColumnsHandler = (columnSetId) => {
    const flatArrayColumns = getAllColumns([...itemsColumnSets, ...itemsColumnSetsPresets]);
    const columnsSetExisting = flatArrayColumns.find((item) => item.id === columnSetId);
    const defaultColumnsSet = flatArrayColumns.find((item) => item.isDefault);

    if (defaultColumnsSet) {
      const currentId = (!columnSetId || !columnsSetExisting) ? defaultColumnsSet?.id : columnSetId;

      const currentColumns = getColumnsSetItem(flatArrayColumns, currentId, columnsList);
      const activeColumns = getActiveColumns(columnsList, FIXED_COLUMNS, currentColumns?.columns);

      dispatchHelper(currentId, activeColumns, currentColumns?.name);
    }
  };

  const applyColumnsFromSpecificPlace = () => {
    if (
      pagePath === TABLE_SCREEN_PATH.SCREENER
      && !Object.keys(selectedScreen).length
    ) {
      applyColumnsHandler(userSettings?.lastScreenerColumnsSet);
    }
    if (
      pagePath === TABLE_SCREEN_PATH.SCREENER
      && selectedScreen
      && Object.keys(selectedScreen).length
    ) {
      applyColumnsHandler(findKeyWithValue(userSettings?.presetsColumnSetsScreenerIds, selectedScreen.id)
        || selectedScreen?.data?.columnsId);
    }
    if (
      pagePath !== TABLE_SCREEN_PATH.SCREENER
      && selectedTab
      && Object.keys(selectedTab).length
    ) {
      applyColumnsHandler(findKeyWithValue(userSettings?.presetsColumnSetsScreenerIds, selectedTab.id)
        || selectedTab?.data?.columnsId);
    }
  };

  return { applyColumnsFromSpecificPlace, applyColumnsHandler };
};

export default useApplyColumns;
