import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';
import getInputArray from '../utilities/getInputArrayTs';

export default function ttmSqueeze(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - TTM Squeeze',
    metainfo: {
      id: 'TL-dvttmsqueeze@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - TTM Squeeze',
      description: 'DV - TTM Squeeze',
      format: {
        precision: 2,
        type: 'price'
      },
      is_hidden_study: false,
      is_price_study: false,
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Momentum'
        },
        plot_2: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Squeeze Type'
        }
      },
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line,
        },
        {
          id: 'plot_1',
          palette: 'palette_0',
          target: 'plot_0',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_2',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_3',
          palette: 'palette_1',
          target: 'plot_2',
          type: 'colorer' as StudyPlotType.Colorer
        }
      ],
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Oscillator Rising Above Zero'
            },
            1: {
              name: 'Oscillator Falling Above Zero'
            },
            2: {
              name: 'Oscillator Falling Below Zero'
            },
            3: {
              name: 'Oscillator Rising Below Zero'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3
          }
        },
        palette_1: {
          colors: {
            0: {
              name: 'Tight Squeeze'
            },
            1: {
              name: 'Medium Squeeze'
            },
            2: {
              name: 'Loose Squeeze'
            },
            3: {
              name: 'No Squeeze'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3
          }
        }
      },
      inputs: [
        {
          defval: 20,
          id: 'in_0',
          name: 'TTM Squeeze Length',
          type: 'integer' as StudyInputType.Integer,
          min: 0
        },
        {
          defval: 2,
          id: 'in_1',
          name: 'Bollinger Band STD Multiplier',
          type: 'float' as StudyInputType.Float,
          min: 0
        },
        {
          defval: 1,
          id: 'in_2',
          name: 'Keltner Channel - Tight',
          type: 'float' as StudyInputType.Float,
          min: 0
        },
        {
          defval: 1.5,
          id: 'in_3',
          name: 'Keltner Channel - Medium',
          type: 'float' as StudyInputType.Float,
          min: 0
        },
        {
          defval: 2,
          id: 'in_4',
          name: 'Keltner Channel - Loose',
          type: 'float' as StudyInputType.Float,
          min: 0
        },
      ],
      defaults: {
        inputs: {
          in_0: 20,
          in_1: 2,
          in_2: 1,
          in_3: 1.5,
          in_4: 2,
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#00bcd4',
                style: 0,
                width: 2
              },
              1: {
                color: '#2962ff',
                style: 0,
                width: 2
              },
              2: {
                color: '#ff5252',
                style: 0,
                width: 2
              },
              3: {
                color: '#ffeb3b',
                style: 0,
                width: 2
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#ff9800',
                style: 0,
                width: 3
              },
              1: {
                color: '#ff5252',
                style: 0,
                width: 3
              },
              2: {
                color: '#363a45',
                style: 0,
                width: 3
              },
              3: {
                color: '#4caf50',
                style: 0,
                width: 3
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 1,
            trackPrice: false,
            transparency: 0
          },
          plot_2: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 3,
            plottype: 6,
            trackPrice: false,
            transparency: 0
          }
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [length, BB_mult, KC_mult_high, KC_mult_mid, KC_mult_low] = getInputArray({ inputs, length: 5 });
        const closeSeries = context.new_unlimited_var(mPineJS.Std.close(this._context));
        const lowSeries = context.new_unlimited_var(mPineJS.Std.low(this._context));
        const highSeries = context.new_unlimited_var(mPineJS.Std.high(this._context));

        const dev = BB_mult * mPineJS.Std.stdev(closeSeries, length, context);
        const BB_basis = mPineJS.Std.sma(closeSeries, length, context);
        const BB_upper = BB_basis + dev;
        const BB_lower = BB_basis - dev;

        const KC_basis = mPineJS.Std.sma(closeSeries, length, context);
        const devKC = mPineJS.Std.sma(context.new_unlimited_var(mPineJS.Std.tr(0, context)), length, context)

        const KC_upper_high = KC_basis + devKC * KC_mult_high;
        const KC_lower_high = KC_basis - devKC * KC_mult_high;
        const KC_upper_mid = KC_basis + devKC * KC_mult_mid;
        const KC_lower_mid = KC_basis - devKC * KC_mult_mid;
        const KC_upper_low = KC_basis + devKC * KC_mult_low;
        const KC_lower_low = KC_basis - devKC * KC_mult_low;

        //SQUEEZE CONDITIONS
        const NoSqz = BB_lower < KC_lower_low || BB_upper > KC_upper_low;
        const LowSqz = BB_lower >= KC_lower_low || BB_upper <= KC_upper_low;
        const MidSqz = BB_lower >= KC_lower_mid || BB_upper <= KC_upper_mid;
        const HighSqz = BB_lower >= KC_lower_high || BB_upper <= KC_upper_high;

        const OSCILLATOR = mPineJS.Std.close(this._context) - mPineJS.Std.avg(mPineJS.Std.avg(mPineJS.Std.highest(highSeries, length, context), mPineJS.Std.lowest(lowSeries, length, context)), mPineJS.Std.sma(closeSeries, length, context));

        const mom = mPineJS.Std.linreg(context.new_unlimited_var(OSCILLATOR), length, 0);
        const momSeries = context.new_unlimited_var(mom);

        const iff_1 = momSeries.get(0) > mPineJS.Std.nz(momSeries.get(1)) ? 0 : 1;
        const iff_2 = momSeries.get(0) < mPineJS.Std.nz(momSeries.get(1)) ? 2 : 3;
        const mom_color = mom > 0 ? iff_1 : iff_2;

        //SQUEEZE DOTS COLOR
        const sq_color = HighSqz ? 0 : MidSqz ? 1 : LowSqz ? 2 : 3;

        return [
          mom,
          mom_color,
          0,
          sq_color
        ];
      };
    }
  }
}
