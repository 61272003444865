export const GET_ITEMS_LIGHT_SERVER = 'v1.0/get';
export const DEF_URLS = 'v1.0/definition-urls';
export const DOWNLOAD_FILE = 'download';
export const DOWNLOAD_FILE_LIGHT_SERVER = 'v1.0/get-file';
export const GET_BASE_URL = 'cloud-front/signed-cookie?distributionsType=historical';
export const ADAPTER_PATH = 's3-adapter.html';
export const DASHBOARD_ITEMS = 'dashboard-items';
export const DASHBOARD_FOLDERS = 'dashboard-item-folder';
export const DASHBOARD_ITEM_TYPE_COLUMNS = 'type=columns';
export const DASHBOARD_ITEM_TYPE_COLUMNS_PRESETS = 'type=columnsPreset';
export const DASHBOARD_ITEM_TYPE_TV_SETTINGS = 'type=settingsLayout';
export const DASHBOARD_ITEM_TYPE_DRAWING_TEMPLATES = 'type=drawingTemplate';
export const DASHBOARD_ITEM_TYPE_SCREENER = 'type=screener';
export const DASHBOARD_ITEM_TYPE_PRESETS = 'type=presets';
export const DASHBOARD_ITEM_TYPE_PRESETS_FOLDERS = 'type=presetsScreener';
export const DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST_FOLDERS = 'type=presetsWatchlist';
export const DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST = 'type=watchlistPresets';
export const DASHBOARD_ITEM_TYPE_WATCHLIST = 'type=watchList';
export const DASHBOARD_ITEM_TYPE_ALERTS = 'type=alerts';
export const DASHBOARD_ITEM_TYPE_DATA_PANEL = 'type=dataPanel';
export const DASHBOARD_ITEM_TYPE_DATA_PANEL_PRESETS = 'type=presetsDataPanel';
export const DASHBOARD_ITEM_TYPE_CHART_WIDGET = 'type=chartWidget';
export const DASHBOARD_ITEM_TYPE_CHART_WIDGET_MOBILE = 'type=chartWidgetMobile';
export const DASHBOARD_ITEM_TYPE_CHART_TEMPLATE = 'type=chartTemplate';
export const DASHBOARD_ITEM_TYPE_DASHBOARDS = 'type=userDashboard';
export const DASHBOARD_ITEM_TYPE_DASHBOARDS_PRESET = 'type=userDashboardPresets';
export const DASHBOARD_ITEM_CHANGE_ORDER = 'set-item-sort-order';
export const DASHBOARD_ITEM_CHANGE_ORDER_FOLDER = 'set-folder-sort-order';
export const DASHBOARD_ITEM_SHARE = 'share-dashboard-item';
export const DASHBOARD_ITEM_GET_SHARED_ITEM = 'get-shared-dashboard-item';
export const DASHBOARD_ITEM_LAST_STATE = 'type=userLastState';
export const DASHBOARD_ITEM_LAST_STATE_MOBILE = 'type=userMobileLastState';
export const ME = '/users/me';
export const TWITTER_SHARE = 'socials/twitter/share';
export const USERS_ME_PATH = 'users/me';
export const PAYMENT_CARDS_PATH = 'payment/cards';
export const GET_ACTIVE_SUBSCRIPTION_PATH = 'payment/active-subscription';
export const GET_PAYMENT_SUBSCRIPTION_PATH = '/payment/history';
export const GET_STRIPE_KEY_PATH = 'payment/config';
export const GET_VERSION = 'v1.0/get-app-version';
export const GET_TICK_VERSION = 'v1.0/get-ticks-version';
export const LINK_TO_WATCHLIST = '/link-to-watchlist';
export const UNLINK_FROM_WATCHLIST = '/unlink-from-watchlist';
export const STATS_CHART_PATH_FIRST = 'v1.0/stats-chart-data?&reportType=';
export const STATS_CHART_PATH_SECOND = '&symbolIndex=';
export const ALERTS_NOTIFICATION_PATH = 'alert-notification';
export const ALERT_PATH = 'alert';
export const ALERT_TICK = 'alert-notification/web-notifications';
export const USER_LAST_STATE = 'users/last-state';
export const LOG_OUT = 'auth/logout';
export const HTTPS_PREFIX = 'https://';
export const WSS_PREFIX = 'wss://';
