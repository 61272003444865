import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MouseTransition, MultiBackend, TouchTransition } from 'react-dnd-multi-backend';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import App from './app/App';
import store from './store/reducers';
import DashboardItemsProvider from './context/DasboardItemsContext/DashboardItemsProvider';

const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition
    }
  ]
};

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <DashboardItemsProvider>
          <App />
        </DashboardItemsProvider>
      </DndProvider>
    </QueryClientProvider>
  </Provider>
);
