import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import stylesModal from './sass/NewScreenModals.module.scss';
import TextField from '../TextField/TextField';
import Input from '../Input/Input';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import Select from '../Select/Select';
import useNewScreen from '../../utils/hooks/useNewScreen';
import useSaveColumns from '../Columns/hooks/useSaveColumns';
import constants from '../../constants/filters';
import { getLabelModal, getTitleDuplicateModal } from '../../utils/helperNewScreenModal';
import { resetCurrentPage } from '../../store/tableData/slice';
import { DEFAULT_PAGINATION_STATE, MAX_LENGTH_COUNT, SUBSCRIPTION_TYPE } from '../../constants/screener';
import NewScreenButtonBlock from './NewScreenButtonBlock';
import UseDashboardsDialogs from '../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import DashboardItemsDialog from '../DashboardItemsDialog/DashboardItemsDialog';
import { checkRoleUser } from '../../utils/userHelper';
import { DashboardItemsDialogActionTypes } from '../DashboardItemsDialog/types/constants';
import { DialogItemEntityType } from '../DashboardsItemsDropdown/types/constants';
import { useDashboardItems } from '../../context/DasboardItemsContext/DashboardItemsProvider';

const DuplicateModal = ({
  setOpenModal, currentPresetItem, duplicatePreset, type, data
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [valueSelect, setValueSelect] = useState(-1);
  const [valueName, setValueName] = useState('');
  const [emptyField, setEmptyField] = useState(false);

  const { screenerItems } = useDashboardItems();
  const itemsNewScreen = screenerItems;

  const titleModal = getTitleDuplicateModal(currentPresetItem.type, t, valueName);
  const label = getLabelModal(currentPresetItem.type, t);
  const itemsModal = currentPresetItem.type === constants.columns ? data : itemsNewScreen;

  const { saveColumnsToSpecificPlace } = useSaveColumns();
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);
  const {
    actionOpenDialog,
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();
  const {
    newFolderData
  } = useNewScreen(currentPresetItem);

  useEffect(() => {
    setValueName(currentPresetItem?.title || '');
  }, []);

  useEffect(() => {
    if (newFolderData?.id) {
      setValueSelect(newFolderData.id);
    }
  }, [newFolderData]);

  const duplicateHandler = () => {
    if (valueName === '') {
      setEmptyField('true');
    } else {
      duplicatePreset(valueName, currentPresetItem.data, valueSelect, type, saveColumnsToSpecificPlace);
      dispatch(resetCurrentPage(DEFAULT_PAGINATION_STATE));
    }
  };

  const addFolderHandler = () => {
    actionOpenDialog(
      DashboardItemsDialogActionTypes.ADD,
      2,
      DialogItemEntityType.FOLDER,
      0,
      data
    );
  };

  return useMemo(() => {
    return (
      <>
        {open ? (
          <DashboardItemsDialog
            open={open}
            actionCancel={actionCancelDialog}
            dialogData={dialogData}
            currentItemHandler={() => {}}
            adminRole={adminRole}
          />
        ) : (
          <ButtonListenerWrapper handleCancel={setOpenModal} handleConfirm={duplicateHandler}>
            <div className={stylesModal.duplicatePresetModal}>
              <TextField text={titleModal} styleType="duplicateModalTitle" />
              <div className={stylesModal.inputBlock}>
                <TextField text={label} styleType="inputLabelModal" />
                <Input
                  type="text"
                  inputClass={stylesModal.inputDuplicateModal}
                  handlerChange={(e) => setValueName(e.currentTarget.value)}
                  placeholder={t('upOnVolume')}
                  value={valueName}
                  error={emptyField}
                  message={emptyField ? t('messageEmptyFolderName') : ''}
                  maxLength={MAX_LENGTH_COUNT}
                />
              </div>
              <div className={stylesModal.inputBlockSelect}>
                <Select
                  title={t('folder')}
                  valueSelect={valueSelect}
                  setValueSelect={setValueSelect}
                  data={itemsModal}
                  placeholder={t('selectedFolder')}
                  addFolderHandler={addFolderHandler}
                  defaultValue={newFolderData}
                />
              </div>
              <NewScreenButtonBlock
                cancelHandler={setOpenModal}
                cancelButton={t('cancel')}
                actionHandler={duplicateHandler}
                actionDisabled={!valueName.length}
                actionButton={t('save')}
              />
            </div>
          </ButtonListenerWrapper>
        )}
      </>
    );
  }, [currentPresetItem, itemsNewScreen, valueSelect, open, valueName, itemsModal]);
};

DuplicateModal.defaultProps = {
  currentPresetItem: {
    id: -1,
    myScreenTitle: ''
  }
};

DuplicateModal.propTypes = {
  duplicatePreset: PropTypes.func.isRequired,
  currentPresetItem: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({}),
    title: PropTypes.string
  }),
  setOpenModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

export default DuplicateModal;
