import {
  useContext, useEffect, useState
} from 'react';
import { WS_CHANNELS } from '../../../constants/socketConnection';
import SocketContext from '../../../context/SocketContext';
import {
  BubbleDatasetValues,
  SocketDataArrayTypes,
  SocketDataResponse,
  StoredSymbol,
  TDataPoint,
} from '../types/WidgetChartsypes';
import {
  DATA_POINT_PERFORMANCE_FILTER,
  DEFAULT_DATA_POINT_INDEX,
  DEFAULT_DATA_POINT_TITLE,
  DEFAULT_HEATMAP_SCREENER_TITLE,
  DEFAULT_RR50DAY_INDEX,
  DEFAULT_RR50DAY_TITLE,
  S_P_500_ID_PROD,
} from '../types/WidgetChartsEnums';
import { filtersStoreHelper } from '../../../utils/helpers';
import {
  IUseWidgetBubbleChart,
} from '../types/WidgetChartsInterfaces';
import { ScreenerQueryMessage } from '../../../services/Interfaces';
import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import useWidgetCharts from './useWidgetCharts';
import { handleFormattedValues, bubbleScaling } from '../utils/bubble';
import useWidgetSocketListener from './useWidgetSocketListener';
import {
  dataCallback, dataCallbackPatch, getWidgetValues,
} from '../utils/initHelper';
import useWidgetAddQuery from './useWidgetAddQuery';

const useWidgetBubbleChart: IUseWidgetBubbleChart = (widget, dashboardId, updateWidget) => {
  const debouncedUpdateLastState = useDebouncedCallback();
  const { Socket, Connection, Authorized } = useContext(SocketContext);
  const { addWidgetQuery } = useWidgetAddQuery();
  const {
    presetsNewScreen,
    columnsList,
    symbolsList,
    userSettings,
    screenerStates,
    watchLists,
    itemsDeeplist,
    setScreenerStates,
    queryId,
    setQueryId,
    dataPointList,
    setDataPointList,
    selectedType,
    setDefaultFilter,
    screenerSearch,
    updateFilter,
    setFilter,
    selectedDashboard
  } = useWidgetCharts(
    widget,
    S_P_500_ID_PROD,
    DEFAULT_HEATMAP_SCREENER_TITLE,
  );

  const [dataSetLabels, setDataSetLabels] = useState<SocketDataArrayTypes[]>([]);
  const [dataSetValues, setDataSetValues] = useState<BubbleDatasetValues[]>([]);
  const [storedSymbols, setStoredSymbols] = useState<StoredSymbol[]>([]);

  const convertIntoLabelValue = (dataResponse: SocketDataResponse) => {
    const labels: SocketDataArrayTypes[] = [];
    const values: BubbleDatasetValues[] = [];
    const symbols: StoredSymbol[] = [];
    const maxValue = Math.max(...dataResponse?.data.map((item) => Math.abs(Number(item[4])))) ?? 25;
    dataResponse?.data?.forEach((item) => {
      if (!Number.isNaN(item[2]) && !Number.isNaN(item[3])) {
        const item2Value = item[2] ? Number(item[2]) : 0;
        const item3Value = item[3] ? Number(item[3]) : 0;

        const xValue = handleFormattedValues(item2Value, screenerStates.dataPointsXIndex,
          screenerStates.dataPointsXType);
        const yValue = handleFormattedValues(item3Value, screenerStates.dataPointsYIndex || null,
          screenerStates.dataPointsYType);

        labels.push(item[1]);
        values.push({
          x: xValue,
          y: yValue,
          r: item[4] ? bubbleScaling(Math.abs(Number(item[4])), maxValue) : 3,
          max: maxValue
        });
        const sym = symbolsList.find((subItem: StoredSymbol) => subItem.sym === item[1]);
        if (sym && sym.sym) symbols.push(sym);
      }
    });
    return { labels, values, symbols };
  };

  const prepareDataHandler = (resp: SocketDataResponse) => {
    const { labels, values, symbols } = convertIntoLabelValue(resp);
    setDataSetLabels(labels);
    setDataSetValues(values);
    setStoredSymbols(symbols);
    setQueryId(resp.screenerId);
  };

  useWidgetSocketListener({
    widget,
    screenerStates,
    dataCallback: (resp: SocketDataResponse) => dataCallback(resp, widget, addWidgetQuery, prepareDataHandler),
    dataCallbackPatch: (resp: SocketDataResponse) => dataCallbackPatch(
      resp, widget, addWidgetQuery, prepareDataHandler
    ),
    dataCallbackAll: () => undefined,
    partialCallback: () => undefined,
    queryId,
    storedSymbols,
    isPartial: false
  });

  const initialWidget = () => {
    const findDataPoint = columnsList.find(
      (item: TDataPoint) => item.index === DEFAULT_RR50DAY_INDEX
    ) ?? { type: '' };
    const findDataPointY = columnsList.find(
      (item: TDataPoint) => item.index === DEFAULT_DATA_POINT_INDEX
    ) ?? { type: '' };

    setScreenerStates((prev) => ({
      ...prev,
      selectedScreenerTitle: DEFAULT_HEATMAP_SCREENER_TITLE,
      dataPointsXTitle: DEFAULT_RR50DAY_TITLE,
      dataPointsXIndex: DEFAULT_RR50DAY_INDEX,
      dataPointsXType: findDataPoint.type,
      dataPointsYTitle: DEFAULT_DATA_POINT_TITLE,
      dataPointsYIndex: DEFAULT_DATA_POINT_INDEX,
      dataPointsYType: findDataPointY?.type,
      dataPointsZTitle: '',
      dataPointsZIndex: null,
      dataPointsZType: '',
    }));
    setDefaultFilter();
  };

  useEffect(() => {
    if (parseInt(widget.id) > 0 && columnsList.length && presetsNewScreen.length) {
      getWidgetValues(
        widget,
        userSettings.bubbleLastState,
        selectedDashboard,
        columnsList,
        setFilter,
        initialWidget,
        {
          itemId: dashboardId,
          defaultScreenId: S_P_500_ID_PROD,
          findY: true,
          findZ: true,
        }
      );
      setDataPointList(columnsList.filter((item: TDataPoint) => (item.show && item.enabled
        && DATA_POINT_PERFORMANCE_FILTER.includes(item.index))));
    }
  }, [columnsList, presetsNewScreen]);

  useEffect(() => {
    if (
      screenerStates.filtersData
      && screenerStates.dataPointsXIndex
      && screenerStates.dataPointsYIndex
      && Socket?.readyState === WebSocket.OPEN
      && Authorized
    ) {
      const queryMessage: ScreenerQueryMessage = {
        messageId: Number(widget.id),
        columns: screenerStates.dataPointsZIndex
          ? [0, 17, screenerStates.dataPointsXIndex, screenerStates.dataPointsYIndex, screenerStates.dataPointsZIndex]
          : [0, 17, screenerStates.dataPointsXIndex, screenerStates.dataPointsYIndex],
        filters: filtersStoreHelper(JSON.parse(screenerStates.filtersData)),
        sortBy: screenerStates.dataPointsZIndex ? [[screenerStates.dataPointsZIndex, 1]] : [],
        range: [0, 500],
        etfSymbolIndex: -1,
      };
      let emitter;
      if (queryId) {
        queryMessage.screenerId = queryId;
        emitter = () => Connection?.sendMessage(WS_CHANNELS.SCREENER_PATCH, queryMessage);
      } else {
        emitter = () => Connection?.sendMessage(WS_CHANNELS.SCREENER_SUBSCRIBE, queryMessage);
      }
      debouncedUpdateLastState(emitter, 500);
    }
  }, [
    screenerStates.dataPointsXIndex,
    screenerStates.dataPointsYIndex,
    screenerStates.dataPointsZIndex,
    screenerStates.filtersData,
    Socket?.readyState,
    Authorized
  ]);

  useEffect(() => {
    if (
      widget.id
      && screenerStates.dataPointsXIndex
      && screenerStates.dataPointsYIndex
    ) {
      const newWidget = {
        ...widget,
        datapointIndex: screenerStates.dataPointsXIndex,
        datapointYIndex: screenerStates.dataPointsYIndex,
        datapointZIndex: screenerStates.dataPointsZIndex ?? null,
        screenerId: screenerStates.screenerId ?? S_P_500_ID_PROD,
      };
      updateWidget(newWidget);
    }
  }, [
    widget?.id,
    screenerStates.screenerId,
    screenerStates.dataPointsXIndex,
    screenerStates.dataPointsYIndex,
    screenerStates.dataPointsZIndex,
    selectedDashboard?.type
  ]);

  useEffect(() => {
    if (watchLists?.length && itemsDeeplist.length) {
      updateFilter();
    }
  }, [watchLists, itemsDeeplist]);

  return {
    storedSymbols,
    dataPointList,
    dataSetLabels,
    dataSetValues,
    selectedType,
    queryId,
    screenerStates,
    setScreenerStates,
    setDefaultFilter,
    screenerSearch,
  };
};

export default useWidgetBubbleChart;
