import { useState, useEffect, useRef } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import { IWidgetHeaderHook } from '../types/DashboardComponentsInterfaces';
import RootStateTypes from '../../../store/RootStateTypes';
import {
  ColumnSetItemData, DataPanelItemData, DeepListItemData, ScreenItemData, TDashboardItemsData
} from '../../../utils/Types';
import { findItem } from '../utils/utils';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import { setSelectedScreen, setNewScreenFlag } from '../../../store/newScreen/actions';
import { setModalType } from '../../../store/watchlist/actions';
import { setSelectedWidgetOnCreateId } from '../../../store/dashboards/slice';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import { userPanel } from '../../../constants/account';

const useWidgetHeader: IWidgetHeaderHook = (widget, updateWidget) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newScreenRef = useRef<ScreenItemData[]>([]);
  const deeplistsRef = useRef<DeepListItemData[]>([]);
  const dataPanelRef = useRef<DataPanelItemData[]>([]);

  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [isColorDropOpen, setIsColorDropOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(() => {
    switch (widget.type) {
      case WidgetTypes.DEEP_LIST: return t('deepListName');
      case WidgetTypes.SCREENER: return t('screener');
      case WidgetTypes.DATA_PANEL: return t('dataPanel');
      default: return 'Screen';
    }
  });
  const [selectedItem, setSelectedItem] = useState<TDashboardItemsData | null>(null);

  const widgetConnectionQueries = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetConnectionQueries, shallowEqual
  );

  const {
    screenerItems,
    screenerPresetsItems,
    itemsWatchlist,
    tabsList,
    listPresetsWatchlist,
    dataPanelsItems,
    storedDataPanels,
    dataPanelsItemsPresets
  } = useDashboardItems();
  const itemsNewScreen = screenerItems as (ScreenItemData | ColumnSetItemData)[];
  const presetsNewScreen = screenerPresetsItems as (ScreenItemData | ColumnSetItemData)[];

  const selectedWidgetOnCreateId = useSelector(
    (state: RootStateTypes) => state.dashboardsState.selectedWidgetOnCreateId, shallowEqual
  );

  const getDiffScreen = (newScreen: ScreenItemData[], oldScreen: ScreenItemData[]): ScreenItemData | null => {
    if (JSON.stringify(newScreen) !== JSON.stringify(oldScreen)
      && widgetConnectionQueries[Number(widget.id)]) {
      let CleanNewScreens: ScreenItemData[] = [];
      let CleanOldScreens: ScreenItemData[] = [];
      newScreen.forEach((item: ScreenItemData) => {
        if (item?.items?.length) {
          CleanNewScreens = [...CleanNewScreens, ...item?.items];
        } else {
          CleanNewScreens.push(item);
        }
      });
      oldScreen.forEach((item: ScreenItemData) => {
        if (item?.items?.length) {
          CleanOldScreens = [...CleanOldScreens, ...item?.items];
        } else {
          CleanOldScreens.push(item);
        }
      });
      if (CleanNewScreens.length < CleanOldScreens.length) return null;
      const foundScreens = CleanNewScreens.filter(({ id: id1 }) => !CleanOldScreens.some(({ id: id2 }) => id2 === id1));
      return foundScreens?.length ? foundScreens[0] : null;
    }
    return null;
  };

  const getDiffDeeplist = (deeplists: DeepListItemData[], olddDeeplists: DeepListItemData[]):
  DeepListItemData | null => {
    if (JSON.stringify(deeplists) !== JSON.stringify(olddDeeplists)
      && widgetConnectionQueries[Number(widget.id)]) {
      let CleanNewDeeplists: DeepListItemData[] = [];
      let CleanOldDeeplists: DeepListItemData[] = [];
      deeplists.forEach((item: DeepListItemData) => {
        if (item?.items?.length) {
          CleanNewDeeplists = [...CleanNewDeeplists, ...item?.items];
        } else {
          CleanNewDeeplists.push(item);
        }
      });
      olddDeeplists.forEach((item: DeepListItemData) => {
        if (item?.items?.length) {
          CleanOldDeeplists = [...CleanOldDeeplists, ...item?.items];
        } else {
          CleanOldDeeplists.push(item);
        }
      });
      if (CleanNewDeeplists.length < CleanOldDeeplists.length) return null;
      const foundScreens = CleanNewDeeplists.filter(
        ({ id: id1 }) => !CleanOldDeeplists.some(({ id: id2 }) => id2 === id1)
      );
      return foundScreens?.length ? foundScreens[0] : null;
    }
    return null;
  };

  const getDiffDataPanel = (dataPanels: DataPanelItemData[], oldDataPanels: DataPanelItemData[]):
  DataPanelItemData | null => {
    if (JSON.stringify(dataPanels) !== JSON.stringify(oldDataPanels)
      && widgetConnectionQueries[Number(widget.id)]) {
      let CleanNewDataPanels: DataPanelItemData[] = [];
      let CleanOldDataPanels: DataPanelItemData[] = [];
      dataPanels.forEach((item: DataPanelItemData) => {
        if (item?.items?.length) {
          CleanNewDataPanels = [...CleanNewDataPanels, ...item?.items];
        } else {
          CleanNewDataPanels.push(item);
        }
      });
      oldDataPanels.forEach((item: DataPanelItemData) => {
        if (item?.items?.length) {
          CleanOldDataPanels = [...CleanOldDataPanels, ...item?.items];
        } else {
          CleanOldDataPanels.push(item);
        }
      });
      if (CleanNewDataPanels.length < CleanOldDataPanels.length) return null;
      const foundScreens = CleanNewDataPanels.filter(
        ({ id: id1 }) => !CleanOldDataPanels.some(({ id: id2 }) => id2 === id1)
      );
      return foundScreens?.length ? foundScreens[0] : null;
    }
    return null;
  };

  const setFilters = (isNew: boolean) => {
    dispatch(setSelectedWidgetOnCreateId(widget.id));
    if (selectedItem && !isNew) {
      dispatch(setSelectedScreen(selectedItem));
      dispatch(setNewScreenFlag(false));
    } else {
      dispatch(setSelectedScreen({}));
      dispatch(setNewScreenFlag(true));
    }
  };

  const createDeepListModal = () => {
    dispatch(setSelectedWidgetOnCreateId(widget.id));
    dispatch(setModalType(t('create')));
  };

  useEffect(() => {
    let foundItem: ScreenItemData | ColumnSetItemData | DeepListItemData | undefined;

    if (widget.type === WidgetTypes.SCREENER) {
      foundItem = findItem(itemsNewScreen, Number(widget?.screenerId))
        || findItem(presetsNewScreen, Number(widget?.screenerId));
    } else if (widget.type === WidgetTypes.DATA_PANEL) {
      const clonedData = [...dataPanelsItems, ...dataPanelsItemsPresets] as DataPanelItemData[];
      foundItem = findItem(clonedData, Number(widget?.dataPanelId));
    } else {
      const clonedData = [...tabsList, ...listPresetsWatchlist] as DeepListItemData[];
      foundItem = clonedData.find((item) => item.id === widget?.deepListId);
    }

    if (foundItem?.items?.length) {
      const deepFound: DataPanelItemData = foundItem.items.find(
        (item: DataPanelItemData) => item.id
          === (widget.type === WidgetTypes.SCREENER ? widget?.screenerId : widget?.dataPanelId)
      ) as unknown as DataPanelItemData;
      if (deepFound) {
        setTitle(deepFound?.title);
        setSelectedItem(deepFound);
        return;
      }
    }

    if (foundItem) {
      setTitle(foundItem.title || '');
      setSelectedItem(foundItem);
      return;
    }

    if (widget.type === WidgetTypes.SCREENER && !foundItem) {
      setTitle(t('screener'));
      return;
    }

    if (widget.type === WidgetTypes.DEEP_LIST && !foundItem) {
      const universeTab = tabsList.find((item) => item.data?.subType === UNIVERSE_TYPE);
      setTitle(universeTab?.title as string || t('deepListName'));
      setSelectedItem(universeTab as DeepListItemData);
      return;
    }

    if (widget.type === WidgetTypes.DATA_PANEL) {
      const defaultDataPanel = storedDataPanels.find(
        (dataPanel) => dataPanel?.title === userPanel
      ) || storedDataPanels.filter(
        (dataPanel: TDashboardItemsData) => !dataPanel?.isDefault
      )[storedDataPanels.length - 2] || storedDataPanels[0];
      setTitle(defaultDataPanel?.title);
      setSelectedItem(defaultDataPanel as DataPanelItemData | DeepListItemData);
    }
  }, [widget, tabsList, itemsNewScreen, presetsNewScreen, itemsWatchlist]);
  useEffect(() => {
    if (newScreenRef.current.length && widget.type === WidgetTypes.SCREENER) {
      const newScreen = getDiffScreen(itemsNewScreen as ScreenItemData[], newScreenRef.current);
      if (newScreen && widget.id === selectedWidgetOnCreateId) {
        setTitle(newScreen?.title || '');
        setSelectedItem(newScreen);
        updateWidget({ ...widget, screenerId: newScreen?.id });
      }
    }
    newScreenRef.current = itemsNewScreen as ScreenItemData[];
  }, [itemsNewScreen]);

  useEffect(() => {
    if (deeplistsRef.current.length && widget.type === WidgetTypes.DEEP_LIST) {
      const newDeeplist = getDiffDeeplist(itemsWatchlist as DeepListItemData[], deeplistsRef.current);
      if (newDeeplist && widget.id === selectedWidgetOnCreateId) {
        setSelectedItem(newDeeplist);
        setTitle(newDeeplist?.title || '');
        updateWidget({ ...widget, deepListId: newDeeplist?.id });
      }
    }
    deeplistsRef.current = itemsWatchlist as DeepListItemData[];
  }, [itemsWatchlist]);

  useEffect(() => {
    if (dataPanelRef.current.length && widget.type === WidgetTypes.DATA_PANEL) {
      const newDataPanel = getDiffDataPanel(dataPanelsItems as DataPanelItemData[], dataPanelRef.current);
      if (newDataPanel && widget.id === selectedWidgetOnCreateId) {
        setTitle(newDataPanel?.title || '');
        setSelectedItem(newDataPanel);
        updateWidget({ ...widget, dataPanelId: newDataPanel?.id });
      }
    }
    dataPanelRef.current = dataPanelsItems as DataPanelItemData[];
  }, [dataPanelsItems]);

  useEffect(() => {
    dispatch(setSelectedWidgetOnCreateId(null));
  }, []);

  return {
    setIsDropDownOpen,
    setIsColorDropOpen,
    setFilters,
    setTitle,
    isDropDownOpen,
    isColorDropOpen,
    title,
    selectedItem,
    setSelectedItem,
    createDeepListModal
  };
};

export default useWidgetHeader;
