import React from 'react';
import PropTypes from 'prop-types';
import TableHeaderCell from '../TableHeaderCell';
import { deepEqual } from '../../../utils/objectHelper';
import { REMOVE_COLUMN } from '../../../constants/screener';

const areEqual = (prevProps, nextProps) => {
  return deepEqual(prevProps.columns, nextProps.columns)
    && prevProps.checked === nextProps.checked
    && prevProps.sortStatus === nextProps.sortStatus
    && prevProps.sortHandler === nextProps.sortHandler
    && prevProps.setAllItems === nextProps.setAllItems;
};

const ListTableHeaderCell = ({
  columns,
  setAllItems,
  toggleIconHandler,
  sortHandler,
  sortStatus,
  columnsSize,
  updateColumnsSize,
  initColumnsSize,
  loaderStatus,
  setScrollLeft,
  checked,
  alertTable,
  setGridWidth,
  showWeight,
  widgetResizeSelector,
  isWidget,
  isWatchlist,
}) => (
  <>
    {columns.map((column, index) => (((column?.index === REMOVE_COLUMN.INDEX
      || index === REMOVE_COLUMN.FIRST
      || column?.index === REMOVE_COLUMN.SYM
    ) && !alertTable) || (column?.index === REMOVE_COLUMN.WEIGHT && !showWeight) ? null
      : (
        <TableHeaderCell
          key={[column?.index, column?.name].join('_')}
          column={column}
          setAllItems={setAllItems}
          toggleIconHandler={toggleIconHandler}
          sortHandler={sortHandler}
          sortStatus={sortStatus}
          name={column.name}
          columnsSize={columnsSize}
          updateColumnsSize={updateColumnsSize}
          initColumnsSize={initColumnsSize}
          loaderStatus={loaderStatus}
          zIndex={columns?.length + 2 - index}
          setScrollLeft={setScrollLeft}
          alertTable={alertTable}
          checked={checked}
          setGridWidth={setGridWidth}
          widgetResizeSelector={widgetResizeSelector}
          isWidget={isWidget}
          isWatchlist={isWatchlist}
        />
      )))}
  </>
);

ListTableHeaderCell.propTypes = {
  sortStatus: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired,
  setAllItems: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  toggleIconHandler: PropTypes.func.isRequired,
  columnsSize: PropTypes.shape({}).isRequired,
  initColumnsSize: PropTypes.func.isRequired,
  updateColumnsSize: PropTypes.func.isRequired,
  loaderStatus: PropTypes.bool.isRequired,
  setScrollLeft: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  alertTable: PropTypes.bool,
  setGridWidth: PropTypes.func.isRequired,
  showWeight: PropTypes.bool,
  widgetResizeSelector: PropTypes.number,
  isWidget: PropTypes.bool,
  isWatchlist: PropTypes.bool,
};

ListTableHeaderCell.defaultProps = {
  alertTable: false,
  showWeight: false,
  widgetResizeSelector: 0,
  isWidget: false,
  isWatchlist: false,
};

export default React.memo(ListTableHeaderCell, areEqual);
