import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import $api from '../../http';
import {
  DASHBOARD_ITEMS,
} from '../../constants/paths';
import { errorMessageAction } from '../../store/account/actions';
import {
  FILTER_SETTINGS,
  SCREENER_VIEWS,
  TABLE_SCREEN_PATH,
} from '../../constants/screener';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../store/auth/actions';
import { DASHBOARD_ITEM_EXISTS, WATCHLIST_FULL } from '../../constants/responseStatuses';
import {
  setAlertsData,
  setSelectedTab,
  setTabFromDropDownWatchlist,
  updateFavouriteWatchlist,
  updateSpecificWatchlist
} from '../../store/watchlist/actions';
import {
  clearSelectedSymbolsScreener,
  setMarketsFilterWatchlistAction,
  setSearchHeaderValue, setSearchValue
} from '../../store/screener/actions';
import { sortDataHandler } from '../watchListsHelper';
import {
  TAB_SYMBOLS_LIMIT
} from '../../constants/watchlist';
import { setCleanedItemsForTarget } from '../../store/tableData/slice';
import { useDashboardItems } from '../../context/DasboardItemsContext/DashboardItemsProvider';
import useItemsMutations from '../../tanStack/Items/Mutations/itemsMutations';

const useWatchList = () => {
  const { t } = useTranslation();
  const tabsListRef = useRef([]);
  const presetsRef = useRef([]);

  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual);

  const {
    tabsList,
    listPresetsWatchlist,
    setTabsList
  } = useDashboardItems();

  const screenerView = useSelector((state) => state.accountState.userSettings.screenerView, shallowEqual);
  const lastTablePath = useSelector((state) => state.accountState.userSettings.lastTablePath, shallowEqual);
  const searchHeaderValue = useSelector((state) => state.screenerState.globalSearchHeaderValue, shallowEqual);
  const {
    updateDashboardItemMutation,
    saveOrderMutation,
    updateSortDataMutation,
    addSymbolToWatchlistMutation,
    updateStocksForEmptyWlItemMutation,
    updateWatchListDataMutation
  } = useItemsMutations();

  tabsListRef.current = tabsList;
  presetsRef.current = listPresetsWatchlist;

  useEffect(() => {
    return () => {
      tabsListRef.current = null;
      presetsRef.current = null;
    };
  }, []);

  const successHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
    dispatch(setTabFromDropDownWatchlist({}));
  };
  const errorHandler = ({ message, title, type }) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type }));
  };
  const errorNotionWatchlistExist = (name) => {
    return {
      title: t('watchlistExist', { title: name }),
      message: t('changeName'),
      type: t('error')
    };
  };
  const addNewSymbolSuccessHandler = (data) => {
    dispatch(setMarketsFilterWatchlistAction(data));
    dispatch(setSelectedTab(data));
    successHandler(data.title, t('watchlistUpdate'));
  };
  const addNewSymbolErrorHandler = (error, newSelectedTab) => {
    if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
      const message = errorNotionWatchlistExist(newSelectedTab.title);
      errorHandler(message);
    }
    if (error.response.data.message === WATCHLIST_FULL) {
      const errorFullNotion = {
        title: t('watchlistLimit'),
        message: t('watchlistLimitCount'),
        type: t('error')
      };
      errorHandler(errorFullNotion);
    }
  };
  const copyToSuccessHandler = (data) => {
    if (selectedTab.id === data.id) {
      dispatch(setSelectedTab(data));
    }

    if (searchHeaderValue) {
      dispatch(setSearchHeaderValue(''));
      dispatch(setSearchValue({}));
    }

    successHandler(data.title, t('watchlistUpdate'));
  };
  const copyToErrorHandler = (error, newSelectedTab) => {
    if (error.response.data.message === WATCHLIST_FULL) {
      const errorFullNotion = {
        title: t('watchlistLimit'),
        message: t('watchlistLimitCount'),
        type: t('error')
      };
      errorHandler(errorFullNotion);
    }
    if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
      const message = errorNotionWatchlistExist(newSelectedTab.title);
      errorHandler(message);
    }
  };

  const saveSortingToWatchlist = (id, sortData, isAlertTable = false) => {
    const successActionHandler = (updatedItem) => {
      if (updatedItem) {
        if (isAlertTable) {
          dispatch(setAlertsData(updatedItem));
        }
        dispatch(updateSpecificWatchlist(updatedItem));
        if (!searchHeaderValue) {
          dispatch(setSelectedTab(updatedItem));
        }
      }
    };
    const currentItem = [...tabsListRef.current, ...presetsRef.current].find(((tab) => tab?.id === id)) || selectedTab;
    updateSortDataMutation.mutate({
      currentSelectedScreen: currentItem,
      sortData,
      errorHandler: () => {},
      successActionHandler
    });
  };
  const addNewSymbolToWatchlist = (selectedSymbolsNew = null, newSelectedTab = null) => {
    addSymbolToWatchlistMutation.mutate({
      selectedSymbolsNew,
      newSelectedTab,
      copyToFlag: false,
      wlSuccessItemHandler: addNewSymbolSuccessHandler,
      wlErrorItemHandler: addNewSymbolErrorHandler,
    });
  };
  const copySymbolsToWatchlist = (item, newSymbols) => {
    addSymbolToWatchlistMutation.mutate({
      selectedSymbolsNew: newSymbols,
      newSelectedTab: item,
      copyToFlag: true,
      wlSuccessItemHandler: copyToSuccessHandler,
      wlErrorItemHandler: copyToErrorHandler
    });
  };
  const updateWatchlistData = (id, data) => {
    updateDashboardItemMutation.mutate({
      id,
      data,
      errorHandler: (error) => dispatch(errorMessageAction({ errorMessage: error.message })),
    });
  };
  const saveOrder = (itemId, placeAfterId, itemType) => {
    saveOrderMutation.mutate({
      itemId,
      placeAfterId,
      itemType,
      isFolder: false
    });
  };
  const updateStocksForEmptyDL = (item) => {
    updateStocksForEmptyWlItemMutation.mutate({
      item,
      errorHandler: (error) => dispatch(errorMessageAction({ errorMessage: error.message }))
    });
  };
  const updateLocalWatchlist = (watchList, symbolsArray) => {
    const updateData = {
      ...watchList,
      data: {
        ...watchList.data,
        stocks: symbolsArray.length,
        filters: [[[
          FILTER_SETTINGS.ZERO,
          FILTER_SETTINGS.ZERO,
          symbolsArray.length > 0 ? [...new Set([...symbolsArray])] : []
        ]]]
      }
    };

    setTabsList((prevTabsList) => prevTabsList.map((item) => (item.id === updateData.id ? updateData : item)));
    if (selectedTab.id === updateData.id) {
      dispatch(setSelectedTab(updateData));
      dispatch(setMarketsFilterWatchlistAction(updateData.data.filters));
    }
  };
  const updateTargetList = (targetList, symbolsArray, symbol = null, updateFlag = false, noPostAction = false) => {
    if (targetList.id === selectedTab.id && searchHeaderValue) {
      dispatch(setSearchHeaderValue(''));
      dispatch(setSearchValue({}));
    }

    if (noPostAction) {
      updateLocalWatchlist(targetList, symbolsArray);
    }

    updateWatchListDataMutation.mutate({
      data: targetList,
      symbolsArray,
      successActionHandler: (updatedItem) => {
        if (noPostAction) return;
        dispatch(clearSelectedSymbolsScreener());
        if (selectedTab.id === updatedItem.id) {
          if (updateFlag && lastTablePath !== TABLE_SCREEN_PATH.SCREENER && screenerView !== SCREENER_VIEWS.CHART) {
            dispatch(setMarketsFilterWatchlistAction(updatedItem));
            dispatch(setCleanedItemsForTarget([symbol.index]));
          }
          if (!searchHeaderValue) {
            dispatch(setSelectedTab(updatedItem));
          }
        }
      },
      errorHandler: (error) => {
        if (error.response.data.message === WATCHLIST_FULL) {
          const errorFullNotion = {
            title: t('watchlistLimit'),
            message: t('watchlistLimitCount'),
            type: t('error')
          };
          errorHandler(errorFullNotion);
        }
      }
    });
  };
  const addSymbolToTargetList = ({ symbol, targetList, noPostAction = false }) => {
    if (targetList && targetList.data) {
      const symbolsList = [...targetList.data.filters[0][0][2], symbol.sortIndex];
      const filteredList = symbolsList.filter((item) => item !== 100000000);

      const sortedData = sortDataHandler(filteredList);
      const noPostActionFlag = symbolsList.length < TAB_SYMBOLS_LIMIT && noPostAction;

      updateTargetList(targetList, sortedData, null, false, noPostActionFlag);
    }
  };
  const removeSymbolFromTargetList = ({
    symbol, currentTargetList, updateFlag = false, noPostAction = false
  }) => {
    const buffer = currentTargetList.data.filters[0][0][2];
    const index = buffer.indexOf(symbol.sortIndex);
    if (index !== -1) {
      buffer.splice(index, 1);
    }
    const noPostActionFlag = buffer.length < TAB_SYMBOLS_LIMIT && noPostAction;

    updateTargetList(currentTargetList, buffer, symbol, updateFlag, noPostActionFlag);
  };
  const moveSymbolToTargetList = ({
    symbol, targetList, currentTargetList, updateFlag = false
  }) => {
    removeSymbolFromTargetList({ symbol, currentTargetList, updateFlag });
    addSymbolToTargetList({ symbol, targetList });
  };
  const handlerTargetList = ({
    symbol, targetList, currentTargetList, updateFlag
  }) => {
    if (targetList && currentTargetList) {
      removeSymbolFromTargetList({
        symbol, currentTargetList, updateFlag, noPostAction: true
      });
      addSymbolToTargetList({ symbol, targetList, noPostAction: true });
    }

    if (targetList && !currentTargetList) {
      addSymbolToTargetList({ symbol, targetList, noPostAction: true });
    }

    if (!targetList && currentTargetList) {
      removeSymbolFromTargetList({
        symbol, currentTargetList, updateFlag: false, noPostAction: true
      });
    }
  };
  const deleteSymbolsFromWatchlist = (
    symbolIds,
    watchListItem,
    noPostAction = false,
    showNotification = true
  ) => {
    const symbolsToRemove = symbolIds || selectedSymbols;
    const currentTab = tabsList.find((tab) => tab?.id === watchListItem?.id) || selectedTab;
    const isSelectedTab = currentTab?.id === selectedTab?.id;
    const filtersData = currentTab?.data?.filters.flat(2)[2].filter((item) => !symbolsToRemove.includes(item));

    if (currentTab.title === 'Favorites') {
      currentTab.data.subType = 'Favorites';
    }

    if (!noPostAction) {
      updateLocalWatchlist(currentTab, filtersData);
    }

    updateWatchListDataMutation.mutate({
      data: currentTab,
      symbolsArray: filtersData,
      successActionHandler: (updatedItem) => {
        if (showNotification) {
          successHandler(updatedItem.title, t('watchlistUpdate'));
        }
        if (isSelectedTab) {
          dispatch(setMarketsFilterWatchlistAction(updatedItem));
        }
      },
      errorHandler: (error) => {
        if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
          const message = errorNotionWatchlistExist(currentTab.title);
          errorHandler(message);
        }
      }
    });
  };
  const removeHandlerFromTargetList = (symbols, currentTargetList) => {
    const isMultiChart = screenerView === SCREENER_VIEWS.CHART;
    const withoutRefresh = isMultiChart && !!selectedTab.parentId;

    deleteSymbolsFromWatchlist(symbols, currentTargetList, withoutRefresh, false);
    if (isMultiChart) {
      dispatch(clearSelectedSymbolsScreener());
    }
  };
  //

  const getWatchlistById = (id, favourite = false) => {
    try {
      $api.get(`${DASHBOARD_ITEMS}/${id}`).then((res) => {
        if (!favourite) {
          dispatch(updateSpecificWatchlist(res.data));
        }

        if (favourite) {
          dispatch(updateFavouriteWatchlist(res.data));
        }

        if (selectedTab?.id === res.data.id) {
          dispatch(setMarketsFilterWatchlistAction(res.data));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  return {
    saveOrder,
    copySymbolsToWatchlist,
    addNewSymbolToWatchlist,
    getWatchlistById,
    deleteSymbolsFromWatchlist,
    updateWatchlistData,
    addSymbolToTargetList,
    handlerTargetList,
    moveSymbolToTargetList,
    updateStocksForEmptyDL,
    saveSortingToWatchlist,
    removeHandlerFromTargetList,
  };
};

export default useWatchList;
