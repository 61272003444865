import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import WidgetHeader from './WidgetHeader';
import useSelectItems from '../hook/useSelectItems';
import { IWidgetRendered } from '../types/DashboardComponentsInterfaces';
import DataPanelContent from '../../DataPanel/components/DataPanelContent';
import useDataPanelConnection from '../../DataPanel/hooks/useDataPanelConnection';
import useDropdownCategory from '../../DataPanel/hooks/useDropdownCategory';
import styles from '../../DataPanel/sass/DataPanel.module.scss';
import WatchlistEmptyBlock from '../../WatchlistEmptyBlock/WatchlistEmptyBlock';
import { MAX_CATEGORY_NAME, MODAL_TYPES, PANEL_CONTENT_GRID_TYPES } from '../../DataPanel/dataPanelConstants';
import useDataPanelsModals from '../../DataPanel/hooks/useDataPanelsModals';
import DataPanelEditContent from '../../DataPanel/components/DataPanelEditContent';
import DataPanelAddComponent from '../../DataPanel/components/DataPanelAddComponent';
import useDataPanelItems from '../../DataPanel/hooks/useDataPanelItems';
import useDataPanelWidget from '../hook/useDataPanelWidget';
import { ShortSymbol } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';
import RootStateTypes from '../../../store/RootStateTypes';
import ClientOnlyPortal from '../../ClientOnlyPortal/ClientOnlyPortal';
import DataPanelModal from '../../DataPanel/components/DataPanelModal';
import { setDataPanelEditMenuOpen } from '../../../store/dashboards/slice';

const DataPanelWidget = ({
  widget, removeWidget,
  updateWidget, setColorGroupe,
  changeSymbolForGroup,
  openModalHandler,
  openItemsModalHandler,
  isEditable,
  activeSymbolIndexValue
}: IWidgetRendered): React.ReactElement => {
  const dispatch = useDispatch();

  const symbolsList = useSelector((state:RootStateTypes) => state.screenerState.symbolsList, shallowEqual);

  const {
    selectedItems,
    currentCount,
    clearSelections,
  } = useSelectItems();

  const {
    togglePanelAdd,
    currentDataPanels, editMode,
    setEditMode,
  } = useDataPanelWidget(widget);

  const {
    renameCategory,
    updatePanelsData,
    updateCategoryData,
    maxCategoryError,
    checkUniqueCategory,
    saveDataPanels
  } = useDataPanelItems(widget);

  const {
    toggleModal,
    isModalOpen, modalData
  } = useDataPanelsModals(widget);

  const {
    isOpen, toggleDropOpen,
    dataPanelCategory, selectedCategory,
    setSelectedCategory, requestedPoints,
    scrollHandler, addPoint, removePoint, selectedPoints,
    search, handleSearch, getPointTitle, getPointData
  } = useDropdownCategory(widget);

  const { dataPoints, screenerId, symbol } = useDataPanelConnection(
    false, widget,
    activeSymbolIndexValue || symbolsList?.find((symbolRow: ShortSymbol) => symbolRow?.sym === widget?.symbol)?.index
  );

  const addNewCategory = () => {
    setEditMode(true);
    dispatch(setDataPanelEditMenuOpen(true));
    togglePanelAdd();
    toggleModal(true, MODAL_TYPES.ADD_CATEGORY, null);
  };

  const handleRemove = (category: string) => {
    toggleModal(true, MODAL_TYPES.REMOVE_CATEGORY, category);
  };

  const handleRename = (category: string, newCategory: string) => {
    renameCategory(category, newCategory);
    if (newCategory && newCategory?.length <= MAX_CATEGORY_NAME && checkUniqueCategory(newCategory)) {
      const timeoutSet = setTimeout(() => {
        setSelectedCategory(newCategory);
        clearTimeout(timeoutSet);
      }, 100);
    }
  };

  const handleRemovePoint = (point: number) => {
    removePoint(point);
  };

  const getDataPanelContent = () => {
    if (!editMode) {
      return (
        <DataPanelContent
          symbolIndex={symbol as number}
          dataPanels={currentDataPanels}
          dataPoints={dataPoints}
          screenerId={screenerId}
          getPointData={getPointData}
          dataPanelType={PANEL_CONTENT_GRID_TYPES.DEFAULT}
          widget={widget as { id: string, dataPanelId: number }}
          changeSymbolForGroup={changeSymbolForGroup}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <div id={`dataPanelComponent-${widget?.id}`} className={`${styles.dataPanel} ${styles.open}`}>
        <WidgetHeader
          openModalHandler={openModalHandler}
          openItemsModalHandler={openItemsModalHandler}
          updateWidget={updateWidget}
          widget={widget}
          removeWidget={removeWidget}
          setColorGroupe={setColorGroupe}
          selectedItems={selectedItems}
          clearSelections={clearSelections}
          symbolCounter={currentCount}
          isEditable={isEditable}
          togglePanelAdd={togglePanelAdd}
        />
        {(currentDataPanels && editMode) && (
          <DataPanelEditContent
            checkUniqueCategory={checkUniqueCategory}
            onSelect={setSelectedCategory}
            onRemove={handleRemove}
            onRename={handleRename}
            dataPanels={currentDataPanels}
            dataPanelType={PANEL_CONTENT_GRID_TYPES.DEFAULT}
            selected={selectedCategory}
            removeDataPoint={handleRemovePoint}
            getTitle={getPointTitle}
            updatePanelsData={updatePanelsData}
            updateCategoryData={updateCategoryData}
            saveDataPanels={saveDataPanels}
            widget={widget as { id: string, dataPanelId: number }}
          />
        )}
        {
          (currentDataPanels?.leftColumn.length || currentDataPanels?.rightColumn.length)
            ? getDataPanelContent()
            : <WatchlistEmptyBlock isDataPanel addNewCategory={addNewCategory} />
        }
      </div>
      <ClientOnlyPortal selector="#watchlist_modals">
        {isModalOpen ? (
          <DataPanelModal
            checkUniqueCategory={checkUniqueCategory}
            modalData={modalData as unknown as {
              modalType: string,
              category: string,
              header: string,
              icon: string,
              content: string,
              reverceAction: boolean,
              input: {
                label: string,
                placeholder: string,
              } }}
          />
        ) : <div />}
      </ClientOnlyPortal>

      {editMode && (
        <DataPanelAddComponent
          toggleModal={toggleModal}
          togglePanelAdd={togglePanelAdd}
          categories={dataPanelCategory}
          selectCategory={setSelectedCategory}
          selected={selectedCategory}
          isOpen={isOpen}
          toggleDropdown={toggleDropOpen}
          // addCategory={addCategory}
          pointList={requestedPoints}
          addPoint={addPoint}
          selectedPoints={selectedPoints}
          scrollHandler={scrollHandler}
          removePoint={removePoint}
          searchHandler={handleSearch}
          search={search}
          maxCategoryError={maxCategoryError}
          widget={widget as { id: string }}
        />
      )}
    </>
  );
};

export default DataPanelWidget;
