import { useRef, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { SCREENER_SORTING } from '../../../constants/tvWidgetOptions';
import { ISortItem, IUseSaveSortForSpecificPlace } from '../types/interfaces';
import RootStateTypes from '../../../store/RootStateTypes';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import useWatchList from '../../../utils/hooks/useWatchList';
import usePlaceData from './usePlaceData';
import { ScreenItemData } from '../../../utils/Types';

const useSaveSort = (): IUseSaveSortForSpecificPlace => {
  const currentSelectedScreen = useRef<ScreenItemData | null>(null);
  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const selectedScreen = useSelector((state: RootStateTypes) => state.newScreenState.selectedScreen, shallowEqual);
  const alertTab = useSelector((state: RootStateTypes) => state.watchlistState.alertsTab, shallowEqual);

  const { updateStoreHandler, updateSortingNestedData } = useLastStateUpdateStore();
  const { saveSortingToMyScreen } = useNewScreen();
  const { saveSortingToWatchlist } = useWatchList();

  const {
    isEmptyScreener, isSelectedScreen, isSelectedPreset, isDeepList
  } = usePlaceData();

  const saveSortHandler = (sortData: ISortItem[], id: number | undefined) => {
    if (isDeepList && selectedTab?.id === alertTab?.id) {
      saveSortingToWatchlist(selectedTab.id, sortData, true);
    }
    if (isEmptyScreener) {
      updateStoreHandler(SCREENER_SORTING, sortData);
    }
    if (isSelectedScreen) {
      saveSortingToMyScreen(currentSelectedScreen.current, sortData);
    }
    if (isSelectedPreset) {
      updateSortingNestedData(id ?? currentSelectedScreen?.current?.id, sortData);
    }
    if (isDeepList) {
      saveSortingToWatchlist(id ?? selectedTab.id, sortData);
    }
  };

  useEffect(() => {
    currentSelectedScreen.current = selectedScreen;
  }, [selectedScreen]);

  return { saveSortHandler };
};

export default useSaveSort;
