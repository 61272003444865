import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { LAST_DASHBOARDS_TAB, LAST_TABLE_PATH, SELECTED_DATA_PANEL_ID } from '../../constants/storage';
import { removeLocalStorage } from '../storageWorks';
import $api from '../../http';
import {
  DASHBOARD_ITEM_GET_SHARED_ITEM,
  DASHBOARD_ITEM_SHARE,
  DASHBOARD_ITEMS,
} from '../../constants/paths';
import { errorMessageAction } from '../../store/account/actions';
import {
  getItemsId,
  setCreatedFolder,
  setSelectedDataPanel,
  setSelectedScreen,
} from '../../store/newScreen/actions';
import constants from '../../constants/filters';
import {
  TEMPLATE_TYPE,
  TABLE_SCREEN_PATH
} from '../../constants/screener';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../store/auth/actions';
import { DASHBOARD_ITEM_EXISTS } from '../../constants/responseStatuses';
import {
  getTitle,
  getTitleCreateSuccess
} from '../helperNewScreenModal';
import useLastStateUpdateStore from './useLastStateUpdateStore';
import { setSharedDashboardItem } from '../../store/screener/actions';
import { SELECTED_SCREEN_ID, SELECTED_TAB_ID } from '../../constants/tvWidgetOptions';
import { setModalType, setSelectedTab } from '../../store/watchlist/actions';
import ROUTES_URLS from '../../constants/routesUrls';
import useItemsMutations from '../../tanStack/Items/Mutations/itemsMutations';
import { setRequestStateAction } from '../../store/filters/slice';

const useNewScreen = (currentItemSelected, modalHandler = () => undefined) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const favoritesFilter = useSelector((state) => state.screenerState.favoritesFilter, shallowEqual);
  const lastPath = useSelector((state) => state.accountState.userSettings.lastTablePath, shallowEqual);

  const { updateStoreHandler } = useLastStateUpdateStore();

  const {
    updateScreenFiltersMutation,
    updateFiltersInBackgroundMutation,
    updateColumnsSetStateMutation,
    updateSortDataMutation,
    saveOrderMutation,
    createItemMutation,
    saveScreenToColumnsSetMutation,
    updateItemMutation
  } = useItemsMutations();

  const successHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
    modalHandler();
  };
  const errorHandler = (title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: `The name ${title} already exists` }));
    dispatch(messageAction({ message: t('changeName') }));
    dispatch(changeNotificationTypeAction({ type: t('error') }));
  };
  const alertHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('alert') }));
  };
  const updateLastStateHandler = (data) => {
    if (data.type === TEMPLATE_TYPE.WATCHLIST
      || data.type === TEMPLATE_TYPE.WATCHLIST_PRESETS
    ) {
      dispatch(setSelectedTab(data));
      updateStoreHandler(SELECTED_TAB_ID, data.id);
    } else if (data.type === TEMPLATE_TYPE.SCREENER) {
      dispatch(setSelectedScreen(data));
      updateStoreHandler(SELECTED_SCREEN_ID, data.id);
    } else if (data.type === TEMPLATE_TYPE.DATA_PANEL) {
      dispatch(setSelectedDataPanel(data));
      updateStoreHandler(SELECTED_DATA_PANEL_ID, data.id);
    } else if (data.type === TEMPLATE_TYPE.DASHBOARDS) {
      updateStoreHandler(LAST_DASHBOARDS_TAB, data.id);
    } else {
      dispatch(getItemsId(data));
    }
  };
  const successActionHandler = (item) => {
    const labelSuccess = getTitleCreateSuccess(item.type, t);

    successHandler(item.title, labelSuccess);

    if (item.id && item.id !== 0 && item.type !== constants.columns) {
      updateLastStateHandler(item);
    }
    if (lastPath === TABLE_SCREEN_PATH.DASHBOARDS && item.type === constants.deepList) {
      currentItemSelected(item);
    }

    dispatch(setModalType(''));
    dispatch(setCreatedFolder(null));
  };

  const createScreen = (nameScreen, filters, folderId, nameFolder, type, description) => {
    createItemMutation.mutate({
      nameScreen,
      filters,
      folderId,
      nameFolder,
      type,
      columns: false,
      description,
      favoritesFilter,
      successActionHandler,
      errorHandler
    });
  };
  const createPresets = (nameScreen, filters, folderId = -1, nameFolder = '') => {
    createItemMutation.mutate({
      nameScreen,
      filters,
      folderId,
      nameFolder,
      type: constants.presets,
      favoritesFilter,
      successActionHandler,
      errorHandler
    });
  };
  const saveScreenIdToColumnSet = (data, columnSetId, pagePath) => {
    saveScreenToColumnsSetMutation.mutate({ data, id: columnSetId, pagePath });
  };
  const updateScreenFilters = (screenId, filters, data) => {
    updateScreenFiltersMutation.mutate({
      screenId,
      filters,
      data,
      successActionHandler: (updatedItem) => {
        dispatch(setSelectedScreen(updatedItem));
        dispatch(setRequestStateAction(updatedItem?.data?.filters));
      },
    });
  };
  const updateFiltersState = (screenId, data) => {
    if (screenId) {
      updateFiltersInBackgroundMutation.mutate({ screenId, data });
    }
  };
  const updateColumnsSetState = (columnsSetId, selectedColumns, callback = () => {}) => {
    updateColumnsSetStateMutation.mutate({ columnsSetId, selectedColumns, callback });
  };
  const saveSortingToMyScreen = (currentSelectedScreen, sortData) => {
    updateSortDataMutation.mutate({ currentSelectedScreen, sortData, errorHandler });
  };
  const saveOrder = (itemId, placeAfterId, itemType) => {
    saveOrderMutation.mutate({
      itemId,
      placeAfterId,
      itemType,
      isFolder: false
    });
  };
  const saveOrderFolder = (folderId, placeAfterId, itemType) => {
    saveOrderMutation.mutate({
      itemId: folderId,
      placeAfterId,
      itemType,
      isFolder: true
    });
  };
  const addColumnsToScreen = (data, widgetType) => {
    if (!data.id) return;
    updateItemMutation.mutate({
      item: data, itemSuccessActionHandler: () => {}, errorHandler, widgetType
    });
  };

  // const updateCurrentPreset = (id, title, saveColumns) => {
  //   saveColumns(id);
  //   dispatch(setColumnSetsId(id));
  //   dispatch(setNameColumnSets(title));
  //   dispatch(setColumnSetUpdateFlag(false));
  // };

  // const restoreToRecommendedHandler = (id) => {
  //   if (id === columnSetIdItem) {
  //     const recommended = columns.find((item) => item.isDefault);
  //
  //     if (recommended) {
  //       const columnsData = getActiveColumns(columnsList,
  //       [...FIXED_COLUMNS, ...recommended?.data.selectedColumns], []);
  //       dispatch(setColumnSetsId(recommended.id));
  //       dispatch(setColumnsScreenerAction({ data: columnsData }));
  //       dispatch(setNameColumnSets(t('recommended')));
  //     }
  //   }
  // };

  const hideItem = useCallback(
    (screenId, flag) => {
      if (screenId) {
        $api.patch(
          `${DASHBOARD_ITEMS}/${screenId}`,
          {
            isVisible: flag,
          }
        ).then(() => {
          // getData(resolve);
        }).catch((error) => {
          if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
            errorHandler(screenId);
          }
        });
      } else {
        createScreen(constants.myScreen);
      }
    },
    [],
  );

  const shareDashboardItem = (id, itemType, itemName) => {
    $api.get(`${DASHBOARD_ITEMS}/${DASHBOARD_ITEM_SHARE}/${id}`).then((resolve) => {
      const title = getTitle(itemType);
      const linkId = resolve.data?.split(':')[resolve.data?.split(':').length - 1];
      const copiedLink = `${process.env.REACT_APP_PUB_APP_URL}share-item/${linkId}`;
      const textField = document.createElement('textarea');
      textField.innerText = copiedLink;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      successHandler(itemName, t('linkCopied', { itemType: title }));
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    });
  };
  const getSharedDashboardItem = (hash) => {
    $api.get(`${DASHBOARD_ITEMS}/${DASHBOARD_ITEM_GET_SHARED_ITEM}/${hash}`).then((resolve) => {
      dispatch(setSharedDashboardItem(resolve.data));
      removeLocalStorage('sharedItemHash');
      switch (resolve.data.type) {
        case TEMPLATE_TYPE.WATCHLIST: {
          history.push(ROUTES_URLS.TABLE);
          updateStoreHandler(LAST_TABLE_PATH, TABLE_SCREEN_PATH.DEEPLIST);
          break;
        }
        case TEMPLATE_TYPE.SCREENER: {
          history.push(ROUTES_URLS.TABLE);
          updateStoreHandler(LAST_TABLE_PATH, TABLE_SCREEN_PATH.SCREENER);
          break;
        }
        default: {
          history.push(ROUTES_URLS.TABLE);
          updateStoreHandler(LAST_TABLE_PATH, TABLE_SCREEN_PATH.SCREENER);
          break;
        }
      }
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    });
  };

  return {
    createScreen,
    addColumnsToScreen,
    updateScreenFilters,
    updateFiltersState,
    createPresets,
    hideItem,
    updateColumnsSetState,
    saveScreenIdToColumnSet,
    alertHandler,
    successHandler,
    errorHandler,
    saveSortingToMyScreen,
    saveOrder,
    saveOrderFolder,
    shareDashboardItem,
    getSharedDashboardItem
  };
};

export default useNewScreen;
