import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import $api from '../../../http';
import { DASHBOARD_FOLDERS } from '../../../constants/paths';
import { ICreateArgs, IRemoveArgs, IUpdateArgs } from '../types';
import { FolderItemData } from '../../../utils/Types';

const createFolder = async ({ name, type }: ICreateArgs) => {
  const response = await $api.post(DASHBOARD_FOLDERS, { name, type });
  return response.data;
};

const updateFolder = async ({ name, id }: IUpdateArgs) => {
  const response = await $api.patch(`${DASHBOARD_FOLDERS}/${id}`, { name });
  return response.data;
};

const removeFolder = async ({ id, name, type }: IRemoveArgs) => {
  await $api.delete(`${DASHBOARD_FOLDERS}/${id}`);
  return { type, id, name };
};

interface IUseFolderMutations {
  creteFolderMutation: UseMutationResult<FolderItemData, Error, ICreateArgs>,
  updateFolderMutation: UseMutationResult<FolderItemData, Error, IUpdateArgs>,
  removeFolderMutation: UseMutationResult<{
    type: string | undefined;
    id: string | number;
    name: string; },
  Error,
  IRemoveArgs
  >,
}

const useFolderMutations = (): IUseFolderMutations => {
  const queryClient = useQueryClient();

  const creteFolderMutation = useMutation({
    mutationFn: createFolder,
    onSuccess: (newFolder, variables) => {
      variables.folderSuccessActionHandler(newFolder.id);
      queryClient.invalidateQueries({
        queryKey: [`${newFolder.type}Folders`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.name);
    }
  });

  const updateFolderMutation = useMutation({
    mutationFn: updateFolder,
    onSuccess: (newFolder, variables) => {
      variables.folderSuccessActionHandler();
      queryClient.invalidateQueries({
        queryKey: [`${newFolder.type}Folders`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.name);
    }
  });

  const removeFolderMutation = useMutation({
    mutationFn: removeFolder,
    onSuccess: (_, variables) => {
      const { type = '' } = variables;
      variables.folderSuccessActionHandler();
      queryClient.invalidateQueries({
        queryKey: [`${type}Folders`],
        exact: true,
        refetchType: 'active',
      }).then();
    },
    onError: (_, variables) => {
      variables.errorHandler(variables.name);
    }
  });

  return {
    creteFolderMutation,
    updateFolderMutation,
    removeFolderMutation
  };
};

export default useFolderMutations;
