import React, {
  useState, useEffect, useCallback, useMemo, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import TextField from '../TextField/TextField';
import styles from './sass/GlobalSearch.module.scss';
import Button from '../Button/Button';
import SearchField from './components/SearchField';
import ResultBlock from './components/ResultBlock';
import { KEY_CODES } from '../../constants/screener';
import {
  setVisibleColumnsScreenAction,
} from '../../store/screener/actions';

const GlobalSearch = ({
  handlerSearch,
  search,
  listResults,
  setSearch,
  setGlobalSearchVisible,
  setIsVisibleFilters,
  setPaging,
  hideChart,
  handleOpenChart,
  setActiveSymbolValue,
  searchDropdownVisible,
  setSearchDropdownVisible,
  isWidget,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ref = useRef(null);
  const inputRef = useRef(null);

  const [readySearch, setReadySearch] = useState('');
  const [activeResult, setActiveResult] = useState(0);

  useEffect(() => {
    dispatch(setVisibleColumnsScreenAction(false));
    setIsVisibleFilters(false);
  }, []);

  const handleSearch = useCallback((item) => {
    if (isWidget) {
      setSearch(item.sym);
      setActiveSymbolValue(item.index, item, true);
    } else {
      setActiveSymbolValue(item.index, item);
      setReadySearch(item.sym);
      setSearch('');
      if (hideChart) {
        handleOpenChart();
      }
    }
  }, [search]);

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      setGlobalSearchVisible(false);
    }
  };

  useEffect(() => {
    if (!searchDropdownVisible && readySearch !== search) {
      setSearchDropdownVisible(true);
      inputRef.current.focus();
    }

    if (search === '') {
      setSearchDropdownVisible(true);
      inputRef.current.focus();
    }
  }, [search]);

  const closeModalHandler = () => {
    setGlobalSearchVisible(false);
    if (isWidget) {
      setPaging(false);
      if (search === '') {
        setActiveSymbolValue(undefined, undefined, true);
      }
    } else {
      setSearch('');
      setPaging(true);
    }
  };

  const keyPressHandler = (event) => {
    if (event.code === KEY_CODES.ARROW_DOWN && activeResult !== listResults.length - 1) {
      setActiveResult(activeResult + 1);
    }
    if (event.code === KEY_CODES.ESC) {
      closeModalHandler();
    }
    if (event.code === KEY_CODES.ARROW_UP && activeResult > 0) {
      setActiveResult(activeResult - 1);
    }
    if (event.code === KEY_CODES.ENTER) {
      handleSearch(listResults[activeResult]);
      setActiveResult(0);
      setGlobalSearchVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyPressHandler);
    return () => {
      window.removeEventListener('keyup', keyPressHandler);
    };
  }, [activeResult, listResults]);

  const handleChange = (event) => {
    handlerSearch(event);
  };

  useEffect(() => () => {
    ref.current = null;
    inputRef.current = null;
    if (!isWidget) {
      setSearch('');
    }
  }, []);

  return (
    useMemo(() => (
      <div className={styles.globalSearch} onClick={handleClickOutside} aria-hidden="true">
        <div className={styles.globalSearch_modal} ref={ref}>
          <div className={styles.globalSearch_header}>
            <div className={styles.titles}>
              <TextField text={t('searchBySymbol')} styleType="globalModalTitle" />
              <Button
                handleClick={closeModalHandler}
                buttonClass={styles.globalSearch_closeModal}
              >
                <span className={styles.closeIcon} />
              </Button>
            </div>

            <div className={styles.globalSearch_inputWrapper}>
              <SearchField
                handlerChange={handleChange}
                placeholder={t('placeholderSearch')}
                headerResult={t('searchResults')}
                value={search}
                listResults={listResults}
                setValue={setSearch}
                inputRef={inputRef}
              />
            </div>
          </div>
          <ResultBlock
            setPaging={setPaging}
            value={search}
            listResults={listResults}
            handleClick={handleSearch}
            activeResult={activeResult}
            setGlobalSearchVisible={setGlobalSearchVisible}
            isWidget={isWidget}
          />
        </div>
      </div>
    ), [search, listResults, activeResult])
  );
};

GlobalSearch.propTypes = {
  setPaging: PropTypes.func.isRequired,
  setGlobalSearchVisible: PropTypes.func.isRequired,
  handlerSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  listResults: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSearch: PropTypes.func.isRequired,
  hideChart: PropTypes.bool.isRequired,
  handleOpenChart: PropTypes.func.isRequired,
  setIsVisibleFilters: PropTypes.func,
  searchDropdownVisible: PropTypes.bool,
  setSearchDropdownVisible: PropTypes.func,
  isWidget: PropTypes.bool,
};

GlobalSearch.defaultProps = {
  setIsVisibleFilters: () => undefined,
  searchDropdownVisible: false,
  setSearchDropdownVisible: () => undefined,
  isWidget: false,
};

export default GlobalSearch;
