import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_SIZE_STATE, FIXED_COLUMNS
} from '../../../constants/screener';
import { setColumnSetsId, setNameColumnSets } from '../../../store/newScreen/actions';
import { getActiveColumns } from '../../../utils/helperColumnsWorker';
import {
  setColumnsScreenerAction
} from '../../../store/screener/actions';
import { getTitleCreateSuccess } from '../../../utils/helperNewScreenModal';
import useSaveColumns from './useSaveColumns';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import { saveUserState } from '../../../store/account/actions';
import { setCleanedItemsColumnsAction } from '../../../store/tableData/slice';
import useItemsMutations from '../../../tanStack/Items/Mutations/itemsMutations';

const useNewColumnSet = (modalHandler = () => {}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);
  const userLastState = useSelector((state) => state.accountState.userSettings, shallowEqual);
  const { createItemMutation } = useItemsMutations();
  const { saveColumnsToSpecificPlace } = useSaveColumns();
  const { successHandler, errorHandler } = useNewScreen();

  const successHandlerColumns = (message, title) => {
    successHandler(message, title);
    modalHandler();
  };

  const initColumnsSizeState = (id) => {
    const newState = { ...userLastState };
    newState.columnsWidth = {
      ...newState.columnsWidth,
      ...{ [id]: DEFAULT_SIZE_STATE }
    };

    dispatch(saveUserState(newState));
  };

  const setNewColumnSet = (columnSet) => {
    const activeColumns = getActiveColumns(columnsList, FIXED_COLUMNS, []);

    dispatch(setColumnSetsId(columnSet?.id));
    dispatch(setColumnsScreenerAction({ data: activeColumns }));
    dispatch(setNameColumnSets(columnSet?.title || t('recommended')));

    initColumnsSizeState(columnSet?.id);
    saveColumnsToSpecificPlace(columnSet?.id, columnSet);
  };

  const successActionHandler = (newItem) => {
    const labelSuccess = getTitleCreateSuccess(newItem.type, t);
    successHandlerColumns(newItem.title, labelSuccess);
    setNewColumnSet(newItem);
    dispatch(setCleanedItemsColumnsAction());
  };

  const createColumnsSet = (params) => {
    createItemMutation.mutate({
      nameScreen: params.itemName,
      filters: [],
      folderId: params.folderTargetId,
      nameFolder: params.folderTargetName,
      type: params.itemType,
      columns: false,
      description: params.descriptionValue,
      favoritesFilter: [],
      successActionHandler,
      errorHandler
    });
  };

  return { createColumnsSet };
};

export default useNewColumnSet;
