import React, {
  createRef,
  memo,
  useRef,
  useEffect,
  useState
} from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  AutoSizer, List, ListRowProps
} from 'react-virtualized';
import { RenderedRows } from 'react-virtualized/dist/es/List';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../sass/WidgetCharts.module.scss';
import { IPerformanceCharts } from '../types/WidgetChartsInterfaces';
import { useTheme } from '../../../utils/hooks/useTheme';
import PerformanceChartSymbolItem from './PerformanceChartSymbolItem';
import {
  BAR_HEIGHT, VirtualizeData
} from '../types/WidgetChartsEnums';
import { KEY_CODES } from '../../../constants/screener';
import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import pBackgroundColor from '../utils/performanceCharts/backgroundColor';
import pLabelColor from '../utils/performanceCharts/labelColor';
import pFormatter from '../utils/performanceCharts/formatter';
import useWatchList from '../../../utils/hooks/useWatchList';
import RootStateTypes from '../../../store/RootStateTypes';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
const PerformanceChart = ({
  height,
  dataPointsXType,
  onLoadMore,
  changeSymbolForGroup,
  widget,
  storedSymbols,
  targetLists,
  width,
  dataPointXIndex,
  screenerId,
  sortBy
}: IPerformanceCharts): React.ReactElement => {
  const hoveredSymbolIndex = useSelector(
    (state: RootStateTypes) => state.tableDataState.hoveredSymbolIndex, shallowEqual
  );

  const ref = createRef<List>();
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const debouncedUpdateLastState = useDebouncedCallback();
  const { handlerTargetList } = useWatchList();

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const { theme } = useTheme();
  const findIndex = storedSymbols.findIndex((item) => (sortBy === 1 ? item.value < 0 : item.value > 0));
  const findMaxValue = Math.max(...storedSymbols.map((o) => Math.abs(o.value)));
  const maxValue = findMaxValue + (findMaxValue * VirtualizeData.MAX_OFFSET);

  const onSelectionChange = (index: number) => {
    setSelectedIndex(index);
    changeSymbolForGroup(storedSymbols[index].sym, widget.colorGroup as string, widget.id);
  };

  const setItemsRef = (element: HTMLDivElement, index: number) => {
    itemsRef.current[index] = element;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    let newIndex = selectedIndex;
    let timeoutDuration = 0;

    switch (e.code) {
      case KEY_CODES.ARROW_UP:
        newIndex = newIndex === 0 ? storedSymbols.length - 1 : newIndex - 1;
        timeoutDuration = newIndex === storedSymbols.length - 1 ? 50 : 0;
        break;
      case KEY_CODES.ARROW_DOWN:
      case KEY_CODES.SPACE:
        newIndex = newIndex === storedSymbols.length - 1 ? 0 : newIndex + 1;
        timeoutDuration = newIndex === 0 ? 50 : 0;
        break;
      default: break;
    }

    ref?.current?.scrollToRow(newIndex);
    onSelectionChange(newIndex);

    setTimeout(() => {
      const elRef = itemsRef.current[newIndex];
      if (ref) {
        elRef?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
        elRef?.focus();
      }
    }, timeoutDuration);
  };

  const renderItem = ({ index, key, style }: ListRowProps) => {
    const item = storedSymbols[index];
    const data: ChartData<'bar'> = {
      labels: [item.sym],
      datasets: [
        {
          data: [Math.abs(item.value)],
          barThickness: 15,
          borderRadius: 2,
          backgroundColor: () => pBackgroundColor(findIndex, index, sortBy),
        },
      ],
    };

    const chartOptions: ChartOptions<'bar'> = {
      animation: false,
      maintainAspectRatio: false,
      responsive: true,
      indexAxis: 'y' as const,
      layout: {
        padding: {
          right: 100,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
        x: {
          grace: '10%',
          stacked: false,
          display: false,
          max: maxValue,
          grid: {
            display: false,
          },
        },

      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: true,
          color: (context) => pLabelColor(theme, dataPointsXType, context, findIndex, index, sortBy),
          align: 'end',
          anchor: 'end',
          font: { size: 10, weight: 500 },
          offset: 0,
          formatter: (value) => pFormatter(dataPointXIndex, dataPointsXType, value, findIndex, index, sortBy),
        },
        tooltip: {
          enabled: false,
        },

      },
    };

    return (
      <div
        className={`${styles.bar} ${selectedIndex === index && styles.selectedBarColor} 
        ${item.index === hoveredSymbolIndex.index
          && widget.id === hoveredSymbolIndex.dataId && styles.selectedBarColor}`}
        key={key}
        style={style}
        ref={(el) => setItemsRef(el as HTMLDivElement, index)}
        role="button"
        tabIndex={0}
        aria-label="highlight"
        onMouseDown={() => onSelectionChange(index)}
      >
        <div className={styles.symbolsWrapper}>
          <PerformanceChartSymbolItem
            key={item.index.toString()}
            symbol={item}
            targetLists={targetLists}
            widget={widget}
            changeSymbolForGroup={changeSymbolForGroup}
            screenerId={screenerId}
            handlerTargetList={handlerTargetList}
          />
        </div>
        <Bar
          options={chartOptions}
          data={data}
          plugins={[ChartDataLabels]}
          redraw={false}
          style={{ height: BAR_HEIGHT.canvasBarHeight }}
        />
      </div>
    );
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('=> widget_ready', widget.id, new Date().getTime());
    return () => {
      itemsRef.current = [];
    };
  }, []);

  return (
    <div
      style={{ width, height }}
      role="row"
      tabIndex={0}
      onKeyDown={(event) => {
        handleKeyDown(event);
      }}
    >
      <AutoSizer>
        {
          ({ height: height2, width: width2 }) => (
            <List
              ref={ref}
              className={styles.chartWrapper}
              rowHeight={BAR_HEIGHT.canvasBarHeight}
              rowCount={storedSymbols.length}
              overscanRowCount={VirtualizeData.OVER_SCAN_ROW_COUNT}
              rowRenderer={renderItem}
              width={width2}
              height={height2 - BAR_HEIGHT.headerOffset}
              onRowsRendered={(info: RenderedRows) => {
                debouncedUpdateLastState(onLoadMore, VirtualizeData.DEBOUNCE, info.overscanStartIndex);
              }}
            />
          )
        }
      </AutoSizer>
    </div>

  );
};

const areEqual = (prevProps: IPerformanceCharts, nextProps: IPerformanceCharts) => (
  prevProps.storedSymbols === nextProps.storedSymbols
  && prevProps.height === nextProps.height
  && prevProps.width === nextProps.width
  && prevProps.widget?.colorGroup === nextProps.widget?.colorGroup
  && prevProps.targetLists === nextProps.targetLists);

export default memo(PerformanceChart, areEqual);
