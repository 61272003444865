import { transformData } from '../../../utils/convertingDataHelper';
import { removeNonNumeric } from '../../DasboardComponents/utils/utils';
import { BUBBLE_FORMATTING_M } from '../types/WidgetChartsEnums';

const minScaleValue = 3;

export const bubbleScaling = (value: number, maxValue: number): number => {
  const final = (value / maxValue) * 25;
  return final < minScaleValue ? minScaleValue : final;
};

export const handleFormattedValues = (value: number, index: number | null, dataType: string): number => {
  if (index !== null && BUBBLE_FORMATTING_M.includes(index)) {
    return parseFloat((value / 1_000_000).toFixed(2));
  }
  return parseFloat(removeNonNumeric(transformData(dataType, value, '')));
};
