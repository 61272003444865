import React from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IWidgetHeadScreenDropdown } from '../types/DashboardComponentsInterfaces';
import { ScreenItemData, TDashboardItemsData } from '../../../utils/Types';
import DashboardsItemDropdown from '../../DashboardsItemsDropdown/DasboardsItemDropdown';
import { ReactComponent as IconStar } from '../../../assets/images/icons/screener/screenerIcon.svg';
import { setSelectedScreen } from '../../../store/newScreen/actions';
import { setSortData, setVisibleColumnsScreenAction } from '../../../store/screener/actions';
import { DEFAULT_PAGINATION_STATE, NEW_SCREEN_DATA } from '../../../constants/screener';
import { resetCurrentPage } from '../../../store/tableData/slice';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const ScreenerWidgetDropdown = ({
  widget, updateWidget,
  setDropdownTitle, setSelectedItem, selectedItem,
  isDropDownOpen, setIsDropDownOpen, dropdownButtonRef, searchHeaderValue, resetSearchHandler,
  handleOpenFiltersNew, handleOpenFilters
}: IWidgetHeadScreenDropdown): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { screenerItems, screenerPresetsItems } = useDashboardItems();
  const itemsNewScreen = screenerItems as TDashboardItemsData[];
  const presetsNewScreen = screenerPresetsItems as TDashboardItemsData[];

  const actionUpdateState = () => {
  };

  const actionCreateNew = () => {
    setIsDropDownOpen(false);
    handleOpenFiltersNew();
    if (searchHeaderValue) {
      resetSearchHandler();
    }
  };

  const actionEdit = (item: TDashboardItemsData) => {
    setIsDropDownOpen(false);
    if (handleOpenFilters) {
      handleOpenFilters();
    }
    updateWidget({ ...widget, screenerId: item.id });
    dispatch(setSelectedScreen(item));
    dispatch(setVisibleColumnsScreenAction(false));
  };

  const setCurrentScreenWidgetHandler = (screen: TDashboardItemsData) => {
    setSelectedItem(screen);
    setDropdownTitle(screen.title);
    updateWidget({ ...widget, screenerId: screen.id });
  };

  const currentItemHandler = (item: string | TDashboardItemsData) => {
    setIsDropDownOpen(false);
    if (typeof item !== 'string') {
      setSelectedItem(item);
      if (!item) {
        setSelectedItem({} as ScreenItemData);
        setDropdownTitle(t('screener'));
        updateWidget({ ...widget, screenerId: 0 });
      } else if (item?.type !== NEW_SCREEN_DATA.FOLDER && selectedItem?.id !== item?.id) {
        dispatch(resetCurrentPage(DEFAULT_PAGINATION_STATE));
        setCurrentScreenWidgetHandler(item);
        dispatch(setSelectedScreen(item));
        resetSearchHandler();
        dispatch(setSortData(item?.data?.sortData || []));
      }
    }
  };

  return (
    <>
      <DashboardsItemDropdown
        itemType={0}
        savedItems={itemsNewScreen}
        presetItems={presetsNewScreen}
        isOpen={isDropDownOpen}
        setIsOpen={setIsDropDownOpen}
        parrentRef={dropdownButtonRef}
        labelPreset={t('presetsScreen')}
        labelSaved={t('savedScreens')}
        labelAdd={t('screener')}
        labelDescription={t('myScreens')}
        iconAddMenu={<IconStar />}
        actionCreateNewCallback={actionCreateNew}
        actionEditCallback={actionEdit}
        selectedItem={selectedItem}
        currentItemHandler={currentItemHandler}
        updateAfterDragActions={actionUpdateState}
        topMargin={7}
      />
    </>
  );
};

export default ScreenerWidgetDropdown;
