import React, {
  useEffect, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SidebarScreener from '../../components/SidebarScreener/SidebarScreener';
import { SCREENS_WITH_LAYOUT } from '../../constants/screener';
import useAccount from '../../utils/hooks/useAccount';
import styles from './sass/ScreenerLayout.module.scss';
import useHolidays from '../../utils/hooks/useHolidays';
import useFilesLoaded from '../../utils/hooks/useFilesLoaded';
import { useTheme } from '../../utils/hooks/useTheme';
import useDefFiles from '../../utils/hooks/useDefFiles';
import SocketProvider from '../../context/SocketProvider';

const AppLayout = ({ children }) => {
  const history = useHistory();
  const { filesLoaded } = useFilesLoaded();

  const {
    getBaseURL,
    getDefsUrls,
    socketInstanceCallback
  } = useDefFiles();

  const { theme } = useTheme();
  const { getHolidays } = useHolidays();

  const {
    profileWorker,
    activeSubscriptionWorker,
    userStateWorker,
    getUserLayouts,
    getTickVersion
  } = useAccount();

  useLayoutEffect(() => {
    getBaseURL();
  }, []);

  useEffect(() => {
    getHolidays();
  }, []);

  useLayoutEffect(() => {
    userStateWorker();
    profileWorker();
    getTickVersion();
    activeSubscriptionWorker();
    getDefsUrls();
  }, []);

  useEffect(() => {
    getUserLayouts();
  }, [history.location.pathname]);

  return (
    <div
      className={`${styles.wrapper} ${!SCREENS_WITH_LAYOUT.includes(history.location.pathname)
        ? styles.extraWrapper : ''} ${theme}`}
    >
      {SCREENS_WITH_LAYOUT.includes(history.location.pathname) && <SidebarScreener />}
      <div className={`${styles.content}`}>
        {filesLoaded && (
          <SocketProvider socketInstanceCallback={socketInstanceCallback}>
            {children}
          </SocketProvider>
        )}
      </div>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

AppLayout.defaultProps = {
  children: null,
};

export default AppLayout;
