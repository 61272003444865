import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { Bubble } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  Chart
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from '../sass/WidgetCharts.module.scss';
import { IBubbleChart } from '../types/WidgetChartsInterfaces';
import { useTheme } from '../../../utils/hooks/useTheme';
import ThemeVariants from '../../../constants/theme';
import {
  BAR_CHART_COLORS, BAR_HEIGHT, BUBBLE_FORMATTING_M, FONT_FAMILY
} from '../types/WidgetChartsEnums';
import { BubbleDatasetValues, SocketPartialResponse } from '../types/WidgetChartsypes';
import SocketContext from '../../../context/SocketContext';
import { WS_CHANNELS } from '../../../constants/socketConnection';
import { transformData } from '../../../utils/convertingDataHelper';
import { removeNonNumeric } from '../../DasboardComponents/utils/utils';
import { bubbleScaling } from '../utils/bubble';
import Zoom from '../components/Zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement, LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);
const BubbleChart = ({
  dataSetLabels,
  dataSetValues,
  height,
  changeSymbolForGroup,
  widget,
  queryId,
  storedSymbols,
  dataPointsXType,
  dataPointsXIndex,
  dataPointsYType,
  dataPointsYIndex,
  dataPointsZIndex,
  dataPointsXTitle,
  dataPointsYTitle
}: IBubbleChart): React.ReactElement => {
  const ref = useRef<Chart<'bubble'> | null>();
  const { theme } = useTheme();
  const { Socket, Connection, Authorized } = useContext(SocketContext);
  const maxValue = useRef<number>(dataSetValues[0].max);

  const data: ChartData<'bubble'> = {
    datasets: [
      {
        data: dataSetValues,
        backgroundColor: (context) => {
          const raw = context.raw as BubbleDatasetValues;
          return (raw?.x < 0) ? BAR_CHART_COLORS.bubble_pink : BAR_CHART_COLORS.bubble_blue;
        },
      },
    ],
  };

  const updatedDataPointsXTitle = !BUBBLE_FORMATTING_M.includes(dataPointsXIndex)
    ? dataPointsXTitle : `${dataPointsXTitle} (in millions)`;

  const updatedDataPointsYTitle = !BUBBLE_FORMATTING_M.includes(dataPointsYIndex)
    ? dataPointsYTitle : `${dataPointsYTitle} (in millions)`;

  const chartOptions: ChartOptions<'bubble'> = useMemo(() => (
    {
      animation: false,
      maintainAspectRatio: false,
      responsive: true,
      onClick(event, elements) {
        if (elements.length) {
          const label = dataSetLabels[elements[0].index];
          changeSymbolForGroup(label as string, widget.colorGroup as string, widget.id);
        }
      },
      layout: {
        padding: {
          left: 10,
          bottom: 10,
        },
      },
      scales: {
        y: {
          grace: '10%',
          grid: {
            display: true,
            color: theme === ThemeVariants.DARK_MODE ? BAR_CHART_COLORS.dark_gray : BAR_CHART_COLORS.off_white,
          },
          title: {
            display: true,
            text: updatedDataPointsYTitle,
            font: { size: 12, weight: 700, family: FONT_FAMILY },
            color: theme === ThemeVariants.DARK_MODE ? BAR_CHART_COLORS.textDark : BAR_CHART_COLORS.textLight,
          }
        },
        x: {
          grace: '10%',
          grid: {
            display: true,
            color: theme === ThemeVariants.DARK_MODE ? BAR_CHART_COLORS.dark_gray : BAR_CHART_COLORS.off_white,
          },
          title: {
            display: true,
            text: updatedDataPointsXTitle,
            font: { size: 12, weight: 700, family: FONT_FAMILY },
            color: theme === ThemeVariants.DARK_MODE ? BAR_CHART_COLORS.textDark : BAR_CHART_COLORS.textLight,
          }
        },
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          zoom: {
            drag: {
              enabled: true,
              modifierKey: 'shift'
            },
            wheel: {
              enabled: true,
            }
          }
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: 'auto',
          color(context) {
            const index = context.dataIndex;
            const { data: chartData } = context.chart;
            if (chartData?.datasets) {
              const values = chartData?.datasets[0].data[index] as BubbleDatasetValues;
              if (values?.x < 0) {
                return BAR_CHART_COLORS.pink;
              }
            }
            return BAR_CHART_COLORS.blue;
          },
          align: 'bottom',
          anchor: 'center',
          formatter(value, context) {
            const index = context.dataIndex;
            return dataSetLabels[index];
          },
          clip: true,
        },
        tooltip: {
          mode: 'nearest',
          callbacks: {
            label(context) {
              const { x, y } = context.raw as BubbleDatasetValues;
              return `${dataSetLabels[context.dataIndex]}: (${x}, ${y})`;
            },
          }
        },
      },
    }
  ), [dataSetLabels, widget, theme, dataPointsYTitle, dataPointsXTitle]);

  const updateMaxValue = (value: number) => {
    if (Math.abs(Number(value)) > maxValue.current) {
      maxValue.current = Math.abs(Number(value));
    }
  };

  const partialCallback = (resp: SocketPartialResponse) => {
    if (resp.screenerId === queryId) {
      const [symIndex, xIndex, xValue, yIndex, yValue, zIndex, zValue] = resp.data;
      const index = storedSymbols.findIndex((item) => item.index === symIndex);

      if (ref && ref.current && index > -1) {
        const refContext = ref.current.data.datasets[0].data[index];
        if (xIndex && xIndex === dataPointsXIndex) {
          refContext.x = parseFloat(
            removeNonNumeric(transformData(dataPointsXType, xValue, ''))
          );
        }
        if (xIndex && xIndex === dataPointsYIndex) {
          refContext.y = parseFloat(
            removeNonNumeric(transformData(dataPointsYType, xValue, ''))
          );
        }
        if (xIndex && xIndex === dataPointsZIndex) {
          updateMaxValue(xValue);
          refContext.r = bubbleScaling(Math.abs(Number(xValue)), maxValue.current);
        }
        if (yIndex && yIndex === dataPointsXIndex) {
          refContext.x = parseFloat(
            removeNonNumeric(transformData(dataPointsXType, yValue, ''))
          );
        }
        if (yIndex && yIndex === dataPointsYIndex) {
          refContext.y = parseFloat(
            removeNonNumeric(transformData(dataPointsYType, yValue, ''))
          );
        }
        if (yIndex && yIndex === dataPointsZIndex) {
          updateMaxValue(yValue);
          refContext.r = bubbleScaling(Math.abs(Number(yValue)), maxValue.current);
        }
        if (zIndex && zIndex === dataPointsXIndex) {
          refContext.x = parseFloat(
            removeNonNumeric(transformData(dataPointsXType, zValue, ''))
          );
        }
        if (zIndex && zIndex === dataPointsYIndex) {
          refContext.y = parseFloat(
            removeNonNumeric(transformData(dataPointsYType, zValue, ''))
          );
        }
        if (zIndex && zIndex === dataPointsZIndex) {
          updateMaxValue(zValue);
          refContext.r = bubbleScaling(Math.abs(Number(zValue)), maxValue.current);
        }
      }
    }
  };

  useEffect(() => {
    maxValue.current = dataSetValues[0].max;
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, partialCallback);
    }

    return () => {
      if (Socket?.readyState === WebSocket.OPEN && Authorized) {
        Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, partialCallback);
      }
    };
  }, [
    Socket,
    Authorized,
    queryId,
    storedSymbols,
    dataPointsXType,
    dataPointsYType,
    dataPointsXIndex,
    dataPointsYIndex,
    dataPointsZIndex,
    dataSetValues
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (ref.current) ref.current.update();
    }, 1000);
    // eslint-disable-next-line no-console
    console.log('=> widget_ready', widget.id, new Date().getTime());
    return () => {
      maxValue.current = 0;
      ref.current = null;
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={styles.chartWrapper} style={{ height: (height - BAR_HEIGHT.heatMapOffset) }}>
      <Bubble
        options={chartOptions}
        data={data}
        plugins={[ChartDataLabels]}
        ref={ref}
      />
      <Zoom ref={ref} />
    </div>
  );
};

const areEqual = (prevProps: IBubbleChart, nextProps: IBubbleChart) => (
  prevProps.dataSetValues === nextProps.dataSetValues
  && prevProps.dataSetLabels === nextProps.dataSetLabels
  && prevProps.widget?.colorGroup === nextProps.widget?.colorGroup
  && prevProps.height === nextProps.height);

export default memo(BubbleChart, areEqual);
