import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import Button from '../../Button/Button';
import styles from '../sass/ScreenerTable.module.scss';
import { ReactComponent as IconSettings } from '../../../assets/images/icons/settings.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/images/icons/screener/color_stroke.svg';
import { ReactComponent as IconClose } from '../../../assets/images/icons/alertsIcons/close.svg';
import { SETTING_BUTTON_CLASS } from '../../../constants/screener';
import { setColumnSetsId, setSelectedScreen } from '../../../store/newScreen/actions';
import { setSelectedTab, setSelectedTabIdWatchlist } from '../../../store/watchlist/actions';
import useWidgetColumnsModalWorker from '../../DasboardComponents/hook/useWidgetColumsModalWorker';
import { findItem, getColumnsSetId } from '../../DasboardComponents/utils/utils';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import { LAST_SCREENER_COLUMNS_SET } from '../../../constants/tvWidgetOptions';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const areEqual = (prevProps, nextProps) => {
  return prevProps.isEmpty === nextProps.isEmpty
    && prevProps.hideChart === nextProps.hideChart
    && JSON.stringify(prevProps.sortData) === JSON.stringify(nextProps.sortData)
    && prevProps.visibleColumnsScreen === nextProps.visibleColumnsScreen
    && prevProps.isWidget === nextProps.isWidget
    && prevProps.selectedItemFromWidget === nextProps.selectedItemFromWidget
    && prevProps.selectedColumnIdFromWidget === nextProps.selectedColumnIdFromWidget
    && prevProps.hideColumnsSettings === nextProps.hideColumnsSettings;
};

const SettingsComponent = ({
  clearSorting,
  openColumnsHandler,
  hideChart,
  handleOpenChart,
  isEmpty,
  visibleColumnsScreen,
  isWidget,
  selectedItemFromWidget,
  isWatchlist,
  sortData,
  widgetId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [viewTool, setViewTool] = useState(false);
  const [broomstickTool, setBroomstickTool] = useState(false);
  const [showBroomstick, setShowBroomstick] = useState(false);
  const [currentSortStatus, setCurrentSortStatus] = useState(false);

  const dataRows = useSelector((state) => state.tableDataState.dataRows, shallowEqual);
  const userSettings = useSelector((state) => state.accountState.userSettings, shallowEqual);

  const {
    screenerItems,
    screenerPresetsItems,
    itemsColumnSets,
    tabsList,
    listPresetsWatchlist
  } = useDashboardItems();
  const itemsNewScreen = screenerItems;
  const presetsNewScreen = screenerPresetsItems;

  const onMouseLeave = useCallback(() => setViewTool(false));
  const onMouseMove = useCallback(() => setViewTool(true));

  const onMouseBroomstickLeave = useCallback(() => {
    setShowBroomstick(false);
    setBroomstickTool(false);
  });
  const onMouseBroomstickMove = useCallback(() => setBroomstickTool(true));

  const { updateStoreHandler } = useLastStateUpdateStore();
  const { openColumnsModal } = useWidgetColumnsModalWorker();

  const handleOpenSettings = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (isWidget) {
      const columnSetId = getColumnsSetId(
        selectedItemFromWidget,
        isWatchlist,
        itemsNewScreen,
        presetsNewScreen,
        tabsList,
        listPresetsWatchlist,
        itemsColumnSets,
        userSettings,
      );

      if (
        !isWatchlist && !selectedItemFromWidget
        && (!userSettings?.lastScreenerColumnsSet || userSettings?.lastScreenerColumnsSet === -1)
      ) {
        updateStoreHandler(LAST_SCREENER_COLUMNS_SET, columnSetId);
      }
      dispatch(setColumnSetsId(columnSetId));
      if (!isWatchlist) {
        const screener = findItem(itemsNewScreen, Number(selectedItemFromWidget));
        const preset = findItem(presetsNewScreen, Number(selectedItemFromWidget));
        let selectedScreen = preset || screener;
        if (selectedScreen?.items?.length > 0) {
          selectedScreen = selectedScreen?.items?.find((item) => item.id === selectedItemFromWidget);
        }
        dispatch(setSelectedScreen(selectedScreen || {}));
      } else {
        let deepList;
        if (!selectedItemFromWidget) {
          deepList = [...tabsList, ...listPresetsWatchlist].find((item) => item.data.subType === UNIVERSE_TYPE);
        } else {
          deepList = [...tabsList, ...listPresetsWatchlist].find((item) => item.id === selectedItemFromWidget);
        }
        dispatch(setSelectedTab(deepList));
        dispatch(setSelectedTabIdWatchlist(deepList?.id));
      }
      return openColumnsModal(widgetId);
    }
    return openColumnsHandler(!visibleColumnsScreen);
  };

  useEffect(() => {
    setCurrentSortStatus(sortData && sortData?.length > 0);
  }, [sortData, dataRows]);

  useEffect(() => {
    if (!currentSortStatus) {
      setShowBroomstick(false);
    }
  }, [showBroomstick, currentSortStatus]);

  const handleClear = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setShowBroomstick(false);
    setBroomstickTool(false);
    return clearSorting();
  };

  const handledisableClick = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    return null;
  };

  return (
    <div
      aria-hidden
      onClick={hideChart ? handleOpenChart : () => undefined}
      style={hideChart ? { right: '5px' } : { right: '21px' }}
      className={`${styles[!isEmpty ? SETTING_BUTTON_CLASS.EMPTY : SETTING_BUTTON_CLASS.NOT_EMPTY]}`}
    >
      <div aria-hidden onMouseDown={handledisableClick} onClick={handledisableClick} className={styles.settingsWrapper}>
        {currentSortStatus && (
          <div
            className={styles.sortingLengthIndicator}
            onMouseEnter={() => setShowBroomstick(true)}
            onMouseLeave={() => setShowBroomstick(false)}
          >
            {showBroomstick
              ? (
                <Button buttonClass={styles.broomstick} handleClick={handleClear}>
                  <IconClose
                    onMouseEnter={onMouseBroomstickMove}
                    onMouseLeave={onMouseBroomstickLeave}
                  />
                </Button>
              )
              : sortData?.length}
          </div>
        )}
        <Button
          buttonClass={styles.settingsButton}
          handleClick={handleOpenSettings}
        >
          <IconSettings onMouseLeave={onMouseLeave} onMouseEnter={onMouseMove} />
        </Button>
      </div>
      {viewTool && <div className={styles.toolDropDown}>{t('columnSetting')}</div>}
      {broomstickTool && <div className={styles.toolBroomstick}>{t('clearSort')}</div>}
      {hideChart && !isWidget && (
        <div className={styles.chevronWrapper}>
          <div
            aria-hidden
            className={styles.buttonShowChart}
          >
            <ChevronIcon />
          </div>
        </div>
      )}
    </div>
  );
};

SettingsComponent.propTypes = {
  isEmpty: PropTypes.bool,
  openColumnsHandler: PropTypes.func,
  clearSorting: PropTypes.func,
  hideChart: PropTypes.bool,
  handleOpenChart: PropTypes.func,
  visibleColumnsScreen: PropTypes.bool,
  isWidget: PropTypes.bool,
  selectedItemFromWidget: PropTypes.number,
  isWatchlist: PropTypes.bool,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
  widgetId: PropTypes.string,
};

SettingsComponent.defaultProps = {
  isEmpty: false,
  clearSorting: () => undefined,
  openColumnsHandler: () => undefined,
  hideChart: false,
  handleOpenChart: () => undefined,
  visibleColumnsScreen: false,
  isWidget: false,
  selectedItemFromWidget: null,
  isWatchlist: false,
  sortData: [],
  widgetId: '',
};

export default React.memo(SettingsComponent, areEqual);
