export default {
  twitterAlreadyUsed: 'This Twitter account already used',
  changeTwitterAccount: 'Change Twitter account',
  changeImage: 'Please change the picture',
  wrongFormat: 'Wrong image format. Only "png", "jpg", "jpeg".',
  wrongSize: 'Too large image. Max size 5MB.',
  shareSuccess: 'Share Success',
  congrats: 'Congrats',
  screenerPage: 'Screener',
  alertExport: 'Export is impossible from all stocks in the same time. Choose a single one to do so.',
  labelCopy: 'Copy symbols to clipboard',
  deleteSelectedSymbols: 'Delete selected symbols',
  labelCopyToFavorites: 'Add symbols to Watchlists',
  addNewSymbol: 'Add Symbol',
  twitterCopy: 'Copy symbols to Twitter',
  labelExportCSV: 'Export to CSV',
  labelExportExcel: 'Export to Excel',
  addTo: 'Add to',
  addToTitle: 'Add to...',
  currentScreen: 'New screen 13',
  addNewScreen: 'New Screen',
  createWatchlist: 'Create new Watchlist',
  saveWatchlist: 'Save Watchlist',
  createNewFolder: 'New Folder',
  searchPlaceholder: 'Search by symbol...',
  searchByColumns: 'Search by column...',
  saveChanges: 'Save changes',
  discard: 'Discard',
  noColumns: 'No columns selected',
  clearAll: 'Clear All',
  success: 'Success',
  successType: 'success',
  successCreated: 'New screen successfully added',
  successPresetCreated: 'New preset successfully added',
  successCreatedColumnSet: 'New columns set successfully added',
  folderCreated: 'New folder successfully added',
  linkCopied: 'Link of {{itemType}} was copied to clipboard',
  screenUpdate: 'Screen successfully updated',
  columnsSetUpdate: 'Column Set successfully updated',
  screenDelete: 'Screen successfully deleted',
  columnsSetDelete: 'Columns Set successfully deleted',
  upgradeToSeeMore: 'Upgrade to see more!',
  folderUpdate: 'Folder successfully updated',
  folderDelete: 'Folder successfully deleted',
  folderMove: '{{screenerName}} successfully moved to {{folderName}}',
  selectedColumn: 'Columns selected',
  noColumnsDescription: 'Choose columns on the left',
  searchByPresetPlaceholder: 'Search by presets...',
  searchByScreensPlaceholder: 'Search by screens...',
  searchByPresetDLPlaceholder: 'Search by watchlists presets...',
  searchByDLPlaceholder: 'Search by watchlists...',
  createFolderButton: 'Create new folder',
  changeFolderName: 'Change folder name',
  folderName: 'Folder name',
  typeFolderName: 'Type folder name',
  changeScreenName: 'Change screen name',
  share: 'Share',
  create: 'Create',
  delete: 'Delete',
  edit: 'Edit',
  remove: 'Remove',
  removeSubItem: 'Are you sure you want to remove {{subItem}} from folder:',
  removeSubItemMsg: '{{subItem}} successfully removed from {{folderName}}',
  duplicatePreset: 'Duplicate preset',
  duplicateScreen: 'Duplicate screen',
  makeFolder: 'Make folder first',
  screenName: 'Screen Name',
  upOnVolume: 'Up on Volume',
  folder: 'Folder',
  description: 'Description',
  selectedFolder: 'Select a folder',
  save: 'Save',
  moveTo: 'Move to...',
  chooseFolder: 'Choose folder you want to move',
  areYouSure: 'Are you sure?',
  empty: 'Empty...',
  youSureYouWant: 'Are you sure you want to delete:',
  A: 'A',
  Z: 'Z',
  myScreens: 'My Screens',
  myDeeplists: 'My Watchlists',
  myPresets: 'My Presets',
  scans: 'scans',
  noStocks: 'No stocks',
  presets: 'Presets',
  createNewScreenDrop: 'New Screen',
  createNewScreen: 'New Screen',
  allStocks: 'All Stocks',
  columnSetting: 'Column Settings',
  loadFiles: 'Loading Items ...',
  savedScreens: 'Saved Screens',
  presetsScreen: 'Screener Presets',
  copy: 'Copy',
  markets: 'Markets',
  yourWatchlist: 'Your Watchlists',
  search: 'Search...',
  searchResult: 'Search result',
  whatsNew: 'What’s New',
  new: 'New',
  checkAllNotifikations: 'Check all notifications',
  premium: 'Premium',
  accountSettings: 'Account settings',
  userSettings: 'User Settings',
  signOut: 'Sign Out',
  headerModalTwitter: 'Share on Twitter',
  tweetText: 'Post Message',
  tweet: 'Post to X',
  cancel: 'Cancel',
  close: 'Close',
  in: 'in',
  move: 'Move',
  duplicate: 'Duplicate',
  hideMenu: 'Hide menu',
  openMenu: 'Open menu',
  dashboard: 'Dashboard',
  screener: 'Screener',
  watchlist: 'Watchlists',
  education: 'Education',
  educationTooltip: 'Education',
  support: 'Help',
  selectDate: 'Select date',
  apply: 'Apply',
  earningsDate: 'Earnings Date',
  greater: 'Greater',
  less: 'Less',
  period: 'Period',
  dateRange: 'Select date range',
  add: 'Add',
  technical: 'Technicals',
  marginsAndRations: 'Margins & Rations',
  earningsSourseFlag: 'Earnings Source Flag',
  earningsTimeOfDay: 'Earnings Time of Day',
  exchange: 'Exchange',
  employees: 'Employees',
  drawing: 'Drawing',
  noResultsFound: 'No results found',
  noFindAnyResults: 'Sorry, no results found.',
  getPremium: 'Get Premium',
  commingSoon: 'Coming Soon!',
  upgradeYourAccountToSeeMore: 'Upgrade your account to see more',
  copySymbols: 'Selected symbols copied to clipboard',
  copySymbolsToTwitter: 'Selected symbols copied to Twitter',
  copySymbolsToFavorites: 'Selected symbols copied to favorites',
  export: 'Export',
  refresh: 'Refresh',
  singleChart: 'Single Chart',
  multiChartView: 'Mini Chart',
  multiChart: 'Mini Chart Settings',
  copyTo: 'Add to...',
  multiView: 'Multi-Timeframe Chart View',
  chartView: 'Chart View',
  listView: 'List View',
  createScreenButton: 'Create New Screen',
  typeScreenName: 'Type screen name',
  typeDescription: 'Type screen description',
  upgradeYourAccount: 'Upgrade your account',
  filtersAvailableToPremiumUsers: 'All filters are available to use only for premium users',
  columnsAvailableToPremiumUsers: 'All columns are available to use only for premium users',
  thisFeatureIsComingSoon: 'This feature is coming soon!',
  theNewFeatureIsSavedScreens: 'The new feature is a Saved Screens. It will make your work more convenient.',
  theNewFeatureIsFavorites: 'The new feature is a Saved Favorites. It will make your work more convenient.',
  sorryNoResultsFor: 'Sorry, no results found for',
  noTWChart: 'No symbol selected',
  resultsSymbolCount: '{{select}} of {{all}} results',
  selectedResult: '{{totalSelected}} selected',
  reslts: 'results',
  pleaseChooseScreen: 'Please choose the Screen!',
  buttonTitlePresetFree: 'Upgrade your account to use all presets',
  to: 'to',
  changeName: 'Please choose another name',
  error: 'error',
  noResultScreens: 'We couldn’t find a {{type}} named "{{search}}"',
  clearSort: 'Clear Sorting',
  resetColumn: 'Reset Columns',
  market: 'Market',
  account: 'Account',
  saveScreen: 'Save screen',
  hasBeenUpdated: 'Deepvue has been updated!',
  checkUpdateDetails: 'Check update details',
  gotIt: 'Got it',
  createNewPresetDrop: 'New Preset',
  presetName: 'Preset Name',
  show: 'Show',
  hide: 'Hide',
  dividendTitle: 'Ex-Dividend Date',
  earningsTitle: 'Earnings Date',
  searchBySymbol: 'Search by symbol',
  visibilitySetting: 'Visibility settings',
  defaultCompanySymbol: 'Show Company Symbol',
  defaultEarningsDate: 'Show Earnings Date',
  defaultDescriptionHover: 'Show Description on Hover',
  defaultExDividendDate: 'Show Ex-dividend Date',
  columnSets: 'Column sets',
  columnSet: 'Column set',
  searchColumnSetsPlaceholder: 'Search by column sets...',
  columnSetsDesc: 'column sets',
  descriptionColumnsSets: 'columns selected',
  noDescriptionColumnsSets: 'No columns selected',
  createNewColumnSet: 'Create new column set',
  createNewColumnSetPreset: 'Create new column set preset',
  columnSetName: 'Column Set Name',
  duplicateColumnSets: 'Duplicate column set ',
  removeColumnFromFolder: 'Are you sure you want to remove ',
  fromFolder: 'from folder:',
  characterLimit: '40 character limit',
  characterLimitWarning: 'Naming is limited to 40 characters',
  alert: 'alert',
  changeColumnName: 'Change Column set name',
  columnName: 'Column set Name',
  typeColumnName: 'Type Column set name',
  adBlockerDetectedTitle: 'Please turn off all Adblock Extensions',
  adBlockerDetectedText: 'In order to use Deepvue to it\'s fullest'
    + ' potential please turn off any adblock extensions you have enabled.',
  recommended: 'Recommended',
  recentSearch: 'Recently Searched',
  deepvue: 'Deepvue',
  notMyTarget: 'Not my target',
  progressLoaderTitle: 'Getting everything ready…',
  timeLabel: 'Time',
  sortBy: 'Sort by',
  timeFrame: 'Timeframe',
  themeContextNotFound: 'Theme context is not found',
  theme: 'Theme',
  columnsWereSaved: 'The changes to columns were saved',
  marketDropdownTitle: 'Market: ',
  marketLabel: 'Platform Data Settings',
  preMarket: 'Pre Market',
  preRegularMarket: 'Pre & Regular Market',
  regularMarket: 'Regular Market',
  postMarket: 'Post Market',
  preRegularPostMarket: 'Pre, Regular & Post Market',
  marketTooltip: `In this menu you can select the Market Periods you would like to analyze.
    Adjusting the periods adjusts the data columns in the Screener and Data Panel.`,
  sessionRTH: 'Regular',
  sessionETH: 'Extended',
  sessionRTHdesk: 'Regular Trading Hours',
  sessionETHdesk: 'Extended Trading Hours',
  multiChartSettings: 'Mini Chart Settings',
  chartSettings: 'Chart Data Settings',
  automarketLabel: 'Current (automatically)',
  automarketSubLabel: 'Auto selection of the current market',
  automarketLabelShort: 'Current',
  symbols: 'Symbols',
  statsTable: 'Stats Table',
  dataPanel: 'Data Panel',
  createNewLabel: 'Create new',
  changeNameLabel: 'Change {{nameOfType}} name',
  duplicateNameLabel: 'Duplicate {{nameOfType}}',
  screenTitleLabel: 'screen',
  deepListTitleLabel: 'watchlist',
  columnSetTitleLabel: 'column set',
  dataPanelTitleLabel: 'data panel',
  chooseFolderLabel: 'Choose folder you want to move',
  noResultFoundDashboardItems: 'We couldn’t find a {{entityName}} named',
  dashItemEmpty: '{{entityName}}s list is empty',
  labelCreateNew: 'Create your first {{entityName}}',
  labelRemoveFromFolder: 'Are you sure you want to remove {{itemName}} from folder:',
  labelPresetAdd: 'Preset',
  placeholerScreenName: 'Screen name',
  columnset: 'column set',
  appSettings: 'App Settings',
  columnsPreset: 'Column sets Presets',
  columns: 'Column sets',
  column: 'Column set',
  savedColumns: 'Saved Column sets',
};
