import React, { useCallback, useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import WatchlistTabsBlock from '../../../components/WatchlistTabsBlock/WatchlistTabsBlock';
import { setToLocalStorage } from '../../../utils/storageWorks';
import { SELECTED_TAB_ID, WATCHLIST_PARENT_ID } from '../../../constants/storage';
import { setSelectedTab } from '../../../store/watchlist/actions';
import { defaultPage } from '../../../constants/screener';
import {
  resetSelectedColumnsScreenerAction,
  setMarketsFilterWatchlistAction,
  setSelectedSymbolsBatchScreener,
  setSortData,
  setVisibleColumnsScreenAction,
} from '../../../store/screener/actions';
import { setSelectedItems } from '../../../store/selectSymbol/slice';
import { setVisibleItemsTableStore, changeTabAction } from '../../../store/tableData/slice';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import TableContext from '../context/TableContext';

const DLHeader = () => {
  const {
    copySelectedSymbols,
    disableExport,
    screenerView,
    setFocus,
    resetSearchHandler,
    searchHeaderValue,
    isAlertsTab,
    removeAlerts,
    getSymbolFormatedByAlertIds,
    searchTabResetHandler,
    selectedItems,
    activeSymbolIndexValue,
    setIsVisibleFilters,
    setCurrentPresetItem,
    currentItemSelected,
    openModalHandler
  } = useContext(TableContext);

  const dispatch = useDispatch();

  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);

  const { updateStoreHandler } = useLastStateUpdateStore();

  const selectTabClickHandler = useCallback((tab) => {
    dispatch(resetSelectedColumnsScreenerAction());
    dispatch(setSortData(tab?.data?.sortData || []));
    dispatch(changeTabAction());
    if (Object.keys(tab).length) {
      setToLocalStorage(WATCHLIST_PARENT_ID, tab.parentId);
    }
    dispatch(setSelectedTab(tab));
    if (tab?.id !== alertTab?.id) {
      dispatch(setMarketsFilterWatchlistAction(tab));
    }

    dispatch(setVisibleItemsTableStore(defaultPage));
    updateStoreHandler(SELECTED_TAB_ID, tab.id);
  }, [selectedTab, screenerView]);

  useEffect(() => {
    dispatch(setVisibleColumnsScreenAction(false));
  }, []);

  useEffect(() => {
    if (searchHeaderValue) {
      searchTabResetHandler(selectedTab);
    }

    if (selectedTab?.id !== alertTab?.id) {
      dispatch(setMarketsFilterWatchlistAction(selectedTab));
    }
  }, [selectedTab?.id]);

  useEffect(() => {
    if (selectedItems?.length > 0) {
      dispatch(setSelectedItems([]));
      dispatch(setSelectedSymbolsBatchScreener([]));
    }
  }, [selectedTab?.id]);

  return (
    <WatchlistTabsBlock
      selectTabClickHandler={selectTabClickHandler}
      selectedItems={selectedItems}
      handleCopySymbols={copySelectedSymbols}
      disableExport={disableExport}
      search={searchHeaderValue}
      setFocus={setFocus}
      resetSearchHandler={resetSearchHandler}
      screenerView={screenerView}
      isAlertsTab={isAlertsTab}
      removeAlerts={removeAlerts}
      getSymbolFormatedByAlertIds={getSymbolFormatedByAlertIds}
      currentItemSelected={currentItemSelected}
      setCurrentPresetItem={setCurrentPresetItem}
      setIsVisibleFilters={setIsVisibleFilters}
      activeSymbolIndex={activeSymbolIndexValue}
      openModalHandler={openModalHandler}
    />
  );
};

export default DLHeader;
