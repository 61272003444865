import moment from 'moment-timezone';
import {
  RESOLUTIONS, BUTTON_SETTINGS,
  BUTTON_IMAGES, BUTTON_TITLES, CHART_TYPES_KEYS, RESOLUTIONS_DEFAULT,
} from '../../../constants/tvWidgetOptions';
import { DEFAULT_TIMEZONE } from '../../../constants/account';
import { KEY_CODES, ONE_THOUSAND, TV_EVENTS } from '../../../constants/screener';
import {
  IResolution, IButtonGenerator,
} from '../../../utils/HelpersInterfaces';
import {
  IBar, CandleData, EventHandlerReturn
} from '../../../services/Interfaces';
import { ChartActionId } from '../../../charting_library';
import { IExtendCurrentWidget } from '../types/Interfaces';
import chevronLeft from '../../../assets/images/icons/tvIcons/chevronLeft.svg';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';
import { Alert } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';

export const resolutionInterface: IResolution = (resolution, tickVersion) => {
  const resolutionsBasedOnRegion = tickVersion === 1 ? RESOLUTIONS_DEFAULT : RESOLUTIONS;
  if (resolutionsBasedOnRegion[resolution]) {
    return resolutionsBasedOnRegion[resolution];
  }

  return resolutionsBasedOnRegion.default;
};

export const realTimeBarInterface = (data: number[]): CandleData => ({
  time: data[0] * ONE_THOUSAND,
  open: data[1],
  high: data[2],
  low: data[3],
  close: data[4],
  volume: data[5],
  accVolume: data[5],
});

export const buttonGeneratorWidget = (tvWidget: IExtendCurrentWidget): void => {
  tvWidget.headerReady().then(() => {
    const button = tvWidget.createButton();
    const img = document.createElement(BUTTON_SETTINGS.IMG_ELEMENT);
    img.setAttribute(BUTTON_SETTINGS.SRC_ATTRIBUTE, chevronLeft);
    button.setAttribute(BUTTON_SETTINGS.TITLE_ATTRIBUTE, BUTTON_SETTINGS.BACK);
    button.setAttribute(BUTTON_SETTINGS.CLASS, BUTTON_SETTINGS.BACK.toLowerCase());
    button.appendChild(img);

    button.addEventListener('click', () => {
      // eslint-disable-next-line
      // @ts-ignore
      window?.ReactNativeWebView?.postMessage(BUTTON_SETTINGS.BACK);
    });
  });
};

export const buttonGenerator: IButtonGenerator = (
  newWidget,
  sendAlertAction,
  widgetId,
) => {
  const buttons = widgetId ? BUTTON_SETTINGS.BUTTON_LIST_SHORT : BUTTON_SETTINGS.BUTTON_LIST;
  buttons.forEach((item) => {
    const button = newWidget.createButton();
    const img = document.createElement(BUTTON_SETTINGS.IMG_ELEMENT) as HTMLImageElement;
    img.setAttribute(BUTTON_SETTINGS.SRC_ATTRIBUTE, BUTTON_IMAGES[item as keyof typeof BUTTON_IMAGES]);
    button.setAttribute(BUTTON_SETTINGS.TITLE_ATTRIBUTE, BUTTON_TITLES[item as keyof typeof BUTTON_TITLES]);
    button.setAttribute(BUTTON_SETTINGS.CLASS, item);
    button.appendChild(img);

    if (item === BUTTON_SETTINGS.BUTTON_LIST[2]) {
      button.addEventListener(BUTTON_SETTINGS.EVENT, () => sendAlertAction(newWidget));
    } else {
      button.addEventListener(BUTTON_SETTINGS.EVENT, () => {
        newWidget?.activeChart().executeActionById(
          item === BUTTON_SETTINGS.ITEM_NAME
            ? BUTTON_SETTINGS.ACTION_ID_DATE as ChartActionId
            : BUTTON_SETTINGS.ACTION_ID_DRAW as ChartActionId
        );
      });
    }

    return button;
  });
};

export const parseChartTypes = (array: string[]): string[] => {
  const parsedArray: string[] = [];
  array.forEach((type) => {
    const parsedType = CHART_TYPES_KEYS[type as unknown as keyof typeof CHART_TYPES_KEYS];
    if (parsedType) {
      parsedArray.push(parsedType);
    }
  });
  return parsedArray;
};

export const messageIdGenerator = (): number => Math.floor(100000 + Math.random() * 900000);

export const extractUserSession = (data: string): boolean => {
  if (!data) return false;
  const parsedData = JSON.parse(data);
  const { preMarket, postMarket } = parsedData?.graphics?.backgrounds;
  if (preMarket?.visible && postMarket?.visible) {
    return true;
  }
  return false;
};

export const cleanExtendedChart = (bars: IBar[]): IBar[] => bars.filter(
  (item) => moment(item.time).tz(DEFAULT_TIMEZONE).hour() < 16 && moment(item.time).tz(DEFAULT_TIMEZONE).hour() >= 9
);

export const removeAlertWithTrendLine = (
  type: keyof typeof TV_EVENTS,
  isRemove: boolean,
  shapeId: string,
  key: string,
  alertListFromStore: Alert[],
  removeAlerts: (id: Array<number>, isForceRemove: boolean) => void,
  handleSetSelectedShapeId: (shapeId: string | null) => void,
): void => {
  if (
    (type === TV_EVENTS.CLICK && isRemove) || (type === TV_EVENTS.KEY_DOWN && shapeId && key === KEY_CODES.BACKSPACE)
  ) {
    const currentAlert = (alertListFromStore as Alert[])?.find((alert: Alert) => alert?.lineId?.includes(shapeId));
    if (currentAlert) {
      removeAlerts([currentAlert?.id], true);
      handleSetSelectedShapeId(null);
    }
  }
};

export const injectScriptToIframe = (dashWidget: Widget): void => {
  const divWrapper = document.getElementById(
    dashWidget?.id ? `tv_chart_container_${dashWidget?.id}` : 'tv_chart_container'
  );
  const iframe = divWrapper?.querySelector('iframe');

  if (iframe) {
    iframe.style.height = 'calc(100% + 8px)';
    iframe.onload = () => {
      try {
        const iframeDocument = iframe.contentDocument || iframe?.contentWindow?.document;
        const scriptElement = iframeDocument?.createElement('script');
        const scriptElement2 = iframeDocument?.createElement('script');

        if (scriptElement) {
          scriptElement.type = 'text/javascript';
          scriptElement.text = `
            document.addEventListener('click', (event) => {
              const message = {
                type: 'click',
                isRemove: event.target.parentElement.parentElement.getAttribute('data-name') === 'remove'
                || event.target.innerText === 'Remove' || event.target.getAttribute('data-name') === 'remove'
              };
               window.parent.postMessage(message, '*');
            });
            document.addEventListener('keydown', (event) => {
              const message = {
                type: 'keydown',
                key: event.key,
                ctrl: event.ctrlKey,
                alt: event.altKey,
                meta: event.metaKey
              };
              if (!['TEXTAREA','INPUT'].includes(event?.target?.tagName)) {
                window.parent.postMessage(message, '*');
              }
            });
          `;
          iframeDocument?.body.appendChild(scriptElement);
        }

        if (scriptElement2) {
          scriptElement2.type = 'text/javascript';
          scriptElement2.text = `
            document.addEventListener('focus', function(e) {
              if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
                  window.parent.postMessage({ type: 'input-focus', focused: true }, '*');
              }
            }, true);
            
            document.addEventListener('blur', function(e) {
              if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
                  window.parent.postMessage({ type: 'input-focus', focused: false }, '*');
              }
            }, true);
          `;
          iframeDocument?.body.appendChild(scriptElement2);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Impossible to inject script into iframe:', error);
      }
    };
  }
};

export const eventHandler = (event: string): EventHandlerReturn => {
  if (event === ' ') {
    return { code: 'Space', preventDefault: () => {} };
  }

  if (event === 'ArrowDown' || event === 'ArrowUp') {
    return { code: event, preventDefault: () => {} };
  }

  return { key: event, preventDefault: () => {}, stopPropagation: () => {} };
};
