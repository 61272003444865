import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  columnsWereUpdateScreenerAction,
  setColumnScreenerAction,
} from '../../../../store/screener/actions';
import { changeTabAction } from '../../../../store/tableData/slice';
import ColumnsListView from './ColumnsListView';
import useNewScreen from '../../../../utils/hooks/useNewScreen';
import { DELAY_SAVE_COLUMNS } from '../../../../constants/numberConstants';
import { getAllColumns } from '../../../../utils/helperColumnsWorker';
import { findIndexItem } from '../../../../utils/helpers';
import { DEFAULT_ITEM, FIND_NAME } from '../../../../constants/screener';
import { setColumnSetsId } from '../../../../store/newScreen/actions';
import { DEFAULT_ALERT_GROUP } from '../../../../constants/alerts';
import useLastStateUpdateStore from '../../../../utils/hooks/useLastStateUpdateStore';
import { ALERTS_COLUMNS } from '../../../../constants/storage';
import { useDashboardItems } from '../../../../context/DasboardItemsContext/DashboardItemsProvider';

const ColumnsList = ({ isWatchlist, visibleColumnsScreen }) => {
  const dispatch = useDispatch();
  const { updateColumnsSetState } = useNewScreen();
  const [currentGroup, setCurrentGroup] = useState(-1);
  const [currentList, setCurrentList] = useState([]);
  const { updateStoreHandler } = useLastStateUpdateStore();
  const profile = useSelector((state) => state.accountState.userProfile, shallowEqual);
  const listColumns = useSelector((state) => state.screenerState.listColumns, shallowEqual);
  const selectedColumns = useSelector((state) => state.screenerState.selectedColumns);
  const columnSetId = useSelector((state) => state.newScreenState.columnSetId, shallowEqual);
  const currentPage = useSelector((state) => state.tableDataState.currentPage, shallowEqual);
  const { itemsColumnSets } = useDashboardItems();

  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const alertColumns = useSelector((state) => state.accountState.userSettings.alertColumns, shallowEqual);
  const [firstRequest, setFirstRequest] = useState(false);
  const [saveId, setSaveId] = useState(-1);

  const updateColumnsList = (clean) => {
    let newList = [...listColumns];
    if (!clean) {
      newList.push(DEFAULT_ALERT_GROUP);
    } else {
      newList = newList.filter((item) => item.groupId !== DEFAULT_ALERT_GROUP.groupId);
    }

    setCurrentList(newList);
  };

  const updateColumnsHandler = (selectedColumnsData, id) => {
    const arrayId = selectedColumnsData.map((item) => item.id);
    updateColumnsSetState(id, arrayId);
  };

  const updateColumnSetHandler = () => {
    if (columnSetId !== -1 && selectedColumns.length > 0 && saveId === columnSetId && firstRequest) {
      updateColumnsHandler(selectedColumns, columnSetId);
    } else if (columnSetId === -1 && selectedColumns.length > 0 && saveId === columnSetId && firstRequest) {
      const allColumnSet = getAllColumns(itemsColumnSets);
      const indexItem = findIndexItem(allColumnSet, FIND_NAME.TITLE, FIND_NAME.RECOMMENDED);
      updateColumnsHandler(selectedColumns, allColumnSet[indexItem]?.id);
      if (indexItem !== DEFAULT_ITEM) {
        dispatch(setColumnSetsId(allColumnSet[indexItem]?.id));
      }
    } else {
      setFirstRequest(true);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      updateColumnSetHandler();
    }, DELAY_SAVE_COLUMNS);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedColumns]);

  useEffect(() => {
    setSaveId(columnSetId);
  }, [columnSetId]);

  const columnsSelectedHandler = (item) => {
    if (item.group === DEFAULT_ALERT_GROUP.groupId) {
      let currentAlertColumns = [...alertColumns];
      const currentStatus = currentAlertColumns.indexOf(item.id);
      if (currentStatus === -1) {
        currentAlertColumns.push(item.id);
      } else if (currentStatus === 0 && currentAlertColumns?.length === 1) {
        currentAlertColumns = [];
      } else {
        currentAlertColumns.splice(currentStatus, 1);
      }
      updateStoreHandler(ALERTS_COLUMNS, currentAlertColumns);
    } else {
      const newColumnSet = [...selectedColumns];
      newColumnSet.splice(0, 2);
      dispatch(setColumnScreenerAction({ data: item }));
      dispatch(columnsWereUpdateScreenerAction(true));

      if (currentPage[0] !== 0) {
        dispatch(changeTabAction());
      }
    }
  };

  const handleMatch = (columnItem, group) => {
    if (group === DEFAULT_ALERT_GROUP.groupId) {
      return !!alertColumns?.find((matchItem) => matchItem === columnItem);
    }
    return !!selectedColumns?.find((matchItem) => matchItem.id === columnItem);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (selectedTab?.id === alertTab?.id && isWatchlist) {
        updateColumnsList(false);
      } else {
        updateColumnsList(true);
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [selectedTab]);

  useEffect(() => {
    if (listColumns.length > 0) {
      setCurrentList(listColumns);
    }
  }, [listColumns]);

  return (
    <>
      {currentList?.length ? (
        <ColumnsListView
          profile={profile}
          listColumns={currentList}
          columnsSelectedHandler={columnsSelectedHandler}
          setCurrentGroup={setCurrentGroup}
          currentGroup={currentGroup}
          match={handleMatch}
          visibleColumnsScreen={visibleColumnsScreen}
        />
      ) : null}
    </>
  );
};

ColumnsList.propTypes = {
  isWatchlist: PropTypes.bool,
  visibleColumnsScreen: PropTypes.bool.isRequired
};

ColumnsList.defaultProps = {
  isWatchlist: false,
};

export default React.memo(ColumnsList);
