import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import { Dashboard, Widget, WidgetColorsTypes } from '../../../pages/Dashboards/types/DashboardTypes';
import { IUseColorGroup } from '../types/DashboardComponentsInterfaces';
import UseDashboards from '../../../pages/Dashboards/hooks/UseDashboards';
import { WidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const useColorGroup: IUseColorGroup = () => {
  const { updateWidget, updateDashboardInStore } = UseDashboards();
  const dashboardsRef = useRef<Dashboard[] | null>([]);
  const [lastActiveGroup, setLastActiveGroup] = useState<WidgetColorsTypes>(WidgetColorsTypes.BLUE);

  const {
    dashboards, dashboardsPresets
  } = useDashboardItems();

  const selectedDashboardId = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.selectedDashboardId, shallowEqual
  );

  const setColorGroup = (id: string, color: string): void => {
    const dashboard = dashboardsRef.current?.find((item: Dashboard) => item.id === selectedDashboardId);
    if (dashboard) {
      const widget = dashboard?.data?.widgets.find((item: Widget) => item.id === id);
      const clonedWidget = { ...widget };
      if (clonedWidget) {
        clonedWidget.colorGroup = color as WidgetColorsTypes;
        if (color === WidgetColorsTypes.NONE) {
          clonedWidget.symbol = '';
        }
        updateWidget(clonedWidget as Widget, dashboard.id);
      }
    }
  };

  const changeSymbolForGroup = (symbolName: string, color: string, parrentId: string): void => {
    const dashboard = dashboardsRef.current?.find((item: Dashboard) => item.id === selectedDashboardId);
    let clonedDashboard = { ...dashboard };
    if (clonedDashboard && dashboard?.data?.widgets) {
      const clonedWidgets = [...dashboard?.data?.widgets as Widget[]];
      if (clonedWidgets.length) {
        const newWidgets: Widget[] = [];
        clonedWidgets.forEach((item: Widget) => {
          const clonedWidget = { ...item };
          if ((item.colorGroup === color && [WidgetTypes.CHART, WidgetTypes.STATS_CHART, WidgetTypes.DATA_PANEL,
            WidgetTypes.POSITION_SIZE_CALCULATOR]
            .includes(item?.type as WidgetTypes)) || item.id === parrentId
          ) {
            clonedWidget.symbol = symbolName;
          }
          newWidgets.push(clonedWidget);
        });
        clonedDashboard = { ...clonedDashboard, data: { widgets: newWidgets } };
        updateDashboardInStore(clonedDashboard as Dashboard);
      }
    }
  };

  const changeSymbolForAllGroups = (symbolName: string): void => {
    const dashboard = dashboardsRef.current?.find((item: Dashboard) => item.id === selectedDashboardId);
    let clonedDashboard = { ...dashboard };
    if (clonedDashboard && dashboard?.data?.widgets) {
      const clonedWidgets = [...dashboard?.data?.widgets as Widget[]];
      if (clonedWidgets.length) {
        const newWidgets: Widget[] = [];
        clonedWidgets.forEach((item: Widget) => {
          const clonedWidget = { ...item };
          if (![WidgetTypes.DEEP_LIST, WidgetTypes.SCREENER].includes(item?.type as WidgetTypes)) {
            clonedWidget.symbol = symbolName;
          }
          newWidgets.push(clonedWidget);
        });
        clonedDashboard = { ...clonedDashboard, data: { widgets: newWidgets } };
        updateDashboardInStore(clonedDashboard as Dashboard);
      }
    }
  };

  const handleSetLasActiveGroup = (widget: Widget): void => {
    setLastActiveGroup(widget.colorGroup as WidgetColorsTypes);
  };

  useEffect(() => {
    dashboardsRef.current = [...dashboards as Dashboard[], ...dashboardsPresets as Dashboard[]];
    return () => {
      dashboardsRef.current = null;
    };
  }, [dashboards, dashboardsPresets]);

  return {
    setColorGroup,
    changeSymbolForGroup,
    lastActiveGroup,
    setLastActiveGroup: handleSetLasActiveGroup,
    changeSymbolForAllGroups,
  };
};

export default useColorGroup;
