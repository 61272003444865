import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Grid,
} from 'react-virtualized';
import { useDispatch } from 'react-redux';
import { SectionRenderedParams } from 'react-virtualized/dist/es/Grid';
import ChartElement from './ChartElement';
import { setScrollToRow } from '../../../store/multiChart/actions';
import { VIEW_TYPE_TARGET } from '../../../constants/watchlist';
import { ITargetListItem } from '../../Screener/interfaces/ITargetList';
import { IMultichartWrapper } from '../types/MultichartInterfaces';
import convertToOrder from '../../../utils/reactVirtualizeHelper';
import {
  defaultColumnCount,
  firstMultiChartRow,
  overScanRowCount,
  rowsViewCount,
} from '../../../constants/multiChart';
import multiChartStyle from '../sass/MultiChart.module.scss';
import EmptyTableItem from '../../Screener/components/EmptyTableItem';
import SocketContext from '../../../context/SocketContext';
import { WS_CHANNELS, WS_EVENTS } from '../../../constants/socketConnection';
import { setToLocalStorage } from '../../../utils/storageWorks';
import { WS_CHART_ERROR } from '../../../constants/storage';
import { pingCallback, rtDataListenerFromSocket, saveChartIdToSubscription } from '../../../services/socketTvSubscriber';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const MultiChartWrapper = ({
  symbolsList,
  setSelectedCharts,
  selectedCharts,
  setCurrentRange,
  scrollToRow,
  isDeepList,
}: IMultichartWrapper): React.ReactElement => {
  const grid: React.MutableRefObject<Grid | null> = useRef(null);
  const [currentSymbols, setCurrentSymbols] = useState(symbolsList);
  const [refreshGrid, setRefreshGrid] = useState(false);
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (currentSymbols.length > 0 && currentSymbols.length < symbolsList.length) {
      setRefreshGrid(true);
    }
    setCurrentSymbols(symbolsList);
  }, [symbolsList]);

  const [cache] = useState(new CellMeasurerCache({ fixedWidth: true }));
  const dispatch = useDispatch();
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const { Socket, Authorized, Connection } = useContext(SocketContext);

  const { tabsList } = useDashboardItems();

  const onCellsRenderer = (cellParams: SectionRenderedParams) => {
    const { rowStartIndex, rowStopIndex } = cellParams;
    const startIndex = rowStartIndex === 0 ? 0 : rowStartIndex * defaultColumnCount;
    const stopIndex = (rowStopIndex * defaultColumnCount) + rowsViewCount;

    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(() => {
      setCurrentRange([startIndex, stopIndex]);
    }, 500);

    setTimerId(newTimerId);
  };

  useEffect(() => {
    if (scrollToRow === firstMultiChartRow) {
      dispatch(setScrollToRow(undefined));
    }
  }, [scrollToRow]);

  useEffect(() => {
    if (Authorized && Socket) {
      Connection?.messageEmitter.on(WS_CHANNELS.PING_PERIODICAL_DATA, (data) => {
        pingCallback(data, Connection);
      });
      Connection?.messageEmitter?.on(WS_CHANNELS.CHART_SUBSCRIBE, (data) => {
        saveChartIdToSubscription(data.chartId, data.messageId);
      });
      Connection?.messageEmitter?.on(WS_CHANNELS.CHART_TICK, (data) => {
        rtDataListenerFromSocket(data?.symbolIndex, data.chartId, data);
      });
      Connection?.messageEmitter?.on(WS_EVENTS.EXCEPTION, (error) => {
        setToLocalStorage(WS_CHART_ERROR, error);
      });
    }
  }, [Authorized]);

  useEffect(() => {
    if (refreshGrid) {
      timeout = setTimeout(() => {
        setRefreshGrid(false);
      }, 0);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [refreshGrid]);

  return (
    <div
      id="multiChart"
      className={multiChartStyle.multiChartContainer}
    >
      {currentSymbols.length > 0 && !refreshGrid ? (
        <AutoSizer>
          {({ width, height }) => (
            <Grid
              ref={grid}
              columnWidth={(width / defaultColumnCount) - defaultColumnCount}
              columnCount={defaultColumnCount}
              overscanRowCount={overScanRowCount}
              overscanColumnCount={defaultColumnCount}
              scrollToRow={scrollToRow}
              rowHeight={height / rowsViewCount}
              rowCount={Math.ceil(currentSymbols.length / defaultColumnCount)}
              onSectionRendered={onCellsRenderer}
              cellRenderer={({
                key,
                style,
                parent,
                rowIndex,
              }) => {
                const index = convertToOrder(key);
                const currentSymbol = currentSymbols[index];
                const isChecked = selectedCharts.filter((id) => id === currentSymbol).length;
                const targetLists = tabsList.filter((list) => list.viewType === VIEW_TYPE_TARGET) as ITargetListItem[];
                return currentSymbol !== undefined && (
                  <CellMeasurer
                    key={key}
                    cache={cache}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={rowIndex}
                  >
                    <div style={style}>
                      <ChartElement
                        checked={!!isChecked}
                        targetLists={targetLists}
                        setSelectedCharts={setSelectedCharts}
                        symbolIdx={currentSymbol}
                        isDeepList={isDeepList}
                      />
                    </div>
                  </CellMeasurer>
                );
              }}
              width={width}
              height={height}
            />
          )}
        </AutoSizer>
      ) : (
        <EmptyTableItem />
      )}
    </div>
  );
};

export default MultiChartWrapper;
