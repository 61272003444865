import React, { createContext } from 'react';
import {
  DataPanelItemData, DeepListItemData, TDashboardItemsData, TJoinedDashboardItemsData
} from '../../utils/Types';
import { Dashboard } from '../../pages/Dashboards/types/DashboardTypes';
import { ITargetListItem } from '../../components/Screener/interfaces/ITargetList';

export type JoinedState = (
  TJoinedDashboardItemsData
  | TDashboardItemsData
  | Dashboard
  | DataPanelItemData
  | DeepListItemData
)[];

export interface FoldersContextProps {
  screeners: TDashboardItemsData[],
  screenersFolders: TDashboardItemsData[],
  screenerItems: JoinedState;
  presets: TDashboardItemsData[],
  presetsFolders: TDashboardItemsData[],
  screenerPresetsItems: JoinedState;
  dataPanelsItems: JoinedState | DataPanelItemData[];
  dataPanelsItemsPresets: JoinedState | DataPanelItemData[];
  storedDataPanels: (TDashboardItemsData | DataPanelItemData)[];
  storedDataPanelsFolders: TDashboardItemsData[];
  storedDataPanelsPresetsList: (TDashboardItemsData | DataPanelItemData)[];
  storedDataPanelsFoldersPresetsList: TDashboardItemsData[];
  itemsColumnSets: JoinedState;
  itemsColumnSetsPresets: JoinedState;
  itemsWatchlist: JoinedState | DeepListItemData[];
  presetsWatchlist: JoinedState | DeepListItemData[];
  tabsList: (TDashboardItemsData | DeepListItemData | ITargetListItem)[];
  listPresetsWatchlist: (TDashboardItemsData | DeepListItemData)[];
  watchListFolders: TDashboardItemsData[];
  presetsWatchlistFolders: TDashboardItemsData[];
  columns: TDashboardItemsData[];
  columnsPresets: TDashboardItemsData[];
  columnsFolders: TDashboardItemsData[];
  columnsPresetsFolders: TDashboardItemsData[];
  dashboardsItems: JoinedState,
  dashboards: (TDashboardItemsData | Dashboard)[],
  dashboardsFolders: TDashboardItemsData[],
  itemsDashboardPresets: JoinedState,
  dashboardsPresets: (TDashboardItemsData | Dashboard)[],
  dashboardsPresetFoldersItems: TDashboardItemsData[],
  setScreenerMergedItems: (items: JoinedState) => void;
  setPresetsMergedItems: (items: JoinedState) => void;
  setTabsList: (items: React.SetStateAction<TDashboardItemsData[]>) => void;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
}

const FoldersContext = createContext<FoldersContextProps>({
  screeners: [],
  screenersFolders: [],
  presets: [],
  presetsFolders: [],
  screenerItems: [],
  screenerPresetsItems: [],
  dataPanelsItems: [],
  dataPanelsItemsPresets: [],
  storedDataPanels: [],
  storedDataPanelsFolders: [],
  storedDataPanelsPresetsList: [],
  storedDataPanelsFoldersPresetsList: [],
  itemsColumnSets: [],
  itemsColumnSetsPresets: [],
  itemsWatchlist: [],
  presetsWatchlist: [],
  tabsList: [],
  listPresetsWatchlist: [],
  watchListFolders: [],
  presetsWatchlistFolders: [],
  columns: [],
  columnsPresets: [],
  columnsFolders: [],
  columnsPresetsFolders: [],
  dashboardsItems: [],
  dashboards: [],
  dashboardsFolders: [],
  itemsDashboardPresets: [],
  dashboardsPresets: [],
  dashboardsPresetFoldersItems: [],
  setScreenerMergedItems: () => undefined,
  setPresetsMergedItems: () => undefined,
  setTabsList: () => undefined,
  isLoading: false,
  isError: false,
  error: null,
});

export default FoldersContext;
