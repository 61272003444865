import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../../store/auth/actions';
import { setSelectedDataPanel } from '../../../store/newScreen/actions';
import { CATEGORY_MAX_LENGTH, DATA_PANEL_ACTION_TYPES, MAX_CATEGORY_NAME } from '../dataPanelConstants';
import { SELECTED_DATA_PANEL_ID } from '../../../constants/storage';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { userPanel } from '../../../constants/account';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import useItemsMutations from '../../../tanStack/Items/Mutations/itemsMutations';

const useDataPanelItems = (widget) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateStoreHandler } = useLastStateUpdateStore();
  const selectedDataPanelScreener = useSelector(
    (state) => state.newScreenState?.selectedDataPanel, shallowEqual
  );

  const { storedDataPanels, storedDataPanelsPresetsList } = useDashboardItems();
  const { updateDataPanelMutation } = useItemsMutations();
  const [selectedDataPanel, setSelectedDataPanelWidget] = useState(
    widget?.id ? [...storedDataPanels, ...storedDataPanelsPresetsList].find(
      (dataPanel) => dataPanel?.id === widget?.dataPanelId
    ) : selectedDataPanelScreener
  );

  const successHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const errorHandler = ({ message, title, type }) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type }));
  };

  const checkUniqueCategory = (category) => {
    const userDataPanel = selectedDataPanel;
    const leftColumn = userDataPanel.data.leftColumn?.length
      ? userDataPanel.data.leftColumn?.filter((item) => item && item?.title === category) : [];
    const rightColumn = userDataPanel.data.rightColumn?.length
      ? userDataPanel.data.rightColumn?.filter((item) => item && item?.title === category) : [];
    return leftColumn.length === 0 && rightColumn.length === 0;
  };

  const selectedDataPanelId = useSelector(
    (state) => state.accountState?.userSettings?.selectedDataPanelId, shallowEqual
  );

  const updateUserDataPanel = (id, data, type) => {
    updateDataPanelMutation.mutate({
      id,
      data,
      type,
      successActionHandler: (updatedItem) => {
        dispatch(setSelectedDataPanel(updatedItem));
        setSelectedDataPanelWidget(updatedItem);
        updateStoreHandler(SELECTED_DATA_PANEL_ID, updatedItem?.id);
        let message = '';
        let title = '';
        switch (type) {
          case DATA_PANEL_ACTION_TYPES.REMOVE_CATEGORY:
            message = t('dataPanelSuccessRemove');
            title = t('dataPanelSuccessRemoveTitle');
            break;
          case DATA_PANEL_ACTION_TYPES.RESTORE_DEFAULTS:
            message = t('dataPanelSuccessRestore');
            title = t('dataPanelRestoreTitle');
            break;
          default:
            message = t('dataPanelSuccess');
            title = t('dataPanelSuccessTitle');
            break;
        }
        successHandler(message, title);
      }
    });
  };

  const saveDataPanels = (type) => {
    if (selectedDataPanel?.id) {
      updateUserDataPanel(selectedDataPanel.id, selectedDataPanel.data, type);
    }
  };

  const updateUserDataPanels = (dataPanel) => {
    dispatch(setSelectedDataPanel(dataPanel));
    setSelectedDataPanelWidget(dataPanel);
    updateStoreHandler(SELECTED_DATA_PANEL_ID, dataPanel?.id);
  };

  const restoreDefaults = () => {
    const userDataPanel = selectedDataPanel;
    userDataPanel.data = selectedDataPanel.data;
    dispatch(setSelectedDataPanel(userDataPanel));
    setSelectedDataPanelWidget(userDataPanel);
    const defaultDataPanel = storedDataPanels?.find((dataPanel) => dataPanel?.isDefault)
    || storedDataPanels?.find((dataPanel) => dataPanel?.title === 'Default') || storedDataPanels[0];
    updateUserDataPanel(userDataPanel.id, defaultDataPanel?.data, DATA_PANEL_ACTION_TYPES.RESTORE_DEFAULTS);
    updateStoreHandler(SELECTED_DATA_PANEL_ID, userDataPanel?.id);
  };

  const removeCategory = (category) => {
    const userDataPanel = selectedDataPanel;
    const leftColumn = userDataPanel.data.leftColumn.filter((item) => item.title !== category);
    const rightColumn = userDataPanel.data.rightColumn.filter((item) => item.title !== category);
    const updatedPanels = {
      leftColumn,
      rightColumn,
    };
    userDataPanel.data = updatedPanels;
    if (!widget) {
      updateUserDataPanels(userDataPanel);
    } else {
      updateUserDataPanel(selectedDataPanel.id, userDataPanel.data);
    }
    saveDataPanels(DATA_PANEL_ACTION_TYPES.REMOVE_CATEGORY);
  };

  const renameCategory = (category, newCategory) => {
    if (newCategory?.length > MAX_CATEGORY_NAME) {
      errorHandler({
        message: t('maxCategoryName', { max: MAX_CATEGORY_NAME }),
        title: t('maxCategoryNameTitle'),
        type: t('error')
      });
      return;
    }
    if (!newCategory || newCategory.replace(/\s/g, '').length === 0) {
      errorHandler(
        {
          message: t('emptyCategoryName'),
          title: t('emptyCategoryNameTitle'),
          type: t('error')
        }
      );
      return;
    }
    if (!checkUniqueCategory(newCategory)) {
      errorHandler(
        {
          message: t('categoryUniqueError'),
          title: t('categoryUniqueErrorTitle'),
          type: t('error')
        }
      );
      return;
    }
    const userDataPanel = selectedDataPanel;
    const updateColumn = (column) => {
      return column
        ? column.map((item) => {
          if (item.title === category) {
            return { ...item, title: newCategory };
          }
          return item;
        }) : column;
    };
    const leftColumn = updateColumn(userDataPanel?.data?.leftColumn);
    const rightColumn = updateColumn(userDataPanel?.data?.rightColumn);
    userDataPanel.data = {
      leftColumn,
      rightColumn,
    };
    if (!widget) {
      updateUserDataPanels(userDataPanel);
    } else {
      updateUserDataPanel(selectedDataPanel.id, userDataPanel.data);
    }
    saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
  };

  const addNewCategory = (newCategory) => {
    const userDataPanel = selectedDataPanel;
    userDataPanel.data.rightColumn.push({ title: newCategory, columns: [] });
    if (!widget) updateUserDataPanels(userDataPanel);
    saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
  };

  const updatePanelsData = (data) => {
    const userDataPanel = selectedDataPanel;
    userDataPanel.data = data;
    updateUserDataPanels(userDataPanel);
  };

  const updateCategoryData = (category, data) => {
    const userDataPanel = selectedDataPanel;
    const leftColumn = userDataPanel.data.leftColumn.map((item) => {
      if (item.title === category) {
        return { ...item, columns: data };
      }
      return item;
    });
    const rightColumn = userDataPanel.data.rightColumn.map((item) => {
      if (item.title === category) {
        return { ...item, columns: data };
      }
      return item;
    });
    const updatedPanels = {
      leftColumn,
      rightColumn
    };
    userDataPanel.data = updatedPanels;
    if (!widget) {
      updateUserDataPanels(userDataPanel);
    } else {
      updateUserDataPanel(selectedDataPanel.id, userDataPanel.data);
    }
  };

  const maxCategoryError = () => {
    errorHandler({
      message: t('maxCategoryError', { max: CATEGORY_MAX_LENGTH }),
      title: t('maxCategoryErrorTitle'),
      type: t('error')
    });
  };

  useEffect(() => {
    if (widget?.dataPanelId) {
      setSelectedDataPanelWidget([...storedDataPanels, ...storedDataPanelsPresetsList].find(
        (dataPanel) => dataPanel?.id === widget?.dataPanelId
      ));
    } else {
      const defaultDataPanel = selectedDataPanelScreener
        || storedDataPanels.find(
          (dataPanel) => dataPanel?.title === userPanel
        ) || storedDataPanels.filter(
        (dataPanel) => !dataPanel?.isDefault
      )[storedDataPanels.length - 2] || storedDataPanels[0];
      setSelectedDataPanelWidget(defaultDataPanel);
    }
  }, [widget, widget?.dataPanelId, [...storedDataPanels, ...storedDataPanelsPresetsList].find(
    (dataPanel) => dataPanel?.id === widget?.dataPanelId
  ), selectedDataPanelScreener]);

  useEffect(() => {
    if (storedDataPanels.length) {
      const clonedData = [...storedDataPanels, ...storedDataPanelsPresetsList];
      const selectedItem = clonedData.find((dataPanel) => dataPanel?.id === selectedDataPanelId);
      const defaultDataPanel = clonedData?.find((dataPanel) => dataPanel?.title === userPanel)
        || storedDataPanels.find(
          (dataPanel) => dataPanel?.title === userPanel
        ) || storedDataPanels.filter(
        (dataPanel) => !dataPanel?.isDefault
      )[storedDataPanels.length - 2] || storedDataPanels[0];

      dispatch(setSelectedDataPanel(selectedItem?.isDefault ? defaultDataPanel : selectedItem));
      setSelectedDataPanelWidget(selectedItem?.isDefault ? defaultDataPanel : selectedItem);
    }
  }, [selectedDataPanelId, storedDataPanels.length, storedDataPanelsPresetsList.length]);

  return {
    updateUserDataPanels,
    updateUserDataPanel,
    restoreDefaults,
    removeCategory,
    renameCategory,
    addNewCategory,
    updatePanelsData,
    updateCategoryData,
    saveDataPanels,
    maxCategoryError,
    errorHandler,
    checkUniqueCategory
  };
};

export default useDataPanelItems;
