import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from '../sass/DataPanel.module.scss';
import TextWithTooltip from './TextWithTooltip';
import { setGlobalSectorIndustrySearch } from '../../../store/dashboards/slice';
import {
  groupIndex, industryColumnTitleNames, industryIndex, sectorIndex, subIndustryIndex
} from '../../../constants/dataPanel';
import RootStateTypes from '../../../store/RootStateTypes';

interface IIndustryRankDropDownProps {
  dataRows: [string, number, number][],
  dataPointIndex: typeof industryIndex | typeof sectorIndex | typeof groupIndex | typeof subIndustryIndex,
  symbolIndex: number,
  rankIndex: number,
  sectorIndustryWidgetHandler: (index: number, columnName: string) => void
  widget: {
    id: string,
  },
}

const IndustryRankDropDown = ({
  dataRows, sectorIndustryWidgetHandler, dataPointIndex, symbolIndex, rankIndex, widget
}: IIndustryRankDropDownProps): React.ReactElement => {
  const dispatch = useDispatch();
  const [industryTitle, setIndustryTitle] = useState('');
  const industryOfSymbols = useSelector((state:RootStateTypes) => state.tableDataState.industryOfSymbols, shallowEqual);
  const onClick = (columnName: string) => {
    dispatch(setGlobalSectorIndustrySearch(true));
    sectorIndustryWidgetHandler(dataPointIndex, columnName);
  };

  const columns = [
    industryColumnTitleNames[dataPointIndex], 'Rank', 'Stocks'
  ];
  useEffect(() => {
    const foundIndustry = industryOfSymbols?.find(({ index }) => index === symbolIndex);
    if (foundIndustry) {
      const {
        group, industry, sector, subIndustry
      } = foundIndustry;

      const industryColumnTitleBlock = {
        [industryIndex]: industry,
        [sectorIndex]: sector,
        [groupIndex]: group,
        [subIndustryIndex]: subIndustry
      };

      setIndustryTitle(industryColumnTitleBlock[dataPointIndex]);
      const currentItem = document.getElementById(
        `dataPanel-${dataPointIndex}-${rankIndex}-${industryColumnTitleBlock[dataPointIndex]}`
      );
      const parentItem = document.getElementById(
        `insideScrollContainer-${widget?.id}`
      );
      if (currentItem && parentItem) {
        const parentScrollTop = parentItem.scrollTop;
        currentItem.scrollIntoView({ block: 'center' });

        // Prevent the parent from scrolling
        parentItem.scrollTop = parentScrollTop;
      }
    }
  }, [industryOfSymbols, symbolIndex, dataPointIndex]);

  return (
    <div className={styles.industryDropDownContainer}>
      <div className={styles.industryTableHeader}>
        {
          columns.map((column) => (
            <div className={`${styles.industryColumnCell} ${styles.industryRankColumnCell}`}>
              {column || '-'}
            </div>
          ))
        }
      </div>
      <div className={`${styles.industryTableBody} ${styles.industryRankTableBody}`}>
        {
          dataRows.map((column: [string, number, number]) => (
            <div
              className={styles.industryTableHeader}
              id={`dataPanel-${dataPointIndex}-${rankIndex}-${column[0]}`}
            >
              {
                column.map((dataInfo) => (
                  <div
                    aria-hidden
                    className={`${styles.industryRankBodyCell}
                    ${String(dataInfo) === industryTitle && styles.industryRankBodyCellBold}`}
                    onClick={() => onClick(column[0])}
                  >
                    <TextWithTooltip title={String(dataInfo === 10000 ? '-' : dataInfo)} />
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default IndustryRankDropDown;
