import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RootStateTypes from '../../../store/RootStateTypes';
import { ChartType, FrameType, SortType } from '../types/Enums';
import { frameTypeArray } from '../../../utils/helpers';
import styles from '../sass/MultiChartSettings.module.scss';
import MCSettingsSelect from './MCSettingsSelect';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import { ReactComponent as IconBrush } from '../../../assets/images/icons/screener/brush.svg';
import Button from '../../Button/Button';
import { IChartColumn, IColumnGroup } from '../types/MultichartInterfaces';
import MCSettingsSortButton from './MCSettingsSortButton';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';

interface IMultichartSettingsPanel {
  setShowSettings: (state: boolean) => void;
  showSettings: boolean;
}

type SortOption = {
  id: number | string;
  title: string;
};

const ALPHABETICAL_SORT = {
  title: SortType.Alphabetically,
  id: 17,
};

type DropdownType = 'sort' | 'type' | 'timeframe';

const getTitle = (items: SortOption[], id: string | number) => items
  .find((i: SortOption) => i.id.toString() === id)?.title;

const MultiChartSettingsPanel = ({ setShowSettings, showSettings }: IMultichartSettingsPanel): JSX.Element => {
  const { t } = useTranslation();
  const timeFrame = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.multiChartTimeframe, shallowEqual
  );
  const chartType = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.multiChartType, shallowEqual
  );
  const multiChartSorting = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.multiChartSorting, shallowEqual
  );
  const { direction, columnId } = multiChartSorting ?? { direction: 1, columnId: '' };
  const listColumns: IColumnGroup[] = useSelector(
    (state: RootStateTypes) => state.screenerState.listColumns, shallowEqual
  ) || [];
  const [sortDirection, setSortDirection] = useState(direction);
  const [sortById, setSortById] = useState(columnId);
  const [isSortByOpen, setIsSortByOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isTimeframeOpen, setIsTimeframeOpen] = useState(false);

  const { updateStoreHandler } = useLastStateUpdateStore();
  const sortOptions = listColumns
    .reduce((acc: IChartColumn[], col: IColumnGroup) => [...acc, ...col.items], [])
    .map((item: IChartColumn) => ({ id: item.id, title: item.name }));
  sortOptions.unshift(ALPHABETICAL_SORT);
  const timeFrameOptions = frameTypeArray(FrameType);
  const typeOptions = frameTypeArray(ChartType);

  const sortTitle = getTitle(sortOptions, columnId);
  const timeTitle = getTitle(timeFrameOptions, timeFrame);
  const typeTitle = getTitle(typeOptions, chartType);

  const handleOpenDropDown = (type: DropdownType) => {
    const dropdownStates = {
      sort: setIsSortByOpen,
      type: setIsTypeOpen,
      timeframe: setIsTimeframeOpen
    };

    dropdownStates[type]((prevState) => !prevState);

    Object.keys(dropdownStates).forEach((key) => {
      if (key !== type) {
        dropdownStates[key as DropdownType](false);
      }
    });
  };

  const handleCloseDropdowns = () => {
    setIsSortByOpen(false);
    setIsTimeframeOpen(false);
    setIsSortByOpen(false);
  };

  const hideShowSettings = () => {
    setShowSettings(false);
  };

  useEffect(() => {
    if (sortById !== undefined && sortDirection === -1) {
      if (sortById === ALPHABETICAL_SORT.id) {
        setSortDirection(1);
      } else {
        setSortDirection(0);
      }
    } else {
      updateStoreHandler('multiChartSorting', { columnId: sortById, direction: sortDirection });
    }
  }, [sortById, sortDirection]);

  useEffect(() => {
    handleCloseDropdowns();
  }, [sortById, timeFrame, chartType]);

  return (
    <div className={`${styles.panel} ${showSettings ? styles.visible : styles.hide}`}>
      <div className={styles.topContainer}>
        <div className={styles.title}>
          <span className={styles.caption}>
            {t('multiChartSettings')}
          </span>
          <Button
            buttonClass={styles.iconWrapper}
            handleClick={hideShowSettings}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className={styles.sortBy}>
          <MCSettingsSelect
            title={sortTitle || t('sortBy')}
            selectOption={sortOptions}
            activeId={columnId}
            setIsOpen={() => handleOpenDropDown('sort')}
            isOpen={isSortByOpen}
            setActiveId={setSortById}
            optionSearch
            className={styles.narrowSelect}
          />
          <MCSettingsSortButton
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
          />
        </div>
        <MCSettingsSelect
          title={timeTitle || t('timeFrame')}
          selectOption={timeFrameOptions}
          activeId={timeFrame}
          setActiveId={(val: string) => updateStoreHandler('multiChartTimeframe', val)}
          setIsOpen={() => handleOpenDropDown('timeframe')}
          isOpen={isTimeframeOpen}
        />
        <MCSettingsSelect
          title={typeTitle || t('type')}
          selectOption={typeOptions}
          activeId={chartType}
          setActiveId={(val: string) => updateStoreHandler('multiChartType', val)}
          setIsOpen={() => handleOpenDropDown('type')}
          isOpen={isTypeOpen}
        />
      </div>
      <div className={styles.footer}>
        <button
          title={t('clearSort')}
          type="button"
          className={styles.footerButton}
          onClick={() => {
            setSortById(undefined);
            setSortDirection(-1);
          }}
        >
          <IconBrush />
          {t('clearSort')}
        </button>
      </div>
    </div>
  );
};

export default MultiChartSettingsPanel;
