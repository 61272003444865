import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import $api from '../../../http';
import constants from '../../../constants/filters';
import { FetchFoldersArgs, FetchFoldersResponse, Folder } from '../types';
import numberConstant from '../../../constants/numberConstants';
import { TDashboardItemsData } from '../../../utils/Types';

const fetchFolders = async ({ pageParam = 0, type }: FetchFoldersArgs): Promise<FetchFoldersResponse> => {
  const response = await $api.get(
    `dashboard-item-folder?type=${type}&page=${pageParam}&limit=${numberConstant.limitItems}`,
  );

  if (!response.status) {
    throw new Error('Failed to fetch folders');
  }

  const { items, count } = response.data;

  return { folders: items.map((item: Folder) => ({ ...item, subType: item.type, type: constants.folder })), count };
};

type TypePages = {
  count: number;
  folders: TDashboardItemsData[];
};

type TypeGetFoldersReturn = {
  pageParams: number[];
  pages: TypePages[]
};

const useGetAllFolders = (type: string):UseInfiniteQueryResult<TypeGetFoldersReturn, unknown> => useInfiniteQuery({
  queryKey: [`${type}Folders`],
  queryFn: (params) => fetchFolders({ ...params, type }),
  initialPageParam: 0,
  getNextPageParam: (lastPage, allPages) => {
    const totalFetched = allPages.flatMap((page) => page.folders).length;
    const totalItems = lastPage.count;
    const hasNextPage = totalFetched < totalItems;

    return hasNextPage ? allPages.length : undefined;
  },
  refetchOnWindowFocus: false,
});

export default useGetAllFolders;
