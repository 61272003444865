import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import { FolderItemData, TDashboardItemsData, TFolderInnerData } from '../../../utils/Types';
import { TDialogData, TDashboardItemExt } from '../types/types';
import {
  DialogItemDataTypes, CreateFolderTypes,
  DashboardItemsDialogActionTypes, CreateFolderPresetTypes
} from '../types/constants';
import { DasboardsItemsDropdownTypes, DialogItemEntityType } from '../../DashboardsItemsDropdown/types/constants';
import { resetCurrentPage } from '../../../store/tableData/slice';
import {
  DEFAULT_PAGINATION_STATE, FIND_NAME,
  SUBSCRIPTION_TYPE,
  TEMPLATE_TYPE
} from '../../../constants/screener';
import { setModalType, setSelectedTab } from '../../../store/watchlist/actions';
import UseDashboards from '../../../pages/Dashboards/hooks/UseDashboards';
import { WIDGET_CHARTS_REMOVE_DASHBOARD, PSC_REMOVE_DASHBOARD, SELECTED_TAB_ID } from '../../../constants/storage';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { LAST_STATE_KEYS } from '../../WidgetCharts/types/WidgetChartsEnums';
import { checkRoleUser } from '../../../utils/userHelper';
import { Dashboard } from '../../../pages/Dashboards/types/DashboardTypes';
import useFolderMutations from '../../../tanStack/Folders/Mutations/folderMutations';
import useItemsMutations from '../../../tanStack/Items/Mutations/itemsMutations';
import {
  setColumnSetsId,
  setColumnSetUpdateFlag,
  setCreatedFolder,
  setNameColumnSets, setSelectedScreen
} from '../../../store/newScreen/actions';
import { getTitleCreateSuccess, getTitleDeleteSuccess, getTitleUpdateSuccess } from '../../../utils/helperNewScreenModal';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import RootStateTypes from '../../../store/RootStateTypes';
import { LAST_SCREENER_COLUMNS_SET, SELECTED_SCREEN_ID } from '../../../constants/tvWidgetOptions';

interface IDashboardItemsDialogsCallback {
  (
    closeModal: () => void,
    dataFromDialog: TDialogData | null,
    setDataCb: (data: TDialogData) => void,
    setFolderId: (id: string) => void,
    currentItemHandler: (item: TDashboardItemsData | string) => void
  ): {
    dialogAction: (item: TDashboardItemsData, dialogData: TDialogData, folderId?: string | null) => void;
  };
}

const DashboardItemsDialogsCallback: IDashboardItemsDialogsCallback = (
  closeModal, dataFromDialog,
  setDataCb, setFolderId, currentItemHandler
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const columnSetId = useSelector((state: RootStateTypes) => state.newScreenState.columnSetId, shallowEqual);
  const selectedScreen = useSelector((state: RootStateTypes) => state.newScreenState.selectedScreen, shallowEqual);

  const {
    createDashboard,
    removeDashboard,
    renameDashboard,
    duplicateDashboard,
  } = UseDashboards();
  const {
    updateNestedPscActionData,
    updateWidgetChartsActionData,
    updateStoreHandler
  } = useLastStateUpdateStore();
  const [currentCbDialog, setCurrentCbDialog] = useState<TDialogData | null>(dataFromDialog);

  const {
    tabsList,
    watchListFolders,
    storedDataPanelsFolders,
    screenersFolders,
    dashboardsFolders,
    columns,
  } = useDashboardItems();

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const { successHandler, errorHandler } = useNewScreen(currentItemHandler);

  const {
    creteFolderMutation,
    updateFolderMutation,
    removeFolderMutation
  } = useFolderMutations();

  const {
    updateItemMutation,
    moveToItemMutation,
    removeItemMutation,
    removeItemFromFolderMutation,
    duplicatePresetMutation
  } = useItemsMutations();

  const addFolder = (
    item: TFolderInnerData,
    type: DialogItemDataTypes,
    selectedTabData: DasboardsItemsDropdownTypes
  ) => {
    const folderSuccessActionHandler = (id: number | string) => {
      dispatch(setCreatedFolder(id));
      successHandler(item?.title, t('folderCreated'));
      if (currentCbDialog) {
        setFolderId(id as string);
        setDataCb(currentCbDialog);
        dispatch(setModalType(''));
      } else {
        closeModal();
      }
    };

    const folderType: string = selectedTabData === DasboardsItemsDropdownTypes.PRESET && adminRole
      ? CreateFolderPresetTypes[type] : CreateFolderTypes[type];
    creteFolderMutation.mutate({
      name: item?.title || '',
      type: folderType,
      folderSuccessActionHandler,
      errorHandler
    });
  };

  const editFolder = (folder: TFolderInnerData) => {
    const folderSuccessActionHandler = () => {
      successHandler(folder?.title, t('folderUpdate'));
      closeModal();
    };

    updateFolderMutation.mutate({
      name: folder?.title || '',
      id: folder?.id || '',
      folderSuccessActionHandler,
      errorHandler
    });
  };

  const actionAdd = (item: TDashboardItemsData, dialogData: TDialogData) => {
    switch (dialogData?.entityType) {
      case DialogItemEntityType.FOLDER:
        addFolder(
          item as TFolderInnerData,
          dialogData?.itemType as DialogItemDataTypes,
          dialogData?.selectedTab as DasboardsItemsDropdownTypes
        );
        break;
      case DialogItemEntityType.ITEM:
        createDashboard(item.title, !!dialogData.selectedTab);
        closeModal();
        break;
      default:
        break;
    }
  };

  const actionEdit = (item: TDashboardItemsData, dialogData: TDialogData) => {
    const screensTypes = [
      DialogItemDataTypes.SCREENER,
      DialogItemDataTypes.DEEP_LIST,
      DialogItemDataTypes.DATA_PANEL,
      DialogItemDataTypes.COLUMN_SET
    ];

    const itemSuccessActionHandler = (updatedItem: TDashboardItemsData) => {
      if (updatedItem.type === TEMPLATE_TYPE.COLUMN_SET && columnSetId === updatedItem.id) {
        dispatch(setColumnSetsId(updatedItem.id));
        dispatch(setNameColumnSets(updatedItem.title));
        dispatch(setColumnSetUpdateFlag(false));
      }

      if (updatedItem.type === TEMPLATE_TYPE.WATCHLIST && selectedTab.id === updatedItem.id) {
        dispatch(setSelectedTab(updatedItem));
      }

      if (updatedItem.type === TEMPLATE_TYPE.SCREENER && selectedScreen.id === updatedItem.id) {
        dispatch(setSelectedScreen(updatedItem));
      }

      successHandler(updatedItem.title, getTitleUpdateSuccess(updatedItem.type, t));
      dispatch(setModalType(''));
      closeModal();
    };

    switch (dialogData?.entityType) {
      case DialogItemEntityType.FOLDER:
        editFolder(item as TFolderInnerData);
        break;
      case DialogItemEntityType.ITEM:
        if (screensTypes.includes(dialogData?.itemType as number)) {
          updateItemMutation.mutate({ item, itemSuccessActionHandler, errorHandler });
        }
        if (dialogData?.itemType === DialogItemDataTypes.DASHBOARD) {
          renameDashboard(item.title, item.id);
          dispatch(setModalType(''));
          closeModal();
        }
        break;
      default:
        break;
    }
  };
  const actionDuplicate = (item: TDashboardItemExt, cbFolderId?: string | null) => {
    const itemSuccessActionHandler = (newItem: TDashboardItemsData) => {
      successHandler(newItem?.title, getTitleCreateSuccess(newItem?.type, t));
      dispatch(resetCurrentPage(DEFAULT_PAGINATION_STATE));

      if (newItem.type === TEMPLATE_TYPE.WATCHLIST) {
        dispatch(setSelectedTab(newItem));
        updateStoreHandler(SELECTED_TAB_ID, newItem?.id);
      }

      if (newItem.type === TEMPLATE_TYPE.COLUMN_SET) {
        dispatch(setColumnSetsId(newItem.id));
        dispatch(setNameColumnSets(newItem.title));
        dispatch(setColumnSetUpdateFlag(false));
        updateStoreHandler(LAST_SCREENER_COLUMNS_SET, newItem.id);
      }

      if (newItem.type === TEMPLATE_TYPE.SCREENER) {
        dispatch(setSelectedScreen(newItem));
        updateStoreHandler(SELECTED_SCREEN_ID, newItem.id);
      }
    };

    const folderId = item?.folderId || cbFolderId;

    if (item.type === TEMPLATE_TYPE.DASHBOARDS) {
      duplicateDashboard(item as never as Dashboard, item.title, folderId, closeModal);
    } else {
      duplicatePresetMutation.mutate({
        titlePreset: item?.title,
        dataPreset: item?.data,
        folderId,
        type: item?.type,
        successActionHandler: itemSuccessActionHandler,
        errorHandler
      });
    }
    closeModal();
  };
  const actionRemove = (item: TDashboardItemsData, dialogData: TDialogData) => {
    const folderSuccessActionHandler = () => {
      dispatch(setModalType(''));
      successHandler(item.title, t('folderDelete'));
    };

    const itemSuccessActionHandler = (removedItem: TDashboardItemsData) => {
      if (removedItem?.type === TEMPLATE_TYPE.WATCHLIST) {
        const universeTab = tabsList.find((tabItem) => tabItem.data?.subType === UNIVERSE_TYPE);
        const removeTabIndex = tabsList.findIndex((tabItem) => tabItem.id === removedItem.id);
        if (selectedTab.id === removedItem.id) {
          const nextTab = tabsList[removeTabIndex + 1];
          updateStoreHandler(SELECTED_TAB_ID, nextTab?.id);
          dispatch(setSelectedTab(nextTab || universeTab));
        }
      }
      if (removedItem?.type === TEMPLATE_TYPE.COLUMN_SET) {
        const recommended = columns.find((columnsItem) => columnsItem.title === FIND_NAME.RECOMMENDED);
        const removeTabIndex = columns.findIndex((columnsItem) => columnsItem.id === removedItem.id);
        if (columnSetId === removedItem.id) {
          const nextColumnsSet = columns[removeTabIndex + 1];
          updateStoreHandler(LAST_SCREENER_COLUMNS_SET, nextColumnsSet?.id);
          dispatch(setColumnSetsId(nextColumnsSet?.id || recommended?.id));
        }
      }
      if (removedItem?.type === TEMPLATE_TYPE.SCREENER) {
        if (selectedScreen.id === removedItem.id) {
          updateStoreHandler(SELECTED_SCREEN_ID, '');
          dispatch(setSelectedScreen({}));
        }
      }

      successHandler(removedItem?.title, getTitleDeleteSuccess(removedItem?.type, t));
    };

    switch (dialogData?.entityType) {
      case DialogItemEntityType.FOLDER: {
        removeFolderMutation.mutate({
          name: item?.title,
          id: item?.id,
          type: CreateFolderTypes[dialogData?.itemType || 0],
          folderSuccessActionHandler,
          errorHandler
        });
        break;
      }
      default:
      {
        dispatch(setModalType(''));
        if (dialogData?.itemType === DialogItemDataTypes.DASHBOARD) {
          updateNestedPscActionData(PSC_REMOVE_DASHBOARD, {}, item.id);
          updateWidgetChartsActionData(WIDGET_CHARTS_REMOVE_DASHBOARD, {}, item.id, LAST_STATE_KEYS.performance);
          updateWidgetChartsActionData(WIDGET_CHARTS_REMOVE_DASHBOARD, {}, item.id, LAST_STATE_KEYS.heatmap);
          updateWidgetChartsActionData(WIDGET_CHARTS_REMOVE_DASHBOARD, {}, item.id, LAST_STATE_KEYS.bubble);
          removeDashboard(item.id);
          closeModal();
          break;
        } else {
          removeItemMutation.mutate({
            item,
            successActionHandler: itemSuccessActionHandler,
            errorHandler
          });
        }
      }
    }
    closeModal();
  };
  const actionMoveTo = (item: TDashboardItemExt) => {
    const moveToFolders = {
      [TEMPLATE_TYPE.WATCHLIST]: watchListFolders,
      [TEMPLATE_TYPE.SCREENER]: screenersFolders,
      [TEMPLATE_TYPE.DATA_PANEL]: storedDataPanelsFolders,
      [TEMPLATE_TYPE.DASHBOARDS]: dashboardsFolders,
    };

    const foldersSource = moveToFolders[item?.type] || screenersFolders;
    const folder = foldersSource.find(
      (f: FolderItemData) => f.id.toString() === item?.folderId
    ) as never as TFolderInnerData;
    const details = { folder: folder?.name, screen: item.title };

    const itemSuccessActionHandler = () => {
      if (details) {
        successHandler(t('folderMove', { folderName: details.folder, screenerName: details.screen }));
      }
      closeModal();
    };

    moveToItemMutation.mutate({
      id: item.id,
      folderId: item.folderId,
      details,
      itemSuccessActionHandler,
      errorHandler
    });
  };

  const actionRemoveFromFolder = (item: TDashboardItemsData) => {
    const successActionHandler = () => {
      successHandler(t('removeSubItemMsg', { folderName: item?.folder?.name, subItem: item?.title }));
      closeModal();
    };
    removeItemFromFolderMutation.mutate({ item, successActionHandler, errorHandler });
  };

  const dialogAction = (item: TDashboardItemsData, dialogData: TDialogData, folderId? : string | null) => {
    switch (dialogData?.type) {
      case DashboardItemsDialogActionTypes.EDIT:
        actionEdit(item, dialogData);
        break;
      case DashboardItemsDialogActionTypes.DELETE:
        actionRemove(item, dialogData);
        break;
      case DashboardItemsDialogActionTypes.DUPLICATE:
        actionDuplicate(item, folderId);
        break;
      case DashboardItemsDialogActionTypes.REMOVE:
        actionRemoveFromFolder(item);
        break;
      case DashboardItemsDialogActionTypes.MOVE_TO:
        actionMoveTo(item);
        break;
      default:
        actionAdd(item, dialogData);
    }
  };

  useEffect(() => {
    setCurrentCbDialog(dataFromDialog);
  }, [dataFromDialog]);

  return {
    dialogAction,
  };
};

export default DashboardItemsDialogsCallback;
