import React, { useEffect, useState } from 'react';

import update from 'immutability-helper';
import styles from './sass/DashboardComponents.module.scss';
import { IDasboardsTabs } from './types/DashboardComponentsInterfaces';
import DashboardTab from './DashboardTab';
import UseDashboards from '../../pages/Dashboards/hooks/UseDashboards';

type TDropItem = {
  index: number;
  id: number;
};

const DashboardsTabs = ({
  dashboards, selectedDashboard, setCurrentDashboard, morePopupCallback
}: IDasboardsTabs): React.ReactElement => {
  const { saveOrder } = UseDashboards();
  const [tabOrder, setTabOrder] = useState<number[]>(dashboards?.map((dashboard) => dashboard.id) || []);
  const [dropResult, setDropResult] = useState<TDropItem | null>(null);
  const handleSelect = (id: number) => {
    setCurrentDashboard(id);
  };

  const changeOrderHandler = (dragIndex: number, hoverIndex: number) => {
    setTabOrder((prevOrder) => update(prevOrder, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevOrder[dragIndex]],
      ],
    }));
  };

  const endDragHandler = (dragItem: TDropItem) => {
    setDropResult(dragItem);
  };

  useEffect(() => {
    if (dropResult !== null) {
      const prevIndex = tabOrder.indexOf(dropResult.id) - 1;
      saveOrder(dropResult.id, prevIndex < 0 ? 0 : tabOrder[prevIndex], 'userDashboard');
      setDropResult(null);
    }
  }, [dropResult]);

  useEffect(() => {
    setTabOrder(dashboards.map((dashboard) => dashboard.id));
  }, [dashboards, dashboards?.length]);

  return (
    <div className={styles.dashboardsTabsWrapper}>
      {tabOrder?.map((itemId, index) => {
        const item = dashboards.find((i) => i.id === itemId);
        return item && (
          <DashboardTab
            key={item?.id}
            handleSelect={handleSelect}
            dashboardName={item?.title || ''}
            tabId={item?.id || 0}
            selected={item?.id === selectedDashboard}
            changeOrderHandler={changeOrderHandler}
            endDragHandler={endDragHandler}
            index={index}
            selectedTabId={selectedDashboard}
            morePopupCallback={morePopupCallback}
          />
        );
      })}
    </div>
  );
};

export default DashboardsTabs;
