import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ChartingLibraryWidgetOptions, ResolutionString, widget } from '../../../charting_library';
import { IChartElement } from '../types/MultichartInterfaces';
import Checkbox from '../../Checkbox/Checkbox';
import styles from '../sass/MultiChart.module.scss';
import TradingViewAdapter from '../../../services/chartingHistoricalClient';
import RootStateTypes from '../../../store/RootStateTypes';
import ChartAdditionalInfo from './ChartAdditionalInfo';
import {
  ADDITIONAL_DISABLED_FEATURES,
  DISABLED_FEATURES,
  ENABLED_FOR_MULTICHART,
  OVERRIDES,
  SECOND,
  STUDIES_OVERRIDES,
} from '../../../constants/tvWidgetOptions';
import { useTheme } from '../../../utils/hooks/useTheme';
import { ITradingViewWidget } from '../../../models/tradingView.model';
import { FullSymbolInfo } from '../../../services/Interfaces';
import { onChangeTradingViewTheme } from '../../ChartContainer/utils/subscribeUtils';
import { setMultiChartIsMounted, setSelectedSymbolMultiChart } from '../../../store/multiChart/actions';
import TargetListWrapper from '../../Screener/components/TargetMenu/TargetListWrapper';
import useLongDescription, { descriptionCache } from '../../../utils/hooks/useLongDescription';
import useWatchList from '../../../utils/hooks/useWatchList';
import { ITargetListItem } from '../../Screener/interfaces/ITargetList';
import { ChartType, FrameType } from '../types/Enums';
import { lastBarsCache } from '../../../services/HistoricalSharedData';
import SocketContext from '../../../context/SocketContext';
import { MarketConstants, MarketSession } from '../../MarketDropdown/types/MarketEnums';
import { changeActiveSymbol } from '../../ChartContainer/utils/tradingViewHelper';
import useAdditionalDataForTv from '../../ChartContainer/hooks/useAdditionalDataForTv';

let descriptionTimeout: ReturnType<typeof setTimeout>;

const ChartElement = ({
  symbolIdx,
  checked,
  targetLists,
  setSelectedCharts,
  isDeepList,
}: IChartElement): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handlerTargetList } = useWatchList();
  const { theme } = useTheme();
  const RefExtended = useRef<string | null>(null);

  const [tradingViewWidget, setTradingViewWidget] = useState<ITradingViewWidget | null>(null);

  const symbolsList: FullSymbolInfo[] = useSelector(
    (state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual
  );
  const userProfile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const holidaysList = useSelector((state: RootStateTypes) => state.watchlistState.holidaysList, shallowEqual);
  const timeFrame = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.multiChartTimeframe, shallowEqual
  ) ?? FrameType.Daily;
  const chartType = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.multiChartType, shallowEqual
  ) ?? ChartType.Candlestick;
  const additionalData = useSelector((state: RootStateTypes) => state.multiChartState.additionalData, shallowEqual);
  const selectedSymbol = useSelector((state: RootStateTypes) => state.multiChartState.selectedSymbol, shallowEqual);
  const isMountedChart = useSelector((state: RootStateTypes) => state.multiChartState.isMountedChart, shallowEqual);
  const overwriteFlag = useSelector((state: RootStateTypes) => state.accountState.overwriteFlag, shallowEqual);
  const tvLayoutsList = useSelector((state: RootStateTypes) => state.screenerState.tvLayoutsList, shallowEqual);
  const tickVersion = useSelector((state: RootStateTypes) => state.accountState.tickVersion, shallowEqual);

  const [currentTargetList, setCurrentTargetList] = useState<ITargetListItem | null>(null);
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState('');
  const [toggleTargetList, setToggleTargetList] = useState(false);
  const [iframeMouseOver, setIFrameMouseOver] = useState(false);

  const { Socket, Authorized, Connection } = useContext(SocketContext);

  const { getDescription } = useLongDescription();
  const { contentHandler } = useAdditionalDataForTv();

  let timerId: ReturnType<typeof setTimeout>;
  const [symbol, setSymbol] = useState(symbolsList[symbolIdx]);

  const setSelectedSymbol = () => {
    dispatch(setSelectedSymbolMultiChart(symbol.index));
  };

  const handleRemoveSymbolFromTargetList = () => {
    if (currentTargetList) {
      setCurrentTargetList(null);
    }
  };

  const onWindowBlur = () => {
    if (iframeMouseOver && selectedSymbol !== symbol.index) {
      dispatch(setSelectedSymbolMultiChart(symbol.index));
    }
  };

  const showDescriptionHandler = () => {
    descriptionTimeout = setTimeout(() => setShowDescription(true), SECOND);
  };

  const hideDescriptionHandler = () => {
    clearTimeout(descriptionTimeout);
    setShowDescription(false);
  };

  const descriptionHandler = async () => {
    await getDescription(symbol?.sym);
    setDescription(descriptionCache[symbol?.sym]);
  };

  const handleOnMouseOver = () => {
    setIFrameMouseOver(true);
  };

  const handleOnMouseOut = () => {
    window.focus();
    setIFrameMouseOver(false);
  };

  useEffect(() => {
    if (symbol && Socket?.readyState === WebSocket.OPEN && Authorized) {
      const isExtendedSessionFromStore = userLastState?.currentSessionFromStore === MarketSession.EXTENDED;

      timerId = setTimeout(() => {
        const configuration = {
          symbol: symbol.sym,
          interval: timeFrame as ResolutionString,
          width: '100%',
          timezone: moment.tz.guess(),
          height: '100%',
          theme: theme.substring(0, theme.indexOf('-')),
          library_path: '/charting_library/',
          disabled_features: [...DISABLED_FEATURES, ...ADDITIONAL_DISABLED_FEATURES],
          enabled_features: ENABLED_FOR_MULTICHART,
          container: `tv_multi_container_${symbol.sym}_0`,
          overrides: {
            ...OVERRIDES,
            'mainSeriesProperties.sessionId': isExtendedSessionFromStore
              ? MarketConstants.SUBSESSION_EXTENDED_ID : MarketConstants.SUBSESSION_REGULAR_ID
          },
          studies_overrides: STUDIES_OVERRIDES,
          locale: 'en',
          datafeed: new TradingViewAdapter({
            symbol: symbol.sym,
            interval: timeFrame,
            symbolsList,
            socket: Connection,
            chanelId: userProfile.id,
            currentPath: history.location.pathname,
            holidaysList,
            extendedFromUser: { current: userLastState?.currentSessionFromStore },
            isMultiChart: true,
            tickVersion: tickVersion || 1
          }),
        };
        // eslint-disable-next-line
        const newWidget: ITradingViewWidget = new widget(configuration as unknown as ChartingLibraryWidgetOptions);
        if (newWidget) {
          newWidget.datafeed = configuration.datafeed;
          newWidget.onChartReady(() => {
            newWidget.activeChart().createStudy('Volume', true, false);
            newWidget.activeChart().getTimeScale().defaultRightOffsetPercentage().setValue(1);
            newWidget.activeChart().getTimeScale().defaultRightOffset().setValue(1);
            newWidget.activeChart().setChartType(+chartType);
          });
          dispatch(setMultiChartIsMounted(false));
          setTradingViewWidget(newWidget);
        }
      }, isMountedChart ? 0 : 1500);
    }
  }, [symbol, Socket?.readyState, Authorized]);

  useEffect(() => () => {
    clearTimeout(timerId);
    if (symbol) {
      delete descriptionCache[symbol.sym];
      lastBarsCache.delete(`${symbol.sym}_${timeFrame}`);
    }
  }, []);

  useEffect(() => {
    if (tradingViewWidget && symbol) {
      tradingViewWidget.onChartReady(() => {
        tradingViewWidget.activeChart().setZoomEnabled(selectedSymbol === symbol.index);
      });
    }
  }, [selectedSymbol]);

  useEffect(() => {
    if (theme) {
      if (symbol) {
        onChangeTradingViewTheme(
          tradingViewWidget,
          theme,
          userLastState?.chartProperties,
          overwriteFlag,
          userLastState?.activeChartLayoutId,
          tvLayoutsList,
          userLastState?.layoutFlag
        );
      }
    }
  }, [theme]);

  useEffect(() => {
    if (tradingViewWidget) {
      tradingViewWidget.onChartReady(() => {
        tradingViewWidget.applyOverrides(OVERRIDES);
      });
    }
  }, [tradingViewWidget]);

  useEffect(() => {
    if (symbolIdx) {
      setSymbol(symbolsList[symbolIdx]);
    }
  }, [symbolIdx]);

  useEffect(() => {
    if (symbol && tradingViewWidget) {
      changeActiveSymbol({
        tradingViewWidget,
        symbol: symbol.sym,
        contentHandler,
      });
    }
  }, [symbolIdx, symbol, tradingViewWidget]);

  useEffect(() => {
    if (Connection && tradingViewWidget) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tradingViewWidget.datafeed.socket = Connection;
    }
  }, [Connection, Authorized]);

  useEffect(() => {
    window.addEventListener('blur', onWindowBlur);
    return () => window.removeEventListener('blur', onWindowBlur);
  });

  useEffect(() => {
    if (timeFrame && tradingViewWidget) {
      if (symbol) {
        tradingViewWidget?.onChartReady(() => {
          tradingViewWidget?.activeChart().setResolution(timeFrame as ResolutionString);
        });
      }
    }
  }, [timeFrame, tradingViewWidget]);

  useEffect(() => {
    if (chartType) {
      if (symbol) {
        tradingViewWidget?.onChartReady(() => {
          tradingViewWidget?.activeChart().setChartType(Number(chartType));
        });
      }
    }
  }, [chartType, tradingViewWidget]);

  useEffect(() => {
    const getCurrentTargetList = targetLists.find((list) => {
      const result = list?.data?.filters[0][0][2].find((symbolItem: number) => symbolItem === symbol?.index);
      return (result !== undefined) ? list : false;
    });
    setCurrentTargetList(getCurrentTargetList as ITargetListItem);
  }, [targetLists]);

  useEffect(() => {
    if (showDescription) {
      descriptionHandler();
    }
  }, [showDescription]);

  useEffect(() => {
    RefExtended.current = userLastState?.currentSessionFromStore;
  }, [userLastState?.currentSessionFromStore]);

  return (
    <>
      {symbol ? (
        <div
          onClick={setSelectedSymbol}
          className={`${styles.chartElement} ${selectedSymbol === symbol.index ? styles.chartElementSelected : ''}`}
          aria-hidden
        >
          <div className={styles.chartHeader}>
            <div className={styles.chartHeaderSymbolInfo}>
              <Checkbox
                id={symbol.index.toString()}
                checked={checked}
                changeHandler={() => setSelectedCharts(symbol.index)}
              />
              <TargetListWrapper
                item={{ ...symbol, sortIndex: symbol.index }}
                showDescription={showDescription}
                setShowDescription={setShowDescription}
                toggleTargetList={toggleTargetList}
                currentTargetList={currentTargetList}
                setToggleTargetList={setToggleTargetList}
                handleRemoveSymbolFromTargetList={handleRemoveSymbolFromTargetList}
                watchList={isDeepList}
                description={description}
                targetLists={targetLists}
                addSymbolToTargetList={handlerTargetList}
              />
              <div
                className={styles.chartHeaderTitle}
                onMouseEnter={showDescriptionHandler}
                onMouseLeave={hideDescriptionHandler}
              >
                {symbol.sym}
              </div>
            </div>
            <ChartAdditionalInfo
              industry={additionalData?.industry[symbolIdx]}
              sector={additionalData?.sector[symbolIdx]}
              earningsValue={additionalData?.earningsValue[symbolIdx]}
              earningTimeValue={additionalData?.earningTimeValue[symbolIdx]}
              dividendValue={additionalData?.dividendValue[symbolIdx]}
            />
          </div>
          <div
            onMouseOver={handleOnMouseOver}
            onMouseOut={handleOnMouseOut}
            onFocus={handleOnMouseOver}
            onBlur={handleOnMouseOver}
            id={`tv_multi_container_${symbol.sym}_0`}
            className={styles.chartElementContainer}
          />
        </div>
      ) : <div />}
    </>
  );
};

export default ChartElement;
