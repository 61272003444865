import React, {
  useCallback,
  useRef, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import MorePopUp from './MorePopUp';
import ClientOnlyPortal from '../ClientOnlyPortal/ClientOnlyPortal';
import TextField from '../TextField/TextField';
import useDnDtabs from '../../utils/hooks/useDnDtabs';

import useWatchList from '../../utils/hooks/useWatchList';
import { setToLocalStorage } from '../../utils/storageWorks';
import { getCountForLinkedWatchList, stocksControllerForEmptyDL } from '../../utils/watchListsHelper';

import {
  limitation,
  VIEW_TYPE_TARGET,
  TAB_WIDTH,
  UNIVERSE_TYPE,
  FAVOURITE_TYPE, MORE_POS,
} from '../../constants/watchlist';
import TargetListIcon from '../../assets/images/icons/targetList/TargetListIcon';
import { ReactComponent as MoreIcon } from '../../assets/images/icons/moreWatchlistIcon.svg';
import { ReactComponent as DndControl } from '../../assets/images/icons/dndWatchlistIcon.svg';
import { ReactComponent as UniverseIcon } from '../../assets/images/icons/universe.svg';
import { ReactComponent as Favorites } from '../../assets/images/icons/wlIcons/favorites.svg';

import { WATCHLIST_PARENT_ID } from '../../constants/storage';
import { VIEW_TYPES } from '../../constants/screener';
import styles from './sass/WatchlistTabsBlock.module.scss';
import ScreenerViewEnum from '../ScreenerMenu/enums';
import { useDashboardItems } from '../../context/DasboardItemsContext/DashboardItemsProvider';

const CommonTab = ({
  search,
  index,
  item,
  endDragHandler,
  setPrevItem,
  prevItemStyles,
  changeOrderHandler,
  selectTabClickHandler,
  resetSearchHandler
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);

  const { tabsList } = useDashboardItems();

  const searchValue = useSelector((state) => state.screenerState.searchValue, shallowEqual);
  const isHideMenu = useSelector((state) => state.accountState.userSettings.isHideSideMenu, shallowEqual);
  const screenerView = useSelector((state) => state.accountState.userSettings.screenerView, shallowEqual);
  const counterSymbols = useSelector((state) => state.tableDataState.counterSymbols, shallowEqual);

  const currentLimitation = limitation[item.viewType];

  let hoverTimeout = null;

  const [viewTool, setViewTool] = useState(false);
  const [hoveredTab, setHoveredTab] = useState(-1);
  const [moreVisible, setMoreVisible] = useState(false);
  const [symbolCount, setSymbolCount] = useState(0);
  const [isChanged, setIsChanged] = useState(0);
  const {
    drop,
    handlerId,
    drag,
    isDragging
  } = useDnDtabs(ref, index, item.id, changeOrderHandler, endDragHandler, selectedTab.id);

  const { updateWatchlistData, updateStocksForEmptyDL } = useWatchList();

  const isUniverse = (item?.data?.subType === UNIVERSE_TYPE);
  const isFavourites = (item?.data?.subType === FAVOURITE_TYPE);
  const isShowMoreVisible = !isUniverse
    && !isFavourites
    && (selectedTab?.id === item?.id || hoveredTab === item?.id)
    && !item?.parentId;

  const onMouseLeaveTab = useCallback(() => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    hoverTimeout = setTimeout(() => {
      setHoveredTab(-1);
      setPrevItem(-1);
      setMoreVisible(false);
    }, 500);
  }, [hoveredTab, viewTool]);

  const onMouseEnterTab = useCallback(() => {
    const findItem = tabsList[index - 1];
    setHoveredTab(item?.id);
    setPrevItem(findItem?.id || -1);
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
  }, [hoveredTab, viewTool, selectedTab]);

  const clickHandler = async () => {
    setViewTool(false);
    if (selectedTab?.id !== item?.id) {
      selectTabClickHandler(item);
      setToLocalStorage(WATCHLIST_PARENT_ID, item?.parentId);
    }

    if (searchValue && Object.keys(searchValue)?.length) {
      resetSearchHandler(item);
    }
  };

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  useEffect(() => {
    if (selectedTab?.id === item?.id) {
      if (counterSymbols !== symbolCount) {
        setIsChanged(true);
      }

      if (!search) {
        setSymbolCount(counterSymbols);
      }
    }
  }, [counterSymbols]);

  useEffect(() => {
    if (item?.data) {
      stocksControllerForEmptyDL(item, updateStocksForEmptyDL);
    }

    if (item?.parentId) {
      getCountForLinkedWatchList(item?.data)
        .then((resp) => {
          setSymbolCount(resp?.data[3]);
          const data = item?.data;
          data.stocks = resp?.data[3];
          updateWatchlistData(item?.id, data);
        });
    } else {
      setSymbolCount(item?.data?.stocks);
    }

    return () => {
      ref.current = null;
    };
  }, []);

  useEffect(() => {
    if ((item?.data?.stocks || item?.data?.stocks === 0)
      && !item?.parentId && screenerView !== ScreenerViewEnum.CHART) {
      setSymbolCount(item?.data?.stocks);
    }
  }, [tabsList]);

  const getTabIcon = () => {
    let icon = null;
    if (item?.viewType === VIEW_TYPE_TARGET) {
      icon = (
        <TargetListIcon
          colorStart={item?.data?.color1}
        />
      );
    }
    if (isUniverse) {
      icon = <UniverseIcon />;
    }
    if (isFavourites) {
      icon = <Favorites />;
    }
    return icon ? (
      <div className={styles.targetIcon}>
        {icon}
      </div>
    )
      : null;
  };

  const calculateTooltipPosition = () => {
    const selectedItemRect = ref?.current?.getBoundingClientRect();
    const isTargetList = selectedTab.viewType === VIEW_TYPES.TARGET;
    const expandedRightPosition = isTargetList ? MORE_POS.RIGHT_SIDEBAR * 3 : MORE_POS.RIGHT_SIDEBAR;
    const defaultRightPosition = isTargetList ? MORE_POS.RIGHT_TARGET : MORE_POS.RIGHT;
    const rightPosition = isHideMenu ? expandedRightPosition : defaultRightPosition;

    const tooltipLeft = selectedItemRect.right - rightPosition;
    const tooltipTop = selectedItemRect.top + MORE_POS.TOP;

    return { left: tooltipLeft, top: tooltipTop };
  };

  return (
    <div
      data-handler-id={handlerId}
      id={`${item?.id}`}
      ref={ref}
      style={{ opacity }}
      aria-hidden="true"
      className={`
        ${styles.tabItem}
        ${selectedTab?.id !== item?.id && styles.unActiveTab}
        ${hoveredTab === item?.id && selectedTab?.id !== item?.id && styles.hoverEffect}
        ${hoveredTab === item?.id && styles.additionalStyles}
        ${styles[prevItemStyles]}
      `}
      onClick={clickHandler}
      onMouseDown={() => setViewTool(false)}
      onMouseEnter={onMouseEnterTab}
      onMouseLeave={onMouseLeaveTab}
    >
      {selectedTab?.id === item?.id || hoveredTab === item?.id
        ? <DndControl className={styles.dndIcon} />
        : <div className={styles.dndIcon} />}

      <div className={styles.tabItemRightContent}>
        <div className={styles.tabItemWrapper}>
          <div
            className={styles.targetWrapper}
            onMouseEnter={() => setViewTool(true)}
            onMouseLeave={() => setViewTool(false)}
          >
            {getTabIcon()}
            <TextField
              styleType="tabItemTitle"
              text={item?.title?.length <= currentLimitation
                ? item?.title : `${item?.title?.slice(0, currentLimitation)}...`}
            />
          </div>
          {viewTool && item?.title?.length > currentLimitation && (
            <ClientOnlyPortal selector="#watchlist_tabs">
              <div
                style={{ left: ref?.current?.getBoundingClientRect().left - TAB_WIDTH }}
                className={styles.toolDropDown}
              >
                {item?.title}
              </div>
            </ClientOnlyPortal>
          )}
          <TextField
            styleType={isChanged ? 'tabItemStocksBlink' : 'tabItemStocks'}
            text={`${symbolCount} ${t('stocks')}`}
          />
        </div>
      </div>

      {isShowMoreVisible ? (
        <div
          aria-hidden="true"
          onClick={() => {
            setMoreVisible(true);
            setViewTool(false);
          }}
          className={styles.moreIconWrapper}
        >
          <MoreIcon className={styles.moreIcon} />
        </div>
      )
        : <div className={styles.moreIconWrapper}><div className={styles.moreIcon} /></div>}

      {selectedTab?.id === item?.id && moreVisible && (
        <ClientOnlyPortal selector="#watchlist_tabs">
          <div style={calculateTooltipPosition()} className={styles.moreDropDown}>
            <MorePopUp
              setMoreVisible={setMoreVisible}
              allowDelete={selectedTab?.viewType !== VIEW_TYPES.TARGET}
            />
          </div>
        </ClientOnlyPortal>

      )}
    </div>
  );
};

CommonTab.propTypes = {
  resetSearchHandler: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  prevItemStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  setPrevItem: PropTypes.func.isRequired,
  selectTabClickHandler: PropTypes.func.isRequired,
  changeOrderHandler: PropTypes.func.isRequired,
  item: PropTypes.shape({
    viewType: PropTypes.string,
    id: PropTypes.number,
    title: PropTypes.string,
    parentId: PropTypes.number,
    data: PropTypes.shape({
      subType: PropTypes.string,
      stocks: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      color1: PropTypes.string,
      color2: PropTypes.string,
      sortingData: PropTypes.arrayOf(PropTypes.shape({})),
      columnsData: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  index: PropTypes.number,
  endDragHandler: PropTypes.func.isRequired,
};

CommonTab.defaultProps = {
  prevItemStyles: '',
  index: -1,
};
export default CommonTab;
