import React, { ReactNode, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FoldersContext, { FoldersContextProps, JoinedState } from './DashboardItemsContext';
import useGetAllFolders from '../../tanStack/Folders/Queries/foldersQuery';
import useGetDBItems from '../../tanStack/Items/Queries/dbItemsQuery';
import { TDashboardItemsData } from '../../utils/Types';
import { ALERTS_TYPE, FAVOURITE_TYPE } from '../../constants/watchlist';
import {
  setAlertsData,
  setFavoritesData
} from '../../store/watchlist/actions';

const joinState = (folders: TDashboardItemsData[], items?: TDashboardItemsData[]): JoinedState => {
  const mergedArray = folders.map((item) => ({
    id: item.id, items: [], title: item?.name, type: item.type
  }));
  items?.forEach((item) => {
    const findIndex = mergedArray.findIndex((subItem) => subItem.id === item.folder?.id);
    if (findIndex !== -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mergedArray[findIndex].items.push(item);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mergedArray.push({ ...item, items: [] });
    }
  });

  return mergedArray;
};

const DashboardItemsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();

  const prepareWatchlistBatchHandler = (data: TDashboardItemsData[]) => {
    const favouriteItem = data.filter((item) => item?.data?.subType === FAVOURITE_TYPE);
    const alertsItem = data.filter((item) => item?.data?.subType === ALERTS_TYPE);

    dispatch(setAlertsData(...alertsItem));
    dispatch(setFavoritesData(...favouriteItem));
  };

  // SCREENS DONE
  // newScreenState.screens KEY
  // newScreenState.folders KEY
  const [screenerFoldersFetched, setScreenerFoldersFetched] = React.useState(false);
  const [screenerItemsFetched, setScreenerItemsFetched] = React.useState(false);
  const [screenerMergedItems, setScreenerMergedItems] = React.useState<JoinedState>([]);
  const [screeners, setScreeners] = React.useState<TDashboardItemsData[]>([]);
  const [screenersFolders, setScreenersFolders] = React.useState<TDashboardItemsData[]>([]);

  const screenerFolders = useGetAllFolders('screener');
  const screenerScreens = useGetDBItems('screener', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = screenerFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setScreenerFoldersFetched(true);
    }
  }, [
    screenerFolders.hasNextPage,
    screenerFolders.isFetchingNextPage,
    screenerFolders.fetchNextPage
  ]);
  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = screenerScreens;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setScreenerItemsFetched(true);
    }
  }, [
    screenerScreens.hasNextPage,
    screenerScreens.isFetchingNextPage,
    screenerScreens.fetchNextPage
  ]);

  useEffect(() => {
    if (screenerItemsFetched && screenerFoldersFetched) {
      const allScreenerFolders = screenerFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allScreenerItems = screenerScreens.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allScreenerFolders, allScreenerItems);

      setScreenersFolders(allScreenerFolders);
      setScreeners(allScreenerItems);
      setScreenerMergedItems(mergedArray);
    }
  }, [screenerFolders.data, screenerScreens.data]);

  // newScreenState.presets KEY
  // newScreenState.presetsFolders KEY
  const [presetsFoldersFetched, setPresetsFoldersFetched] = React.useState(false);
  const [presetsItemsFetched, setPresetsItemsFetched] = React.useState(false);
  const [presetsMergedItems, setPresetsMergedItems] = React.useState<JoinedState>([]);
  const [presets, setPresets] = React.useState<TDashboardItemsData[]>([]);
  const [presetsFolders, setPresetsFolders] = React.useState<TDashboardItemsData[]>([]);

  const presetsScreenerFolders = useGetAllFolders('presetsScreener');
  const presetsScreens = useGetDBItems('presets', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = presetsScreenerFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setPresetsFoldersFetched(true);
    }
  }, [
    presetsScreenerFolders.hasNextPage,
    presetsScreenerFolders.isFetchingNextPage,
    presetsScreenerFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = presetsScreens;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setPresetsItemsFetched(true);
    }
  }, [
    presetsScreens.hasNextPage,
    presetsScreens.isFetchingNextPage,
    presetsScreens.fetchNextPage
  ]);

  useEffect(() => {
    if (presetsItemsFetched && presetsFoldersFetched) {
      const allPresetScreenerFolders = presetsScreenerFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allPresetScreenerItems = presetsScreens.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allPresetScreenerFolders, allPresetScreenerItems);

      setPresetsFolders(allPresetScreenerFolders);
      setPresets(allPresetScreenerItems);
      setPresetsMergedItems(mergedArray);
    }
  }, [presetsScreenerFolders.data, presetsScreens.data]);
  // SCREENS

  // DATA_PANELS DONE
  // .newScreenState.dataPanels KEY
  // .newScreenState.dataPanelsFolders KEY
  const [dataPanelsFoldersFetched, setDataPanelsFoldersFetched] = React.useState(false);
  const [dataPanelsItemsFetched, setDataPanelsItemsFetched] = React.useState(false);
  const [dataPanelsMergedItems, setDataPanelsMergedItems] = React.useState<JoinedState>([]);
  const [dataPanelsList, setDataPanelsList] = React.useState<TDashboardItemsData[]>([]);
  const [dataPanelsFoldersList, setDataPanelsFoldersList] = React.useState<TDashboardItemsData[]>([]);

  const dataPanelsFolders = useGetAllFolders('dataPanel');
  const dataPanels = useGetDBItems('dataPanel', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dataPanelsFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDataPanelsFoldersFetched(true);
    }
  }, [
    dataPanelsFolders.hasNextPage,
    dataPanelsFolders.isFetchingNextPage,
    dataPanelsFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dataPanels;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDataPanelsItemsFetched(true);
    }
  }, [
    dataPanels.hasNextPage,
    dataPanels.isFetchingNextPage,
    dataPanels.fetchNextPage
  ]);

  useEffect(() => {
    if (dataPanelsItemsFetched && dataPanelsFoldersFetched) {
      const allDataPanelsFolders = dataPanelsFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allDataPanelsItems = dataPanels.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allDataPanelsFolders, allDataPanelsItems);

      setDataPanelsList(allDataPanelsItems);
      setDataPanelsFoldersList(allDataPanelsFolders);
      setDataPanelsMergedItems(mergedArray);
    }
  }, [dataPanelsFolders.data, dataPanels.data]);

  const [dataPanelsPresetsFoldersFetched, setDataPanelsPresetsFoldersFetched] = React.useState(false);
  const [dataPanelsPresetsItemsFetched, setDataPanelsPresetsItemsFetched] = React.useState(false);
  const [dataPanelsPresetsMergedItems, setDataPanelsPresetsMergedItems] = React.useState<JoinedState>([]);
  const [dataPanelsPresetsList, setDataPanelsPresetsList] = React.useState<TDashboardItemsData[]>([]);
  const [dataPanelsPresetsFoldersList, setDataPanelsFoldersPresetsList] = React.useState<TDashboardItemsData[]>([]);

  const dataPanelsPresetsFolders = useGetAllFolders('presetsDataPanel');
  const dataPanelsPresets = useGetDBItems('presetsDataPanel', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dataPanelsPresetsFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDataPanelsPresetsFoldersFetched(true);
    }
  }, [
    dataPanelsPresetsFolders.hasNextPage,
    dataPanelsPresetsFolders.isFetchingNextPage,
    dataPanelsPresetsFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dataPanelsPresets;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDataPanelsPresetsItemsFetched(true);
    }
  }, [
    dataPanelsPresets.hasNextPage,
    dataPanelsPresets.isFetchingNextPage,
    dataPanelsPresets.fetchNextPage
  ]);

  useEffect(() => {
    if (dataPanelsPresetsItemsFetched && dataPanelsPresetsFoldersFetched) {
      const allPresetDataPanelFolders = dataPanelsPresetsFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allPresetDataPanelItems = dataPanelsPresets.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allPresetDataPanelFolders, allPresetDataPanelItems);

      setDataPanelsPresetsList(allPresetDataPanelItems);
      setDataPanelsFoldersPresetsList(allPresetDataPanelFolders);
      setDataPanelsPresetsMergedItems(mergedArray);
    }
  }, [dataPanelsPresetsFolders.data, dataPanelsPresets.data]);
  // DATA_PANELS

  // COLUMNS DONE
  // .newScreenState.itemsColumnSets KEY
  // .newScreenState.columnSets KEY
  // .newScreenState.columnSetFolder KEY
  const [columnsFoldersFetched, setColumnsFoldersFetched] = React.useState(false);
  const [columnsItemsFetched, setColumnsItemsFetched] = React.useState(false);
  const [columnsMergedItems, setColumnsMergedItems] = React.useState<JoinedState>([]);
  const [columnsSets, setColumnsSets] = React.useState<TDashboardItemsData[]>([]);
  const [columnsSetsFolders, setColumnsSetsFolders] = React.useState<TDashboardItemsData[]>([]);

  const columnsFolders = useGetAllFolders('columns');
  const columns = useGetDBItems('columns', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = columnsFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setColumnsFoldersFetched(true);
    }
  }, [
    columnsFolders.hasNextPage,
    columnsFolders.isFetchingNextPage,
    columnsFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = columns;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setColumnsItemsFetched(true);
    }
  }, [
    columns.hasNextPage,
    columns.isFetchingNextPage,
    columns.fetchNextPage
  ]);

  useEffect(() => {
    if (columnsItemsFetched && columnsFoldersFetched) {
      const allColumnsSetFolders = columnsFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allColumnsSetItems = columns.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allColumnsSetFolders, allColumnsSetItems);

      setColumnsSetsFolders(allColumnsSetFolders);
      setColumnsSets(allColumnsSetItems);
      setColumnsMergedItems(mergedArray);
    }
  }, [columnsFolders.data, columns.data]);

  // .newScreenState.itemsColumnSetsPreset KEY
  // .newScreenState.columnSetsPreset KEY
  // .newScreenState.columnSetPresetFolder KEY
  const [columnsPresetsFoldersFetched, setColumnsPresetsFoldersFetched] = React.useState(false);
  const [columnsPresetsItemsFetched, setColumnsPresetsItemsFetched] = React.useState(false);
  const [columnsPresetsMergedItems, setColumnsPresetsMergedItems] = React.useState<JoinedState>([]);
  const [columnsPresetsList, setColumnsPresetsList] = React.useState<TDashboardItemsData[]>([]);
  const [columnsPresetsFoldersList, setColumnsPresetsFoldersList] = React.useState<TDashboardItemsData[]>([]);

  const columnsPresetsFolders = useGetAllFolders('columnsPreset');
  const columnsPresets = useGetDBItems('columnsPreset', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = columnsPresetsFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setColumnsPresetsFoldersFetched(true);
    }
  }, [
    columnsPresetsFolders.hasNextPage,
    columnsPresetsFolders.isFetchingNextPage,
    columnsPresetsFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = columnsPresets;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setColumnsPresetsItemsFetched(true);
    }
  }, [
    columnsPresets.hasNextPage,
    columnsPresets.isFetchingNextPage,
    columnsPresets.fetchNextPage
  ]);

  useEffect(() => {
    if (columnsPresetsItemsFetched && columnsPresetsFoldersFetched) {
      const allColumnsSetPresetsFolders = columnsPresetsFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allColumnsSetPresetsItems = columnsPresets.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allColumnsSetPresetsFolders, allColumnsSetPresetsItems);

      setColumnsPresetsFoldersList(allColumnsSetPresetsFolders);
      setColumnsPresetsList(allColumnsSetPresetsItems);
      setColumnsPresetsMergedItems(mergedArray);
    }
  }, [columnsPresetsFolders.data, columnsPresets.data]);
  // COLUMNS

  // WATCHLIST DONE
  // .watchlistState.itemsWatchlist KEY
  // .watchlistState.tabsList KEY
  // .watchlistState.folders KEY
  const [watchlistFoldersFetched, setWatchlistFoldersFetched] = React.useState(false);
  const [watchlistItemsFetched, setWatchlistItemsFetched] = React.useState(false);
  const [watchlistMergedItems, setWatchlistMergedItems] = React.useState<JoinedState>([]);
  const [tabsList, setTabsList] = React.useState<TDashboardItemsData[]>([]);
  const [watchListFolders, setWatchListFolders] = React.useState<TDashboardItemsData[]>([]);

  const watchlistFolders = useGetAllFolders('watchList');
  const watchlists = useGetDBItems('watchList', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = watchlistFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setWatchlistFoldersFetched(true);
    }
  }, [
    watchlistFolders.hasNextPage,
    watchlistFolders.isFetchingNextPage,
    watchlistFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = watchlists;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setWatchlistItemsFetched(true);
    }
  }, [
    watchlists.hasNextPage,
    watchlists.isFetchingNextPage,
    watchlists.fetchNextPage
  ]);

  useEffect(() => {
    if (watchlistItemsFetched && watchlistFoldersFetched) {
      const allWatchlistFolders = watchlistFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allWatchlistItems = watchlists.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allWatchlistFolders, allWatchlistItems);

      setWatchListFolders(allWatchlistFolders);
      setTabsList(allWatchlistItems.filter((item) => item?.data?.subType !== ALERTS_TYPE));
      setWatchlistMergedItems(mergedArray);

      prepareWatchlistBatchHandler(allWatchlistItems);
    }
  }, [watchlistFolders.data, watchlists.data]);

  // .watchlistState.presetsWatchlist KEY
  // .watchlistState.presets KEY
  // .watchlistState.presetFolders KEY
  const [watchlistPresetFoldersFetched, setWatchlistPresetFoldersFetched] = React.useState(false);
  const [watchlistPresetItemsFetched, setWatchlistPresetItemsFetched] = React.useState(false);
  const [watchlistPresetMergedItems, setWatchlistPresetMergedItems] = React.useState<JoinedState>([]);
  const [presetsWatchlist, setPresetsWatchlist] = React.useState<TDashboardItemsData[]>([]);
  const [presetsWatchlistFolders, setPresetsWatchlistFolders] = React.useState<TDashboardItemsData[]>([]);

  const watchlistPresetFolders = useGetAllFolders('presetsWatchlist');
  const watchlistPresets = useGetDBItems('watchlistPresets', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = watchlistPresetFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setWatchlistPresetFoldersFetched(true);
    }
  }, [
    watchlistPresetFolders.hasNextPage,
    watchlistPresetFolders.isFetchingNextPage,
    watchlistPresetFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = watchlistPresets;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setWatchlistPresetItemsFetched(true);
    }
  }, [
    watchlistPresets.hasNextPage,
    watchlistPresets.isFetchingNextPage,
    watchlistPresets.fetchNextPage
  ]);

  useEffect(() => {
    if (watchlistPresetItemsFetched && watchlistPresetFoldersFetched) {
      const allPresetWatchlistFolders = watchlistPresetFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allPresetWatchlistItems = watchlistPresets.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allPresetWatchlistFolders, allPresetWatchlistItems);

      setPresetsWatchlistFolders(allPresetWatchlistFolders);
      setPresetsWatchlist(allPresetWatchlistItems);
      setWatchlistPresetMergedItems(mergedArray);
    }
  }, [watchlistPresetFolders.data, watchlistPresets.data]);
  // WATCHLIST

  // DASHBOARDS DONE
  // .dashboardsState.itemsDashboard KEY
  // .dashboardsState.dashboard KEY
  // .dashboardsState.dashboardFolders KEY
  const [dashboardsFoldersFetched, setDashboardsFoldersFetched] = React.useState(false);
  const [dashboardsItemsFetched, setDashboardsItemsFetched] = React.useState(false);
  const [dashboardsMergedItems, setDashboardsMergedItems] = React.useState<JoinedState>([]);
  const [dashboardsItems, setDashboardsItems] = React.useState<TDashboardItemsData[]>([]);
  const [dashboardsFoldersItems, setDashboardsFoldersItems] = React.useState<TDashboardItemsData[]>([]);

  const dashboardsFolders = useGetAllFolders('userDashboard');
  const dashboards = useGetDBItems('userDashboard', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dashboardsFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDashboardsFoldersFetched(true);
    }
  }, [
    dashboardsFolders.hasNextPage,
    dashboardsFolders.isFetchingNextPage,
    dashboardsFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dashboards;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDashboardsItemsFetched(true);
    }
  }, [
    dashboards.hasNextPage,
    dashboards.isFetchingNextPage,
    dashboards.fetchNextPage
  ]);

  useEffect(() => {
    if (dashboardsItemsFetched && dashboardsFoldersFetched) {
      const allDashboardsFolders = dashboardsFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allDashboardsItems = dashboards.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allDashboardsFolders, allDashboardsItems);

      setDashboardsFoldersItems(allDashboardsFolders);
      setDashboardsItems(allDashboardsItems);
      setDashboardsMergedItems(mergedArray);
    }
  }, [dashboardsFolders.data, dashboards.data]);

  // .dashboardsState.itemsDashboardPresets KEY
  // .dashboardsState.dashboardPresets KEY

  const [dashboardsPresetFoldersFetched, setDashboardsPresetFoldersFetched] = React.useState(false);
  const [dashboardsPresetItemsFetched, setDashboardsPresetItemsFetched] = React.useState(false);
  const [dashboardsPresetMergedItems, setDashboardsPresetMergedItems] = React.useState<JoinedState>([]);
  const [dashboardsPresetItems, setDashboardsPresetItems] = React.useState<TDashboardItemsData[]>([]);
  const [dashboardsPresetFoldersItems, setDashboardsPresetFoldersItems] = React.useState<TDashboardItemsData[]>([]);

  const dashboardsPresetFolders = useGetAllFolders('userDashboardPresets');
  const dashboardsPresets = useGetDBItems('userDashboardPresets', true);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dashboardsPresetFolders;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }
    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDashboardsPresetFoldersFetched(true);
    }
  }, [
    dashboardsPresetFolders.hasNextPage,
    dashboardsPresetFolders.isFetchingNextPage,
    dashboardsPresetFolders.fetchNextPage
  ]);

  useEffect(() => {
    const {
      hasNextPage, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage
    } = dashboardsPresets;
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage().then();
    }

    if (!hasNextPage && !isFetchingNextPage && !isFetchingPreviousPage) {
      setDashboardsPresetItemsFetched(true);
    }
  }, [
    dashboardsPresets.hasNextPage,
    dashboardsPresets.isFetchingNextPage,
    dashboardsPresets.fetchNextPage
  ]);

  useEffect(() => {
    if (dashboardsPresetItemsFetched && dashboardsPresetFoldersFetched) {
      const allDashboardsPresetsFolders = dashboardsPresetFolders.data?.pages.flatMap((page) => page.folders) || [];
      const allDashboardsPresetsItems = dashboardsPresets.data?.pages.flatMap((page) => page.items) || [];
      const mergedArray = joinState(allDashboardsPresetsFolders, allDashboardsPresetsItems);

      setDashboardsPresetFoldersItems(allDashboardsPresetsFolders);
      setDashboardsPresetItems(allDashboardsPresetsItems);
      setDashboardsPresetMergedItems(mergedArray);
    }
  }, [dashboardsPresetFolders.data, dashboardsPresets.data]);

  // DASHBOARDS
  return (
    <FoldersContext.Provider value={{
      screeners,
      screenersFolders,
      screenerItems: screenerMergedItems,
      presets,
      presetsFolders,
      screenerPresetsItems: presetsMergedItems,
      dataPanelsItems: dataPanelsMergedItems,
      dataPanelsItemsPresets: dataPanelsPresetsMergedItems,
      storedDataPanels: dataPanelsList,
      storedDataPanelsFolders: dataPanelsFoldersList,
      storedDataPanelsPresetsList: dataPanelsPresetsList,
      storedDataPanelsFoldersPresetsList: dataPanelsPresetsFoldersList,
      itemsColumnSets: columnsMergedItems,
      itemsColumnSetsPresets: columnsPresetsMergedItems,
      itemsWatchlist: watchlistMergedItems,
      presetsWatchlist: watchlistPresetMergedItems,
      tabsList,
      listPresetsWatchlist: presetsWatchlist,
      watchListFolders,
      presetsWatchlistFolders,
      columns: columnsSets,
      columnsPresets: columnsPresetsList,
      columnsFolders: columnsSetsFolders,
      columnsPresetsFolders: columnsPresetsFoldersList,
      dashboardsItems: dashboardsMergedItems,
      dashboards: dashboardsItems,
      dashboardsFolders: dashboardsFoldersItems,
      itemsDashboardPresets: dashboardsPresetMergedItems,
      dashboardsPresets: dashboardsPresetItems,
      dashboardsPresetFoldersItems,
      setScreenerMergedItems,
      setPresetsMergedItems,
      setTabsList,
      isLoading: false,
      isError: false,
      error: null
    }}
    >
      {children}
    </FoldersContext.Provider>
  );
};

export default DashboardItemsProvider;

export const useDashboardItems = (): FoldersContextProps => useContext(FoldersContext);
