import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardComponents.module.scss';
import DefaultButton from '../../DefaultButton/DefaultButton';
import { ReactComponent as Chevron } from '../../../assets/images/icons/wlIcons/chevron.svg';
import { IDashboardDropdown } from '../types/DashboardComponentsInterfaces';
import TextWithTooltip from '../../DataPanel/components/TextWithTooltip';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';
import UseDashboardsDialogs from '../../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import DashboardItemsDialog from '../../DashboardItemsDialog/DashboardItemsDialog';
import { checkRoleUser } from '../../../utils/userHelper';
import { MODAL_TITLES, SUBSCRIPTION_TYPE } from '../../../constants/screener';
import DashboardsItemDropdown from '../../DashboardsItemsDropdown/DasboardsItemDropdown';
import { ReactComponent as DashboardIcon } from '../../../assets/images/icons/DashboardsBlue.svg';
import { TDashboardItemsData } from '../../../utils/Types';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const DashboardsTabDropdown = ({
  selectedDashboardId,
  setCurrentDashboard,
  setOpenModal
}: IDashboardDropdown): React.ReactElement => {
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  const {
    dashboardsItems,
    dashboards,
    itemsDashboardPresets,
    dashboardsPresets
  } = useDashboardItems();

  const { t } = useTranslation();
  const {
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();

  const [isShow, setIsShow] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState<TDashboardItemsData | undefined>(
    ([...dashboards, ...dashboardsPresets] as unknown as TDashboardItemsData[]).find(
      (dashboard: TDashboardItemsData) => dashboard.id === selectedDashboardId
    )
  );

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const handleToggleDropdown = () => {
    setIsShow(!isShow);
  };

  const handleShowCreateModal = (isPreset: boolean) => {
    if (isPreset) {
      setOpenModal(MODAL_TITLES.CREATE_DASHBOARD_PRESET);
    } else setOpenModal(MODAL_TITLES.CREATE_DASHBOARD);
    setIsShow(false);
  };

  const actionCreateNew = (selectedTab: number) => {
    handleShowCreateModal(selectedTab !== 0);
  };

  const actionEdit = (item: TDashboardItemsData) => {
    setCurrentDashboard(item.id);
  };

  const actionSetCurrentItem = (item: string | TDashboardItemsData) => {
    if (typeof item !== 'string') setCurrentDashboard(item.id);
    setIsShow(false);
  };

  const actionUpdateState = () => {
  };

  useEffect(() => {
    setSelectedDashboard(
      ([...dashboards, ...dashboardsPresets] as unknown as TDashboardItemsData[]).find(
        (dashboard: TDashboardItemsData) => dashboard.id === selectedDashboardId
      )
    );
  }, [selectedDashboardId, dashboards, dashboardsPresets]);
  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          currentItemHandler={() => {}}
          adminRole={adminRole}
        />
      )}
      <div
        className={styles.dashboardsTabDropdown}
      >
        <div className={styles.dashboardsTabDropdownWrap}>
          <DefaultButton
            buttonRef={dropdownButtonRef}
            handleClick={handleToggleDropdown}
            className={`${styles.dashboardsTabDropdownButton} ${isShow && styles.open}`}
          >
            {dashboards?.length && selectedDashboardId
              ? (
                <TextWithTooltip
                  className={styles.dashboardTabTitle}
                  placement="bottom-end"
                  isStatic={false}
                  title={selectedDashboard?.title || ''}
                />
              )
              : t('myDashboard')}
            <Chevron />
          </DefaultButton>
          <DashboardsItemDropdown
            itemType={DialogItemDataTypes.DASHBOARD}
            savedItems={dashboardsItems as TDashboardItemsData[]}
            presetItems={itemsDashboardPresets as TDashboardItemsData[]}
            isOpen={isShow}
            setIsOpen={setIsShow}
            iconAddMenu={<DashboardIcon />}
            parrentRef={dropdownButtonRef}
            labelPreset={t('presetsDashboards')}
            labelSaved={t('savedDashboards')}
            labelAdd={t('dashboard')}
            labelDescription={t('myDashboards')}
            actionCreateNewCallback={actionCreateNew}
            actionEditCallback={actionEdit}
            selectedItem={selectedDashboard as TDashboardItemsData}
            currentItemHandler={actionSetCurrentItem}
            updateAfterDragActions={actionUpdateState}
            topMargin={9}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardsTabDropdown;
