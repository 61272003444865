import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from '../sass/DataPanel.module.scss';
import { DATA_PANEL_ACTION_TYPES, PANEL_CONTENT_GRID_TYPES } from '../dataPanelConstants';
import DataPanelContentEditItems from './DataPanelContentEditItems';

const DataPanelEditContent = ({
  dataPanelType, dataPanels,
  onRemove, onRename,
  selected, onSelect,
  removeDataPoint, getTitle,
  updatePanelsData, updateCategoryData,
  checkUniqueCategory, saveDataPanels, widget
}) => {
  const timeout = null;
  const dataPanelInnerElement = document.getElementById(`dataPanelEditContent-${widget?.id}`);

  const [panelWidth, setPanelWidth] = useState(0);
  const [panelInnerChildren, setPanelInnerChildren] = useState([]);

  const [disableDrag, setDisableDrag] = useState(false);

  const getWidth = (dataPanelsNumberWidget) => {
    const minWidth = 220;
    const maxHeightOfCategory = 395;
    let maxHeightOfDataPanel = maxHeightOfCategory;

    let calculatedWidth = Math.floor(panelWidth / dataPanelsNumberWidget) - 10;
    let lessDataPanels = dataPanelsNumberWidget;

    while (calculatedWidth < minWidth) {
      lessDataPanels -= 1;
      calculatedWidth = panelWidth / lessDataPanels - 10;
      maxHeightOfDataPanel = (maxHeightOfCategory * dataPanelsNumberWidget) / (lessDataPanels + 1) + 10;
    }

    let columnsCountEdit = 1;
    let leftHeightOfColumn = maxHeightOfDataPanel;
    if (panelInnerChildren.length) {
      Array.from(panelInnerChildren).forEach((child, index) => {
        const childHeight = child.offsetHeight;

        // If the child fits in the current column
        if (leftHeightOfColumn - childHeight - 10 >= 0) {
          if (index === panelInnerChildren.length - 1) {
            columnsCountEdit += 1;
          }
          leftHeightOfColumn -= childHeight - 10;
        } else {
          // If the child doesn't fit, create a new column
          columnsCountEdit += 1;
          leftHeightOfColumn = maxHeightOfDataPanel - childHeight - 10;
        }
      });
      if (columnsCountEdit > lessDataPanels) {
        maxHeightOfDataPanel += panelInnerChildren[panelInnerChildren.length - 1]?.offsetHeight / 1.5;
      }
      if (columnsCountEdit < lessDataPanels) {
        maxHeightOfDataPanel -= panelInnerChildren[panelInnerChildren.length - 1]?.offsetHeight / 1.5;
      }
    }
    return [calculatedWidth, maxHeightOfDataPanel];
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const sourcePanel = source.droppableId;
    const destinationPanel = destination.droppableId;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const sourceItem = dataPanels[sourcePanel][sourceIndex];
    dataPanels[sourcePanel].splice(sourceIndex, 1);
    dataPanels[destinationPanel].splice(destinationIndex, 0, sourceItem);
    updatePanelsData(dataPanels);
    saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
  };

  const getClass = () => {
    if (!Object.keys(widget).length) {
      switch (dataPanelType) {
        case PANEL_CONTENT_GRID_TYPES.COMPACT:
          return styles.compact;
        default:
          return styles.default;
      }
    }
    return '';
  };

  useEffect(() => {
    updatePanelsData({ leftColumn: [...dataPanels.leftColumn, ...dataPanels.rightColumn], rightColumn: [] });
    clearTimeout(timeout);
    const dataPanelElement = document.getElementById(widget?.id);
    const dataPanelInnerElementInside = document.getElementById(`dataPanelEditContent-${widget?.id}`);
    setPanelInnerChildren(dataPanelInnerElementInside ? dataPanelInnerElementInside?.children : []);
    setPanelWidth(dataPanelElement ? dataPanelElement.getBoundingClientRect()?.width : 0);
    return () => clearTimeout(timeout);
  }, [document.getElementById(`dataPanelComponent-${widget?.id}`), dataPanelInnerElement?.offsetWidth,
    widget?.points]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div
        className={`${styles.dataPanelContent} ${getClass()} ${Object.keys(widget).length && styles.vertical}`}
      >
        {
          Object.keys(widget).length ? (
            <>
              <Droppable
                droppableId="leftColumn"
              >
                {(provided) => (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`${styles.dataPanelContentInner} ${widget?.id && styles.dataPanelContentInnerDashboard}
                    ${panelWidth < 450 && styles.dataPanelContentInnerDashboardBigger}`}
                    style={{ height: getWidth([...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length)[1] }}
                    id={`dataPanelEditContent-${widget?.id}`}
                  >
                    {[...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.map((item, index) => (
                      <Draggable
                        key={[item?.title, 'dragCart'].join('_')}
                        draggableId={item?.title}
                        index={index}
                        isDragDisabled={disableDrag}
                      >
                        {/* eslint-disable-next-line no-shadow */}
                        {(dragProvided) => {
                          const { style } = dragProvided.draggableProps;
                          return (

                            <div
                              ref={dragProvided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
                              {...dragProvided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
                              {...dragProvided.dragHandleProps}
                              style={{
                                ...style,
                                width: getWidth([...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length)[0]
                              }}
                            >
                              <DataPanelContentEditItems
                                data={item}
                                onRemove={onRemove}
                                onRename={onRename}
                                selected={selected === item?.title}
                                onSelect={onSelect}
                                removeDataPoint={removeDataPoint}
                                getTitle={getTitle}
                                updateCategoryData={updateCategoryData}
                                setDisableDrag={setDisableDrag}
                                checkUniqueCategory={checkUniqueCategory}
                                saveDataPanels={saveDataPanels}
                                widget={widget}
                              />
                              {dragProvided.placeholder}
                            </div>
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          ) : (
            <>
              <Droppable
                droppableId="leftColumn"
              >
                {(provided) => (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.dataPanelContentInner}
                  >
                    {dataPanels?.leftColumn.map((item, index) => (
                      <Draggable
                        key={[item?.title, 'dragCart'].join('_')}
                        draggableId={item?.title}
                        index={index}
                        isDragDisabled={disableDrag}
                      >
                        {/* eslint-disable-next-line no-shadow */}
                        {(provided) => (
                          <div
                            ref={provided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.dragHandleProps}
                          >
                            <DataPanelContentEditItems
                              data={item}
                              onRemove={onRemove}
                              onRename={onRename}
                              selected={selected === item?.title}
                              onSelect={onSelect}
                              removeDataPoint={removeDataPoint}
                              getTitle={getTitle}
                              updateCategoryData={updateCategoryData}
                              setDisableDrag={setDisableDrag}
                              checkUniqueCategory={checkUniqueCategory}
                              saveDataPanels={saveDataPanels}
                            />
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <Droppable
                droppableId="rightColumn"
              >
                {(provided) => (
                  <div
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={styles.dataPanelContentInner}
                  >
                    {dataPanels?.rightColumn.map((item, index) => (
                      <Draggable
                        key={[item?.title, 'dragCart'].join('_')}
                        draggableId={item?.title}
                        index={index}
                        isDragDisabled={disableDrag}
                      >
                        {/* eslint-disable-next-line no-shadow */}
                        {(provided) => (
                          <div
                            ref={provided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.dragHandleProps}
                          >
                            <DataPanelContentEditItems
                              data={item}
                              onRemove={onRemove}
                              onRename={onRename}
                              selected={selected === item?.title}
                              onSelect={onSelect}
                              removeDataPoint={removeDataPoint}
                              getTitle={getTitle}
                              updateCategoryData={updateCategoryData}
                              setDisableDrag={setDisableDrag}
                              checkUniqueCategory={checkUniqueCategory}
                              saveDataPanels={saveDataPanels}
                            />
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          )
        }

      </div>

    </DragDropContext>
  );
};
DataPanelEditContent.propTypes = {
  saveDataPanels: PropTypes.func.isRequired,
  dataPanelType: PropTypes.string,
  dataPanels: PropTypes.shape({
    leftColumn: PropTypes.arrayOf(PropTypes.shape({})),
    rightColumn: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  widget: PropTypes.shape({
    id: PropTypes.string,
    points: PropTypes.shape({})
  }),
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
  removeDataPoint: PropTypes.func.isRequired,
  getTitle: PropTypes.func.isRequired,
  updatePanelsData: PropTypes.func.isRequired,
  updateCategoryData: PropTypes.func.isRequired,
  checkUniqueCategory: PropTypes.func.isRequired,
};

DataPanelEditContent.defaultProps = {
  dataPanelType: 'default',
  selected: '',
  widget: {},
};

export default DataPanelEditContent;
