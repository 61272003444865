import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../utils/hooks/useTheme';
import AddSymbolsModal from './AddSymbolsModal';
import DeleteSymbolsModal from './DeleteSymbolsModal';
import styles from './sass/WatchListModals.module.scss';
import ConfirmSignOutModal from '../ConfirmSignOutModal/ConfirmSignOutModal';

const WatchListModals = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const modalType = useSelector((state) => state.watchlistState.modalType, shallowEqual);

  return (
    <>
      <div className={`${styles.watchlistModalsWrapper} ${!modalType && styles.watchlistModalsHide} ${theme}`}>
        {modalType === t('addSymbol') && <AddSymbolsModal />}
        {modalType === t('deleteSymbols') && <DeleteSymbolsModal />}
        {modalType === t('signOut') && <ConfirmSignOutModal />}
      </div>
    </>
  );
};

export default React.memo(WatchListModals);
