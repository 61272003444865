/* eslint-disable no-empty */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardsItemDropdown from '../../DashboardsItemsDropdown/DasboardsItemDropdown';
import { ReactComponent as Settings } from '../../../assets/images/icons/settingsBlue.svg';

import styles from '../sass/PickColumnWrapper.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/closeBlack.svg';
import { setVisibleColumnsScreenAction, columnsWereUpdateScreenerAction } from '../../../store/screener/actions';
import {
  MODAL_TITLES, DEFAULT_ITEM, TABLE_SCREEN_PATH
} from '../../../constants/screener';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../../store/auth/actions';
import { free } from '../../../constants/statsChart';
import DefaultButton from '../../DefaultButton/DefaultButton';
import TextWithImage from '../../TextWithImage/TextWithImage';
import { ReactComponent as selectArrow } from '../../../assets/images/icons/selectArrow.svg';
import { checkArray } from '../../../utils/helperColumnsWorker';
import useSaveColumns from '../hooks/useSaveColumns';
import { LENGTH_CHECK } from '../../../constants/numberConstants';
import TitleColumnBar from '../../TitleColumnBar/TitleColumnBar';
import ButtonListenerWrapper from '../../ButtonListenerWrapper/ButtonListenerWrapper';
import useApplyColumns from '../hooks/useApplyColumns';
import Button from '../../Button/Button';
import useWidgetColumnsModalWorker from '../../DasboardComponents/hook/useWidgetColumsModalWorker';
import { setSelectedTabColumnsId } from '../../../store/watchlist/actions';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const HeaderBlock = ({
  openModalHandler, isWidget,
  widgetId, widgetType
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownButtonRef = useRef();

  const profile = useSelector((state) => state.accountState.userProfile, shallowEqual);
  const columnsWereUpdate = useSelector((state) => state.screenerState.columnsWereUpdate, shallowEqual);
  const {
    itemsColumnSets, itemsColumnSetsPresets, columnsPresets, columns
  } = useDashboardItems();

  const columnSetId = useSelector((state) => state.newScreenState.columnSetId, shallowEqual);
  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);

  const [data, setData] = useState([]);
  const [activeColumnButton, setActiveColumnButton] = useState(false);

  const [largeTitleVisible, setLargeTitleVisible] = useState(false);

  const { stateColumnsSetName, saveColumnsToSpecificPlace } = useSaveColumns();
  const { applyColumnsHandler } = useApplyColumns();

  const { openColumnsModal } = useWidgetColumnsModalWorker();

  useEffect(() => {
    const check = checkArray(itemsColumnSets, data);
    if (!check) {
      setData(itemsColumnSets);
    }
  }, [itemsColumnSets]);

  const activeHandler = () => {
    setActiveColumnButton(!activeColumnButton);
  };

  const activeColumnSets = (item) => {
    const isWatchlist = pagePath !== TABLE_SCREEN_PATH.SCREENER;

    setActiveColumnButton(false);
    const idItem = item.id === columnSetId ? DEFAULT_ITEM : item.id;
    saveColumnsToSpecificPlace(idItem, undefined, widgetType);
    applyColumnsHandler(idItem);

    if (isWatchlist) {
      dispatch(setSelectedTabColumnsId(idItem));
    }
  };

  const actionUpdateState = () => {

  };

  const createColumnSet = (selectedTabId) => {
    if (selectedTabId) {
      openModalHandler(MODAL_TITLES.CREATE_COLUMNS_SET_PRESET);
    } else openModalHandler(MODAL_TITLES.CREATE_COLUMNS_SET);
    setActiveColumnButton(false);
  };

  useEffect(() => {
    if (isWidget) {
      applyColumnsHandler(columnSetId);
    }
  }, [columnSetId]);

  const closeBar = () => {
    if (columnsWereUpdate) {
      dispatch(columnsWereUpdateScreenerAction(false));
    }

    if (profile?.role !== free && columnsWereUpdate) {
      dispatch(errorMessageTitleAction({ messageTitle: t('success') }));
      dispatch(messageAction({ message: t('columnsWereSaved') }));
      dispatch(changeNotificationTypeAction({ type: 'success' }));
      dispatch(popUpAction(true));
    }
    if (isWidget) {
      openColumnsModal(widgetId);
    } else {
      dispatch(setVisibleColumnsScreenAction(false));
    }
  };

  return (
    <>
      <ButtonListenerWrapper
        handleCancel={closeBar}
        handleConfirm={() => {}}
      >
        <div className={styles.headerWrapper}>
          <TitleColumnBar title={t('columnSetting')} />
          <div className={styles.wrapper}>
            <DefaultButton
              handleClick={activeHandler}
              buttonClass="columnsDropButton"
              mouseEnterHandler={() => setLargeTitleVisible(true)}
              mouseLeaveHandler={() => setLargeTitleVisible(false)}
              buttonRef={dropdownButtonRef}
            >
              <TextWithImage
                ImageSrc={selectArrow}
                label={stateColumnsSetName}
                styleText="columnSet"
                active={activeColumnButton}
              />
            </DefaultButton>
            <DashboardsItemDropdown
              itemType={2}
              savedItems={itemsColumnSets}
              presetItems={itemsColumnSetsPresets}
              isOpen={activeColumnButton}
              setIsOpen={setActiveColumnButton}
              parrentRef={dropdownButtonRef}
              labelPreset={t('columnsPreset')}
              labelSaved={t('savedColumns')}
              labelAdd={t('column')}
              labelDescription={t('column')}
              iconAddMenu={<Settings />}
              actionCreateNewCallback={createColumnSet}
              selectedItem={[...columns, ...columnsPresets].find((column) => column.id === columnSetId)}
              currentItemHandler={activeColumnSets}
              updateAfterDragActions={actionUpdateState}
              topMargin={7}
            />
            <Button
              buttonClass={styles.closeButton}
              handleClick={closeBar}
            >
              <CloseIcon />
            </Button>
            {
        stateColumnsSetName?.length > LENGTH_CHECK
        && !activeColumnButton
        && largeTitleVisible
        && <div className={styles.largeTitleDrop}>{stateColumnsSetName}</div>
      }
          </div>
        </div>

      </ButtonListenerWrapper>
    </>
  );
};

HeaderBlock.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
  isWidget: PropTypes.bool,
  widgetId: PropTypes.string,
  widgetType: PropTypes.string,
};

HeaderBlock.defaultProps = {
  isWidget: false,
  widgetId: '',
  widgetType: '',
};

export default HeaderBlock;
