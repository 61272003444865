import MULTI_CHART from './types';

const INITIAL_STATE = {
  screenerId: 0,
  symbolsList: [],
  refreshFlag: false,
  isMountedChart: true,
  range: [0, 5],
  selectedSymbol: '',
  scrollToRow: undefined,
  additionalData: {
    earningsValue: {},
    dividendValue: {},
    earningTimeValue: {},
    industry: {},
    sector: {},
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case MULTI_CHART.SET_SYMBOL_LIST:
      return { ...state, symbolsList: payload };
    case MULTI_CHART.SET_CURRENT_RANGE:
      return { ...state, range: payload };
    case MULTI_CHART.SET_ADDITIONAL_DATA:
      return { ...state, additionalData: payload };
    case MULTI_CHART.SET_QUERY_ID_MULTICHART:
      return { ...state, screenerId: payload };
    case MULTI_CHART.SET_IS_MOUNTED_CHART:
      return { ...state, isMountedChart: payload };
    case MULTI_CHART.SET_SELECTED_SYMBOL_MULTICHART:
      return { ...state, selectedSymbol: payload };
    case MULTI_CHART.SET_SCROLL_ROW_MULTICHART:
      return { ...state, scrollToRow: payload };
    case MULTI_CHART.REFRESH_FLAG_PATCH:
      return { ...state, refreshFlag: !state.refreshFlag };
    case MULTI_CHART.CLEAR_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
