import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userPanel } from '../../../constants/account';
import { checkRoleUser } from '../../../utils/userHelper';
import styles from '../sass/DataPanel.module.scss';
import DataPanelHeaderActions from './DataPanelHeaderActions';
import DefaultButton from '../../DefaultButton/DefaultButton';
import DashboardsItemDropdown from '../../DashboardsItemsDropdown/DasboardsItemDropdown';
import { ReactComponent as DataPanelIcon } from '../../../assets/images/icons/screener/dataPanel.svg';
import { ReactComponent as IconStroke } from '../../../assets/images/icons/screener/color_stroke.svg';
import { ReactComponent as TwoColumns } from '../../../assets/images/icons/DataTwoColumns.svg';
import { ReactComponent as NormalIcon } from '../../../assets/images/icons/screener/big.svg';
import Button from '../../Button/Button';
import TextField from '../../TextField/TextField';
import { setSelectedDataPanel } from '../../../store/newScreen/actions';
import { MODAL_TITLES, SUBSCRIPTION_TYPE, TEMPLATE_TYPE } from '../../../constants/screener';
import { setModalType } from '../../../store/watchlist/actions';
import { SELECTED_DATA_PANEL_ID } from '../../../constants/storage';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { PANEL_CONTENT_GRID_TYPES } from '../dataPanelConstants';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const DataPanelHeader = ({
  dataPanelType, setDataPanelGridType,
  addPanelOpen, togglePanelAdd, restoreDefault,
  editMode, setEditMode
}) => {
  const { t } = useTranslation();
  const dropdownButtonRef = useRef();
  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();
  const selectedDataPanel = useSelector((state) => state.newScreenState.selectedDataPanel, shallowEqual);
  const { dataPanelsItems, dataPanelsItemsPresets, storedDataPanels } = useDashboardItems();

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const [activeDropDown, setActiveDropDown] = useState(false);
  const [isEditAllowed, setIsEditAllowed] = useState(
    selectedDataPanel && selectedDataPanel?.type === TEMPLATE_TYPE.DATA_PANEL_PRESET ? adminRole : true
  );

  const actionCreateNew = (selectedTab) => {
    if (selectedTab) {
      return dispatch(setModalType(MODAL_TITLES.CREATE_DATA_PANEL_PRESET));
    }
    return dispatch(setModalType(MODAL_TITLES.CREATE_DATA_PANEL));
  };

  const actionSetCurrentItem = (item) => {
    setActiveDropDown(false);
    if (item) {
      dispatch(setSelectedDataPanel(item));
      updateStoreHandler(SELECTED_DATA_PANEL_ID, item?.id);
    } else {
      const defaultDataPanel = storedDataPanels.find(
        (dataPanel) => dataPanel?.title === userPanel
      )
        || storedDataPanels.filter(
          (dataPanel) => !dataPanel?.isDefault
        )[storedDataPanels.length - 2] || storedDataPanels[0];
      dispatch(setSelectedDataPanel(defaultDataPanel));
      updateStoreHandler(SELECTED_DATA_PANEL_ID, defaultDataPanel?.id);
    }
  };

  const actionUpdateState = () => {

  };

  const getTitle = () => (selectedDataPanel ? selectedDataPanel.title : ('Deepvue'));

  const getClassName = () => (styles[activeDropDown ? 'activeStroke' : 'defaultStroke']);

  const actionEdit = (item) => {
    dispatch(setSelectedDataPanel(item));
    updateStoreHandler(SELECTED_DATA_PANEL_ID, item?.id);
  };

  const handleSetDataPanelGridType = () => {
    setDataPanelGridType(dataPanelType === PANEL_CONTENT_GRID_TYPES.DEFAULT
      ? PANEL_CONTENT_GRID_TYPES.COMPACT : PANEL_CONTENT_GRID_TYPES.DEFAULT);
  };

  useEffect(() => {
    setIsEditAllowed(
      selectedDataPanel && selectedDataPanel.type === TEMPLATE_TYPE.DATA_PANEL_PRESET ? adminRole : true
    );
  }, [selectedDataPanel?.type]);

  return (
    <>
      <div className={styles.dataPanelHeader}>
        {!editMode
          ? (
            <Button
              handleClick={() => setActiveDropDown(!activeDropDown)}
              buttonClass={styles.buttonCurrentScreenElement}
              refObject={dropdownButtonRef}
            >
              <TextField
                text={getTitle()}
                className={styles.buttonCurrentScreenElementText}
              />
              <IconStroke className={getClassName()} />
            </Button>
          )
          : (
            <DefaultButton
              handleClick={restoreDefault}
              className={styles.dataPanelHeaderLabelBtnRestore}
            >
              {t('restoreToDefault')}
            </DefaultButton>
          )}
        {!addPanelOpen
          ? (
            <DataPanelHeaderActions
              addPanelOpen={addPanelOpen}
              togglePanelAdd={togglePanelAdd}
              editMode={editMode}
              setEditMode={setEditMode}
              isEditAllowed={isEditAllowed}
            />
          )
          : (
            <div className={styles.dataPanelHeaderActions}>
              <DefaultButton
                handleClick={handleSetDataPanelGridType}
                className={`${styles.dataPanelHeaderActionsButton} ${styles.marginZero}`}
              >
                {dataPanelType === PANEL_CONTENT_GRID_TYPES.DEFAULT ? <NormalIcon /> : <TwoColumns />}
              </DefaultButton>
            </div>
          )}
      </div>
      <DashboardsItemDropdown
        itemType={3}
        savedItems={dataPanelsItems}
        presetItems={dataPanelsItemsPresets}
        isOpen={activeDropDown}
        setIsOpen={setActiveDropDown}
        parrentRef={dropdownButtonRef}
        labelPreset={t('presetsDataPanels')}
        labelSaved={t('savedDataPanels')}
        labelAdd={t('dataPanel')}
        labelDescription={t('dataPanels')}
        iconAddMenu={<DataPanelIcon className={styles.iconDataPanel} />}
        actionCreateNewCallback={actionCreateNew}
        actionEditCallback={actionEdit}
        selectedItem={selectedDataPanel}
        currentItemHandler={actionSetCurrentItem}
        updateAfterDragActions={actionUpdateState}
        topMargin={7}
      />
    </>
  );
};

DataPanelHeader.propTypes = {
  dataPanelType: PropTypes.string,
  setDataPanelGridType: PropTypes.func,
  addPanelOpen: PropTypes.bool,
  togglePanelAdd: PropTypes.func,
  restoreDefault: PropTypes.func,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
};

DataPanelHeader.defaultProps = {
  dataPanelType: 'default',
  setDataPanelGridType: () => undefined,
  addPanelOpen: false,
  togglePanelAdd: () => undefined,
  restoreDefault: () => undefined,
  editMode: false,
  setEditMode: () => undefined,
};

export default DataPanelHeader;
