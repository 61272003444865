import React, { useState, useCallback, useEffect } from 'react';

import {
  useSelector,
  shallowEqual,
  useDispatch
} from 'react-redux';
import Portal from '@mui/material/Portal';
import ScreenerTable from '../../Screener/ScreenerTable';
import styles from '../sass/DashboardComponents.module.scss';
import RootStateTypes from '../../../store/RootStateTypes';
import usePrepareLastSymbolState from '../../../utils/hooks/usePrepareLastSymbolState';
import { KEY_CODES, SUBSCRIPTION_TYPE } from '../../../constants/screener';
import { upFunctionWidget, dynamicPositionHandler, downFunctionWidget } from '../../../utils/helpers';
import { ShortSymbol, StoredSymbol } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';
import { IScreenWidgetTable } from '../types/DashboardComponentsInterfaces';
import UseWidgetTableWorker from '../hook/useWidgetTableWorker';
import Columns from '../../Columns/Columns';
import { WidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import {
  DeepListItemData,
  ScreenerRowData,
  SortDataType,
  TDashboardItemsData,
} from '../../../utils/Types';
import { orderedCache } from '../../../services/ChartPrefetchServices/OrderedSymbolCache';
import DashboardItemsDialog from '../../DashboardItemsDialog/DashboardItemsDialog';
import { DashboardItemsDialogActionTypes } from '../../DashboardItemsDialog/types/constants';
import { DialogItemEntityType } from '../../DashboardsItemsDropdown/types/constants';
import UseDashboardsDialogs from '../../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import { checkRoleUser } from '../../../utils/userHelper';
import { setModalType } from '../../../store/watchlist/actions';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const ScreenWidgetTable = ({
  widget, isDeepList,
  changeSymbolForGroup, openModalHandler,
  updateWidget, activeSymbolIndexValue,
  lastActiveGroup, setAllItems,
  selectedItems, handleItemsSelect,
  setCurrentCount,
  setActiveSymbolIndex, setSearch, clearSelections
}: IScreenWidgetTable): React.ReactElement => {
  const dispatch = useDispatch();
  const {
    tableData,
    columnsData,
    additionalData,
    tableIndexesData,
    volumeBarsData,
    setVisibleItemsTable,
    sortHandler,
    sortStatus,
    clearSortHandler,
    sortData,
    loaderStatus,
    setNewSortData,
    setLoader,
    totalCount,
    setOpenETF,
    sectorIndustryWidgetHandler,
    isHoldingView,
    setTopScreenerIndex,
  } = UseWidgetTableWorker(widget, activeSymbolIndexValue, lastActiveGroup, setSearch, clearSelections);
  const {
    actionOpenDialog,
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);
  const [activeSymbol, setActiveSymbol] = useState<number>(-1);
  const [scrollTop, setScrollTop] = useState<number>(1);
  const [activePosition, setActivePosition] = useState<number>(-1);
  const [rendered, setRendered] = useState<boolean>(false);
  const profile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);
  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const widgetsColumnVisible = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetsColumnVisible, shallowEqual
  );
  const { tabsList, listPresetsWatchlist } = useDashboardItems();

  const isEtfPage = useSelector((state: RootStateTypes) => state.screenerState.etfSymbolIndex, shallowEqual);

  const { currentSymbolHandler } = usePrepareLastSymbolState();

  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      switch (event.code) {
        case KEY_CODES.ARROW_UP:
          upFunctionWidget(
            activeSymbol,
            tableData,
            setActiveSymbol,
          );
          break;
        case KEY_CODES.ARROW_DOWN:
        case KEY_CODES.SPACE:
          downFunctionWidget(
            activeSymbol,
            tableData,
            setActiveSymbol,
            setScrollTop,
            scrollTop
          );
          break;
        default:
          break;
      }
    },
    [
      tableData,
      activeSymbol,
    ]
  );

  const changeForGroupe = (symbol: string) => {
    if (tableData) {
      orderedCache.setData(tableData);
    }

    changeSymbolForGroup(symbol, widget?.colorGroup as string, widget?.id);
  };

  const handleSetActiveOnWidget = (symbolIndex: number) => {
    const symbol = symbolsList.find((item: StoredSymbol) => item.index === symbolIndex)?.sym;
    if (widget?.colorGroup) {
      changeForGroupe(symbol as string);
    }
    setActiveSymbol(symbolIndex);
  };

  const cancelDialog = () => {
    dispatch(setModalType(''));
    actionCancelDialog();
  };

  const folderModalHandler = (data: TDashboardItemsData) => {
    actionOpenDialog(
      data ? DashboardItemsDialogActionTypes.EDIT : DashboardItemsDialogActionTypes.ADD,
      2,
      DialogItemEntityType.FOLDER,
      0,
      data
    );
  };

  useEffect(() => {
    const symbol = symbolsList.find((item: StoredSymbol) => item.index === activeSymbol);
    dynamicPositionHandler(
      { results: tableData, columns: columnsData },
      currentSymbolHandler,
      isDeepList,
      false,
      setActivePosition,
      symbol?.index
    );
  }, [tableData, sortData]);

  const handleSetAllItems = () => {
    const clonedData = [...tabsList, ...listPresetsWatchlist] as DeepListItemData[];
    const foundTab = clonedData.find((item) => item.id === widget?.deepListId);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    setAllItems((isDeepList && !foundTab?.parentId && !isEtfPage)// @ts-ignore
      ? foundTab?.data?.filters[0][0][2]
      : tableIndexesData);
  };

  useEffect(() => {
    if (symbolsList && tableData?.length && columnsData?.length) {
      const symbol = symbolsList.find((item: StoredSymbol) => item.index === activeSymbol)?.sym;
      const activeIndex = tableData?.findIndex((item: ScreenerRowData) => {
        const itemSymdata = item[0] as ShortSymbol;
        return itemSymdata?.sym === symbol;
      });
      if (activeSymbolIndexValue === undefined && activeIndex !== -1) {
        const newWidget = { ...widget };
        newWidget.symbol = symbol;
      }
      if (activeIndex !== undefined && activeIndex !== -1) {
        setActivePosition(activeIndex);
      }
      if (widget?.colorGroup) {
        changeForGroupe(symbol as string);
      }
    }
  }, [activeSymbol]);

  useEffect(() => {
    if (tableData?.length && columnsData?.length) {
      const clonedSortData = sortData?.map((item: SortDataType) => ({ ...item }));
      const newWidget = { ...widget };
      newWidget.sortData = [...clonedSortData as SortDataType[]];
      updateWidget(newWidget);
    }
  }, [sortData]);

  useEffect(() => {
    if (activeSymbolIndexValue !== undefined) {
      if (lastActiveGroup === widget?.colorGroup) {
        setActiveSymbol(activeSymbolIndexValue);
      }
    } else {
      const symbol = symbolsList?.find((item: StoredSymbol) => item.sym === widget?.symbol)?.index;
      setActiveSymbol(symbol || -1);
    }
  }, [activeSymbolIndexValue]);

  useEffect(() => {
    if (widget?.symbol && tableData?.length && !rendered) {
      const activeIndex = tableData?.findIndex((item: ScreenerRowData) => {
        const itemSymdata = item[0] as ShortSymbol;
        return itemSymdata?.sym === widget?.symbol;
      });
      const symbolIndex = symbolsList.find((item: StoredSymbol) => item.sym === widget?.symbol)?.index;
      if (activeIndex !== undefined && activeIndex !== -1) {
        setActiveSymbol(symbolIndex);
        setActivePosition(activeIndex);
      }
      setRendered(true);
    }
  }, [tableData?.length]);

  useEffect(() => {
    setCurrentCount(totalCount);
  }, [totalCount]);

  useEffect(() => {
    setActiveSymbolIndex(activePosition + 1);
  }, [activePosition]);

  return (
    <div className={styles.dashboardScreenWrapper}>
      <ScreenerTable
        sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
        data={tableData || []}
        columns={columnsData || []}
        setItems={handleItemsSelect}
        setLoader={setLoader}
        sortStatus={sortStatus}
        setAllItems={handleSetAllItems}
        sortHandler={sortHandler}
        widgetSymbol={activeSymbol}
        selectedItems={selectedItems as number[]}
        keyDownHandler={keyDownHandler}
        setIsVisibleFilters={() => undefined}
        loaderStatus={loaderStatus}
        profile={profile}
        clearSorting={clearSortHandler}
        handleChangeLayoutSize={() => undefined}
        hideChart
        handleOpenChart={() => undefined}
        setHideEvents={() => undefined}
        hideColumnsSettings={false}
        activeAlert={null}
        activePosition={activePosition}
        setActiveAlert={() => undefined}
        watchList={isDeepList}
        scrollTop={scrollTop}
        additionalDataWidget={additionalData}
        volumeBarsWidget={volumeBarsData}
        setVisibleItemsTable={setVisibleItemsTable}
        selectedItemFromWidget={widget?.screenerId || widget?.deepListId}
        handleSetActiveOnWidget={handleSetActiveOnWidget}
        sortData={sortData}
        widgetId={widget?.id}
        setOpenEtfWidget={setOpenETF}
        isHoldingViewFromWidget={isHoldingView}
        isSearch
        isWidget
        clearSelections={clearSelections}
        setTopIndexWidget={setTopScreenerIndex}
      />
      {widgetsColumnVisible[widget?.id] && (
        <Portal>
          {open && (
            <DashboardItemsDialog
              open={open}
              actionCancel={cancelDialog}
              dialogData={dialogData}
              currentItemHandler={() => {}}
              adminRole={adminRole}
            />
          )}
          <Columns
            sortStatus={sortStatus}
            clearSorting={clearSortHandler}
            currentModalRef={null}
            setNewSortData={setNewSortData}
            folderModalHandler={folderModalHandler}
            openModalHandler={openModalHandler}
            isWatchlist={widget?.type === WidgetTypes.DEEP_LIST}
            sortData={sortData}
            visibleFromWidget={widgetsColumnVisible[widget?.id]}
            widgetId={widget?.id}
            widgetType={widget?.type}
            isWidget
          />
        </Portal>
      )}
    </div>
  );
};

export default ScreenWidgetTable;
