import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import setFilesLoadedStore from '../../store/loadingProgress/actions';
import usePrepareColumnsData from '../../components/Columns/hooks/usePrepareColumnsData';

const useFilesLoaded = (webViewLoad = false) => {
  const dispatch = useDispatch();

  const [filesLoaded, setFilesLoaded] = useState(false);

  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);
  const groupsList = useSelector((state) => state.screenerState.groupsList, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const userSettingsLoaded = useSelector((state) => state.accountState?.userSettingsLoaded, shallowEqual);
  const baseUrl = useSelector((state) => state.screenerState.baseUrl, shallowEqual);
  const { preparedColumnsHandler } = usePrepareColumnsData();

  useEffect(() => {
    if (
      columnsList.length
      && groupsList.length
      && symbolsList.length
      && userSettingsLoaded
      && baseUrl
    ) {
      setFilesLoaded(true);
      dispatch(setFilesLoadedStore(true));
      if (!webViewLoad) {
        preparedColumnsHandler();
      }
    }
  }, [
    baseUrl,
    columnsList,
    groupsList,
    symbolsList,
    userSettingsLoaded
  ]);

  return { filesLoaded };
};

export default useFilesLoaded;
