import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ReactComponent as CopyIcon } from '../../../../assets/images/icons/copy.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/images/icons/screener/plus.svg';
import { ReactComponent as AlertIcon } from '../../../../assets/images/icons/notification_target.svg';
import { ReactComponent as MoveIcon } from '../../../../assets/images/icons/folder.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/trash_target.svg';
import TargetBottomMenuItem from './TargetBottomMenuItem';
import styles from './sass/TargeList.module.scss';
import ScreenerDropDown from '../../../ScreenerDropDown/ScreenerDropDown';
import {
  ALERTS_TYPE,
  FAVOURITE_TYPE,
  TARGET_TYPE,
  UNIVERSE_TYPE,
} from '../../../../constants/watchlist';
import { SCREENER_TARGET_MORE_HEIGHT, TABLE_SCREEN_PATH } from '../../../../constants/screener';
import useWatchList from '../../../../utils/hooks/useWatchList';
import useAlerts from '../../../ChartContainer/hooks/useAlerts';
import { copyHandler } from '../../../../utils/hooks/useExportFiles';
import RootStateTypes from '../../../../store/RootStateTypes';
import { setAlertActionFromApp } from '../../../ChartContainer/utils/alertHelper';
import { currentAlertData } from '../../../ChartContainer/constants/constants';
import ScreenerViewEnum from '../../../ScreenerMenu/enums';
import { DeepListItemData } from '../../../../utils/Types';
import { useDashboardItems } from '../../../../context/DasboardItemsContext/DashboardItemsProvider';

interface ITargetBottomMenu {
  currentSymbol: {
    sym: string
    icon: string
    sortIndex: number,
  },
  awayHandler: (e: Record<string, unknown>) => void,
  selectedSymbols: number[],
  watchList: boolean,
  menuOffset: number,
  widgetId?: string,
  selectedItemFromWidget?: number,
  showWeight?: boolean,
  dropdownPosition?: 'left' | 'right',
  selectedRowsFromWidget: number[]
}

const TargetBottomMenu = ({
  currentSymbol,
  awayHandler,
  selectedSymbols,
  menuOffset,
  widgetId,
  dropdownPosition,
  selectedItemFromWidget,
  watchList,
  showWeight,
  selectedRowsFromWidget
}: ITargetBottomMenu): JSX.Element => {
  const [isTransform, setIsTransform] = useState(menuOffset >= window.innerHeight - SCREENER_TARGET_MORE_HEIGHT);

  const { tabsList } = useDashboardItems();
  const watchlists = tabsList as DeepListItemData[];

  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const globalHoldingSearch = useSelector(
    (state: RootStateTypes) => state.dashboardsState.globalHoldingSearch, shallowEqual
  );
  const screenerView = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.screenerView, shallowEqual
  );
  const pagePath = useSelector((state: RootStateTypes) => state.screenerState.pagePath, shallowEqual);

  const isUniverse = selectedTab?.data.subType === UNIVERSE_TYPE;
  const isAlerts = selectedTab?.data.subType === ALERTS_TYPE;
  const isScreenerList = selectedTab?.parentId;

  const {
    alertAction,
    removeAlerts,
  } = useAlerts();
  const { deleteSymbolsFromWatchlist } = useWatchList();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenMove, setIsOpenMove] = useState(false);
  const hasTableSelection = selectedSymbols.length > 0;
  const manySelected = selectedSymbols.length > 1;

  const getSelections = () => {
    if (widgetId) {
      return selectedRowsFromWidget?.length > 1 ? selectedRowsFromWidget : [currentSymbol.sortIndex];
    }
    return manySelected ? selectedSymbols : [currentSymbol.sortIndex];
  };

  const selection = getSelections();

  const getTabList = () => watchlists?.filter(
    (item) => (!item?.parentId
      && item?.data?.subType !== UNIVERSE_TYPE
      && item?.data?.subType !== FAVOURITE_TYPE
      && item?.viewType !== TARGET_TYPE)
  );
  let to: ReturnType<typeof setTimeout>;

  const mouseEnterHandler = (setter: (val: boolean) => void) => {
    if (to) {
      clearTimeout(to);
    }
    setter(true);
  };

  const mouseLeaveHandler = (setter: (val: boolean) => void, closeMenu = false) => {
    to = (setTimeout(() => {
      setter(false);
      if (closeMenu) {
        awayHandler({});
      }
    }, 300));
  };

  const commonDeleteHandler = () => {
    if (selectedTab) {
      if (isAlerts) {
        removeAlerts(selection);
      } else {
        deleteSymbolsFromWatchlist(selection, selectedTab, false);
      }
    }
    awayHandler({});
  };

  const deleteHandler = () => {
    if (widgetId) {
      const currentTab = watchlists.find((tab: DeepListItemData) => tab.id === selectedItemFromWidget);
      deleteSymbolsFromWatchlist(selection, currentTab, false);
      awayHandler({});
    } else {
      commonDeleteHandler();
    }
  };

  const copyItemHandler = () => {
    copyHandler({
      result: [...selection.map((s) => [symbolsList[s]])]
    });
    awayHandler({});
  };

  const alertHandler = () => {
    setAlertActionFromApp();
    const timeoutSet = setTimeout(() => {
      alertAction(currentAlertData.data);
      awayHandler({});
      clearTimeout(timeoutSet);
    }, 1000);
  };

  const isShowDelete = () => {
    if (!watchList) return false;
    if (widgetId) {
      const currentTab = watchlists.find((tab: DeepListItemData) => tab.id === selectedItemFromWidget);
      const currentUniverse = currentTab?.data?.subType === UNIVERSE_TYPE;
      const currentIsScreenList = !!currentTab?.parentId;
      return !(currentUniverse || currentIsScreenList || showWeight);
    }
    return (pagePath !== TABLE_SCREEN_PATH.SCREENER) && !(isUniverse || isScreenerList || globalHoldingSearch);
  };

  useEffect(() => {
    if (isOpenAdd) {
      setIsOpenMove(false);
    }
  }, [isOpenAdd]);

  useEffect(() => {
    if (isOpenMove) {
      setIsOpenAdd(false);
    }
  }, [isOpenMove]);

  useEffect(() => {
    setIsTransform(menuOffset >= window.innerHeight - SCREENER_TARGET_MORE_HEIGHT);
  }, [menuOffset]);

  return (
    <div className={styles.bottomMenu}>
      <TargetBottomMenuItem
        key="add"
        caption="Add to"
        icon={PlusIcon}
        hasSubMenu
        iconColor="black"
        mouseEnterHandler={() => mouseEnterHandler(setIsOpenAdd)}
        mouseLeaveHandler={() => mouseLeaveHandler(setIsOpenAdd)}
        classNames={styles.plusWrapper}
      />
      {!globalHoldingSearch ? (
        <TargetBottomMenuItem
          key="move"
          caption="Move to"
          icon={MoveIcon}
          hasSubMenu
          iconColor="black"
          mouseEnterHandler={() => mouseEnterHandler(setIsOpenMove)}
          mouseLeaveHandler={() => mouseLeaveHandler(setIsOpenMove)}
        />
      ) : null}
      <TargetBottomMenuItem
        key="copy"
        caption="Copy"
        icon={CopyIcon}
        onClick={copyItemHandler}
      />
      {(!manySelected && screenerView === ScreenerViewEnum.SCREENER) && !widgetId ? (
        <TargetBottomMenuItem
          key="alert"
          caption="Add alert"
          icon={AlertIcon}
          onClick={alertHandler}
          classNames={styles.alertWrapper}
        />
      ) : null}
      {isShowDelete() ? (
        <TargetBottomMenuItem
          key="delete"
          caption="Delete"
          iconColor="red"
          icon={DeleteIcon}
          onClick={deleteHandler}
        />
      ) : null}
      {isOpenAdd && (
        <div
          className={`
            ${styles.dropDownWrapper} ${hasTableSelection ? styles.msAdjust : ''}
            ${isTransform ? styles.dropDownTransform : ''}
            ${dropdownPosition === 'left' ? styles.dropDownTransformLeft : ''}
          `}
          onMouseEnter={() => mouseEnterHandler(setIsOpenAdd)}
        >
          <ScreenerDropDown
            watchListDropDownData={getTabList()}
            setVisibleDrop={(closeMenu) => mouseLeaveHandler(setIsOpenAdd, closeMenu)}
            watchList
            multiSelect
            selectedSymbols={selection}
            widgetId={widgetId}
          />
        </div>
      )}
      {isOpenMove && (
        <div
          className={`
          ${styles.dropDownWrapper} ${styles.secondRow}
          ${hasTableSelection ? styles.msAdjust : ''} ${isTransform ? styles.dropDownTransformSecond : ''}
          ${dropdownPosition === 'left' ? styles.dropDownTransformLeft : ''}`}
          onMouseEnter={() => mouseEnterHandler(setIsOpenMove)}
        >
          <ScreenerDropDown
            watchListDropDownData={getTabList()}
            setVisibleDrop={() => mouseLeaveHandler(setIsOpenMove, true)}
            watchList
            selectedSymbols={selection}
            moveTo
            widgetId={widgetId}
          />
        </div>
      )}
    </div>
  );
};

export default TargetBottomMenu;
