import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from '../sass/DataPanel.module.scss';
import { PANEL_CONTENT_GRID_TYPES } from '../dataPanelConstants';
import DataPanelContentCartItem from './DataPanelContentCartItem';

const DataPanelContent = ({
  dataPanelType,
  dataPanels,
  dataPoints,
  screenerId,
  getPointData,
  symbolIndex,
  widget,
  changeSymbolForGroup,
  sectorIndustryWidgetHandler
}) => {
  const timeout = null;
  const dataPanelInnerElement = document.getElementById(`dataPanelContent-${widget?.id}`);

  const [panelWidth, setPanelWidth] = useState(1);
  const [historyPanelWidth, setHistoryPanelWidth] = useState(0);
  const [panelInnerChildren, setPanelInnerChildren] = useState([]);
  const [historyHeight, setHistoryHeight] = useState(0);
  const [historyWidth, setHistoryWidth] = useState(0);
  const [historyDataPanelId, setHistoryDataPanelId] = useState(0);

  const getWidth = (dataPanelsNumber) => {
    if (historyPanelWidth !== panelWidth || historyDataPanelId !== widget.dataPanelId) {
      const minWidth = 220;
      const maxHeightOfCategory = 395;
      let lessDataPanels = dataPanelsNumber;

      let categoryWidth = Math.floor(panelWidth / dataPanelsNumber) - 10;
      let heightOfDataPanel = dataPanelInnerElement?.offsetHeight;
      while (categoryWidth < minWidth) {
        lessDataPanels -= 1;
        categoryWidth = panelWidth / lessDataPanels - 5;
        heightOfDataPanel = (maxHeightOfCategory * dataPanelsNumber) / (lessDataPanels + 1) + 10;
      }

      const adjustMaxHeight = (increment) => {
        heightOfDataPanel += increment;
      };

      let columnsCount = 0;
      let loopIndex = 0;

      while (columnsCount !== lessDataPanels && heightOfDataPanel > 0 && heightOfDataPanel < 10000
      && loopIndex < 1000) {
        columnsCount = 1;
        loopIndex += 1;
        let leftHeightOfColumn = heightOfDataPanel - 8;

        let currentHeightOfDataPanel = heightOfDataPanel;
        let currentColumnsCount = columnsCount;

        Array.from(panelInnerChildren).forEach((child, index) => {
          const childHeight = child.offsetHeight;

          // If the child fits in the current column
          if (leftHeightOfColumn - childHeight - 10 >= 0) {
            if (index === panelInnerChildren.length - 1 && leftHeightOfColumn === currentHeightOfDataPanel) {
              currentColumnsCount += 1;
              currentHeightOfDataPanel -= leftHeightOfColumn - childHeight - 8;
            } else {
              leftHeightOfColumn -= childHeight + 10;
            }
          } else {
            // If the child doesn't fit, create a new column
            if (index === panelInnerChildren.length - 1 && currentColumnsCount + 2 === lessDataPanels) {
              currentHeightOfDataPanel += (leftHeightOfColumn - childHeight - 3);
            }
            currentColumnsCount += 1;
            leftHeightOfColumn = currentHeightOfDataPanel - childHeight - 10;
          }
        });
        heightOfDataPanel = currentHeightOfDataPanel;
        columnsCount = currentColumnsCount;

        // If columnsCount is greater than lessDataPanels, increase the maxHeight
        if (columnsCount > lessDataPanels) adjustMaxHeight(5);
        // If columnsCount is less than lessDataPanels, decrease the maxHeight
        else if (columnsCount < lessDataPanels) {
          adjustMaxHeight(-5);
        }
      }

      if (columnsCount === dataPanelsNumber) {
        heightOfDataPanel = 50;
      }

      setHistoryPanelWidth(panelWidth);
      setHistoryDataPanelId(widget.dataPanelId);
      setHistoryWidth(categoryWidth);
      setHistoryHeight(heightOfDataPanel);
      return [categoryWidth, heightOfDataPanel];
    } return [historyWidth, historyHeight];
  };

  const getClass = () => {
    switch (dataPanelType) {
      case PANEL_CONTENT_GRID_TYPES.COMPACT:
        return styles.compact;
      default:
        return styles.default;
    }
  };

  useEffect(() => {
    clearTimeout(timeout);
    const dataPanelElement = document.getElementById(`dataPanelComponent-${widget?.id}`);
    const dataPanelInnerElementInside = document.getElementById(`dataPanelContent-${widget?.id}`);
    setPanelInnerChildren(dataPanelInnerElementInside ? dataPanelInnerElementInside?.children : []);
    setPanelWidth(dataPanelElement ? dataPanelElement.getBoundingClientRect()?.width - 8 : 0);
    return () => clearTimeout(timeout);
  }, [document.getElementById(`dataPanelComponent-${widget?.id}`),
    dataPanelInnerElement?.offsetWidth, widget?.dataPanelId]);

  return (
    <>
      {
      widget?.id ? (
        <div
          className={`${styles.dataPanelContent} ${Object.keys(widget).length && styles.vertical}`}
          id={`insideScrollContainer-${widget?.id}`}
        >
          <div
            className={`${styles.dataPanelContentInner} ${widget?.id && styles.dataPanelContentInnerDashboard}
      ${![...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length && styles.dataPanelContentInnerDashboardEmpty}
      ${panelWidth < 450 && styles.dataPanelContentInnerDashboardBigger}`}
            style={{ height: getWidth([...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length)[1] }}
            id={`dataPanelContent-${widget?.id}`}
          >
            {[...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.map((item) => (
              <DataPanelContentCartItem
                key={[item?.title, 'showCart'].join('_')}
                dataPoints={dataPoints}
                data={item}
                screenerId={screenerId}
                getPointData={getPointData}
                symbolIndex={symbolIndex}
                widget={widget}
                changeSymbolForGroup={changeSymbolForGroup}
                width={getWidth([...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length)[0]}
                sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={`${styles.dataPanelContent} ${getClass()}`}>
          <div className={styles.dataPanelContentInner}>
            {dataPanels?.leftColumn.map((item) => (
              <DataPanelContentCartItem
                key={[item?.title, 'showCart'].join('_')}
                dataPoints={dataPoints}
                data={item}
                screenerId={screenerId}
                getPointData={getPointData}
                symbolIndex={symbolIndex}
                sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
              />
            ))}
          </div>
          <div className={styles.dataPanelContentInner}>
            {dataPanels?.rightColumn.map((item) => (
              <DataPanelContentCartItem
                key={[item?.title, 'showCart'].join('_')}
                dataPoints={dataPoints}
                data={item}
                screenerId={screenerId}
                getPointData={getPointData}
                symbolIndex={symbolIndex}
                sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
              />
            ))}
          </div>
        </div>
      )
      }
    </>

  );
};
DataPanelContent.propTypes = {
  dataPanelType: PropTypes.string,
  dataPanels: PropTypes.shape({
    leftColumn: PropTypes.arrayOf(PropTypes.shape({})),
    rightColumn: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  widget: PropTypes.shape({
    id: PropTypes.string,
    dataPanelId: PropTypes.number,
    points: PropTypes.shape({})
  }),
  dataPoints: PropTypes.shape({}).isRequired,
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  getPointData: PropTypes.func.isRequired,
  changeSymbolForGroup: PropTypes.func,
  symbolIndex: PropTypes.number.isRequired,
  sectorIndustryWidgetHandler: PropTypes.func,
};

DataPanelContent.defaultProps = {
  dataPanelType: 'default',
  screenerId: null,
  widget: {},
  changeSymbolForGroup: () => null,
  sectorIndustryWidgetHandler: () => null
};

export default DataPanelContent;
