import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Checkbox from '../../Checkbox/Checkbox';
import SearchField from '../../SearchField/SearchField';
import TextWithTooltip from './TextWithTooltip';
import { INITIAL_SCROLL } from '../dataPanelConstants';
import styles from '../sass/DataPanel.module.scss';
import EmptyResultsContainer from '../../EmptyResultsContainer/EmptyResultsContainer';

const DataPanelAddContent = ({
  pointList, addPoint, removePoint,
  selectedPoints, scrollHandler, searchHandler, search,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const checkSelected = (pointId) => {
    return selectedPoints?.includes(pointId);
  };

  const handleAddPoint = (e, pointId) => {
    e.stopPropagation();
    if (!checkSelected(pointId)) {
      return addPoint(pointId);
    }
    return removePoint(pointId);
  };

  const onSearchHandler = (event) => {
    searchHandler(event);
    ref.current.scrollTop = INITIAL_SCROLL;
  };

  return (
    <div className={styles.dataPanelAddWrapper}>
      <div className={styles.dataPanelAddSearch}>
        <SearchField
          styleType="clenUp"
          newScreen={false}
          handlerChange={onSearchHandler}
          placeholder={t('searchPlaceholder')}
          resetSearchHandler={() => searchHandler({ target: { value: '' } })}
          value={search}
          className={styles.dataPanelAddSearchField}
          autoFocus
        />
      </div>
      <div ref={ref} onScroll={scrollHandler} className={styles.dataPanelAddContent}>
        {pointList.map((point) => (
          <div
            aria-hidden
            key={[point.value, 'checkbox'].join('_')}
            className={`${styles.dataPanelAddContentItem} ${checkSelected(point.value) && styles.checked}`}
            onClick={(e) => {
              e.stopPropagation();
              handleAddPoint(e, point.value);
            }}
          >
            <TextWithTooltip title={point.label} />
            <Checkbox
              id={[point.value, 'checkbox'].join('_')}
              checked={checkSelected(point.value)}
            />
          </div>
        ))}
      </div>
      {pointList.length === 0 && search && (
        <EmptyResultsContainer
          noResultLabel={t('noResultsFound')}
          noResultTitle={
            t('noResultScreens',
              {
                search,
                type: t('emptySearch')
              })
          }
        />
      )}
    </div>
  );
};
DataPanelAddContent.propTypes = {
  pointList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addPoint: PropTypes.func.isRequired,
  selectedPoints: PropTypes.arrayOf(PropTypes.number).isRequired,
  scrollHandler: PropTypes.func.isRequired,
  removePoint: PropTypes.func.isRequired,
  searchHandler: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

export default DataPanelAddContent;
