import { useState, useEffect } from 'react';

import { TSortStatus, TDashboardItemsData } from '../../../utils/Types';
import { DasboardsItemsDropdownTypes, defaultSort } from '../types/constants';
import filters from '../../../constants/filters';

interface IUseSearchSortDashboardItems {
  (
    savedItems: TDashboardItemsData[],
    presetItems: TDashboardItemsData[],
    selectedItem: TDashboardItemsData | null,
    isOpen: boolean
  ) : {
    search: string;
    setSearch: (search: string) => void;
    sort: TSortStatus;
    setSort: (sort: TSortStatus) => void;
    sortedSavedItems: TDashboardItemsData[];
    sortedPresetItems: TDashboardItemsData[];
    selectedTab: DasboardsItemsDropdownTypes;
    setSelectedTab: (selectedTab: DasboardsItemsDropdownTypes) => void;
  }
}

const useSearchSortDashboardItems: IUseSearchSortDashboardItems = (savedItems, presetItems, selectedItem, isOpen) => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<TSortStatus>(defaultSort);
  const [sortedSavedItems, setSortedSavedItems] = useState<TDashboardItemsData[]>([]);
  const [sortedPresetItems, setSortedPresetItems] = useState<TDashboardItemsData[]>([]);
  const [selectedTab, setSelectedTab] = useState<DasboardsItemsDropdownTypes>(DasboardsItemsDropdownTypes.SAVED);

  const setFirstTab = (): void => {
    if (selectedItem) {
      const selectedItemTab = (selectedItem?.type === filters.preset || selectedItem?.type === filters.watchlistPresets
        || selectedItem?.type === filters.dataPanelPresets || selectedItem?.type === filters.userDashboardPresets
        || selectedItem?.type === filters.columnsPreset)
        ? DasboardsItemsDropdownTypes.PRESET
        : DasboardsItemsDropdownTypes.SAVED;
      setSelectedTab(selectedItemTab);
    }
  };

  const convertOrder = (items: TDashboardItemsData[]): TDashboardItemsData[] => {
    const folderItems: TDashboardItemsData[] = [];
    const singleItems: TDashboardItemsData[] = [];
    items.forEach((item) => {
      if (item?.type === 'folder') {
        folderItems.push(item);
      } else {
        singleItems.push(item);
      }
    });
    return [...folderItems, ...singleItems];
  };

  const searchItems = (item: TDashboardItemsData): boolean => {
    const lowerCaseSearch = search?.toLowerCase();
    if (!lowerCaseSearch) {
      return true;
    }
    const itemNamed = item.title;
    return (
      itemNamed?.toLowerCase().includes(lowerCaseSearch)
    );
  };

  const sortItems = (itemsToSort: TDashboardItemsData[]): TDashboardItemsData[] => {
    const flattenedArray: TDashboardItemsData[] = itemsToSort?.reduce(
      (array: TDashboardItemsData[], presetFolder: TDashboardItemsData) => {
        array.push(presetFolder);
        presetFolder.items?.forEach((presetItem) => {
          array.push(presetItem);
        });
        return array;
      }, []
    );
    const filteredFromSearch = search ? flattenedArray.filter(searchItems) : itemsToSort?.filter(searchItems);
    const { sortValue } = sort;
    if (sortValue === -1) {
      return filteredFromSearch;
    }
    const sorted = filteredFromSearch?.sort((prevItem: TDashboardItemsData, nextItem: TDashboardItemsData) => {
      const prevValue = prevItem?.title;
      const nextValue = nextItem?.title;
      if (sortValue === 1) {
        return prevValue?.localeCompare(
          nextValue,
          'en-US',
          {
            numeric: true,
            caseFirst: 'upper',
            sensitivity: 'base',
            ignorePunctuation: true
          }
        );
      }
      return nextValue?.localeCompare(
        prevValue,
        'en-US',
        {
          numeric: true,
          caseFirst: 'upper',
          sensitivity: 'base',
          ignorePunctuation: true
        }
      );
    });
    return convertOrder(sorted);
  };

  const prepareItems = (): void => {
    setSortedSavedItems(sortItems(savedItems));
    setSortedPresetItems(sortItems(presetItems));
  };

  useEffect(() => {
    prepareItems();
  }, [search, sort, savedItems, presetItems, selectedTab]);

  useEffect(() => {
    if (isOpen) setFirstTab();
  }, [isOpen]);

  useEffect(() => {
    setSort(defaultSort);
    setSearch('');
    prepareItems();
  }, [selectedTab]);

  useEffect(() => {
    setSearch('');
    prepareItems();
  }, []);

  return {
    search,
    setSearch,
    sort,
    setSort,
    sortedSavedItems,
    sortedPresetItems,
    selectedTab,
    setSelectedTab
  };
};

export default useSearchSortDashboardItems;
