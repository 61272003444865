const MULTI_CHART = {
  SET_SYMBOL_LIST: '@@SET_SYMBOL_LIST',
  SET_CURRENT_RANGE: '@@SET_CURRENT_RANGE',
  SET_ADDITIONAL_DATA: '@@SET_ADDITIONAL_DATA',
  SET_IS_MOUNTED_CHART: '@@SET_IS_MOUNTED_CHART',
  SET_QUERY_ID_MULTICHART: '@@SET_QUERY_ID_MULTICHART',
  SET_SELECTED_SYMBOL_MULTICHART: '@@SET_SELECTED_SYMBOL_MULTICHART',
  SET_SCROLL_ROW_MULTICHART: '@@SET_SCROLL_ROW_MULTICHART',
  CLEAR_STATE: '@@CLEAR_STATE',
  REFRESH_FLAG_PATCH: '@@REFRESH_FLAG_PATCH',
};

export default MULTI_CHART;
