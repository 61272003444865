import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { PANEL_CONTENT_GRID_TYPES, DATA_PANEL_ACTION_TYPES } from '../dataPanelConstants';
import { togglePanelDataAdd } from '../../../store/account/actions';
import useDataPanelsItems from './useDataPanelItems';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { DATA_PANEL_GRID_TYPE } from '../../../constants/tvWidgetOptions';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

const useDataPanel = () => {
  const { updateStoreHandler } = useLastStateUpdateStore();
  const { saveDataPanels } = useDataPanelsItems();
  const dispatch = useDispatch();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [addPanelOpen, setAddPanelOpen] = useState(false);
  const [dataPanelType, setDataPanelType] = useState(PANEL_CONTENT_GRID_TYPES.COMPACT);
  const [currentDataPanels, setCurrentDataPanels] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [historyEditMode, setHistoryEditMode] = useState(false);
  const [currentHistoryDataPanels, setCurrentHistoryDataPanels] = useState(null);
  const [notClean, setNotClean] = useState(false);

  const selectedDataPanel = useSelector((state) => state.newScreenState?.selectedDataPanel, shallowEqual);
  const { storedDataPanels } = useDashboardItems();

  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);
  const openPanelDataAdd = useSelector((state) => state.accountState?.openPanelDataAdd, shallowEqual);
  const openDataPanel = userSettings?.openDataPanel;
  const dataPanelGridType = userSettings?.dataPanelGridType;

  const setDataPanelGridType = (type) => {
    updateStoreHandler(DATA_PANEL_GRID_TYPE, type);
  };

  const togglePanelAdd = () => {
    setEditMode(!editMode);
    return dispatch(togglePanelDataAdd(!openPanelDataAdd));
  };

  const checkDefault = () => {
    const defaultDataPanel = storedDataPanels?.find((dataPanel) => dataPanel?.isDefault)
      || storedDataPanels?.find((dataPanel) => dataPanel?.title === 'Default');
    if (!selectedDataPanel?.data) return true;
    return JSON.stringify(selectedDataPanel?.data) === JSON.stringify(defaultDataPanel?.data);
  };

  const checkDiff = () => {
    if (notClean && !currentHistoryDataPanels) return false;
    return JSON.stringify(selectedDataPanel?.data) !== JSON.stringify(currentHistoryDataPanels);
  };

  const filterDataPanel = (dataPanelToFilter) => ({
    ...dataPanelToFilter,
    data: {
      ...dataPanelToFilter.data,
      leftColumn: dataPanelToFilter.data?.leftColumn.filter((data) => data),
      rightColumn: dataPanelToFilter.data?.rightColumn.filter((data) => data)
    }
  });

  useEffect(() => {
    setIsPanelOpen(openDataPanel);
  }, [openDataPanel]);

  useEffect(() => {
    setDataPanelType(dataPanelGridType);
  }, [dataPanelGridType]);

  useEffect(() => {
    if (!openPanelDataAdd && addPanelOpen && !checkDefault() && checkDiff()) {
      saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
      setCurrentHistoryDataPanels({ ...selectedDataPanel?.data });
      setNotClean(false);
    }
    setAddPanelOpen(openPanelDataAdd);
  }, [openPanelDataAdd]);

  useEffect(() => {
    if (selectedDataPanel) {
      setNotClean(!currentDataPanels);
      setCurrentDataPanels(filterDataPanel(selectedDataPanel)?.data);
    }
  }, [selectedDataPanel]);

  useEffect(() => {
    if (!editMode && historyEditMode && !checkDefault() && checkDiff()) {
      saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
      setCurrentHistoryDataPanels({ ...selectedDataPanel?.data });
      setNotClean(false);
    }
    setHistoryEditMode(editMode);
  }, [editMode]);

  return {
    isPanelOpen,
    dataPanelType,
    setDataPanelGridType,
    togglePanelAdd,
    addPanelOpen,
    checkDefault,
    currentDataPanels,
    editMode,
    setEditMode,
  };
};

export default useDataPanel;
