import {
  useEffect,
  useRef,
  ReactElement,
  useState,
  useContext
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { widget as Widget } from '../../charting_library';
import TradingViewAdapter from '../../services/chartingHistoricalClient';
import RootStateTypes from '../../store/RootStateTypes';
import { handleDebounce } from '../../utils/helpers';
import chartSearch from '../../utils/chartSearch';
import widgetOptions from '../../utils/widgetOptions';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { WS_CHANNELS, WS_EVENTS } from '../../constants/socketConnection';
import { setToLocalStorage } from '../../utils/storageWorks';
import { WS_CHART_ERROR } from '../../constants/storage';
import usePrepareLastSymbolState from '../../utils/hooks/usePrepareLastSymbolState';
import {
  DEFINE_TIMESCALE_MARK,
  LAST_RESOLUTION,
  LAYOUT_CHANGED,
  LOAD_LAYOUT,
  TV_ACTIONS,
} from '../../constants/tvWidgetOptions';
import { useTheme } from '../../utils/hooks/useTheme';
import { CurrentWidget } from '../ChartContainer/types/Types';
import { IExtendCurrentWidget } from '../ChartContainer/types/Interfaces';
import useAdditionalDataForTv from '../ChartContainer/hooks/useAdditionalDataForTv';
import useChartState from '../../utils/hooks/useChartState';
import {
  alertSubscribe,
  applyOverridesHandler,
  autoSaveSubscribe,
  onChangeTradingViewTheme,
  timeScaleApplier,
  timeScaleListener,
  unbindAll
} from '../ChartContainer/utils/subscribeUtils';
import {
  changeActiveSymbol,
  changeStatsSourceHandler,
  convertResolutionTimeframe
} from '../ChartContainer/utils/tradingViewHelper';
import { buttonGeneratorWidget } from '../ChartContainer/utils/chartingHelper';
import useLastSymbolState from '../../utils/hooks/useLastSymbolState';
import { setTvIsReady } from '../../store/screener/actions';
import SocketContext from '../../context/SocketContext';
import {
  alertsHandler,
  crossLayoutPositionHandler,
  delay,
  fillAlertData,
  setPointsForClick,
  shapeTypeHandler,
  symbolFromActiveChart,
  symbolFromLayoutHandler,
  webViewPriceLineGenerator,
} from '../ChartContainer/utils/alertHelper';
import { currentAlertData, sharedChartData } from '../ChartContainer/constants/constants';
import useAlerts from '../ChartContainer/hooks/useAlerts';
import { TV_EVENTS, TWO_HUNDRED } from '../../constants/screener';
import { COMMON_LINE } from '../../constants/alerts';
import { orderedCache } from '../../services/ChartPrefetchServices/OrderedSymbolCache';
import { pingCallback, rtDataListenerFromSocket, saveChartIdToSubscription } from '../../services/socketTvSubscriber';
import { resetChartData } from '../../services/ResetChartDataProcessor';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../store/auth/actions';
import useAccount from '../../utils/hooks/useAccount';

const WebViewWidget = (): ReactElement => {
  const dispatch = useDispatch();

  const [symbol, setSymbol] = useState<string>('A');
  const [tradingViewWidget, setTradingViewWidget] = useState<CurrentWidget | null>(null);
  const history = useHistory();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    clearLastPrice,
    alertAction,
    getAlertsList,
    updateAlertPoints,
    getAlertsForSymbolIndex,
  } = useAlerts();

  const RefExtended = useRef<string | null>(null);

  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const userProfile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);
  const holidaysList = useSelector((state: RootStateTypes) => state.watchlistState.holidaysList, shallowEqual);
  const userFavorite = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings?.chartFavorites, shallowEqual
  );
  const alertListFromStore = useSelector((state: RootStateTypes) => state?.alertsState?.alertsList, shallowEqual);
  const lastDeepListSymbol = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual
  );
  const alertSettingsItemData = useSelector(
    (state: RootStateTypes) => state?.alertsState?.alertSettingsItemData, shallowEqual
  );
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const searchValue = useSelector(
    (state: RootStateTypes) => state.screenerState.searchValue, shallowEqual
  );
  const tvLayoutsList = useSelector((state: RootStateTypes) => state.screenerState.tvLayoutsList, shallowEqual);
  const defaultLayoutId = useSelector((state: RootStateTypes) => state.screenerState.defaultLayoutId, shallowEqual);

  const [version, setVersion] = useState<number>(1);
  const { getTickVersion } = useAccount();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  useEffect(() => { // @ts-ignore
    getTickVersion(setVersion);
  }, []);

  const { Socket, Authorized, Connection } = useContext(SocketContext);
  const { currentSymbolHandler } = usePrepareLastSymbolState();
  const { contentHandler } = useAdditionalDataForTv();
  const { saveFavoriteState } = useChartState();
  const { updateLastSymbolState } = useLastSymbolState();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const changeSymbolHandler = () => {
    if (currentSymbolHandler(true).name !== symbol && Authorized) {
      setSymbol(currentSymbolHandler(true).name);
    }
  };

  const successHandler = (title: string) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message: t('layoutRemoved') }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const setPointsValue = async (id: string, cb: string, currentWidget: CurrentWidget): Promise<void> => {
    await delay(200);
    const activeChartSymbol = symbolFromActiveChart(
      currentWidget.activeChart().symbol(), symbolsList
    );

    if (cb === TV_EVENTS.REMOVE || !id) {
      currentAlertData.data = null;
      return;
    }

    if (cb === TV_EVENTS.PROPERTIES_CHANGED) {
      const { alreadyAlert, shape } = shapeTypeHandler(sharedChartData.alertList, currentWidget, id);
      const properties = shape.getProperties();

      if (properties.linecolor === COMMON_LINE && !alreadyAlert) {
        fillAlertData(id, shape.getPoints(), properties, activeChartSymbol);
      }
    }
    if (cb === TV_EVENTS.POINTS_CHANGED) {
      const { alreadyAlert, shapeType, shape } = shapeTypeHandler(sharedChartData.alertList, currentWidget, id);

      if (!alreadyAlert && shapeType) {
        fillAlertData(id, shape.getPoints(), shape.getProperties(), activeChartSymbol);
      }

      if (shapeType && alreadyAlert) {
        setTimeout(() => {
          const movedPoints = [...shape.getPoints()];
          updateAlertPoints(alreadyAlert, movedPoints);
        }, TWO_HUNDRED);
      }
    }
  };

  const alertHandler = async (alertData: { sym: string, priceTime1: string }) => {
    await webViewPriceLineGenerator(
      sharedChartData.currentWidgetRef,
      { ...alertData, ...sharedChartData.currentSymbolData },
    );
    alertAction(currentAlertData.data);
  };

  useEffect(() => {
    if (tradingViewWidget) {
      window.addEventListener('message', (data) => {
        if (typeof data.data === 'object' && 'currentSymbol' in data.data) {
          updateLastSymbolState(
            [{ sym: data.data.currentSymbol.sym, sortIndex: data.data.currentSymbol.sortIndex }],
            false,
            true,
            false,
            1,
          );
          sharedChartData.currentSymbolData = {
            name: data.data.currentSymbol.sym, index: data.data.currentSymbol.sortIndex
          };
          sharedChartData.currentHistoryCacheKey = `${data.data.currentSymbol.sym}_${userLastState?.lastResolution}`;
        }
        if (typeof data.data === 'object' && 'theme' in data.data) {
          onChangeTradingViewTheme(
            tradingViewWidget,
            data.data.theme,
            userLastState?.chartproperties,
            true,
            userLastState?.activeChartLayoutIdMobile,
            tvLayoutsList,
            userLastState?.layoutFlag,
          );
        }
        if (typeof data.data === 'object' && 'triggerAlertModal' in data.data) {
          alertHandler(JSON.parse(data.data.triggerAlertModal));
        }
        if (typeof data.data === 'object' && 'prefetchChartData' in data.data) {
          orderedCache.setData(JSON.parse(data.data.prefetchChartData));
        }
        if (typeof data.data === 'object' && 'handleGetAlertsList' in data.data) {
          getAlertsList();
        }
        if (typeof data.data === 'object' && 'chartMode' in data.data) {
          const divWrapper = document.getElementById('tv_chart_container');
          const iframe = divWrapper?.querySelector('iframe');
          if (iframe) {
            const iframeDocument = iframe.contentDocument || iframe?.contentWindow?.document;
            const toolbarContainer = iframeDocument?.getElementsByClassName('tv-floating-toolbar')[0] as Element;
            if (data.data.chartMode === 'view') {
              toolbarContainer.className = 'tv-floating-toolbar ui-draggablei-closed i-closed';
            } else {
              toolbarContainer.className = 'tv-floating-toolbar ui-draggablei-closed';
            }
          }
        }
        if (typeof data.data === 'object' && 'actionById' in data.data) {
          const frame = document?.getElementsByTagName('iframe')[0];
          if (!TV_ACTIONS.includes(data.data.actionById) && data.data.actionById !== LOAD_LAYOUT) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const element = frame.contentDocument.getElementById(
              data.data.actionById
            ).children as HTMLCollectionOf<HTMLElement>;
            if (data.data.actionById === 'header-toolbar-study-templates') {
              element[0].click();
            } else {
              element.item(element.length - 1)?.click();
            }
          } else if (data.data.actionById === LOAD_LAYOUT) {
            tradingViewWidget.showLoadChartDialog();
          } else {
            tradingViewWidget.activeChart().executeActionById(data.data.actionById);
          }
        }
      });
    }
  }, [tradingViewWidget]);

  useEffect(() => {
    changeSymbolHandler();
  }, [lastDeepListSymbol, searchValue, Authorized]);

  useEffect(() => {
    if (
      symbol
      && !tradingViewWidget
      && Object.keys(userProfile)?.length
      && Socket
      && Authorized
      && tvLayoutsList.length
    ) {
      const configuration = widgetOptions({
        symbol,
        interval: userLastState?.activeChartLayoutIdMobile ? null : (userLastState?.lastResolution || '1D'),
        errorHandler: () => {},
        alertAction: () => {},
        setSymbol: () => {},
        setLayoutId: () => {},
        userProfile,
        theme: theme.substring(0, theme.indexOf('-')),
        userFavorite,
        favoriteCallback: saveFavoriteState,
        updateStoreHandler,
        userLastState,
        tvLayoutsList,
        successHandler,
        defaultLayoutId,
        datafeed: new TradingViewAdapter({
          symbol,
          interval: userLastState?.lastResolution || '1D',
          symbolsList,
          searchSymbols: handleDebounce(chartSearch, 300, symbolsList),
          socket: Connection,
          chanelId: userProfile.id,
          currentPath: history.location.pathname,
          holidaysList,
          extendedFromUser: RefExtended,
          tickVersion: version || 1,
        }),
        isMobileWidget: true,
      });
      const tvWidget: IExtendCurrentWidget = new Widget(configuration);
      if (tvWidget) {
        tvWidget.datafeed = configuration.datafeed;
        tvWidget.onChartReady(() => {
          tvWidget?.startFullscreen();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window?.ReactNativeWebView?.postMessage('onChartReady');
          tvWidget?.watermark().setContentProvider((data) => contentHandler(data));
          if (userLastState[DEFINE_TIMESCALE_MARK]) {
            const definedMarks = JSON.parse(userLastState[DEFINE_TIMESCALE_MARK]);
            if (!definedMarks) {
              tvWidget?.activeChart()?.executeActionById('hideAllMarks');
            }
          }
          timeScaleApplier(tvWidget, updateStoreHandler);
          timeScaleListener(tvWidget, updateStoreHandler);
          autoSaveSubscribe({
            tradingViewWidget: tvWidget,
          });
          buttonGeneratorWidget(tvWidget);
          tvWidget?.subscribe('undo_redo_state_changed', (value) => {
            // eslint-disable-next-line
            // @ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify(value)
            );
          });
          alertSubscribe(
            tvWidget,
            setPointsValue,
            setPointsForClick
          );
          tvWidget?.subscribe(LAYOUT_CHANGED, () => tvWidget.closePopupsAndDialogs());
          tvWidget?.activeChart().onIntervalChanged().subscribe(null, (intervalChanged) => {
            // eslint-disable-next-line
            // @ts-ignore
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ resolution: convertResolutionTimeframe(intervalChanged) })
            );
            changeStatsSourceHandler(dispatch, intervalChanged, updateStoreHandler);
            updateStoreHandler(LAST_RESOLUTION, intervalChanged);
          });
          tvWidget?.activeChart().dataReady(() => dispatch(setTvIsReady(false)));
        });
      }

      sharedChartData.currentWidgetRef = tvWidget;
      setTradingViewWidget(tvWidget);
      resetChartData.setChart(tvWidget);
    }
  }, [symbol, userProfile, Socket, Authorized, tvLayoutsList]);

  useEffect(() => {
    const symbolFromLayout = symbolFromLayoutHandler(symbolsList, symbol);
    sharedChartData.currentSymbolData = symbolFromLayout || currentSymbolHandler(true);
  }, [tradingViewWidget, symbol, lastDeepListSymbol]);

  useEffect(() => {
    currentAlertData.data = null;
    changeActiveSymbol({
      tradingViewWidget,
      symbol,
      contentHandler,
    });
  }, [symbol, tradingViewWidget]);

  useEffect(() => {
    if (userLastState?.chartproperties && tradingViewWidget && theme) {
      applyOverridesHandler(
        userLastState?.chartproperties,
        tradingViewWidget,
        theme,
        userLastState?.layoutFlag
      );
    }
  }, [userLastState?.chartproperties, tradingViewWidget]);

  useEffect(() => {
    if (symbol) {
      tradingViewWidget?.onChartReady(() => {
        tradingViewWidget?.activeChart().dataReady(() => {
          const activeChartSymbol = symbolFromActiveChart(
            tradingViewWidget?.activeChart().symbol(), symbolsList
          );

          getAlertsForSymbolIndex(activeChartSymbol.name).then((alerts) => {
            alertsHandler(tradingViewWidget, alerts);
          });
        });
      });
    }
  }, [alertListFromStore.length, symbol]);

  useEffect(() => {
    const symbolFromLayout = symbolFromLayoutHandler(symbolsList, symbol);
    sharedChartData.currentSymbolData = symbolFromLayout || currentSymbolHandler(true);

    tradingViewWidget?.onChartReady(() => {
      tradingViewWidget?.activeChart().dataReady(() => {
        const activeChartSymbol = symbolFromActiveChart(
          tradingViewWidget?.activeChart().symbol(), symbolsList
        );

        getAlertsForSymbolIndex(activeChartSymbol.name).then((alerts) => {
          crossLayoutPositionHandler(tradingViewWidget, alerts);
        });
      });
    });
  }, [alertSettingsItemData, alertListFromStore]);

  useEffect(() => {
    sharedChartData.alertList = alertListFromStore;
  }, [alertListFromStore]);

  useEffect(() => {
    sharedChartData.currentHistoryCacheKey = `${symbol}_${userLastState?.lastResolution}`;
    clearLastPrice();
  }, [symbol, userLastState?.lastResolution]);

  useEffect(() => {
    RefExtended.current = userLastState?.currentSessionFromStoreMobile;
  }, [userLastState?.currentSessionFromStoreMobile]);

  useEffect(() => {
    if (Authorized) {
      Connection?.messageEmitter.on(WS_CHANNELS.PING_PERIODICAL_DATA, (data) => {
        pingCallback(data, Connection);
      });

      Connection?.messageEmitter?.on(WS_CHANNELS.CHART_SUBSCRIBE, (data) => {
        saveChartIdToSubscription(data.chartId, data.messageId);
      });

      Connection?.messageEmitter?.on(WS_CHANNELS.CHART_TICK, (data) => {
        rtDataListenerFromSocket(data?.symbolIndex, data.chartId, data);
      });

      Connection?.messageEmitter?.on(WS_EVENTS.EXCEPTION, (err) => {
        setToLocalStorage(WS_CHART_ERROR, err);
      });
    }
  }, [Authorized, Connection]);

  useEffect(() => unbindAll(tradingViewWidget as CurrentWidget), []);

  useEffect(() => {
    if (Connection && tradingViewWidget) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tradingViewWidget.datafeed.socket = Connection;
    }
  }, [Socket, Socket?.readyState, Authorized]);

  return (
    <>
      <div id="tv_chart_container" />
    </>
  );
};

export default WebViewWidget;
