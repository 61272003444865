import {
  ANNUAL_RESOLUTIONS,
  GET_BARS_SETTINGS,
  DEFAULT_COUNT_BACK,
  SUPPORTED_RESOLUTIONS,
  PRICE_SCALE,
  MIN_MOV,
  SESSION,
  STOCKS,
  SUBSESSIONS_ARRAY,
  EXTENDED_SESSION, SUPPORTED_RESOLUTION_DEFAULT,
} from '../constants/tvWidgetOptions';
import { messageIdGenerator, resolutionInterface } from '../components/ChartContainer/utils/chartingHelper';
import { MarketConstants } from '../components/MarketDropdown/types/MarketEnums';
import { subscribeOnStream, unsubscribeFromStream } from './socketTvSubscriber';
import epsSalesEstimatesHandler from '../utils/epsSalesEstimatesHandler';
import { lastBarsCache, lastBarsPrice } from './HistoricalSharedData';
import { orderedCache } from './ChartPrefetchServices/OrderedSymbolCache';
import { resetChartData } from './ResetChartDataProcessor';
import { EPS_NAME, SALES_NAME } from '../constants/customIndicatorsSettings';
import { marksDataProcessor } from './ChartPrefetchServices/MarksDataProcessor';
import { createMarkId, getMarkLabelData } from '../components/ChartContainer/utils/marksHandlers';
import { rtProcessor } from './ChartPrefetchServices/RtDataProcessor';
import { DEFAULT_TIMEZONE } from '../constants/account';
import { getFromLocalStorage } from '../utils/storageWorks';
import { WS_CHANNELS } from '../constants/socketConnection';
import { ONE_THOUSAND } from '../constants/screener';
import { SectorIndustryCache } from './ChartPrefetchServices/SectorIndusrtryDataProcessor';
import { fillSpeedValuesHandler } from '../utils/hooks/useSpeedCheckup';

const configurationData = {
  supports_timescale_marks: true,
  supports_marks: true,
  pricescale: PRICE_SCALE,
  supported_resolutions: SUPPORTED_RESOLUTIONS,
};

class TradingViewAdapter {
  constructor(props) {
    this.quotesSubscriptionsTimers = {};
    this.interval = props.interval;
    this.symbol = props.symbol;
    this.symbolsList = props.symbolsList;
    this.searchSymbols = props.searchSymbols;
    this.socket = props.socket;
    this.chanelId = props.chanelId;
    this.currentPath = props.currentPath;
    this.longDescription = props.longDescription;
    this.holidaysList = props.holidaysList;
    this.isMultiChart = props.isMultiChart;
    this.getQuotesTimer = null;
    this.onReadyTimer = null;
    this.dashboardWidgetId = props.dashWidgetId;
    this.tickVersion = props.tickVersion;
    this.requests = {};
    this.isLastData = {};
    return this;
  }

  onReady(cb) {
    this.onReadyTimer = setTimeout(() => {
      clearTimeout(this.onReadyTimer);
      return cb(configurationData);
    });
  }

  async resolveSymbol(
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback,
    extension
  ) {
    let symbolInfo;
    const symbols = this.symbolsList;
    let symbolItem = symbols.find((ticker) => ticker.sym === symbolName);

    if (!symbolItem) {
      const splittedSymbolName = symbolName.split('_');
      if ((splittedSymbolName.includes(SALES_NAME) || splittedSymbolName.includes(EPS_NAME))) {
        symbolItem = symbols.find((ticker) => ticker.sym === splittedSymbolName[0]);
        symbolInfo = {
          name: symbolName,
          index: -1,
          sortIndex: -1
        };
      }
      if (!symbolItem && !getFromLocalStorage('isWebView')) {
        onResolveErrorCallback('Cannot resolve symbol');
        return;
      }
    }

    symbolInfo = symbolInfo || {
      name: symbolItem?.sym,
      index: orderedCache.data.findIndex((item) => item.sym === symbolName),
      sortIndex: symbolItem?.index
    };

    const defaultSettings = {
      intraday_multipliers: ['1', '5', '15', '39', '60', '65', '78'],
      supported_resolutions: SUPPORTED_RESOLUTION_DEFAULT,
    };

    const settings = {
      has_daily: true,
      intraday_multipliers: ['1', '3', '5', '10', '15', '30', '39', '65', '78', '130', '195', '60', '120', '240'],
      monthly_multipliers: ['1', '3', '6', '12'],
      weekly_multipliers: ['1'],
      daily_multipliers: ['1'],
      supported_resolutions: SUPPORTED_RESOLUTIONS,
    };

    const settingsBasedOnRegion = this.tickVersion === 1 ? defaultSettings : settings;

    symbolInfo = {
      ...settingsBasedOnRegion,
      ...symbolInfo,
      type: STOCKS,
      description: symbolItem?.name,
      exchange: symbolItem?.sym,
      minmov: MIN_MOV,
      session: SESSION,
      subsession_id: MarketConstants.SUBSESSION_REGULAR_ID,
      subsessions: SUBSESSIONS_ARRAY,
      has_intraday: true,
      has_weekly_and_monthly: true,
      pricescale: PRICE_SCALE,
      timezone: DEFAULT_TIMEZONE,
      visible_plots_set: 'ohlc',
      volume_precision: 2,
      data_status: 'streaming',
    };

    if (extension?.session === MarketConstants.SUBSESSION_REGULAR_ID) {
      symbolInfo.session = SESSION;
      symbolInfo.subsession_id = MarketConstants.SUBSESSION_REGULAR_ID;
    } else if (extension?.session === MarketConstants.SUBSESSION_EXTENDED_ID) {
      symbolInfo.session = EXTENDED_SESSION;
      symbolInfo.subsession_id = MarketConstants.SUBSESSION_EXTENDED_ID;
    }

    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
  }

  removeHandledRequest(id) {
    delete this.requests[id];
  }

  removeHandledLastDataFlag(id) {
    const keys = Object.keys(this.isLastData);
    keys.forEach((key) => {
      if (key !== id) {
        delete this.isLastData[key];
      }
    });
  }

  getBarsResponseHandler(response) {
    if (!this.requests[response.messageId]) return;

    const {
      historicalTicks,
      symbolIndex,
      lastBarCacheKey,
      dataHandler,
      requestTimeStart
    } = this.requests[response.messageId];
    const dataBatch = response.data;
    const uniqKey = `${symbolIndex}_${historicalTicks.resolution}`;

    dataBatch.forEach((dataChunk) => {
      const bars = dataChunk?.historicalTicks.map((item) => ({
        time: item[0] * ONE_THOUSAND,
        open: item[1],
        high: item[2],
        low: item[3],
        close: item[4],
        volume: item[5],
      }));

      const sectorIndustryData = { sector: dataChunk?.dataPoints?.[1], industry: dataChunk?.dataPoints?.[2] };

      SectorIndustryCache.setDataHandler(sectorIndustryData, symbolIndex);

      const data = { final: dataChunk?.isLastHistoricalData, bars };

      const existedLastBar = lastBarsCache.get(lastBarCacheKey);
      if (historicalTicks.isFirstRequest && !existedLastBar) {
        lastBarsCache.set(lastBarCacheKey, { ...data.bars.at(-1) });
        lastBarsPrice.open = data.bars.at(-1)?.open;
        lastBarsPrice.close = data.bars.at(-1)?.close;
      }

      rtProcessor.rtInitDataHandler(symbolIndex, historicalTicks.resolution, dataChunk?.todayTicks);
      this.isLastData[uniqKey] = data.final;

      if (!data.final) {
        dataHandler(data.bars);
      } else {
        dataHandler(data.bars, { noData: true });
      }

      const speedCheck = Date.now() - requestTimeStart;
      fillSpeedValuesHandler('tv_historical_data', speedCheck);

      // eslint-disable-next-line no-console
      console.log('=> tvHistoricalRequest', speedCheck, '/', response.latency);
    });

    this.removeHandledRequest(response.messageId);
    this.socket?.messageEmitter?.on(WS_CHANNELS.SYMBOL_DATA_BATCH, (resp) => this.getBarsResponseHandler(resp));
  }

  async getBars(symbolInfo, resolution, periodParams, onHistoryCallback) {
    const lastBarCacheKey = `${symbolInfo.name}_${resolution}`;
    const formatedResolution = resolutionInterface(resolution, this.tickVersion);
    const extendedFlag = symbolInfo?.subsession_id === MarketConstants.SUBSESSION_EXTENDED_ID;
    const splittedSymbolName = symbolInfo.name.split('_');
    const uniqKey = `${symbolInfo.sortIndex}_${resolution}`;

    this.interval = resolution;

    this.removeHandledLastDataFlag(uniqKey);

    const {
      from, to, firstDataRequest, countBack
    } = periodParams;

    if (this.isLastData[uniqKey]) {
      onHistoryCallback([], { noData: true });
      return;
    }

    if (firstDataRequest && (splittedSymbolName.includes(SALES_NAME) || splittedSymbolName.includes(EPS_NAME))) {
      await epsSalesEstimatesHandler(symbolInfo, this.symbolsList, firstDataRequest, onHistoryCallback, this.socket);
      return;
    }

    if (!firstDataRequest && (splittedSymbolName.includes(SALES_NAME) || splittedSymbolName.includes(EPS_NAME))) {
      onHistoryCallback([], { noData: true });
      return;
    }

    const uniqueMsgId = messageIdGenerator();
    const requestData = {
      symbolIndex: symbolInfo.sortIndex,
      chartMarkets: extendedFlag ? GET_BARS_SETTINGS.MARKET_TYPES_EXTENDED : GET_BARS_SETTINGS.MARKET_TYPES,
      historicalTicks: {
        isFirstRequest: firstDataRequest,
        period: formatedResolution.resolution,
        from,
        to,
        countBack: Math.max(countBack, DEFAULT_COUNT_BACK),
        isExtendedMarket: extendedFlag,
        resolution
      },
      todayTicks: true,
      dataPointIds: [0, 20, 21],
    };

    this.socket.sendMessage(WS_CHANNELS.SYMBOL_DATA_BATCH, {
      messageId: uniqueMsgId,
      requests: [requestData],
    });

    requestData.dataHandler = onHistoryCallback;
    requestData.lastBarCacheKey = lastBarCacheKey;
    requestData.requestTimeStart = Date.now();

    this.requests[uniqueMsgId] = requestData;

    this.socket?.messageEmitter?.on(WS_CHANNELS.SYMBOL_DATA_BATCH, (resp) => this.getBarsResponseHandler(resp));
  }

  subscribeBars = (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
    const currentSymbol = this.symbolsList.find((item) => item.sym === symbolInfo.name);

    if (!this.isMultiChart) {
      resetChartData.setResetRealTime({ cb: onResetCacheNeededCallback });
    }

    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      lastBarsCache.get(`${symbolInfo.name}_${resolution}`),
      currentSymbol?.index,
      this.socket,
      symbolInfo?.subsession_id,
      this.dashboardWidgetId
    );
  }

  unsubscribeBars(subscriberUID) {
    unsubscribeFromStream(subscriberUID, this.socket);
  }

  getTimescaleMarks(symbolInfo, from, to, onDataCallback, resolution) {
    const data = marksDataProcessor.getMarksByRangeHandler(from, to, symbolInfo.sortIndex);

    if (data.length) {
      const isAnnual = ANNUAL_RESOLUTIONS.includes(resolution);

      const marks = data?.map((item) => {
        const settingsData = getMarkLabelData(item, item?.reportDate ?? item.fiscalDate, isAnnual);

        return {
          id: createMarkId(item, item.id),
          time: item?.reportDate ?? item.fiscalDate,
          ...settingsData
        };
      });

      onDataCallback(marks);
    }
  }

  getQuotes(symbols, onDataCallback) {
    const data = [];
    const cacheKey = `${symbols[0]}_${this.interval}`;
    const lastClosedBar = lastBarsCache.get(cacheKey);
    const currentSymbol = this.symbolsList.find((symbol) => symbol.sym === symbols[0]);

    if (!currentSymbol) return;

    const currentLastPrice = rtProcessor.rtData[currentSymbol.index]?.data?.length > 0
      ? rtProcessor.rtData[currentSymbol.index]?.data?.at(-1)[4] : lastClosedBar?.open;

    let priceChangePercent = 0;
    let priceChange = 0;

    if (rtProcessor.rtData[currentSymbol.index]?.data?.length > 0) {
      priceChangePercent = ((currentLastPrice / lastClosedBar?.close) * 100) - 100;
      priceChange = currentLastPrice - lastClosedBar?.close;
    } else {
      priceChangePercent = ((lastClosedBar?.close / lastClosedBar?.open) * 100) - 100;
      priceChange = lastClosedBar?.close - currentLastPrice;
    }

    symbols.forEach((symbol) => {
      data.push({
        n: symbol,
        s: 'ok',
        v: {
          ch: priceChange,
          chp: priceChangePercent,
          exchange: '',
          lp: lastClosedBar?.open,
          ask: lastClosedBar?.high,
          bid: lastClosedBar?.low,
          open_price: lastClosedBar?.open,
          high_price: lastClosedBar?.high,
          low_price: lastClosedBar?.low,
          prev_close_price: lastClosedBar?.close,
          original_name: symbol,
          volume: lastClosedBar?.volume,
        },
      });
    });

    this.getQuotesTimer = setTimeout(() => {
      onDataCallback(data);
      clearTimeout(this.getQuotesTimer);
    }, 0);
  }

  subscribeQuotes(symbols, fastSymbols, onRealtimeCallback, listenerGUID) {
    this.quotesSubscriptionsTimers[listenerGUID] = setInterval(
      () => this.getQuotes(symbols.concat(fastSymbols), onRealtimeCallback),
      1000
    );
  }

  unsubscribeQuotes(listenerGUID) {
    clearInterval(this.quotesSubscriptionsTimers[listenerGUID]);
  }
}

export default TradingViewAdapter;
