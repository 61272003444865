import {
  useContext,
  useEffect, useState
} from 'react';
import {
  WS_CHANNELS,
} from '../../../constants/socketConnection';
import {
  HeatMapDataset,
  PerformanceDataSet,
  SocketDataResponse,
  SocketPartialResponse,
  StoredSymbol,
  TDataPoint,
} from '../types/WidgetChartsypes';
import {
  DATA_POINT_PERFORMANCE_FILTER,
  DEFAULT_DATA_POINT_INDEX,
  DEFAULT_DATA_POINT_TITLE,
  DEFAULT_HEATMAP_SCREENER_TITLE,
  DEFAULT_HEATMAP_Y_DATA_POINT_INDEX,
  DEFAULT_HEATMAP_Y_DATA_POINT_TITLE,
  S_P_500_ID_PROD,
} from '../types/WidgetChartsEnums';
import { filtersStoreHelper } from '../../../utils/helpers';
import {
  IUseWidgetHeatMap,
} from '../types/WidgetChartsInterfaces';
import { orderedCache } from '../../../services/ChartPrefetchServices/OrderedSymbolCache';
import { transformData } from '../../../utils/convertingDataHelper';
import useWidgetCharts from './useWidgetCharts';
import SocketContext from '../../../context/SocketContext';
import { ScreenerQueryMessage } from '../../../services/Interfaces';
import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import {
  dataCallback, dataCallbackAll, dataCallbackPatch,
  getWidgetValues
} from '../utils/initHelper';
import useWidgetSocketListener from './useWidgetSocketListener';
import useWidgetAddQuery from './useWidgetAddQuery';

const useWidgetHeatMap: IUseWidgetHeatMap = (widget, dashboardId, updateWidget) => {
  const debouncedUpdateLastState = useDebouncedCallback();
  const { addWidgetQuery } = useWidgetAddQuery();
  const { Socket, Connection, Authorized } = useContext(SocketContext);
  const [storedSymbols, setStoredSymbols] = useState<HeatMapDataset[]>([]);
  const updateStoreSymbolsState = (data: PerformanceDataSet[] | HeatMapDataset[]) => {
    setStoredSymbols(data as HeatMapDataset[]);
  };
  const {
    presetsNewScreen,
    columnsList,
    symbolsList,
    userSettings,
    screenerStates,
    watchLists,
    itemsDeeplist,
    setScreenerStates,
    queryId,
    setQueryId,
    dataPointList,
    setDataPointList,
    selectedType,
    setDefaultFilter,
    screenerSearch,
    widgetQueriesRef,
    updateFilter,
    setFilter,
    selectedDashboard,
    cleanRefs,
    startInterval,
    refStoredSymbols,
    intervalIdRef
  } = useWidgetCharts(
    widget,
    S_P_500_ID_PROD,
    DEFAULT_HEATMAP_SCREENER_TITLE,
    updateStoreSymbolsState
  );

  const convertIntoLabelValue = (dataResponse: SocketDataResponse) => {
    const symbols: HeatMapDataset[] = [];
    dataResponse?.data?.forEach((item) => {
      if (!Number.isNaN(item[5]) && !Number.isNaN(item[4])) {
        const sym = symbolsList.find((subItem: StoredSymbol) => subItem.sym === item[1]);
        if (sym && sym.sym) {
          symbols.push({
            ...sym,
            sector: item[2],
            industry: item[3],
            marketCap: Math.abs(Number(item[4])),
            value: transformData(screenerStates.dataPointsXType, Number(item[5]), ''),
            topIndustry: item[6],
          });
        }
      }
    });
    return symbols;
  };

  const prepareDataHandler = (resp: SocketDataResponse) => {
    const symbols = convertIntoLabelValue(resp);
    refStoredSymbols.current = symbols;
    setStoredSymbols(symbols);
    setQueryId(resp.screenerId);
    startInterval();
  };

  const partialCallback = (resp: SocketPartialResponse) => {
    if (resp.screenerId === queryId && refStoredSymbols.current.length > 0 && intervalIdRef.current) {
      const [symIndex, xIndex, xValue, yIndex, yValue] = resp.data;
      const index = refStoredSymbols.current.findIndex((item) => item.index === symIndex);
      if (index > -1) {
        const localData = [...refStoredSymbols.current];
        if (xIndex && xIndex === screenerStates.dataPointsXIndex) {
          localData[index] = {
            ...localData[index],
            value: transformData(screenerStates.dataPointsXType, Number(xValue), '')
          };
        }
        if (xIndex && xIndex === screenerStates.dataPointsYIndex) {
          localData[index] = {
            ...localData[index],
            marketCap: Math.abs(Number(xValue)),
          };
        }
        if (yIndex && yIndex === screenerStates.dataPointsXIndex) {
          localData[index] = {
            ...localData[index],
            value: transformData(screenerStates.dataPointsXType, Number(yValue), '')
          };
        }
        if (yIndex && yIndex === screenerStates.dataPointsYIndex) {
          localData[index] = {
            ...localData[index],
            marketCap: Math.abs(Number(yValue)),
          };
        }
        refStoredSymbols.current = localData;
      }
    }
  };

  useWidgetSocketListener({
    widget,
    screenerStates,
    dataCallback: (resp: SocketDataResponse) => dataCallback(resp, widget, addWidgetQuery, prepareDataHandler),
    dataCallbackPatch: (resp: SocketDataResponse) => dataCallbackPatch(
      resp, widget, addWidgetQuery, prepareDataHandler
    ),
    dataCallbackAll: (resp: SocketDataResponse) => dataCallbackAll(resp, widget, widgetQueriesRef, prepareDataHandler),
    partialCallback,
    queryId,
    storedSymbols
  });

  useEffect(() => {
    if (storedSymbols.length) {
      orderedCache.setWidgetData(storedSymbols);
    }
  }, [storedSymbols]);

  const initialWidget = () => {
    const findDataPoint = columnsList.find(
      (item: TDataPoint) => item.index === DEFAULT_DATA_POINT_INDEX
    ) ?? { type: '' };

    setScreenerStates((prev) => ({
      ...prev,
      selectedScreenerTitle: DEFAULT_HEATMAP_SCREENER_TITLE,
      dataPointsXTitle: DEFAULT_DATA_POINT_TITLE,
      dataPointsXIndex: DEFAULT_DATA_POINT_INDEX,
      dataPointsYTitle: DEFAULT_HEATMAP_Y_DATA_POINT_TITLE,
      dataPointsYIndex: DEFAULT_HEATMAP_Y_DATA_POINT_INDEX,
      dataPointsXType: findDataPoint.type,
    }));
    setDefaultFilter();
  };

  useEffect(() => {
    if (parseInt(widget.id) > 0 && columnsList.length && presetsNewScreen.length) {
      getWidgetValues(
        widget,
        userSettings.heatmapLastState,
        selectedDashboard,
        columnsList,
        setFilter,
        initialWidget,
        {
          itemId: dashboardId,
          defaultScreenId: S_P_500_ID_PROD,
          findY: true,
          findZ: false,
        }
      );
      setDataPointList(columnsList.filter((item: TDataPoint) => (item.show && item.enabled
        && DATA_POINT_PERFORMANCE_FILTER.includes(item.index))));
    }
  }, [columnsList, presetsNewScreen]);

  useEffect(() => {
    if (
      screenerStates.dataPointsXIndex
      && screenerStates.filtersData
      && screenerStates.dataPointsYIndex
      && Socket?.readyState === WebSocket.OPEN
      && Authorized
    ) {
      cleanRefs();
      const queryMessage: ScreenerQueryMessage = {
        messageId: Number(widget.id),
        columns: [0, 17, 20, 21, screenerStates.dataPointsYIndex, screenerStates.dataPointsXIndex, 258],
        filters: filtersStoreHelper(JSON.parse(screenerStates.filtersData)),
        sortBy: [[screenerStates.dataPointsYIndex, 1], [screenerStates.dataPointsXIndex, 1]],
        range: [0, 500],
        etfSymbolIndex: -1,
      };
      let emitter;
      if (queryId) {
        queryMessage.screenerId = queryId;
        emitter = () => Connection?.sendMessage(WS_CHANNELS.SCREENER_PATCH, queryMessage);
      } else {
        emitter = () => Connection?.sendMessage(WS_CHANNELS.SCREENER_SUBSCRIBE, queryMessage);
      }
      debouncedUpdateLastState(emitter, 500);
    }
  }, [
    screenerStates.dataPointsXIndex,
    screenerStates.dataPointsYIndex,
    screenerStates.filtersData,
    Socket?.readyState,
    Authorized
  ]);

  useEffect(() => {
    if (
      widget.id
      && screenerStates.dataPointsXIndex
      && screenerStates.dataPointsYIndex
    ) {
      const newWidget = {
        ...widget,
        datapointIndex: screenerStates.dataPointsXIndex,
        datapointYIndex: screenerStates.dataPointsYIndex,
        screenerId: screenerStates.screenerId ?? S_P_500_ID_PROD,
      };
      updateWidget(newWidget);
    }
  }, [
    widget?.id,
    screenerStates.screenerId,
    screenerStates.dataPointsXIndex,
    screenerStates.dataPointsYIndex,
    selectedDashboard?.type
  ]);

  useEffect(() => {
    if (watchLists?.length && itemsDeeplist.length) {
      updateFilter();
    }
  }, [watchLists, itemsDeeplist]);

  return {
    dataPointList,
    storedSymbols,
    selectedType,
    queryId,
    screenerStates,
    screenerSearch,
    setScreenerStates,
    setDefaultFilter,
  };
};

export default useWidgetHeatMap;
