import { useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query';
import $api from '../../../http';
import numberConstant from '../../../constants/numberConstants';
import { FetchItemsArgs, FetchItemsResponse } from '../types';
import { TDashboardItemsData } from '../../../utils/Types';

const fetchItems = async ({ pageParam = 0, type }: FetchItemsArgs): Promise<FetchItemsResponse> => {
  const response = await $api.get(
    `dashboard-items?type=${type}&page=${pageParam}&limit=${numberConstant.limitItems}`,
  );

  if (!response.status) {
    throw new Error('Failed to fetch folders');
  }
  const { items, count } = response.data;
  return { items, count };
};

type TypePages = {
  count: number;
  items: TDashboardItemsData[];
};

type TypeGetDbItemsReturn = {
  pageParams: number[];
  pages: TypePages[]
};

const useGetDBItems = (type: string, enabled: boolean):
UseInfiniteQueryResult<TypeGetDbItemsReturn, unknown> => useInfiniteQuery({
  queryKey: [`${type}Items`],
  queryFn: (params) => fetchItems({ ...params, type }),
  initialPageParam: 0,
  getNextPageParam: (lastPage, allPages) => {
    const totalFetched = allPages.flatMap((page) => page.items).length;
    const totalItems = lastPage.count;
    const hasNextPage = totalFetched < totalItems;

    return hasNextPage ? allPages.length : undefined;
  },
  refetchOnWindowFocus: false,
  enabled,
});

export default useGetDBItems;
