import {
  useEffect, useRef, useState, useContext
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Dashboard, Widget } from '../types/DashboardTypes';
import { IUseDashboardsHook } from '../types/DashboardInterfaces';
import {
  setGlobalHoldingSearch,
  setGlobalSectorIndustrySearch
} from '../../../store/dashboards/slice';
import RootStateTypes from '../../../store/RootStateTypes';
import {
  TEMPLATE_TYPE, SAVE_USER_STATE_DELAY, PATH_TYPE, SUBSCRIPTION_TYPE
} from '../../../constants/screener';
import {
  popUpAction, errorMessageTitleAction,
  messageAction, changeNotificationTypeAction
} from '../../../store/auth/actions';
import useAccount from '../../../utils/hooks/useAccount';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { LAST_DASHBOARDS_TAB } from '../../../constants/storage';
import useAlerts from '../../../components/ChartContainer/hooks/useAlerts';
import { NOTIFICATION_DELAY_TIMES } from '../../../constants/alerts';
import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import { setQueryScreenerId, setDataPanelsQueryId } from '../../../store/tableData/slice';
import SocketContext from '../../../context/SocketContext';
import { messageIdGenerator } from '../../../components/ChartContainer/utils/chartingHelper';
import { WS_CHANNELS } from '../../../constants/socketConnection';
import { checkRoleUser } from '../../../utils/userHelper';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import { TDashboardItemsData } from '../../../utils/Types';
import useItemsMutations from '../../../tanStack/Items/Mutations/itemsMutations';

type TErrorHandleProps = {
  message: string;
  title: string;
  type: string;
};

const UseDashboards: IUseDashboardsHook = () => {
  const dashboardsListRef = useRef<Dashboard[]>([]);
  const { Connection } = useContext(SocketContext);
  const [isUpdatedAllData, setIsUpdatedAllData] = useState<boolean>(false);
  const { updateStoreHandler } = useLastStateUpdateStore();
  const { t } = useTranslation();
  const { updateUserState } = useAccount();
  const {
    getAlertsNotificationList,
    getAlertsList,
    triggeredAlertsListener,
    getAlertSettings
  } = useAlerts();

  const dispatch = useDispatch();
  const route = useRouteMatch();

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const selectedDashboardId = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.selectedDashboardId,
    shallowEqual
  );
  const globalHoldingSearch = useSelector(
    (state: RootStateTypes) => state.dashboardsState.globalHoldingSearch, shallowEqual
  );
  const globalSectorIndustrySearch = useSelector(
    (state: RootStateTypes) => state.dashboardsState.globalSectorIndustrySearch, shallowEqual
  );
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const lastStateId = useSelector((state: RootStateTypes) => state.accountState.lastStateId);

  const {
    screenerItems,
    screenerPresetsItems,
    itemsColumnSets,
    tabsList,
    dashboards,
    dashboardsPresets
  } = useDashboardItems();
  const {
    createDashboardMutation,
    duplicateDashboardMutation,
    updateDashboardMutation,
    removeDashboardMutation,
    saveOrderMutation
  } = useItemsMutations();
  const itemsNewScreen = screenerItems;
  const presetsNewScreen = screenerPresetsItems;

  const updatedLastState = useSelector((state: RootStateTypes) => state.accountState.updatedLastState, shallowEqual);
  const queryScreenerId = useSelector((state: RootStateTypes) => state.tableDataState.queryScreenerId, shallowEqual);
  const dataPanelsQueryId = useSelector(
    (state: RootStateTypes) => state.tableDataState.dataPanelsQueryId, shallowEqual
  );

  const debouncedUpdateLastState = useDebouncedCallback();

  const setCurrentDashboard = (id: number) => {
    updateStoreHandler(LAST_DASHBOARDS_TAB, id);
  };

  const clenUpOldConnections = () => {
    if (queryScreenerId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: messageIdGenerator(), screenerId: queryScreenerId });
      dispatch(setQueryScreenerId(null));
    }
    if (dataPanelsQueryId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: messageIdGenerator(), screenerId: dataPanelsQueryId });
      dispatch(setDataPanelsQueryId(null));
    }
  };

  const successHandler = (message: string, title: string) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const errorHandler = ({ message, title, type }: TErrorHandleProps) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type }));
  };

  const createDashboard = (name: string, isPreset: boolean) => {
    createDashboardMutation.mutate({
      name,
      isPreset,
      successActionHandler: (newItem: TDashboardItemsData) => {
        successHandler(newItem?.title, t('successCreateDashboard'));
      },
      errorHandler: () => {
        errorHandler({
          message: t('errorCreateDashboard'),
          title: t('error'),
          type: t('errorType'),
        });
      }
    });
  };
  const duplicateDashboard = (dashboard: Dashboard, title: string, folderId: string | null | undefined) => {
    duplicateDashboardMutation.mutate({
      title,
      folderId,
      dashboard,
      successActionHandler: (newItem: TDashboardItemsData) => {
        successHandler(newItem?.title, t('successDuplicateDashboard'));
      },
      errorHandler: () => {
        errorHandler({
          message: t('errorCreateDashboard'),
          title: t('error'),
          type: t('errorType'),
        });
      }
    });
  };
  const updateDashboard = (dashboard: Dashboard, skipNotification: boolean) => {
    updateDashboardMutation.mutate({
      dashboard,
      successActionHandler: (newItem: TDashboardItemsData) => {
        if (skipNotification) return;
        successHandler(newItem?.title, t('updatedDashboard'));
      },
      errorHandler: () => {
        errorHandler({
          message: t('errorUpdateDashboard'),
          title: t('error'),
          type: t('errorType'),
        });
      }
    });
  };
  const removeDashboard = (id: number) => {
    removeDashboardMutation.mutate({
      id,
      successActionHandler: () => {
        successHandler(t('successDeleteDashboard'), t('success'));
        const dashboardListNew = dashboardsListRef.current.length ? dashboardsListRef.current
          : dashboards;
        const removeIndex = dashboardListNew.findIndex((item) => item.id === id);
        const lastIndex = dashboardListNew.length - 1;
        if (removeIndex > 0 && lastIndex > 0 && removeIndex === lastIndex) {
          updateStoreHandler(LAST_DASHBOARDS_TAB, dashboardListNew[removeIndex - 1].id);
        }
        if (removeIndex === 0 && lastIndex === 0) {
          updateStoreHandler(LAST_DASHBOARDS_TAB, 0);
        }
        if (removeIndex + 1 <= lastIndex) {
          updateStoreHandler(LAST_DASHBOARDS_TAB, dashboardListNew[removeIndex + 1].id);
        }
      },
      errorHandler: () => {
        errorHandler({
          message: t('errorDeleteDashboard'),
          title: t('error'),
          type: t('errorType'),
        });
      }
    });
  };
  const updateWidget = (widget: Widget, dashboardId: number) => {
    const cloneDashboards = [...dashboardsListRef.current, ...dashboardsPresets] as Dashboard[];
    cloneDashboards.forEach((dashboard) => {
      if (dashboard.id === dashboardId && (adminRole || (dashboard.type !== TEMPLATE_TYPE.DASHBOARDS_PRESET))) {
        const newWidgets = dashboard.data.widgets.map((item: Widget) => {
          if (item.id === widget.id) {
            return widget;
          }
          return item;
        });
        updateDashboard({ ...dashboard, data: { widgets: newWidgets } }, true);
      }
    });
  };
  const renameDashboard = (title: string, id: number,) => {
    const clonedData = [...dashboards, ...dashboardsPresets] as Dashboard[];
    clonedData.forEach((dashboard) => {
      if (dashboard.id === id) {
        updateDashboard({ ...dashboard, title }, false);
      }
    });
  };
  const updateDashboardInStore = (dashboard: Dashboard) => {
    [...dashboardsListRef?.current, ...dashboardsPresets].forEach((item) => {
      if (item.id === dashboard.id && (adminRole || (dashboard.type !== TEMPLATE_TYPE.DASHBOARDS_PRESET))) {
        updateDashboard(dashboard, true);
      }
    });
  };
  const saveOrder = (itemId: number, placeAfterId: number) => {
    saveOrderMutation.mutate({
      itemId,
      placeAfterId,
      itemType: 'userDashboard',
      isFolder: false
    });
  };

  const handleSetGlobalHoldingSearch = (value: boolean) => {
    dispatch(setGlobalHoldingSearch(value));
  };

  const handleSetGlobalSectorIndustrySearch = (value: boolean) => {
    dispatch(setGlobalSectorIndustrySearch(value));
  };

  useEffect(() => {
    if (dashboards.length && !selectedDashboardId) {
      const firstDashboard = dashboards[0];
      updateStoreHandler(LAST_DASHBOARDS_TAB, firstDashboard?.id);
    }
    if (dashboardsListRef.current?.length && (dashboardsListRef.current?.length < dashboards.length)) {
      const firstDashboard = dashboards[0];
      updateStoreHandler(LAST_DASHBOARDS_TAB, firstDashboard?.id);
    }
    dashboardsListRef.current = dashboards as Dashboard[];
  }, [dashboards]);

  useEffect(() => {
    if (updatedLastState) {
      debouncedUpdateLastState(updateUserState, SAVE_USER_STATE_DELAY, userLastState, lastStateId);
    }
  }, [userLastState]);

  useEffect(() => {
    if (tabsList.length
      && itemsColumnSets.length
      && presetsNewScreen.length
      && dashboards
    ) {
      setIsUpdatedAllData(true);
    }
  }, [tabsList, itemsColumnSets, itemsNewScreen, presetsNewScreen, dashboards]);

  useEffect(() => {
    if (route?.path === PATH_TYPE.DASHBOARD) {
      getAlertsNotificationList();
      getAlertsList();
      getAlertSettings();
      clenUpOldConnections();
    }
    const interval = setInterval(() => {
      triggeredAlertsListener();
    }, NOTIFICATION_DELAY_TIMES.TICKS);
    return () => {
      clearInterval(interval);
      dashboardsListRef.current = [];
    };
  }, []);

  return {
    setCurrentDashboard,
    updateWidget,
    createDashboard,
    updateDashboard,
    removeDashboard,
    renameDashboard,
    updateDashboardInStore,
    saveOrder,
    globalHoldingSearch,
    handleSetGlobalHoldingSearch,
    globalSectorIndustrySearch,
    handleSetGlobalSectorIndustrySearch,
    isUpdatedAllData,
    duplicateDashboard
  };
};

export default UseDashboards;
