import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './sass/WatchListModals.module.scss';
import TextField from '../TextField/TextField';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import { setModalType } from '../../store/watchlist/actions';
import Button from '../Button/Button';
import CommonModalInput from './CommonModalInput';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';

let previousName;

const CommonModal = ({
  type, controlledEditName,
  editDasboard, closeDashboardModal,
  isDashboard
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabledFlag, setDisabledFlag] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const tabFromDropDown = useSelector((state) => state.watchlistState.tabFromDropDown, shallowEqual);
  const currentItem = Object.keys(tabFromDropDown).length > 0 ? tabFromDropDown : selectedTab;
  useEffect(() => {
    setInputValue(isDashboard ? controlledEditName : currentItem.title);
    previousName = isDashboard ? controlledEditName : currentItem.title;
  }, []);

  const clickHandler = () => {
    if (type !== t('rename')) {
      if (isDashboard) {
        editDasboard(type);
      }
    }
    if (type === t('rename')) {
      if (isDashboard) {
        editDasboard(type, inputValue);
      }
    }
  };

  const getHeaderText = () => {
    if (isDashboard) {
      return `${type === t('rename') ? t('renameDashboard') : t('areYouSureShort')}`;
    }
    return `${type === t('rename') ? t('renameWatchList') : t('areYouSureShort')}`;
  };

  const getBodyText = () => {
    if (isDashboard) {
      return `${type === t('rename') ? t('dashboardName') : t('areYouSureDashboard')}`;
    }
    return `${type === t('rename') ? t('watchlistName') : t('areYouSureWatchlist')}`;
  };

  const handleClose = () => {
    if (isDashboard) {
      closeDashboardModal();
    } else {
      dispatch(setModalType(''));
    }
  };

  return (
    <div className={styles.modal}>
      <ButtonListenerWrapper handleCancel={() => dispatch(setModalType(''))} handleConfirm={clickHandler}>
        <>
          <div className={styles.modalHeader}>
            <TextField
              text={getHeaderText()}
              styleType="modalHeader"
            />
            <div
              aria-hidden="true"
              onMouseDown={handleClose}
              className={styles.closeIcon}
            >
              <CloseIcon />
            </div>
          </div>
          <div className={styles.modalBody}>
            <TextField
              text={getBodyText()}
              styleType="watchlistModalFolder"
            />
            <CommonModalInput
              type={type}
              setDisabledFlag={setDisabledFlag}
              inputValue={inputValue}
              setInputValue={setInputValue}
              isDashboard={isDashboard}
              autoFocus
              dashboardName={controlledEditName}
            />
            <div className={styles.applyButtonWrapper}>
              {type !== t('rename') && (
                <Button
                  handleClick={handleClose}
                  buttonClass={styles.cancelButton}
                >
                  <TextField text={t('cancel')} styleType="cancelButton" />
                </Button>
              )}
              <Button
                handleClick={clickHandler}
                buttonClass={styles.applyButton}
                disabled={disabledFlag || (previousName === inputValue && type === t('rename'))}
              >
                <TextField text={type} styleType="applyButton" />
              </Button>
            </div>
          </div>
        </>
      </ButtonListenerWrapper>
    </div>
  );
};

CommonModal.propTypes = {
  type: PropTypes.string.isRequired,
  controlledEditName: PropTypes.string,
  editDasboard: PropTypes.func,
  closeDashboardModal: PropTypes.func,
  isDashboard: PropTypes.bool
};

CommonModal.defaultProps = {
  controlledEditName: '',
  editDasboard: () => {},
  closeDashboardModal: () => {},
  isDashboard: false
};

export default CommonModal;
