import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import $api from '../../http';
import {
  GET_ACTIVE_SUBSCRIPTION_PATH,
  USERS_ME_PATH,
  DASHBOARD_ITEMS,
  DASHBOARD_ITEM_LAST_STATE,
  DASHBOARD_ITEM_TYPE_CHART_WIDGET,
  LOG_OUT, GET_TICK_VERSION,
} from '../../constants/paths';
import {
  activeSubscriptionAction,
  avatarAction,
  errorMessageAction,
  profileAction,
  twitterAction,
  saveUserState,
  setLoadedUserSettings,
  logOut,
  setLastStateId, setTickVersion
} from '../../store/account/actions';
import { SOCIALS_TYPE, TEMPLATE_TYPE } from '../../constants/screener';
import useCompression from './useCompression';
import { MarketSession } from '../../components/MarketDropdown/types/MarketEnums';
import { fillSpeedValuesHandler } from './useSpeedCheckup';
import numberConstant from '../../constants/numberConstants';
import { STATUS_200 } from '../../constants/responseStatuses';
import { setDefaultLayoutID, setLayoutsList } from '../../store/screener/actions';
import OUTER_URLS from '../../constants/outerLinks';
import { removeLocalStorage, setToLocalStorage } from '../storageWorks';
import { ACCESS_TOKEN } from '../../constants/storage';
import { lastStateTitle, lastStateType } from '../../constants/account';
import { cleanOldSearchHistoryAndLayoutChart } from '../helpers';
import { setSelectedTab } from '../../store/watchlist/actions';
import { setSelectedScreen } from '../../store/newScreen/actions';
import { SendGAEvent } from '../ga';
import { findItem } from '../../components/DasboardComponents/utils/utils';
import { useDashboardItems } from '../../context/DasboardItemsContext/DashboardItemsProvider';

const useAccount = () => {
  const dispatch = useDispatch();
  const { compressHandler, decompressHandler } = useCompression();
  const tvLayoutsList = useSelector((state) => state.screenerState.tvLayoutsList, shallowEqual);
  const { presetsNewScreen } = useDashboardItems();
  const profile = useSelector((state) => state.accountState.profile, shallowEqual);
  const { REACT_APP_PUBLIC_LIGHT_SERVER_URL } = process.env;
  const profileWorker = () => {
    const start = Date.now();

    try {
      $api.get(USERS_ME_PATH)
        .then((response) => {
          dispatch(profileAction(response.data));
          dispatch(twitterAction(response.data?.socials?.find((item) => item === SOCIALS_TYPE.TWITTER)));

          // speed check temporary data
          const end = Date.now();
          const speedCheck = end - start;
          fillSpeedValuesHandler('get_me_info', speedCheck);
          // eslint-disable-next-line no-console
          console.log('=> get_me_info', speedCheck);
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const avatarWorker = () => {
    try {
      $api.get(USERS_ME_PATH)
        .then((response) => {
          dispatch(avatarAction(response.data?.avatar));
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const activeSubscriptionWorker = () => {
    try {
      $api.get(GET_ACTIVE_SUBSCRIPTION_PATH)
        .then((response) => dispatch(activeSubscriptionAction(response.data)));
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getDashboardItemByID = async (id) => {
    try {
      $api.get(`${DASHBOARD_ITEMS}/${id}`).then((item) => {
        if (item?.data.type === TEMPLATE_TYPE.WATCHLIST || item?.data.type === TEMPLATE_TYPE.WATCHLIST_PRESETS) {
          dispatch(setSelectedTab(item.data));
        } else {
          dispatch(setSelectedScreen(item.data));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const userStateWorker = () => {
    const start = Date.now();

    try {
      $api.get(`${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_LAST_STATE}`)
        .then(async (response) => {
          const decompressedResponse = await decompressHandler(response?.data?.items[0]?.data);
          if (decompressedResponse && !decompressedResponse?.currentSessionFromStore) {
            decompressedResponse.currentSessionFromStore = MarketSession.REGULAR;
          }
          if (decompressedResponse.theme) {
            setToLocalStorage('theme', decompressedResponse.theme);
          }

          if (decompressedResponse.selectedTabId) {
            await getDashboardItemByID(decompressedResponse.selectedTabId);
          }

          if (decompressedResponse.selectedScreenId) {
            await getDashboardItemByID(decompressedResponse.selectedScreenId);
          }

          dispatch(saveUserState(decompressedResponse));
          dispatch(setLastStateId(response?.data?.items[0]?.id));
          dispatch(setLoadedUserSettings(true));

          // speed check temporary data
          const end = Date.now();
          const speedCheck = end - start;
          fillSpeedValuesHandler('get_lastState_info', speedCheck);
          // eslint-disable-next-line no-console
          console.log('=> get_lastState_info', speedCheck);
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getTickVersion = async (setVersion = undefined) => {
    try {
      const response = await $api.get(`${REACT_APP_PUBLIC_LIGHT_SERVER_URL}${GET_TICK_VERSION}`);
      dispatch(setTickVersion(response.data.version || 1));
      if (setVersion) {
        setVersion(response.data.version || 1);
      }
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const updateUserState = async (data, lastStateId) => {
    const cleanedData = cleanOldSearchHistoryAndLayoutChart(data);
    const foundPreset = findItem(presetsNewScreen, Number(cleanedData?.selectedScreenId));
    if (foundPreset) {
      SendGAEvent('user_use_preset', {
        event_label: 'User use preset',
        preset_label: foundPreset?.title,
        cust_identify: profile?.id
      });
    }
    const compressedData = await compressHandler(cleanedData);
    try {
      $api.patch(`${DASHBOARD_ITEMS}/${lastStateId}`,
        {
          data: compressedData.compressed ? { s3Data: compressedData.s3Data } : cleanedData,
          title: lastStateTitle,
          type: lastStateType
        }).then();
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getUserLayouts = async () => {
    if (tvLayoutsList.length) {
      dispatch(setLayoutsList([]));
    }

    const requestFirst = await $api.get(
      `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_CHART_WIDGET}&page=${
        numberConstant.zeroCount}&limit=${numberConstant.limitItems}`
    );

    const defaultLayout = requestFirst?.data?.items.find((item) => item.isDefault);
    if (defaultLayout) {
      dispatch(setDefaultLayoutID(defaultLayout?.id));
    }

    if (requestFirst.status === STATUS_200) {
      const requests = requestFirst.data.items.map(async (item) => {
        const decompressedContent = await decompressHandler(item?.data);
        // eslint-disable-next-line no-console
        console.log(decompressedContent, item?.data, 'decompressedContent');
        if (!decompressedContent?.content?.id) {
          decompressedContent.content.id = item.id;
        }
        return decompressedContent;
      });

      const result = await Promise.all(requests);
      dispatch(setLayoutsList(result));
    }
  };

  const logOutHandler = async () => {
    try {
      $api.post(LOG_OUT).then(({ data }) => {
        if (Number(data.code) === STATUS_200) {
          window.location.replace(OUTER_URLS.SIGN_IN);
          dispatch(logOut());
          removeLocalStorage(ACCESS_TOKEN);
          removeLocalStorage('refreshToken');
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  return {
    profileWorker,
    avatarWorker,
    activeSubscriptionWorker,
    userStateWorker,
    updateUserState,
    getUserLayouts,
    logOutHandler,
    getTickVersion
  };
};

export default useAccount;
