import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../../sass/PickColumnWrapper.module.scss';
import RightContentHeader from './ColumnsSelectedHeader';
import ColumnsSelectedFooter from './ColumnsSelectedFooter';
import {
  columnsWereUpdateScreenerAction,
  resetItemsDashboardScreenerAction,
  setColumnsScreenerAction
} from '../../../../store/screener/actions';
import {
  getActiveColumns, getActiveColumnsHolding, getColumnsSetItem, getIdColumn
} from '../../../../utils/helperColumnsWorker';
import useNewScreen from '../../../../utils/hooks/useNewScreen';
import {
  FIXED_COLUMNS, DEFAULT_ITEM, TYPE_HANDLER, RECOMMENDED_TYPE
} from '../../../../constants/screener';
import ColumnsDnDList from './ColumnsDnDList';
import { useDashboardItems } from '../../../../context/DasboardItemsContext/DashboardItemsProvider';

const ColumnsSelectedItems = ({
  selectedColumns,
  sortStatus,
  clearSorting,
  isWatchlist,
  sortData,
  setNewSortData,
}) => {
  const dispatch = useDispatch();
  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);
  const columnSetId = useSelector((state) => state.newScreenState.columnSetId, shallowEqual);
  const { itemsColumnSets } = useDashboardItems();

  const [localItems, setLocalItems] = useState(getActiveColumnsHolding(selectedScreen.isHoldingView, selectedColumns));
  const [defColumns, setDefColumns] = useState([]);
  const { updateColumnsSetState } = useNewScreen();

  const saveItems = (defaultColumnsList, id) => {
    const indexItems = defaultColumnsList?.columns.map((el) => el.id);
    updateColumnsSetState(id, indexItems);
  };

  const clearItems = (activeColumns) => {
    dispatch(setColumnsScreenerAction({ data: activeColumns }));
    dispatch(columnsWereUpdateScreenerAction(true));
  };

  const setDefaultItems = (activeColumns) => {
    setDefColumns(activeColumns);
  };

  const handlerWorkWithItems = (type, activeColumns, defaultColumnsList, id) => {
    if (type === TYPE_HANDLER.CLEAR) {
      clearItems(activeColumns);
    } else if (type === TYPE_HANDLER.SAVE) {
      saveItems(defaultColumnsList, id);
    } else if (type === TYPE_HANDLER.DEFAULT) {
      setDefaultItems(activeColumns);
    }
  };
  const handlerColumns = (type, id) => {
    const { indexItem, columnSetIdItem } = getIdColumn(itemsColumnSets);
    if (indexItem !== DEFAULT_ITEM && columnsList.length > 0) {
      const defaultColumnsList = getColumnsSetItem(itemsColumnSets, columnSetIdItem, columnsList, RECOMMENDED_TYPE);
      const activeColumns = getActiveColumns(columnsList, FIXED_COLUMNS, defaultColumnsList?.columns);
      handlerWorkWithItems(type, activeColumns, defaultColumnsList, id);
    }
  };

  const clearAllHandler = () => {
    dispatch(resetItemsDashboardScreenerAction());
    handlerColumns(TYPE_HANDLER.CLEAR);
  };

  const saveColumnsHandler = (id) => {
    handlerColumns(TYPE_HANDLER.SAVE, id);
  };

  useEffect(() => {
    setLocalItems(getActiveColumnsHolding(selectedScreen.isHoldingView, selectedColumns));
  }, [selectedColumns]);

  useEffect(() => {
    handlerColumns(TYPE_HANDLER.DEFAULT);
  }, [columnsList, itemsColumnSets]);

  return (
    <div className={styles.rightSelectedContent}>
      <div className={styles.rightTopContent}>
        <RightContentHeader counter={getActiveColumnsHolding(selectedScreen.isHoldingView, selectedColumns)?.length} />
        <div className={styles.rightTopContentBody}>
          <ColumnsDnDList
            isWatchlist={isWatchlist}
            columnSetId={columnSetId}
            itemsColumnSets={itemsColumnSets}
            localItems={localItems}
            sortData={sortData}
            setNewSortData={setNewSortData}
            setLocalItems={setLocalItems}
            updateColumnsSetState={updateColumnsSetState}
            sortStatus={sortStatus}
          />
        </div>
      </div>
      <ColumnsSelectedFooter
        columnSetId={columnSetId}
        saveColumnsHandler={saveColumnsHandler}
        selectedColumns={selectedColumns}
        defaultSelectedColumns={defColumns}
        clearAllHandler={clearAllHandler}
        clearSortHandler={clearSorting}
        sortData={sortData}
      />
    </div>
  );
};

ColumnsSelectedItems.propTypes = {
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortStatus: PropTypes.func.isRequired,
  clearSorting: PropTypes.func.isRequired,
  setNewSortData: PropTypes.func,
  isWatchlist: PropTypes.bool,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
};

ColumnsSelectedItems.defaultProps = {
  setNewSortData: () => {},
  isWatchlist: false,
  sortData: [],
};

export default ColumnsSelectedItems;
