import React, {
  useEffect, useState, useCallback, useRef
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import MultiChartSettingsButton from '../MultichartView/components/MultiChartSettingsButton';
import ScreenerMenuDivider from '../ScreenerMenu/components/ScreenerMenuDivider';
import ButtonChartRefresh from '../MultichartView/components/ButtonChartRefresh';
import TabsBlockMenu from './TabsBlockMenu';
import ScreenerMenuView from '../ScreenerMenu/components/ScreenerMenuView';
import TwitterShare from '../TwitterShare/ButtonTwitterShare';
import ButtonExportScreener from '../ButtonExportScreener/ButtonExportScreener';
import WatchlistTabsList from './WatchlistTabsList';
import AlertTab from './AlertTab';
import useWatchList from '../../utils/hooks/useWatchList';
import ConfirmDialog from './ConfirmDialog';
import AlertConfirmDeleteRow from './AlertConfirmDeleteRow';
import { MODAL_TITLES, SCREENER_VIEWS } from '../../constants/screener';
import styles from './sass/WatchlistTabsBlock.module.scss';
import confirmStyles from './sass/ManageAlerts.module.scss';
import ScreenerMenuOptions from '../ScreenerMenu/components/ScreenerMenuOptions';
import { ReactComponent as IconStar } from '../../assets/images/icons/screener/star.svg';
import constants from '../../constants/filters';
import { setCategoryName } from '../../store/filters/slice';
import { setNewScreenFlag, setSelectedScreen } from '../../store/newScreen/actions';
import { setVisibleColumnsScreenAction } from '../../store/screener/actions';
import { DialogItemDataTypes } from '../DashboardItemsDialog/types/constants';
import DashboardsItemDropdown from '../DashboardsItemsDropdown/DasboardsItemDropdown';
import { useDashboardItems } from '../../context/DasboardItemsContext/DashboardItemsProvider';
import { ALERTS_TYPE } from '../../constants/watchlist';

const areEqual = (prevProps, nextProps) => {
  return prevProps.setFocus === nextProps.setFocus
    && prevProps.removeAlerts === nextProps.removeAlerts
    && prevProps.isAlertsTab === nextProps.isAlertsTab
    && prevProps.search === nextProps.search
    && prevProps.selectedItems === nextProps.selectedItems
    && prevProps.screenerView === nextProps.screenerView
    && prevProps.getSymbolFormatedByAlertIds === nextProps.getSymbolFormatedByAlertIds;
};

const WatchlistTabsBlock = ({
  selectTabClickHandler,
  selectedItems,
  handleCopySymbols,
  setFocus,
  search,
  resetSearchHandler,
  disableExport,
  isAlertsTab,
  removeAlerts,
  screenerView,
  getSymbolFormatedByAlertIds,
  activeSymbolIndex,
  setIsVisibleFilters,
  setCurrentPresetItem,
  currentItemSelected,
  openModalHandler,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownButtonRef = useRef();
  const ref = useRef(null);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const { itemsWatchlist, presetsWatchlist, tabsList } = useDashboardItems();

  const [tabOrder, setTabOrder] = useState([]);
  const [dropResult, setDropResult] = useState(null);
  const { saveOrder } = useWatchList();

  const [openSureDialog, setOpenSureDialog] = useState(false);
  const [prevItem, setPrevItem] = useState(-1);

  const [isDropDownVisible, setDropdownVisible] = useState(false);
  const viewFilter = useCallback((title, newScreenFlag) => {
    if (title === constants.deepList) openModalHandler(MODAL_TITLES.CREATE_DEEPLIST);
    if (title === constants.presetDeeplist) openModalHandler(MODAL_TITLES.CREATE_DEEPLIST_PRESET);
    setDropdownVisible(false);
    dispatch(setCategoryName(''));
    dispatch(setNewScreenFlag(newScreenFlag));

    if (search) {
      resetSearchHandler();
    }
  }, [search]);

  const actionEdit = (item) => {
    currentItemSelected(item?.id);
    dispatch(setSelectedScreen(item));
    dispatch(setVisibleColumnsScreenAction(false));
    viewFilter(item?.title, false);
  };
  const actionCreateNew = (selectedTabId) => {
    viewFilter(selectedTabId === 0 ? constants.deepList : constants.presetDeeplist, true);
  };

  const actionSetCurrentItem = (item) => {
    resetSearchHandler(item);
    selectTabClickHandler(item, true);
    setDropdownVisible(false);
  };

  const changeOrderHandler = useCallback((dragIndex, hoverIndex) => {
    setTabOrder((prevOrder) => update(prevOrder, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevOrder[dragIndex]],
      ],
    }));
  }, []);
  const endDragHandler = useCallback((dragItem) => {
    setDropResult(dragItem);
  }, []);
  useEffect(() => {
    if (dropResult !== null) {
      const prevIndex = tabOrder.indexOf(dropResult.id) - 1;
      saveOrder(dropResult.id, prevIndex < 0 ? 0 : tabOrder[prevIndex], 'watchList');
      setDropResult(null);
    }
  }, [dropResult]);

  useEffect(() => {
    setTabOrder(tabsList.map((tab) => tab.id));
  }, [tabsList]);

  useEffect(() => {
    if (Object.keys(selectedTab).length > 0) {
      const currentItem = document.getElementById(`${selectedTab.id}`);
      if (currentItem) {
        currentItem.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [selectedTab]);

  const handleRemove = useCallback(
    () => {
      setOpenSureDialog(false);
      removeAlerts();
    },
    [openSureDialog],
  );

  const actionUpdateState = () => {
  };

  useEffect(() => {
    return () => {
      dropdownButtonRef.current = null;
      ref.current = null;
    };
  }, []);
  return (
    <div
      className={styles.tabs}
      id="watchlist_tabs"
      ref={dropdownButtonRef}
    >
      {openSureDialog && (
        <ConfirmDialog
          headerTitle={t('alertConfirmRemoveHeader')}
          labelCancel={t('cancel')}
          labelConfirm={t('delete')}
          actionNext={handleRemove}
          actionCancel={() => setOpenSureDialog(false)}
        >
          <>
            <span className={confirmStyles.deleteInfo}>{t('alertsWantToDelete')}</span>
            {getSymbolFormatedByAlertIds(selectedItems).map((symbol, index) => (
              <AlertConfirmDeleteRow
                key={[symbol?.sym, index].join('_')}
                symbolIconPath={symbol?.icon}
                symbolName={symbol?.sym}
              />
            ))}
          </>
        </ConfirmDialog>
      )}

      <TabsBlockMenu
        selectedItems={selectedItems}
        handleCopySymbols={handleCopySymbols}
        setFocus={setFocus}
        search={search}
        resetSearchHandler={resetSearchHandler}
        removeAlerts={() => setOpenSureDialog(true)}
        currentItemSelected={currentItemSelected}
        setCurrentPresetItem={setCurrentPresetItem}
        setIsVisibleFilters={setIsVisibleFilters}
        activeSymbolIndex={activeSymbolIndex}
        openPopUp={isDropDownVisible}
        setOpenPopUp={setDropdownVisible}
        forwardedRef={ref}
        openModalHandler={openModalHandler}
      />
      <div className={`${styles.tabsItemWrapper}`}>
        {screenerView === SCREENER_VIEWS.SCREENER && (
          <AlertTab
            alertTab={alertTab}
            selectTabClickHandler={selectTabClickHandler}
            resetSearchHandler={resetSearchHandler}
          />
        )}
        <WatchlistTabsList
          endDragHandler={endDragHandler}
          search={search}
          tabOrder={tabOrder}
          prevItem={prevItem}
          setPrevItem={setPrevItem}
          tabsList={tabsList}
          changeOrderHandler={changeOrderHandler}
          selectTabClickHandler={selectTabClickHandler}
          resetSearchHandler={resetSearchHandler}
        />
      </div>
      <div>
        <div
          className={`${styles.buttonWrapper} ${screenerView === SCREENER_VIEWS.CHART
            ? styles.buttonWrapperMultiChart : ''}`}
        />
        <DashboardsItemDropdown
          itemType={DialogItemDataTypes.DEEP_LIST}
          savedItems={itemsWatchlist.filter((item) => item?.data?.subType !== ALERTS_TYPE)}
          presetItems={presetsWatchlist}
          isOpen={isDropDownVisible}
          setIsOpen={setDropdownVisible}
          parrentRef={dropdownButtonRef}
          labelPreset={t('deeplistsPresets')}
          labelSaved={t('savedDeeplists')}
          labelAdd={t('deeplist')}
          labelDescription={t('dropDownTitle')}
          iconAddMenu={<IconStar />}
          actionCreateNewCallback={actionCreateNew}
          actionEditCallback={actionEdit}
          selectedItem={isAlertsTab ? alertTab : selectedTab}
          currentItemHandler={actionSetCurrentItem}
          topMargin={0}
          openModalHandler={openModalHandler}
          updateAfterDragActions={actionUpdateState}
        />
      </div>
      <div className={styles.shareWrapper}>
        {screenerView === SCREENER_VIEWS.CHART && (
          <>
            <MultiChartSettingsButton />
            <ButtonChartRefresh />
          </>
        )}
        <ButtonExportScreener
          isFree={false}
          watchList
          screenerView={screenerView}
          disableExport={disableExport}
          selectedItems={selectedItems}
        />
        <TwitterShare />
        <ScreenerMenuDivider />
        <ScreenerMenuOptions />
        <ScreenerMenuDivider />
        <ScreenerMenuView isAlertTab={isAlertsTab} />
      </div>
    </div>
  );
};

WatchlistTabsBlock.propTypes = {
  disableExport: PropTypes.bool.isRequired,
  resetSearchHandler: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  screenerView: PropTypes.string.isRequired,
  isAlertsTab: PropTypes.bool,
  setFocus: PropTypes.func.isRequired,
  handleCopySymbols: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectTabClickHandler: PropTypes.func.isRequired,
  removeAlerts: PropTypes.func.isRequired,
  getSymbolFormatedByAlertIds: PropTypes.func.isRequired,
  activeSymbolIndex: PropTypes.number,
  currentItemSelected: PropTypes.func.isRequired,
  setCurrentPresetItem: PropTypes.func.isRequired,
  setIsVisibleFilters: PropTypes.func.isRequired,
  openModalHandler: PropTypes.func.isRequired,
};

WatchlistTabsBlock.defaultProps = {
  activeSymbolIndex: 0,
  isAlertsTab: false
};

export default React.memo(WatchlistTabsBlock, areEqual);
