import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../../store/auth/actions';
import { errorMessageAction } from '../../../store/account/actions';
import { DasboardsItemsDropdownTypes } from '../types/constants';
import { ScreenItemData } from '../../../utils/Types';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import useItemsMutations from '../../../tanStack/Items/Mutations/itemsMutations';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import { setSelectedTab } from '../../../store/watchlist/actions';
import RootStateTypes from '../../../store/RootStateTypes';

interface IFavoriteHooks {
  (): {
    linkToWatchlist: (id: number, title: string, type: DasboardsItemsDropdownTypes) => void,
    unLinkFromWatchlist: (id: number, title: string, type: DasboardsItemsDropdownTypes) => void
  };
}

const useFavoriteHooks: IFavoriteHooks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);

  const {
    tabsList,
    screenerItems,
    screenerPresetsItems,
    setScreenerMergedItems,
    setPresetsMergedItems
  } = useDashboardItems();
  const {
    linkToWatchListMutation,
    unlLinkFromWatchListMutation
  } = useItemsMutations();
  const itemsNewScreen = screenerItems as ScreenItemData[];
  const presetsNewScreen = screenerPresetsItems as ScreenItemData[];

  const successHandler = (message: string, title: string) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const updateFavorite = (id: number, fav: boolean, type: DasboardsItemsDropdownTypes): void => {
    if (type === DasboardsItemsDropdownTypes.SAVED) {
      const newItems = itemsNewScreen.map((item: ScreenItemData) => {
        if (item?.items?.length) {
          const newSubItems = item.items.map((subItem: ScreenItemData) => {
            if (subItem.id === id) {
              return { ...subItem, isFavorite: fav };
            }
            return subItem;
          });
          return { ...item, items: newSubItems };
        }
        if (item.id === id) {
          return { ...item, isFavorite: fav };
        }
        return item;
      });
      setScreenerMergedItems(newItems);
    }
    if (type === DasboardsItemsDropdownTypes.PRESET) {
      const newPresets = presetsNewScreen.map((item: ScreenItemData) => {
        if (item?.items?.length) {
          const newSubPresets = item.items.map((subPreset: ScreenItemData) => {
            if (subPreset.id === id) {
              return { ...subPreset, isFavorite: fav };
            }
            return subPreset;
          });
          return { ...item, items: newSubPresets };
        }
        if (item.id === id) {
          return { ...item, isFavorite: fav };
        }
        return item;
      });
      setPresetsMergedItems(newPresets);
    }
  };

  const linkToWatchlist = (id: number, title: string, type: DasboardsItemsDropdownTypes): void => {
    updateFavorite(id, true, type);
    linkToWatchListMutation.mutate({
      id,
      successActionHandler: () => {
        successHandler(title, t('watchlistSuccessLinked'));
      },
      errorHandler: (e) => {
        dispatch(errorMessageAction({ errorMessage: e?.message }));
      }
    });
  };
  const unLinkFromWatchlist = (id: number, title: string, type: DasboardsItemsDropdownTypes): void => {
    updateFavorite(id, false, type);
    unlLinkFromWatchListMutation.mutate({
      id,
      type: 'watchList',
      successActionHandler: () => {
        const favoriteItem = tabsList.find((item) => item.data?.subType === UNIVERSE_TYPE);
        if (favoriteItem && selectedTab.parentId === id) {
          dispatch(setSelectedTab(favoriteItem));
        }

        successHandler(title, t('watchlistSuccessUnlinked'));
      },
      errorHandler: (e) => {
        dispatch(errorMessageAction({ errorMessage: e?.message }));
      }
    });
  };

  return {
    linkToWatchlist,
    unLinkFromWatchlist
  };
};
export default useFavoriteHooks;
