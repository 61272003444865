import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../../layout/AppLayout/sass/ScreenerLayout.module.scss';
import AlertsNotionPopUp from '../AlertsNotionPopUp/AlertsNotionPopUp';

const AlertsNotionList = () => {
  const alertNotificationsPopUp = useSelector((state) => state.alertsState.alertNotificationsPopUp, shallowEqual);
  const checkedNotifications = useSelector((state) => state.alertsState.checkedNotifications, shallowEqual);
  const allChecked = alertNotificationsPopUp.length === checkedNotifications.length;

  return alertNotificationsPopUp.length > 0 ? (
    <div className={`${styles.alertsNotionWrapper} ${allChecked ? styles.hideWrapper : ''}`}>
      {alertNotificationsPopUp?.map((alert, index) => (
        (checkedNotifications.indexOf(alert?.id) === -1) && (
          <AlertsNotionPopUp key={alert.id} item={alert} index={index} />
        )
      ))}
    </div>
  ) : <></>;
};

export default AlertsNotionList;
