import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { IWidgetHeadScreenDropdown } from '../types/DashboardComponentsInterfaces';
import { MODAL_TITLES } from '../../../constants/screener';
import { DataPanelItemData, TDashboardItemsData } from '../../../utils/Types';
import DashboardsItemDropdown from '../../DashboardsItemsDropdown/DasboardsItemDropdown';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';
import { ReactComponent as DataPanelIcon } from '../../../assets/images/icons/screener/dataPanel.svg';
import { setSelectedDataPanel } from '../../../store/newScreen/actions';
import styles from '../../DataPanel/sass/DataPanel.module.scss';
import { SELECTED_DATA_PANEL_ID } from '../../../constants/storage';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { DasboardsItemsDropdownTypes } from '../../DashboardsItemsDropdown/types/constants';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import { userPanel } from '../../../constants/account';

const DataPanelWidgetDropdown = ({
  selectedItem, widget, updateWidget, setDropdownTitle,
  setSelectedItem, openModalHandler,
  isDropDownOpen, setIsDropDownOpen,
  dropdownButtonRef
}: IWidgetHeadScreenDropdown): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const { dataPanelsItems, storedDataPanels, dataPanelsItemsPresets } = useDashboardItems();

  const setCurrentDataPanelWidgetHandler = (dataPanel: TDashboardItemsData) => {
    setSelectedItem(dataPanel);
    setDropdownTitle(dataPanel?.title);
    updateWidget({ ...widget, dataPanelId: dataPanel.id });
  };

  const actionSetCurrentItem = (item: string | TDashboardItemsData) => {
    setIsDropDownOpen(false);
    if (item && typeof item !== 'string') {
      setCurrentDataPanelWidgetHandler(item);
    } else {
      const defaultDataPanel = storedDataPanels.find(
        (dataPanel) => dataPanel?.title === userPanel
      ) || storedDataPanels.filter(
        (dataPanel: TDashboardItemsData) => !dataPanel?.isDefault
      )[storedDataPanels.length - 2] || storedDataPanels[0];
      setCurrentDataPanelWidgetHandler(defaultDataPanel);
    }
  };

  const actionEdit = (item: TDashboardItemsData) => {
    dispatch(setSelectedDataPanel(item));
    updateStoreHandler(SELECTED_DATA_PANEL_ID, item?.id);
  };

  const actionCreateNew = (selectedTab: DasboardsItemsDropdownTypes) => {
    if (selectedTab) {
      return openModalHandler(MODAL_TITLES.CREATE_DATA_PANEL_PRESET, true);
    }
    return openModalHandler(MODAL_TITLES.CREATE_DATA_PANEL, true);
  };

  return (
    <DashboardsItemDropdown
      itemType={DialogItemDataTypes.DATA_PANEL}
      savedItems={dataPanelsItems as DataPanelItemData[]}
      presetItems={dataPanelsItemsPresets as DataPanelItemData[]}
      isOpen={isDropDownOpen}
      setIsOpen={setIsDropDownOpen}
      parrentRef={dropdownButtonRef}
      labelPreset={t('presetsDataPanels')}
      labelSaved={t('savedDataPanels')}
      labelAdd={t('dataPanel')}
      labelDescription={t('dataPanels')}
      iconAddMenu={<DataPanelIcon className={styles.iconDataPanel} />}
      actionCreateNewCallback={actionCreateNew}
      actionEditCallback={actionEdit}
      selectedItem={selectedItem}
      currentItemHandler={actionSetCurrentItem}
      topMargin={7}
      updateAfterDragActions={() => undefined}
    />
  );
};

export default DataPanelWidgetDropdown;
