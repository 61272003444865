import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType, StudyShapesPlotPreferences } from '../../charting_library/charting_library';

export default function candlePattern(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Candle Patterns',
    metainfo: {
      id: 'TL-DVCSP@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Candle Patterns',
      description: 'DV - Candle Patterns',
      is_hidden_study: false,
      is_price_study: true,
      format: {
        type: 'inherit'
      },
      styles: {
        plot_0: {
          isHidden: false,
          text: 'Doji',
          title: 'Doji'
        },
        plot_1: {
          isHidden: false,
          text: 'Evening Star',
          title: 'Evening Star'
        },
        plot_2: {
          isHidden: false,
          text: 'Morning Star',
          title: 'Morning Star'
        },
        plot_3: {
          isHidden: false,
          text: 'Shooting Star',
          title: 'Shooting Star'
        },
        plot_4: {
          isHidden: false,
          text: 'H',
          title: 'Hammer'
        },
        plot_5: {
          isHidden: false,
          text: 'IH',
          title: 'Inverted Hammer'
        },
        plot_6: {
          isHidden: false,
          text: 'Bearish Harami',
          title: 'Bearish Harami'
        },
        plot_7: {
          isHidden: false,
          text: 'Bullish Harami',
          title: 'Bullish Harami'
        },
        plot_8: {
          isHidden: false,
          text: 'Bearish Engulfing',
          title: 'Bearish Engulfing'
        },
        plot_9: {
          isHidden: false,
          text: 'Bullish Engulfling',
          title: 'Bullish Engulfing'
        },
        plot_10: {
          isHidden: false,
          text: 'Piercing Line',
          title: 'Piercing Line'
        },
        plot_12: {
          isHidden: false,
          text: 'Bullish Kicker',
          title: 'Bullish Kicker'
        },
        plot_13: {
          isHidden: false,
          text: 'Bearish Kicker',
          title: 'Bearish Kicker'
        },
        plot_14: {
          isHidden: false,
          text: 'Hanging Man',
          title: 'Hanging Man'
        },
        plot_15: {
          isHidden: false,
          text: 'Dark Cloud Cover',
          title: 'Dark Cloud Cover'
        },
      },
      inputs: [
        {
          defval: 0.05,
          id: 'in_0',
          max: 1000000000000,
          min: 0.01,
          name: 'Doji size',
          type: 'float' as StudyInputType.Float
        }
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'chars' as StudyPlotType.Chars
        },
        {
          id: 'plot_1',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_2',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_3',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_4',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_5',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_6',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_7',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_8',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_9',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_10',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_12',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_13',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_14',
          type: 'shapes' as StudyPlotType.Shapes
        },
        {
          id: 'plot_15',
          type: 'shapes' as StudyPlotType.Shapes
        }
      ],
      defaults: {
        inputs: {
          in_0: 0.05
        },
        styles: {
          plot_0: {
            char: '★',
            color: 'gray',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            textColor: 'gray',
            transparency: 35,
            visible: true
          },
          plot_1: {
            color: '#FF0000',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_down',
            textColor: '#FF0000',
            transparency: 35,
            visible: true
          },
          plot_2: {
            color: '#00FF00',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_up',
            textColor: '#00FF00',
            transparency: 35,
            visible: true
          },
          plot_3: {
            color: '#FF0000',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_down',
            textColor: ' #FF0000',
            transparency: 35,
            visible: true
          },
          plot_4: {
            color: '#00FF00',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_diamond',
            textColor: '#00FF00',
            transparency: 35,
            visible: true
          },
          plot_5: {
            color: '#FF0000',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_diamond',
            textColor: '#FF0000',
            transparency: 35,
            visible: true
          },
          plot_6: {
            color: '#FF0000',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_down',
            textColor: '#FF0000',
            transparency: 35,
            visible: true
          },
          plot_7: {
            color: '#00FF00',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_up',
            textColor: '#00FF00',
            transparency: 35,
            visible: true
          },
          plot_8: {
            color: '#FF0000',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_down',
            textColor: '#FF0000',
            transparency: 35,
            visible: true
          },
          plot_9: {
            color: '#00FF00',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_up',
            textColor: '#00FF00',
            transparency: 35,
            visible: true
          },
          plot_10: {
            color: '#00FF00',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_up',
            textColor: '#00FF00',
            transparency: 35,
            visible: true
          },
          plot_12: {
            color: '#00FF00',
            location: 'BelowBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_up',
            textColor: '#00FF00',
            transparency: 35,
            visible: true
          },
          plot_13: {
            color: '#FF0000',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_down',
            textColor: '#FF0000',
            transparency: 35,
            visible: true
          },
          plot_14: {
            color: '#FF0000',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_down',
            textColor: '#FF0000',
            transparency: 35,
            visible: true
          },
          plot_15: {
            color: '#FF0000',
            location: 'AboveBar' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_arrow_down',
            textColor: '#FF0000',
            transparency: 35,
            visible: true
          },
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs) => {
        this._context = context;
        const [dojiSize] = getInputArray({
          inputs,
          length: 1
        });

        const open = mPineJS.Std.open(this._context);
        const high = mPineJS.Std.high(this._context);
        const low = mPineJS.Std.low(this._context);
        const close = mPineJS.Std.close(this._context);

        const openSeries = this._context.new_unlimited_var(open);
        const highSeries = this._context.new_unlimited_var(high);
        const lowSeries = this._context.new_unlimited_var(low);
        const closeSeries = this._context.new_unlimited_var(close);

        const doji = (Math.abs(open - close) <= (high - low) * dojiSize);
        const eveningStar = closeSeries.get(2) > openSeries.get(2) && highSeries.get(2) < lowSeries.get(1) && close < open && close < ((closeSeries.get(2) + openSeries.get(2)) / 2);
        const morningStar = closeSeries.get(2) < openSeries.get(2) && lowSeries.get(2) > highSeries.get(1) && close > open && close > ((closeSeries.get(2) + openSeries.get(2)) / 2);
        const shootingStar = (openSeries.get(1) < closeSeries.get(1) && open > closeSeries.get(1) && high - Math.max(open, close) > Math.abs(open - close) * 3 && Math.min(close, open) - low < Math.abs(open - close));
        const hammer = (((high - low) > 3 * Math.abs(open - close)) && ((close - low) / (high - low) > 0.6) && ((open - low) / (high - low) > 0.6));
        const invertedHammer = (((high - low) > 3 * Math.abs(open - close)) && ((high - close) / (high - low) > 0.6) && ((high - open) / (high - low) > 0.6));
        const bearishHarami = closeSeries.get(1) > openSeries.get(1) && close < closeSeries.get(1) && open < closeSeries.get(1) && low > openSeries.get(1);
        const bullishHarami = closeSeries.get(1) < openSeries.get(1) && close > closeSeries.get(1) && open > closeSeries.get(1) && high < openSeries.get(1);
        const bearishEngulfing = closeSeries.get(1) > openSeries.get(1) && open > closeSeries.get(1) && close < openSeries.get(1);
        const bullishEngulfing = open < closeSeries.get(1) && close > openSeries.get(1) && openSeries.get(1) > closeSeries.get(1);
        const piercingLine = (closeSeries.get(1) < openSeries.get(1)) && (open < lowSeries.get(1)) && (close > ((closeSeries.get(1) + openSeries.get(1)) / 2)) && (CR(close, high, low) > .75);
        const bullishKicker = openSeries.get(1) > closeSeries.get(1) && open > openSeries.get(1) && close > open;
        const bearishKicker = openSeries.get(1) < closeSeries.get(1) && open < openSeries.get(1) && close < open;
        const hangingMan = (((high - low > 4 * (open - close)) && ((close - low) / (high - low) > 0.75) && ((open - low) / (high - low) > 0.75)) && highSeries.get(1) < open && highSeries.get(2) < open);
        const darkCloudCover = closeSeries.get(1) > openSeries.get(1) && open > closeSeries.get(1) && close < ((closeSeries.get(1) + openSeries.get(1)) / 2) && CR(close, high, low) < .25;

        return [
          doji ? 1 : NaN,
          eveningStar ? 1 : NaN,
          morningStar ? 1 : NaN,
          shootingStar ? 1 : NaN,
          hammer ? 1 : NaN,
          invertedHammer ? 1 : NaN,
          bearishHarami ? 1 : NaN,
          bullishHarami ? 1 : NaN,
          bearishEngulfing ? 1 : NaN,
          bullishEngulfing ? 1 : NaN,
          piercingLine ? 1 : NaN,
          bullishKicker ? 1 : NaN,
          bearishKicker ? 1 : NaN,
          hangingMan ? 1 : NaN,
          darkCloudCover ? 1 : NaN,
        ];
      };
    }
  };
}

function CR(close: number, high: number, low: number): number {
  return Math.min(1, Math.max(0, high === low ? 1 : (close - low) / (high - low)));
}
