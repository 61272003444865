import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './sass/WatchListModals.module.scss';
import { ADD_BUTTON_TITLE, addSymbolButtons, UNIVERSE_TYPE } from '../../constants/watchlist';
import Button from '../Button/Button';
import { clearSelectedItems, setModalType } from '../../store/watchlist/actions';
import TextField from '../TextField/TextField';
import useWatchList from '../../utils/hooks/useWatchList';
import { prepareSelectedSymbols } from '../../utils/watchListsHelper';
import { VIEW_TYPES } from '../../constants/screener';
import { useDashboardItems } from '../../context/DasboardItemsContext/DashboardItemsProvider';

let previousList;

const AddSymbolModalButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { addNewSymbolToWatchlist, deleteSymbolsFromWatchlist } = useWatchList();
  const [disabledButtonFlag, setDisabledButtonFlag] = useState(false);

  const selectedSymbolsList = useSelector((state) => state.watchlistState.selectedSymbolsList, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);

  const { tabsList } = useDashboardItems();

  useEffect(() => {
    const filtersData = prepareSelectedSymbols(selectedTab, symbolsList);
    previousList = filtersData.map((item) => item.sym);
  }, []);

  useEffect(() => {
    setDisabledButtonFlag(
      previousList?.toString() === selectedSymbolsList?.map((subItem) => subItem.sym)?.toString()
    );
  }, [selectedSymbolsList]);

  const getAvailableTabList = () => tabsList?.filter(
    (item) => (!item?.parentId
      && item?.data?.subType !== UNIVERSE_TYPE && !!item?.data?.color1 && item?.id !== selectedTab?.id)
  );

  const extractIndicesFromSymbols = (symbols) => {
    return symbols.map((symbol) => symbol.index);
  };

  const deleteSymbolsFromTargetWatchlist = (selectedSymbols, selectedSymbolsIndexList) => {
    const filterAndDeletePromises = getAvailableTabList()
      .filter((watchlistItem) => {
        const thirdLevelFilters = watchlistItem.data?.filters.flat(2)[2];
        return thirdLevelFilters?.some((filterItem) => selectedSymbols.some((symbol) => symbol.index === filterItem));
      })
      .map((watchlistItem) => deleteSymbolsFromWatchlist(selectedSymbolsIndexList, watchlistItem, false));

    return Promise.all(filterAndDeletePromises);
  };

  const actionHandler = () => {
    const selectedSymbolsIndexList = extractIndicesFromSymbols(selectedSymbolsList);

    if (selectedTab?.viewType === VIEW_TYPES.TARGET) {
      deleteSymbolsFromTargetWatchlist(selectedSymbolsList, selectedSymbolsIndexList)
        .then(() => {
          addNewSymbolToWatchlist(selectedSymbolsList, selectedTab);
        });
    } else {
      addNewSymbolToWatchlist(selectedSymbolsList, selectedTab);
    }
  };

  return (
    <div className={styles.applyButtonWrapper}>
      {addSymbolButtons.map((item) => (
        <Button
          key={item.title}
          handleClick={() => {
            if (item.title === ADD_BUTTON_TITLE) {
              dispatch(clearSelectedItems());
              actionHandler();
            }
            dispatch(setModalType(''));
          }}
          disabled={item.title === ADD_BUTTON_TITLE && (!selectedSymbolsList?.length || disabledButtonFlag)}
          buttonClass={styles[`${item.textClass}`]}
        >
          <TextField text={t(item.title)} styleType={item.textClass} />
        </Button>
      ))}
    </div>
  );
};

export default AddSymbolModalButtons;
