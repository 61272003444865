import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { TDashboardItemsData } from '../../../utils/Types';
import { MAX_LENGTH_COUNT } from '../../../constants/screener';
import { getLabelModal } from '../../../utils/helperNewScreenModal';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import { DialogItemDataTypes } from '../types/constants';
import TextField from '../../TextField/TextField';
import TextArea from '../../TextArea/TextArea';
import getNewTitle from '../../../utils/getDuplicateTitle';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';

type TDashboardItemExt = TDashboardItemsData & { folderId?: string | null };

interface IDialogContentDuplicate {
  onChange: (item: TDashboardItemExt) => void,
  item: TDashboardItemsData,
  isFirstDuplicate: boolean,
  theme: string,
  cbFolderId: string | null,
  createNewFolder: () => void,
  type: DialogItemDataTypes
}
const DialogContentDuplicate = ({
  onChange, item, theme, createNewFolder, cbFolderId, type, isFirstDuplicate
}: IDialogContentDuplicate): React.ReactElement => {
  const { t } = useTranslation();
  const [valueSelect, setValueSelect] = useState<string>('');
  const [description, setDescription] = useState(
    item?.data?.description || ''
  );

  const {
    tabsList,
    columns,
    storedDataPanels,
    screeners,
    dashboards,
    screenerItems,
    itemsWatchlist,
    dataPanelsItems,
    dashboardsItems,
    itemsColumnSets,
  } = useDashboardItems();

  const currentItem = item as TDashboardItemExt;

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange({
      ...currentItem,
      title: e.target.value,
      folderId: valueSelect,
      data: {
        ...currentItem.data,
        description
      }
    });
  };

  const handleSelect = (value: string): void => {
    setValueSelect(value);
    onChange({
      ...currentItem,
      folderId: value
    });
  };

  const addFolderHandler = (): void => {
    createNewFolder();
  };

  useEffect(() => {
    if (cbFolderId) {
      setValueSelect(cbFolderId);
    }
  }, [cbFolderId]);

  const getData = () => {
    switch (type) {
      case (DialogItemDataTypes.DEEP_LIST): {
        return itemsWatchlist;
      }
      case (DialogItemDataTypes.SCREENER): {
        return screenerItems;
      }
      case (DialogItemDataTypes.DATA_PANEL): {
        return dataPanelsItems;
      }
      case (DialogItemDataTypes.DASHBOARD): {
        return dashboardsItems;
      }
      case (DialogItemDataTypes.COLUMN_SET): {
        return itemsColumnSets;
      }
      default: return screenerItems;
    }
  };

  const getItems = () => {
    switch (type) {
      case (DialogItemDataTypes.DEEP_LIST): {
        return tabsList;
      }
      case (DialogItemDataTypes.SCREENER): {
        return screeners;
      }
      case (DialogItemDataTypes.DATA_PANEL): {
        return storedDataPanels;
      }
      case (DialogItemDataTypes.DASHBOARD): {
        return dashboards;
      }
      case (DialogItemDataTypes.COLUMN_SET): {
        return columns;
      }
      default: return screeners;
    }
  };

  useEffect(() => {
    if (cbFolderId) {
      onChange({
        ...currentItem,
        folderId: cbFolderId
      });
    }
  }, [cbFolderId]);

  useEffect(() => {
    const newTitle = getNewTitle(currentItem, getItems() as TDashboardItemsData[]);
    if (newTitle && newTitle !== currentItem?.title && isFirstDuplicate) {
      onChange({
        ...currentItem,
        title: newTitle,
      });
    }
  }, []);

  return (
    <div className={`${styles.dialogContent} ${theme}`}>
      <div className={`${styles.dialogContentFieldItem} ${styles.cleanBoarder}`}>
        <label className={styles.dialogContentFieldItemLabel} htmlFor="folderName">
          {getLabelModal(currentItem?.type, t)}
        </label>
        <Input
          type="text"
          autoFocus
          inputClass={styles.dialogContentFieldItemInput}
          handlerChange={handleChanges}
          placeholder={t('upOnVolume')}
          value={currentItem?.title || ''}
          maxLength={MAX_LENGTH_COUNT}
        />
      </div>
      <div className={styles.dialogContentFieldItem}>
        <Select
          wrapperClass={styles.dialogContentFieldItemSelect}
          title={t('folder')}
          valueSelect={valueSelect}
          setValueSelect={handleSelect}
          data={getData()}
          placeholder={t('selectedFolder')}
          addFolderHandler={addFolderHandler}
        />
      </div>
      <div className={styles.dialogContentInputWrapper}>
        <TextField text={t('description')} styleType="createFolderModalBody" />
        <TextArea
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.currentTarget.value)}
          value={description}
        />
      </div>
    </div>
  );
};

export default DialogContentDuplicate;
