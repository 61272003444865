import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';

import { DATA_PANEL_ACTION_TYPES } from '../../DataPanel/dataPanelConstants';
import useDataPanelsItems from '../../DataPanel/hooks/useDataPanelItems';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { DATA_PANEL_GRID_TYPE } from '../../../constants/tvWidgetOptions';
import RootStateTypes from '../../../store/RootStateTypes';
import { DataPanelInnerData, DataPanelItemData, TDashboardItemsData } from '../../../utils/Types';
import { setDataPanelEditMenuOpen, setWidgetMenuOpen } from '../../../store/dashboards/slice';
import { useDashboardItems } from '../../../context/DasboardItemsContext/DashboardItemsProvider';
import { userPanel } from '../../../constants/account';

const useDataPanelWidget: (widget: Widget) => {
  setEditMode: (editModeNew: boolean) => void;
  addPanelOpen: boolean;
  setDataPanelGridType: (type: string) => void;
  editMode: boolean;
  togglePanelAdd: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentDataPanels: any;
  checkDefault: () => boolean
} = (widget) => {
  const { updateStoreHandler } = useLastStateUpdateStore();
  const { saveDataPanels } = useDataPanelsItems(widget);
  const dispatch = useDispatch();
  const [addPanelOpen, setAddPanelOpen] = useState(false);
  const [currentDataPanels, setCurrentDataPanels] = useState<DataPanelInnerData | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [currentHistoryDataPanels, setCurrentHistoryDataPanels] = useState<DataPanelInnerData | null>(null);
  const [notClean, setNotClean] = useState(false);

  const { storedDataPanels, storedDataPanelsPresetsList } = useDashboardItems();

  const dataPanels = storedDataPanels as DataPanelItemData[];
  const dataPanelsPresets = storedDataPanelsPresetsList as DataPanelItemData[];

  const openPanelDataAdd = useSelector((state: RootStateTypes) => state.accountState?.openPanelDataAdd, shallowEqual);
  const [selectedDataPanel, setSelectedDataPanel] = useState<DataPanelItemData | undefined>(
    [...dataPanels, ...dataPanelsPresets].find((dataPanel) => dataPanel?.id === widget?.dataPanelId)
  );

  const setDataPanelGridType = (type: string) => {
    updateStoreHandler(DATA_PANEL_GRID_TYPE, type);
  };

  const togglePanelAdd = () => {
    dispatch(setWidgetMenuOpen(false));
    setEditMode(!editMode);
    dispatch(setDataPanelEditMenuOpen(!editMode));
  };

  const setEditModeNew = (editModeNew: boolean) => {
    dispatch(setWidgetMenuOpen(false));
    setEditMode(editModeNew);
    dispatch(setDataPanelEditMenuOpen(editModeNew));
  };

  const checkDefault = () => {
    const defaultDataPanel = dataPanels?.find((dataPanel: DataPanelItemData) => dataPanel?.isDefault)
      || dataPanels?.find((dataPanel: DataPanelItemData) => dataPanel?.title === 'Default');
    if (!selectedDataPanel?.data) return true;
    return JSON.stringify(selectedDataPanel?.data) === JSON.stringify(defaultDataPanel?.data);
  };

  const checkDiff = () => {
    if (notClean && !currentHistoryDataPanels) return false;
    return JSON.stringify(selectedDataPanel?.data) !== JSON.stringify(currentHistoryDataPanels);
  };

  const depsOne = [...dataPanels, ...dataPanelsPresets].find(
    (dataPanel) => dataPanel?.id === widget?.dataPanelId
  )?.data?.leftColumn?.length;

  const depsTwo = [...dataPanels, ...dataPanelsPresets].find(
    (dataPanel) => dataPanel?.id === widget?.dataPanelId
  )?.data?.rightColumn?.length;

  const filterDataPanel = (dataPanelToFilter: DataPanelItemData) => {
    if (dataPanelToFilter?.data) {
      return (
        {
          ...dataPanelToFilter,
          data: {
            ...dataPanelToFilter?.data,
            leftColumn: dataPanelToFilter?.data?.leftColumn.filter((data) => data),
            rightColumn: dataPanelToFilter?.data?.rightColumn.filter((data) => data)
          }
        });
    } return dataPanelToFilter;
  };

  useEffect(() => {
    setSelectedDataPanel([...dataPanels, ...dataPanelsPresets].find(
      (dataPanel) => dataPanel?.id === widget?.dataPanelId
    ));
  }, [widget?.dataPanelId, dataPanels, depsOne, depsTwo, dataPanelsPresets]);

  useEffect(() => {
    if (!openPanelDataAdd && addPanelOpen && !checkDefault() && checkDiff()) {
      saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
      setCurrentHistoryDataPanels({ ...selectedDataPanel?.data } as DataPanelInnerData);
      setNotClean(false);
    }
    setAddPanelOpen(openPanelDataAdd);
  }, [openPanelDataAdd]);

  useEffect(() => {
    if (selectedDataPanel) {
      setNotClean(!currentDataPanels);
      setCurrentDataPanels(filterDataPanel(selectedDataPanel)?.data as DataPanelInnerData);
    } else {
      const defaultDataPanel = dataPanels.find(
        (dataPanel) => dataPanel?.title === userPanel
      ) || dataPanels.filter(
        (dataPanel: TDashboardItemsData) => !dataPanel?.isDefault
      )[dataPanels.length - 2] || dataPanels[0];
      setNotClean(!currentDataPanels);
      setCurrentDataPanels(filterDataPanel(defaultDataPanel)?.data as DataPanelInnerData);
    }
  }, [selectedDataPanel, dataPanels, selectedDataPanel?.data?.leftColumn?.length,
    selectedDataPanel?.data?.rightColumn?.length, widget?.dataPanelId]);

  return {
    setDataPanelGridType,
    togglePanelAdd,
    addPanelOpen,
    checkDefault,
    currentDataPanels,
    editMode,
    setEditMode: setEditModeNew,
  };
};

export default useDataPanelWidget;
