import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './sass/NewScreenButtonGroup.module.scss';
import { ReactComponent as ClearButton } from '../../assets/images/icons/removeScreen.svg';
import { setMarketsFilterScreenerAction, setSortData } from '../../store/screener/actions';
import { setRequestStateAction } from '../../store/filters/slice';
import { setSelectedScreen } from '../../store/newScreen/actions';
import { changeTabAction } from '../../store/tableData/slice';
import useGlobalSearch from '../../utils/hooks/useGlobalSearch';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import constants from '../../constants/filters';

const areEqual = (prevProps, nextProps) => {
  return prevProps.currentItemSelected === nextProps.currentItemSelected;
};

const ClearScreenButton = ({
  currentItemSelected, setCurrentPresetItem, setIsVisibleFilters, activeSymbolIndex
}) => {
  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);
  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const {
    searchHeaderValue,
  } = useGlobalSearch();

  const clearScreensHandler = (event) => {
    event.stopPropagation();
    dispatch(changeTabAction());
    dispatch(setSelectedScreen({}));
    setCurrentPresetItem(-1);
    currentItemSelected(0);
    dispatch(setSortData(userSettings?.screenerSorting || []));
    dispatch(setRequestStateAction([]));
    if (!searchHeaderValue) {
      dispatch(setMarketsFilterScreenerAction(activeSymbolIndex
        ? { data: [[[0, 0, [activeSymbolIndex]]]] } : { data: 0 }));
    }
    setIsVisibleFilters(false);
    updateStoreHandler(constants.selectedScreenId, 0);
  };

  return (
    <div aria-hidden onClick={clearScreensHandler}>
      <ClearButton className={styles.clearIcon} />
    </div>
  );
};

ClearScreenButton.propTypes = {
  setIsVisibleFilters: PropTypes.func.isRequired,
  setCurrentPresetItem: PropTypes.func.isRequired,
  currentItemSelected: PropTypes.func.isRequired,
  activeSymbolIndex: PropTypes.number
};

ClearScreenButton.defaultProps = {
  activeSymbolIndex: 0,
};

export default React.memo(ClearScreenButton, areEqual);
