import MULTI_CHART from './types';

export const setMultiChartSymbolsList = (payload) => ({
  type: MULTI_CHART.SET_SYMBOL_LIST,
  payload
});

export const setMultiChartRange = (payload) => ({
  type: MULTI_CHART.SET_CURRENT_RANGE,
  payload
});

export const setMultiChartAdditionalData = (payload) => ({
  type: MULTI_CHART.SET_ADDITIONAL_DATA,
  payload
});

export const setMultiChartQueryId = (payload) => ({
  type: MULTI_CHART.SET_QUERY_ID_MULTICHART,
  payload
});

export const setMultiChartIsMounted = (payload) => ({
  type: MULTI_CHART.SET_IS_MOUNTED_CHART,
  payload
});

export const setSelectedSymbolMultiChart = (payload) => ({
  type: MULTI_CHART.SET_SELECTED_SYMBOL_MULTICHART,
  payload
});

export const setScrollToRow = (payload) => ({
  type: MULTI_CHART.SET_SCROLL_ROW_MULTICHART,
  payload
});

export const refreshFlagPatch = () => ({
  type: MULTI_CHART.REFRESH_FLAG_PATCH
});

export const clearMultiChartState = () => ({ type: MULTI_CHART.CLEAR_STATE });
